// Library
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Call, Conversation, ConversationModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import CommunicationInboxTopLine from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxTopLine';
import ConversationProjectAndCustomerName from 'modules/Communication/CommunicationInbox/ConversationList/components/ConversationProjectAndCustomerName';

const Container = Styled.View`
`;

const CallDescription = Styled.Text`
  ${Typography.Responsive.Body}
`;

const CommunicationInboxCallItem = ({
  responsive,
  conversation,
}: {
  responsive: ResponsiveType;
  conversation: ConversationModel;
}) => {
  const {call} = conversation;
  if (!call) {
    return null;
  }
  const {text: callDescription} = Conversation.getStatusInfo(conversation);

  return (
    <Container>
      <CommunicationInboxTopLine
        conversation={conversation}
        responsive={responsive}
        fromName={
          Call.getLeadDisplayName(call) || conversation.project.client.primaryContact.fullName
        }
        // Conversation happens inside component
        sentAt={Call.getDisplayTime(call, (date: string) => date)}
      />
      <Space height={8} />
      <ConversationProjectAndCustomerName project={conversation.project} responsive={responsive} />
      <Space height={8} />
      <CallDescription responsive={responsive} numberOfLines={1}>
        {callDescription} Call
      </CallDescription>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxCallItem.fragment = gql`
  ${ConversationProjectAndCustomerName.fragment}
  ${Call.getLeadDisplayName.fragment}
  ${Call.getDisplayTime.fragment}
  ${Conversation.getStatusInfo.fragment}
  ${CommunicationInboxTopLine.fragment}

  fragment CommunicationInboxCallItem on Conversation {
    id
    ...CommunicationInboxTopLine
    ...Conversation_getStatusInfo
    call {
      id
      direction
      ...Call_getDisplayTime
      ...Call_getLeadDisplayName
    }
    project {
      id
      client {
        id
        primaryContact {
          id
          fullName
        }
      }
      ...ConversationProjectAndCustomerName
    }
  }
`;

export default CommunicationInboxCallItem;
