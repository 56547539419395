// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useToast} from '@supermove/hooks';
import {Email, UserModel, UserFlowRun, CommunicationModel, AccountGrant} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {openNewTab} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import {CommunicationDirectionType} from '@shared/modules/Communication/enums/CommunicationDirection';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import UserFlowRunStatus from '@shared/modules/UserFlows/enums/UserFlowRunStatus';
import CustomerEmailThreadItemAttachments from 'modules/Communication/Email/components/CustomerEmailThreadItemAttachments';
import {toTextFromHtml} from 'modules/Communication/Email/utils/toTextFromHtml';
import CommunicationItemStatus from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationItemStatus';
import CommunicationsListItemWrapper from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsListItemWrapper';

const Row = Styled.View`
  flex-direction: row;
`;

const BodyContainer = Styled.View`
  max-height: 180px;
  overflow: hidden;
`;

const HeaderContainer = Styled.View`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const SubjectText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const CountText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Micro}
  color: ${({color}) => color};
`;

const BodyText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Body}
  color: ${({color}) => color};
`;

const ColorDot = Styled.View<{color: string}>`
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background-color: ${({color}) => color};
`;

const ProgressText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Body}
  color: ${({color}) => color};
`;

const Touchable = Styled.Touchable``;

const CommunicationsListItemEmail = ({
  communication,
  direction,
  viewer,
}: {
  communication: CommunicationModel;
  direction: CommunicationDirectionType;
  viewer: UserModel;
}) => {
  const responsive = useResponsive();
  const copyDocumentFlowLinkSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Document flow link copied to clipboard.',
  });
  const {email} = communication;
  if (!email) {
    return null;
  }
  const hasError = email?.status === 'ERROR';
  const subjectColor = direction === 'INBOUND' ? colors.gray.primary : colors.gray.background;
  const bodyColor = direction === 'INBOUND' ? colors.gray.secondary : colors.gray.background;

  const accountGrantInfo = AccountGrant.accountGrantInfo(viewer);

  const emailThreadLink = `/projects/${email.thread.project.uuid}/emails/${email.thread.uuid}?source=${ProjectWidgetKind.CONVERSATIONS}`;
  const handleOpenEmail = () => {
    openNewTab(emailThreadLink);
  };
  const handleReply = () => {
    openNewTab(`${emailThreadLink}&reply=true`);
  };
  const handleCopyDocumentFlowLink = () => {
    const {userFlowRun} = email.thread;
    navigator.clipboard.writeText(UserFlowRun.getLink(userFlowRun));
    copyDocumentFlowLinkSuccessToast.handleToast();
  };

  const hasUserFlowRun = !!email.thread.userFlowRun;

  return (
    <CommunicationsListItemWrapper
      direction={direction}
      name={email.fromName}
      sentInfo={<CommunicationItemStatus communication={communication} responsive={responsive} />}
      displayTime={Email.getDisplayTime(email)}
      backgroundColorOverride={hasError ? colors.orange.accent : undefined}
    >
      <HeaderContainer>
        <Row style={{flex: 1, alignItems: 'center'}}>
          <SubjectText
            responsive={responsive}
            numberOfLines={1}
            color={hasError ? colors.gray.primary : subjectColor}
          >
            {email.subject}
          </SubjectText>
          {email.thread.allEmailsCount > 1 && !hasUserFlowRun && (
            <React.Fragment>
              <Space width={8} />
              <CountText
                style={{flex: 1}}
                responsive={responsive}
                color={hasError ? colors.gray.primary : subjectColor}
              >
                ({email.thread.allEmailsCount})
              </CountText>
            </React.Fragment>
          )}
        </Row>
        <Row>
          {accountGrantInfo.hasSendableAccountGrant && (
            <React.Fragment>
              <Space width={8} />
              <Touchable onPress={handleReply}>
                <Icon source={Icon.Reply} color={colors.gray.tertiary} />
              </Touchable>
            </React.Fragment>
          )}
        </Row>
      </HeaderContainer>
      {hasUserFlowRun && (
        <Row style={{alignItems: 'center'}}>
          <CountText responsive={responsive} color={hasError ? colors.gray.primary : subjectColor}>
            ({email.thread.allEmailsCount} messages)
          </CountText>
          <Space width={8} />
          <Row style={{cursor: 'pointer'}} onClick={handleCopyDocumentFlowLink}>
            <Row style={{alignItems: 'center'}}>
              <ColorDot color={UserFlowRun.getStepsProgressDotColor(email.thread.userFlowRun)} />
              <Space width={8} />
              <ProgressText
                responsive={responsive}
                color={hasError ? colors.gray.primary : subjectColor}
              >
                {UserFlowRun.getStepsProgressText(email.thread.userFlowRun)}
              </ProgressText>
            </Row>
            <Space width={8} />
            <Badge
              label={UserFlowRunStatus.convertUserFlowRunStatusToLabel(
                email.thread.userFlowRun.status,
              )}
              color={UserFlowRunStatus.convertUserFlowRunStatusToColor(
                email.thread.userFlowRun.status,
              )}
              isResponsive
            />
          </Row>
        </Row>
      )}
      <Space height={8} />
      <BodyContainer>
        <BodyText responsive={responsive} color={hasError ? colors.gray.primary : bodyColor}>
          {toTextFromHtml(email.html, '\n')}
        </BodyText>
      </BodyContainer>
      {email.emailAttachments.length > 0 && (
        <React.Fragment>
          <Space height={8} />
          <CustomerEmailThreadItemAttachments
            emailAttachments={email.emailAttachments}
            emailStatus={email.status}
            onPress={handleOpenEmail}
          />
        </React.Fragment>
      )}
      <Space height={4} />
      <Row style={{justifyContent: 'center'}}>
        <TertiaryButton
          text={'View More'}
          iconRight={Icon.ChevronRight}
          textColor={hasError ? colors.gray.primary : bodyColor}
          isWidthOfContainer
          onPress={handleOpenEmail}
        />
      </Row>
    </CommunicationsListItemWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationsListItemEmail.fragment = gql`
  ${Email.getDisplayTime.fragment}
  ${AccountGrant.accountGrantInfo.fragment}
  ${CustomerEmailThreadItemAttachments.fragment}
  ${UserFlowRun.getLink.fragment}
  ${UserFlowRun.getStepsProgressDotColor.fragment}
  ${UserFlowRun.getStepsProgressText.fragment}
  ${CommunicationItemStatus.fragment}

  fragment CommunicationsListItemEmail_Email on Communication {
    id
    ...CommunicationItemStatus
    email {
      id
      fromName
      status
      subject
      html
      thread {
        id
        allEmailsCount
        project {
          id
          uuid
        }
        userFlowRun {
          id
          status
          isCompleted
          ...UserFlowRun_getLink
          ...UserFlowRun_getStepsProgressDotColor
          ...UserFlowRun_getStepsProgressText
        }
      }
      emailAttachments {
        id
      }
      ...Email_getDisplayTime
      ...CustomerEmailThreadItemAttachments
    }
  }

  fragment CommunicationsListItemEmail_Viewer on User {
    id
    ...AccountGrant_accountGrantInfo
  }
`;

export default CommunicationsListItemEmail;
