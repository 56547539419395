// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ResponsiveType,
  useNavigationDOM,
  usePopover,
  useQuery,
  useResponsive,
  useScrollView,
  useEffect,
  useState,
} from '@supermove/hooks';
import {AccountGrant, EmailModel, ProjectModel, ThreadModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import EmailForm, {EmailFormType} from '@shared/modules/Email/forms/EmailForm';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import Line from 'modules/App/components/Line';
import EmailItem from 'modules/Communication/Email/components/EmailItem';
import EmailProjectInfo from 'modules/Communication/Email/components/EmailProjectInfo';
import EmailReply from 'modules/Communication/Email/components/EmailReply';
import NextPreviousThreadControls from 'modules/Communication/Email/components/NextPreviousThreadControls';
import {hasMultipleRecipients} from 'modules/Communication/Email/utils/hasMultipleRecipients';
import {ReplyType} from 'modules/Communication/Email/utils/replyType';
import EmailGrantExpiredBanner from 'modules/Email/Project/components/EmailGrantExpiredBanner';
import ProjectSendEmailOptionsPopover from 'modules/Project/components/ProjectSendEmailOptionsPopover';

const Container = Styled.View`
  flex: 1;
`;

const SubjectAndNavigationRow = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${({responsive}) => (responsive.mobile ? '16px' : '24px')};
  margin-bottom: ${({responsive}) => (responsive.mobile ? '16px' : '0px')};
`;

const SubjectNavigationSectionContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const SubjectNumberEmails = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.secondary};
`;

const Subject = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const ErrorBannerContainer = Styled.View`
  margin-horizontal: 24px;
`;

const EmailReplyContainer = Styled.View`
  margin-horizontal: 24px;
`;

const SubjectAndNavigation = ({
  subject,
  responsive,
  currentThreadId,
  project,
  numberOfEmails,
  handleCollapseExpandAll,
  refetch,
  isLoading,
}: {
  subject?: string;
  responsive: ResponsiveType;
  currentThreadId: string;
  project: ProjectModel;
  numberOfEmails: number;
  handleCollapseExpandAll: () => void;
  refetch: () => void;
  isLoading: boolean;
}) => {
  return (
    <React.Fragment>
      <SubjectAndNavigationRow responsive={responsive}>
        <SubjectNavigationSectionContainer style={{justifyContent: 'flex-start', flex: 3}}>
          <Subject responsive={responsive}>
            {subject}
            {numberOfEmails > 1 ? (
              <SubjectNumberEmails responsive={responsive}>
                {' '}
                ({numberOfEmails} messages)
              </SubjectNumberEmails>
            ) : null}
          </Subject>
        </SubjectNavigationSectionContainer>

        <SubjectNavigationSectionContainer style={{justifyContent: 'flex-end'}}>
          <TertiaryButton onPress={refetch}>
            <Icon source={Icon.Rotate} color={colors.gray.secondary} rotate={isLoading} />
          </TertiaryButton>
          <Space width={8} />
          {numberOfEmails > 1 ? (
            <React.Fragment>
              <IconButton
                source={Icon.ArrowsToLine}
                onPress={handleCollapseExpandAll}
                isSecondary
              />
              <Space width={14} />
            </React.Fragment>
          ) : null}
          <NextPreviousThreadControls
            responsive={responsive}
            currentThreadId={currentThreadId}
            project={project}
          />
        </SubjectNavigationSectionContainer>
      </SubjectAndNavigationRow>
      {responsive.mobile ? <Line /> : null}
    </React.Fragment>
  );
};

const getInitialFormForReply = (
  replyType: ReplyType,
  email: EmailModel,
  viewerId: string,
  project: ProjectModel,
): EmailFormType => {
  if (replyType === 'Reply') {
    return EmailForm.newForReply({
      project,
      email,
      viewerId,
    });
  }
  if (replyType === 'ReplyAll') {
    return EmailForm.newForReplyAll({
      project,
      email,
      viewerId,
    });
  }
  return EmailForm.newForForward({
    project,
    email,
    viewerId,
  });
};

const NewEmailButton = () => {
  const emailThreadSendEmailOptionsPopover = usePopover({
    name: 'Email Thread Send Email Options Popover',
  });

  return (
    <ProjectSendEmailOptionsPopover actionMenuPopover={emailThreadSendEmailOptionsPopover}>
      <Button
        iconLeft={Icon.Plus}
        text='New Email'
        onPress={emailThreadSendEmailOptionsPopover.handleToggle}
        isResponsive
      />
    </ProjectSendEmailOptionsPopover>
  );
};

const ReplyButton = ({
  onPress,
  latestEmail,
}: {
  onPress: (replyType: ReplyType) => void;
  latestEmail: EmailModel;
}) => {
  const emailHasMultipleRecipients = hasMultipleRecipients(latestEmail);
  return (
    <SecondaryButton
      text={emailHasMultipleRecipients ? 'Reply All' : 'Reply'}
      onPress={() => onPress(emailHasMultipleRecipients ? 'ReplyAll' : 'Reply')}
      isResponsive
    />
  );
};

const EmailThreadContent = ({
  thread,
  viewer,
  refetch,
  isLoading,
}: {
  thread: ThreadModel;
  viewer: UserModel;
  refetch: () => void;
  isLoading: boolean;
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {ref, handleScrollToEnd} = useScrollView();
  const {subject} = thread.allEmails[0] ?? {};
  const emails = thread.allEmails;
  const latestEmail = emails[emails.length - 1];
  // Array that controls which emails and expanded/collapsed by index.
  // Initially all emails are collapsed except the last one
  // Ex if we have 3 emails, this will be [false, false, true]
  const [collapsedEmails, setCollapsedEmails] = useState(() =>
    emails.map((_, index) => index !== emails.length - 1),
  );
  const [replyInitialForm, setReplyInitialForm] = useState<EmailFormType | undefined>();

  useEffect(() => {
    if (emails.length !== collapsedEmails.length) {
      setCollapsedEmails(emails.map((_, index) => collapsedEmails[index] ?? false));
    }
  }, [emails.length]);

  const handleCollapseExpandAll = () => {
    const allCollapsedExceptLast = collapsedEmails.every(
      (isCollapsed, index) => isCollapsed || index === emails.length - 1,
    );
    if (allCollapsedExceptLast) {
      setCollapsedEmails(collapsedEmails.map(() => false));
    } else {
      // Collapse all but the last item
      setCollapsedEmails(collapsedEmails.map((_, index) => index !== emails.length - 1));
    }
  };

  const toggleCollapse = (index: number) => {
    if (emails.length > 1) {
      setCollapsedEmails(collapsedEmails.map((value, i) => (i === index ? !value : value)));
    }
  };

  const handleReply = (newEmailForm: EmailFormType) => {
    setReplyInitialForm(newEmailForm);
    setTimeout(() => handleScrollToEnd({animated: true}), 300);
  };

  useEffect(() => {
    if (params.reply) {
      handleReply(getInitialFormForReply('Reply', latestEmail, viewer.id, thread.project));
    }
  }, []);

  const handleClose = () => {
    if (navigator.canGoBack()) {
      navigator.goBack();
    } else if (thread.project.isStorage) {
      navigator.replace(`/storage/projects/${thread.project.uuid}`);
    } else {
      const source =
        params.source === ProjectWidgetKind.CONVERSATIONS
          ? ProjectWidgetKind.CONVERSATIONS
          : ProjectWidgetKind.EMAILS;
      navigator.replace(`/projects/${thread.project.uuid}/view?widget=${source}`);
    }
  };

  const accountGrantInfo = AccountGrant.accountGrantInfo(viewer);
  const accountGrantEmail = viewer.accountGrantToSendFrom?.identifier;

  return (
    <Container>
      <Space height={24} />
      {thread.project ? (
        <EmailProjectInfo
          project={thread.project}
          responsive={responsive}
          handleClose={handleClose}
          HeadingRight={responsive.desktop ? NewEmailButton : undefined}
        />
      ) : null}
      <SubjectAndNavigation
        subject={subject}
        responsive={responsive}
        currentThreadId={thread.id}
        project={thread.project}
        numberOfEmails={emails.length}
        handleCollapseExpandAll={handleCollapseExpandAll}
        refetch={refetch}
        isLoading={isLoading}
      />
      <ErrorBannerContainer>
        <EmailGrantExpiredBanner viewer={viewer} emailType='reply' spacingAboveIfVisible={16} />
      </ErrorBannerContainer>
      <ScrollView ref={ref}>
        {emails.map((email, index) => {
          const isCollapsed = collapsedEmails[index];
          return (
            <React.Fragment key={email.id + index}>
              <EmailItem
                email={email}
                responsive={responsive}
                isCollapsed={isCollapsed}
                toggleCollapse={() => toggleCollapse(index)}
                accountGrantInfo={accountGrantInfo}
                handleRespond={(replyType) =>
                  handleReply(getInitialFormForReply(replyType, email, viewer.id, thread.project))
                }
              />
              <Line />
            </React.Fragment>
          );
        })}
        {accountGrantInfo.hasSendableAccountGrant && emails.length ? (
          <EmailReplyContainer>
            <Space height={40} />
            {replyInitialForm ? (
              <React.Fragment>
                <EmailReply
                  form={replyInitialForm}
                  handleCancel={() => setReplyInitialForm(undefined)}
                  responsive={responsive}
                  handleEmailSent={() => {
                    refetch();
                    setReplyInitialForm(undefined);
                  }}
                  project={thread.project}
                  viewerId={viewer.id}
                  fromEmail={accountGrantEmail as string}
                />
              </React.Fragment>
            ) : (
              <ReplyButton
                latestEmail={latestEmail}
                onPress={(replyType) =>
                  handleReply(
                    getInitialFormForReply(replyType, latestEmail, viewer.id, thread.project),
                  )
                }
              />
            )}
          </EmailReplyContainer>
        ) : null}
        <Space height={60} />
      </ScrollView>
    </Container>
  );
};

const EmailThreadPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(EmailThreadPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: params.threadUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <EmailThreadContent
            thread={data.threadByUuid}
            viewer={data.viewer}
            isLoading={loading}
            // This errors if passed down directly, need to wrap it in an inline function
            refetch={() => refetch()}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadPage.query = gql`
  ${EmailProjectInfo.fragment}
  ${NextPreviousThreadControls.fragment}
  ${EmailItem.fragment}
  ${EmailReply.fragment}
  ${EmailForm.newForReply.fragment}
  ${AccountGrant.accountGrantInfo.fragment}
  ${EmailGrantExpiredBanner.fragment}
  query EmailThreadPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
      ...EmailGrantExpiredBanner
      ...AccountGrant_accountGrantInfo
      accountGrantToSendFrom {
        id
        identifier
      }
    }
    threadByUuid(uuid: $uuid) {
      id
      project {
        id
        uuid
        isStorage
        ...EmailProjectInfo
        ...NextPreviousThreadControls_Project
        ...EmailReply_Project
        ...EmailForm_newForReply_Project
      }
      allEmails {
        id
        subject
        ...EmailItem
        ...EmailForm_newForReply_Email
      }
      ...EmailReply_Thread
    }
  }
`;

export default EmailThreadPage;
