// Library
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {ConversationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import CommunicationInboxTopLine from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxTopLine';
import ConversationProjectAndCustomerName from 'modules/Communication/CommunicationInbox/ConversationList/components/ConversationProjectAndCustomerName';
import {toTextFromHtml} from 'modules/Communication/Email/utils/toTextFromHtml';

const Container = Styled.View`
`;

const Subject = Styled.Text`
  ${Typography.Responsive.Body}
`;

const BodyContainer = Styled.View`
  flex-direction: row;
`;

const Body = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  flex: 1;
`;

const CommunicationInboxEmailItem = ({
  responsive,
  conversation,
}: {
  responsive: ResponsiveType;
  conversation: ConversationModel;
}) => {
  if (!conversation.email) {
    return null;
  }
  const {subject, fromName, html, sentAt, updatedAt} = conversation.email;
  const sentDate = sentAt || updatedAt;

  return (
    <Container>
      <CommunicationInboxTopLine
        conversation={conversation}
        responsive={responsive}
        fromName={fromName}
        sentAt={sentDate}
      />
      <Space height={8} />
      <ConversationProjectAndCustomerName project={conversation.project} responsive={responsive} />
      <Space height={8} />
      <Subject responsive={responsive} numberOfLines={1}>
        {subject}
      </Subject>
      <Space height={4} />
      <BodyContainer>
        <Body responsive={responsive} numberOfLines={1}>
          {toTextFromHtml(html ?? '')}
        </Body>
      </BodyContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxEmailItem.fragment = gql`
  ${ConversationProjectAndCustomerName.fragment}
  ${CommunicationInboxTopLine.fragment}

  fragment CommunicationInboxEmailItem on Conversation {
    id
    ...CommunicationInboxTopLine
    email {
      id
      subject
      fromName
      sentAt
      updatedAt
      html
    }
    project {
      id
      ...ConversationProjectAndCustomerName
    }
  }
`;

export default CommunicationInboxEmailItem;
