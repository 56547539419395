// Libraries
import React from 'react';

// Supermove
import {Linkify, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {CommunicationModel, FileModel, TextMessage} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {downloadFromUrl} from '@supermove/utils';

// App
import {CommunicationDirectionType} from '@shared/modules/Communication/enums/CommunicationDirection';
import FileImage from 'modules/App/components/FileImage';
import CustomerChatLink from 'modules/Chat/Customer/components/CustomerChatLink';
import CommunicationItemStatus from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationItemStatus';
import CommunicationsListItemWrapper from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsListItemWrapper';

const Text = Styled.Text<{color: string}>`
  ${Typography.Responsive.Body}
  color: ${({color}) => color};
`;

const FileButton = Styled.ButtonV2`
  border-radius: 4px;
  overflow: hidden;
  background-color: ${colors.gray.tertiary};
  width: 160px;
  height: 160px;
  justify-content: center;
  align-self: center;
`;

const FilePreview = ({file}: {file: FileModel}) => {
  const handleDownload = () => downloadFromUrl(file.downloadUrl);
  return (
    <FileButton onPress={handleDownload}>
      <FileImage file={file} />
    </FileButton>
  );
};

const CustomerLink = (href: string, text: string, key: string) => {
  return (
    <CustomerChatLink url={href} color={colors.blue.accentDark} key={key}>
      {text}
    </CustomerChatLink>
  );
};

const OrganizationLink = (href: string, text: string, key: string) => {
  return (
    <CustomerChatLink url={href} color={colors.blue.interactive} key={key}>
      {text}
    </CustomerChatLink>
  );
};

const CommunicationsListItemTextMessage = ({
  communication,
  customerId,
  direction,
}: {
  communication: CommunicationModel;
  customerId: string;
  direction: CommunicationDirectionType;
}) => {
  const responsive = useResponsive();
  const {textMessage} = communication;
  if (!textMessage) {
    return null;
  }
  const senderName =
    direction === 'INBOUND' ? textMessage.creator.fullName : textMessage.organization.name;
  const linkifyComponent = direction === 'INBOUND' ? CustomerLink : OrganizationLink;
  const textColor = direction === 'INBOUND' ? colors.black : colors.gray.background;

  return (
    <CommunicationsListItemWrapper
      direction={direction}
      sentInfo={<CommunicationItemStatus communication={communication} responsive={responsive} />}
      name={senderName}
      displayTime={TextMessage.getDisplayTime(textMessage)}
    >
      <Linkify component={linkifyComponent}>
        <Text responsive={responsive} color={textColor}>
          {textMessage.body}
        </Text>
      </Linkify>
      {textMessage.file && <FilePreview file={textMessage.file} />}
    </CommunicationsListItemWrapper>
  );
};

CommunicationsListItemTextMessage.fragment = gql`
  ${FileImage.fragment}
  ${TextMessage.getDisplayTime.fragment}
  ${CommunicationItemStatus.fragment}

  fragment CommunicationsListItemTextMessage on Communication {
    id
    ...CommunicationItemStatus
    textMessage {
      id
      body
      file {
        id
        downloadUrl
        ...FileImage
      }
      organization {
        id
        name
      }
      creator {
        id
        fullName
      }
      ...TextMessage_getDisplayTime
    }
  }
`;

export default CommunicationsListItemTextMessage;
