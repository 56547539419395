// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Loading, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useQuery,
  useNavigationDOM,
  useDrawer,
  useResponsive,
  useSearch,
  useTabs,
  ResponsiveType,
  TabsType,
} from '@supermove/hooks';
import {WorkflowModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import {BreadcrumbType} from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import MultiPaneLayout from '@shared/design/components/Layout/MultiPaneLayout';
import SearchBar from '@shared/design/components/SearchBar';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import CreateWorkflowDrawer from 'modules/Organization/Settings/Workflow/components/CreateWorkflowDrawer';
import WorkflowPreviewPanel from 'modules/Organization/Settings/Workflow/components/WorkflowPreviewPanel';
import WorkflowsTable from 'modules/Organization/Settings/Workflow/components/WorkflowsTable';

const Container = Styled.View`
`;

const WorkflowsTablePanelContainer = Styled.View<{responsive: ResponsiveType}>`
  ${({responsive}) => (responsive.desktop ? 'flex: 1;' : '')}
  padding-horizontal: 16px;
`;

const WorkflowPreviewPlaceholderTextContainer = Styled.View`
  padding-vertical: 6px;
  align-items: center;
`;

const WorkflowPreviewPlaceholderText = Styled.Text`
  ${Typography.Subheading};
  color: ${colors.gray.tertiary};
`;

const WorkflowsTablePanel = ({
  workflows,
  refetch,
  tabs,
}: {
  workflows: WorkflowModel[];
  refetch: () => void;
  tabs: TabsType;
}) => {
  const responsive = useResponsive();
  // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  const {results: filteredWorkflows, setQuery} = useSearch({
    initialQuery: '',
    items: workflows,
    options: {keys: ['name', 'description', 'updatedBy.fullName']},
  });
  return (
    <WorkflowsTablePanelContainer responsive={responsive}>
      <Space height={24} />
      <SearchBar
        onChangeText={(text) => setQuery(text)}
        placeholder={
          responsive.desktop
            ? 'Search by automation name, description, updated by'
            : 'Search by name, description, updated by'
        }
        style={{width: 420}}
        isResponsive
      />
      <Space height={24} />
      <WorkflowsTable
        workflows={filteredWorkflows}
        refetch={refetch}
        onRowPress={() => !responsive.desktop && tabs.setSelectedIndex(1)}
      />
    </WorkflowsTablePanelContainer>
  );
};

const OrganizationSettingsWorkflowSettingsContent = ({
  workflows,
  refetch,
  organizationId,
}: {
  workflows: WorkflowModel[];
  refetch: () => void;
  organizationId: string;
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const createWorkflowDrawer = useDrawer({name: 'Create Workflow Drawer'});
  const breadcrumbs: [BreadcrumbType, BreadcrumbType] = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Automations', isBeta: true},
  ];
  const selectedWorkflow = _.find(workflows, (workflow) => workflow.uuid === params.workflowUuid);
  const tabs = useTabs({initialIndex: 0});

  useEffect(() => {
    if (!responsive.desktop && params.workflowUuid) {
      tabs.setSelectedIndex(1);
    }
  }, [params.workflowUuid]);

  return (
    <React.Fragment>
      <MultiPaneLayout
        breadcrumbs={breadcrumbs}
        showMobilePaneTabs
        mobilePaneTabs={tabs}
        isMobileScrollablePage
        paneDefinitions={[
          {
            key: 'AUTOMATIONS_TABLE',
            tabLabel: 'Automations',
            titleText: responsive.desktop ? 'Automations' : undefined,
            headerActionsComponent: responsive.desktop ? (
              <Button
                text={'Create Automation'}
                iconLeft={Icon.Plus}
                onPress={createWorkflowDrawer.handleOpen}
              />
            ) : undefined,
            bodyContentComponent: (
              <React.Fragment>
                {!responsive.desktop && (
                  <React.Fragment>
                    <Space height={16} />
                    <Container style={{paddingHorizontal: 16}}>
                      <Button
                        text={'Create Automation'}
                        iconLeft={Icon.Plus}
                        onPress={createWorkflowDrawer.handleOpen}
                        isResponsive
                      />
                    </Container>
                  </React.Fragment>
                )}
                <WorkflowsTablePanel workflows={workflows} refetch={refetch} tabs={tabs} />
              </React.Fragment>
            ),
          },
          {
            key: 'AUTOMATION_PREVIEW',
            width: 580,
            tabLabel: 'Preview',
            titleText: selectedWorkflow ? selectedWorkflow.name : undefined,
            headerContentComponent: selectedWorkflow ? undefined : (
              <WorkflowPreviewPlaceholderTextContainer>
                <WorkflowPreviewPlaceholderText>
                  Select an automation to preview
                </WorkflowPreviewPlaceholderText>
              </WorkflowPreviewPlaceholderTextContainer>
            ),
            headerActionsComponent: selectedWorkflow && (
              <SecondaryButton
                text={'Edit Automation'}
                iconLeft={Icon.Pen}
                onPress={() =>
                  navigator.push(`/settings/automations/${selectedWorkflow.uuid}/build`)
                }
              />
            ),
            bodyContentComponent: selectedWorkflow ? (
              <WorkflowPreviewPanel workflows={workflows} />
            ) : undefined,
          },
        ]}
      />
      <CreateWorkflowDrawer
        key={createWorkflowDrawer.key}
        isOpen={createWorkflowDrawer.isOpen}
        handleClose={createWorkflowDrawer.handleClose}
        organizationId={organizationId}
        onSuccess={(workflow: any) => {
          refetch();
          createWorkflowDrawer.handleClose();
          navigator.push(`/settings/automations/${workflow.uuid}/build`);
        }}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsWorkflowSettingsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsWorkflowSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading}>
        {() => (
          <OrganizationSettingsWorkflowSettingsContent
            workflows={data.viewer.viewingOrganization.workflows}
            organizationId={data.viewer.viewingOrganization.id}
            refetch={refetch}
          />
        )}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsWorkflowSettingsPage.query = gql`
  ${WorkflowsTable.fragment}
  ${CreateWorkflowDrawer.fragment}
  query OrganizationSettingsWorkflowSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...CreateWorkflowDrawer
        workflows {
          id
          uuid
          name
          ...WorkflowsTable
        }
      }
    }
  }
`;

export default OrganizationSettingsWorkflowSettingsPage;
