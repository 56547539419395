// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useResponsive} from '@supermove/hooks';
import {AccountGrantModel, UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import Panel from '@shared/design/components/Panel';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import CreateAccountGrantForm from '@shared/modules/AccountGrant/forms/CreateAccountGrantForm';
import DeactivateAccountGrantForm from '@shared/modules/AccountGrant/forms/DeactivateAccountGrantForm';
import useDeactivateAccountGrantMutation from '@shared/modules/AccountGrant/hooks/useDeactivateAccountGrantMutation';
import AccountCalloutBanner from 'modules/Organization/Settings/Communication/components/AccountCalloutBanner';
import UpdateAccountGrantPopover from 'modules/Organization/Settings/Communication/components/UpdateAccountGrantPopover';
import useConnectAccountGrant from 'modules/Organization/Settings/Communication/hooks/useConnectAccountGrant';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const Group = Styled.View`
  flex: 1;
`;

const DeactivateAccountGrantButton = ({
  accountGrant,
  onDeactivate,
  connectAccountGrant,
}: {
  accountGrant: AccountGrantModel;
  onDeactivate: () => void;
  connectAccountGrant: () => void;
}) => {
  const {handleSubmit: deactivateAccount, submitting: deactivatingAccount} =
    useDeactivateAccountGrantMutation({
      deactivateAccountGrantForm: DeactivateAccountGrantForm.edit({
        accountGrantId: accountGrant.id,
      }),
      onError: (errors) => console.log({errors}),
      onSuccess: onDeactivate,
    });
  const deactivateModal = useModal({name: 'Deactivate Account Grant Modal'});
  const updateAccountPopover = usePopover({name: 'Update Account Grant Popover'});
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Popover.Content innerRef={updateAccountPopover.ref}>
        <Button
          isResponsive
          isSubmitting={deactivatingAccount}
          onPress={updateAccountPopover.handleToggle}
          text={'Update Email Account'}
          iconRight={Icon.AngleDown}
          iconSize={14}
        />
      </Popover.Content>
      <UpdateAccountGrantPopover
        responsive={responsive}
        popover={updateAccountPopover}
        handleUpdateAccount={connectAccountGrant}
        handleDeactivateAccount={deactivateModal.handleOpen}
      />
      <WarningModal
        isOpen={deactivateModal.isOpen}
        title={'Disconnect account'}
        message={`This will unlink ${accountGrant.identifier} and remove access to replies to this email address.`}
        handlePressOutside={deactivateModal.handleClose}
        primaryActionText={'Disconnect'}
        handlePrimaryAction={() => {
          deactivateModal.handleClose();
          deactivateAccount();
        }}
        secondaryActionText={'Cancel'}
        handleSecondaryAction={deactivateModal.handleClose}
      />
    </React.Fragment>
  );
};

const AccountGrantComponent = ({
  accountGrant,
  onDeactivate,
  isExpired,
  handleConnectAccountGrant,
}: {
  accountGrant: AccountGrantModel;
  onDeactivate: () => void;
  isExpired: boolean;
  handleConnectAccountGrant: () => void;
}) => {
  return (
    <Column>
      <AccountCalloutBanner
        connectAccountGrant={handleConnectAccountGrant}
        expiredAt={isExpired ? accountGrant.statusUpdatedAt : undefined}
        hasEditPermissions
        daysUntilExpiration={accountGrant.daysUntilExpiration}
        grantKind={'USER'}
      />
      <Row>
        <Group>
          <Panel.LabelText>Account ID</Panel.LabelText>
          <Space height={8} />
          <Panel.Text style={{color: isExpired ? colors.red.warning : undefined}}>
            {isExpired ? (
              <React.Fragment>
                <Icon source={Icon.Ban} size={12} />
                <Space width={4} />
              </React.Fragment>
            ) : null}
            {accountGrant.identifier}
          </Panel.Text>
        </Group>
        <Space width={8} />
        <DeactivateAccountGrantButton
          accountGrant={accountGrant}
          onDeactivate={onDeactivate}
          connectAccountGrant={handleConnectAccountGrant}
        />
      </Row>
    </Column>
  );
};

const NoAccountGrant = ({
  handleConnectPress,
  isLoading,
}: {
  handleConnectPress: () => void;
  isLoading: boolean;
}) => {
  return (
    <Row>
      <Group>
        <Panel.LabelText>{isLoading ? 'Email Account' : 'Connect Email Account'}</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>
          Send emails from your existing email account by connecting your account to Supermove.
        </Panel.Text>
      </Group>
      <Space width={8} />
      <Button
        onPress={handleConnectPress}
        text={'Connect Email Account'}
        isSubmitting={isLoading}
      />
    </Row>
  );
};

const NylasAccountPanel = ({
  redirectUri,
  redirectPath,
  user,
  onDeactivate,
  organizationId,
  userId,
}: {
  redirectUri: string;
  redirectPath: string;
  user?: UserModel;
  onDeactivate: () => void;
  organizationId: string;
  userId: string;
}) => {
  const activeAccountGrant = user?.activeAccountGrant;
  const expiredAccountGrant = user?.expiredAccountGrant;
  const accountGrant = activeAccountGrant || expiredAccountGrant;
  const createAccountGrantForm = CreateAccountGrantForm.new({
    kind: 'USER',
    provider: 'NYLAS',
    organizationId,
    userId,
  });
  const {connectAccountGrant, submitting} = useConnectAccountGrant({
    createAccountGrantForm,
    onSuccess: () => {
      // After we create an AccountGrant, we need to hard-redirect to the redirectUri which will
      // remove the 'code' from the url params.
      window.location.href = redirectUri;
    },
    redirectPath,
  });

  return (
    <ActionPanel
      title={'Email Account'}
      BodyComponent={() =>
        accountGrant ? (
          <AccountGrantComponent
            accountGrant={accountGrant}
            onDeactivate={onDeactivate}
            isExpired={!activeAccountGrant && !!expiredAccountGrant}
            handleConnectAccountGrant={connectAccountGrant}
          />
        ) : (
          <NoAccountGrant handleConnectPress={connectAccountGrant} isLoading={submitting} />
        )
      }
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NylasAccountPanel.fragment = gql`
  fragment NylasAccountPanel on User {
    id
    activeAccountGrant {
      id
      identifier
      daysUntilExpiration
    }
    expiredAccountGrant {
      id
      identifier
      statusUpdatedAt
    }
  }
`;

export default NylasAccountPanel;
