// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import TextTooltip from '@shared/design/components/TextTooltip';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import FinalizedInvoiceCallout from 'modules/Project/V2/Show/Blocks/components/FinalizedInvoiceCallout';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import BillingActionButtons from 'modules/Project/V2/Show/components/BillingActionButtons';
import InvoiceCard from 'modules/Project/V2/Show/components/InvoiceCard';
import InvoiceSummary from 'modules/Project/V2/Show/components/InvoiceSummary';
import ProjectBills from 'modules/Project/V2/Show/components/ProjectBills';
import ProjectHeader from 'modules/Project/V2/Show/components/ProjectHeader';
import InvoiceStatusBadge from 'modules/Storage/components/InvoiceStatusBadge';

const BLOCK_TITLE = 'Invoice';

const View = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const SkeletonBody = () => {
  return (
    <React.Fragment>
      <SkeletonLoader isFullWidth height={92} />
      <Space height={16} />
      <ProjectBills.SkeletonLoader />
    </React.Fragment>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={() => (
        <Row>
          <BillingActionButtons.SkeletonLoader />
          <Space width={12} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={24} />
        </Row>
      )}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const BodyComponent = ({
  project,
  refetch,
  pageRefetch,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  viewer,
}: any) => {
  return (
    <React.Fragment>
      <FinalizedInvoiceCallout
        invoice={project.mainMoveInvoice}
        refetch={refetch}
        isAuthorizedAccountingActions={viewer.isAuthorizedAccountingActions}
      />
      <InvoiceSummary invoice={project.mainMoveInvoice} />
      <Space height={16} />
      <ProjectBills
        project={project}
        refetch={refetch}
        pageRefetch={pageRefetch}
        descriptionField={descriptionField}
        getTruncatedKey={getTruncatedKey}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
      />
    </React.Fragment>
  );
};

const HeaderStatusBadge = ({invoice}: any) => {
  return (
    <React.Fragment>
      {invoice.hasEmailSendError && (
        <React.Fragment>
          <TextTooltip isEnabledMobileToast={false} text={'Invoice failed to send'}>
            <View style={{justifyContent: 'center'}}>
              <Icon source={Icon.ExclamationTriangle} size={16} color={colors.red.warning} />
            </View>
          </TextTooltip>
          <Space width={12} />
        </React.Fragment>
      )}
      <InvoiceStatusBadge invoice={invoice} />
    </React.Fragment>
  );
};

const ProjectInvoiceBlockContent = ({
  project,
  refetch,
  pageRefetch,
  refetchAndReset,
  urlFilters,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  viewer,
}: any) => {
  // Set state for truncated and enabledToggle after data has been queried.
  useMountEffect(() => {
    const newTruncated = {};
    const newEnabledToggle = {};

    project.bills.forEach((bill: any) => {
      bill.billItems.forEach((billItem: any) => {
        const truncatedKey = getTruncatedKey({billItemId: billItem.id});

        // Whenever component is mounted, initialize expand/collapse state for new bill item
        // descriptions and keep existing value for existing descriptions.
        if (Object.prototype.hasOwnProperty.call(truncated, truncatedKey)) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newTruncated[truncatedKey] = truncated[truncatedKey];
        } else {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newTruncated[truncatedKey] = true;
        }

        if (Object.prototype.hasOwnProperty.call(enabledToggle, truncatedKey)) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newEnabledToggle[truncatedKey] = enabledToggle[truncatedKey];
        } else {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newEnabledToggle[truncatedKey] = false;
        }
      });
    });

    setTruncated(newTruncated);
    setEnabledToggle(newEnabledToggle);
  });

  return (
    <ActionPanel
      BodyComponent={BodyComponent}
      bodyComponentProps={{
        project,
        refetch,
        pageRefetch,
        descriptionField,
        getTruncatedKey,
        truncated,
        setTruncated,
        enabledToggle,
        setEnabledToggle,
        viewer,
      }}
      ActionButtonComponent={BillingActionButtons}
      actionButtonComponentProps={{project, refetch, pageRefetch, refetchAndReset, urlFilters}}
      title={`Invoice ${project.mainMoveInvoice.identifier}`}
      HeaderIconComponent={HeaderStatusBadge}
      headerIconComponentProps={{invoice: project.mainMoveInvoice}}
      style={{width: '100%'}}
    />
  );
};

const ProjectInvoiceBlockContentMobile = ({project}: any) => {
  const {mainMoveInvoice} = project;

  return (
    <React.Fragment>
      <MobileProjectBlockHeader title={BLOCK_TITLE} />
      <InvoiceCard invoice={mainMoveInvoice} />
    </React.Fragment>
  );
};

const ProjectInvoiceBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  pageRefetch,
  refetchAndReset,
  urlFilters,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();

  // Note(shawn): Defining expand/collapse state for bill item descriptions here to avoid state
  // being reset on remounts whenever page height changes.
  const descriptionField = 'description';
  const getTruncatedKey = ({billItemId}: any) =>
    `BILL_ITEM_${billItemId}_${descriptionField.toUpperCase()}`;
  const [truncated, setTruncated] = useState({});
  const [enabledToggle, setEnabledToggle] = useState({});

  return (
    <ProjectBlockWrapper
      key={`${project.billsHash}_${project.jobStopsHash}_${project.totalRevenue}_${project.valuationCoverage?.updatedAt}`}
      index={index}
      query={ProjectInvoiceBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      // Don't pass in the layoutKey on mobile to prevent the block from
      // rerendering when a user completes an action from the view invoice
      // drawer.
      layoutKey={responsive.desktop ? layoutKey : null}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}: any) => {
        const {project, viewer} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectInvoiceBlockContent
                project={project}
                refetch={refetch}
                pageRefetch={pageRefetch}
                refetchAndReset={refetchAndReset}
                urlFilters={urlFilters}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
                viewer={viewer}
              />
            ) : (
              <ProjectInvoiceBlockContentMobile project={project} />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectInvoiceBlock.listener = gql`
  fragment ProjectInvoiceBlock_listener on Project {
    id
    billsHash
    jobStopsHash
    totalRevenue
    valuationCoverage {
      id
      updatedAt
    }
  }
`;

ProjectInvoiceBlock.fragment = gql`
  ${ProjectInvoiceBlock.listener}
  fragment ProjectInvoiceBlock on Project {
    id
    uuid
    ...ProjectInvoiceBlock_listener
  }
`;

ProjectInvoiceBlock.query = gql`
  ${BillingActionButtons.fragment}
  ${FinalizedInvoiceCallout.fragment}
  ${InvoiceSummary.fragment}
  ${ProjectBills.fragment}
  ${ProjectHeader.listener}
  ${ProjectInvoiceBlock.listener}
  ${InvoiceCard.fragment}

  query ProjectInvoiceBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      billsHash
      mainMoveInvoice {
        id
        identifier
        ...FinalizedInvoiceCallout
        ...InvoiceSummary
        ...InvoiceCard
      }
      bills {
        id
        billItems {
          id
        }
      }
      ...BillingActionButtons
      ...ProjectBills
      ...ProjectHeader_listener
      ...ProjectInvoiceBlock_listener
    }
    viewer {
      id
      isAuthorizedAccountingActions
    }
  }
`;

export default ProjectInvoiceBlock;
