// Libraries
import _ from 'lodash';
import React from 'react';

// App
import Tabs from '@shared/design/components/Tabs';
import UserStatus from '@shared/modules/User/enums/UserStatus';
import {
  SelectedUserStatusType,
  TEAMS,
} from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContentV2';

const getUserStatusTabs = ({
  activeUsersCount,
  pendingUsersCount,
  inactiveUsersCount,
  teamsCount,
}: {
  activeUsersCount: number;
  pendingUsersCount: number;
  inactiveUsersCount: number;
  teamsCount: number;
}) => [
  {
    key: UserStatus.ACTIVE,
    label: 'Active',
    status: UserStatus.ACTIVE,
    count: activeUsersCount,
  },
  {
    key: UserStatus.PENDING,
    label: 'Pending',
    status: UserStatus.PENDING,
    count: pendingUsersCount,
  },
  {
    key: UserStatus.INACTIVE,
    label: 'Inactive',
    status: UserStatus.INACTIVE,
    count: inactiveUsersCount,
  },
  {
    key: 'TEAMS',
    label: 'Teams',
    status: TEAMS,
    count: teamsCount,
    prependSeparator: true,
  },
];

const OfficeStaffUserStatusTabs = ({
  selectedUserStatus,
  setSelectedUserStatus,
  activeUsersCount,
  pendingUsersCount,
  inactiveUsersCount,
  teamsCount,
}: {
  selectedUserStatus: SelectedUserStatusType;
  setSelectedUserStatus: (status: SelectedUserStatusType) => void;
  activeUsersCount: number;
  inactiveUsersCount: number;
  pendingUsersCount: number;
  teamsCount: number;
}) => {
  const userStatusTabs = getUserStatusTabs({
    activeUsersCount,
    pendingUsersCount,
    inactiveUsersCount,
    teamsCount,
  });
  const activeTabIndex = _.findIndex(
    userStatusTabs,
    (definition) => definition.status === selectedUserStatus,
  );

  return (
    <Tabs
      tabs={userStatusTabs}
      handlePressTab={({status}) => {
        setSelectedUserStatus(status);
      }}
      activeTabIndex={activeTabIndex}
    />
  );
};

export default OfficeStaffUserStatusTabs;
