// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {PageLoader} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useModal, useScrollView, ResponsiveType} from '@supermove/hooks';
import {CommunicationModel, ProjectModel, UserModel} from '@supermove/models';

// App
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import {CommunicationInboxConversationUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';
import CommunicationsWidgetBody from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsWidgetBody';
import CommunicationsWidgetFooter from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsWidgetFooter';

import CommunicationsInboxProjectConversationNoResults from './CommunicationInboxConversationNoResults';

const CommunicationsInboxProjectConversationContent = ({
  project,
  urlFilters,
  responsive,
}: {
  project: ProjectModel;
  urlFilters: CommunicationInboxConversationUrlFilterType;
  responsive: ResponsiveType;
}) => {
  const editClientModal = useModal({name: 'Edit Client Modal - Project Emails Widget'});
  const conversationsScrollView = useScrollView();

  const {loading, data, refetch} = useQuery<{
    viewer: UserModel;
    user: UserModel;
    project: ProjectModel;
    projectCommunications: CommunicationModel[];
  }>(CommunicationsInboxProjectConversationContent.query, {
    fetchPolicy: 'network-only',
    variables: {
      userUuid: project.client.primaryContact.uuid,
      projectId: project.id,
      projectUuid: project.uuid,
      categories: [TextMessageTemplateCategory.CUSTOMER],
      searchQuery: urlFilters.get('communicationSearchQuery') ?? '',
      conversationKinds: urlFilters.get('communicationTags'),
    },
  });

  return (
    <React.Fragment>
      <PageLoader loading={loading} data={data}>
        {({loadedData}) => {
          const {textMessageTemplatesByCategory} = loadedData.project.organization;
          if (!loadedData.projectCommunications?.length && !loading) {
            return <CommunicationsInboxProjectConversationNoResults responsive={responsive} />;
          }
          return (
            <React.Fragment>
              <CommunicationsWidgetBody
                viewer={loadedData.viewer}
                customer={loadedData.user}
                conversations={loadedData.projectCommunications}
                conversationsScrollView={conversationsScrollView}
                handleMissingPhoneNumber={editClientModal.handleOpen}
              />
              <CommunicationsWidgetFooter
                customer={loadedData.user}
                viewer={loadedData.viewer}
                refetch={refetch}
                projectId={project.id}
                textMessageTemplates={textMessageTemplatesByCategory}
                selectedTextMessageTemplate={_.find(
                  textMessageTemplatesByCategory,
                  (template) => template.uuid === urlFilters.get('textMessageTemplateUuid'),
                )}
              />
            </React.Fragment>
          );
        }}
      </PageLoader>
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={project.client.uuid}
        // A refetch is not needed since the mutation response updates client cache
        refetch={() => {}}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationsInboxProjectConversationContent.query = gql`
  ${CommunicationsWidgetBody.fragment}
  ${CommunicationsWidgetFooter.fragment}

  query CommunicationsInboxProjectConversationContent(
    $userUuid: String!,
    $projectId: Int!,
    $projectUuid: String!,
    $categories: [String],
    $searchQuery: String,
    $conversationKinds: [String],
  ) {
    ${gql.query}
    viewer {
        id
        ...CommunicationsWidgetFooter_Viewer
        ...CommunicationsWidgetBody_Viewer
      }
      user(uuid: $userUuid) {
        id
        ...CommunicationsWidgetFooter_User
        ...CommunicationsWidgetBody_User
      }
      project(uuid: $projectUuid) {
        id
        organization {
          id
          textMessageTemplatesByCategory(categories: $categories) {
            id
            uuid
            ...CommunicationsWidgetFooter_TextMessageTemplate
          }
        }
      }
      projectCommunications(projectId: $projectId, searchQuery: $searchQuery, conversationKinds: $conversationKinds) {
        id
        email {
          id
          thread {
            id
            uuid            
          }
        }
        ...CommunicationsWidgetBody_Communication
      }
  }
`;

CommunicationsInboxProjectConversationContent.fragment = gql`
  fragment CommunicationsInboxProjectConversationContent on Project {
    id
    uuid
    client {
      id
      uuid
      primaryContact {
        id
        uuid
      }
    }
    organization {
      id
      slug
    }
  }
`;

export default CommunicationsInboxProjectConversationContent;
