// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Popover, DragAndDropList, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, List} from '@supermove/utils';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import EditConfirmationStepModal from 'modules/Organization/Settings/ProjectTypes/components/EditConfirmationStepModal';
import EditQuoteStepModal from 'modules/Organization/Settings/ProjectTypes/components/EditQuoteStepModal';

const ConfirmationStepText = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.primary};
`;

const ConfirmationStepDetailsText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const ActionButton = Styled.ButtonV2`
  padding-horizontal: 8px;
  align-items: center;
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
`;

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: ${(props) => 100 - (props as any).index};
  flex: 1;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const DefaultBadge = Styled.View`
  padding: 2px 8px;
  border-radius: 8px;
  background-color: ${colors.gray.border};
`;

const DefaultBadgeText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const LeftContainer = Styled.View`
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const onReorder = ({form, fromIndex, toIndex, handleSubmit}: any) => {
  const stepsJson = _.cloneDeep(JSON.parse(form.values.confirmationStepsForm.stepsJson));
  const reorderedStepsJson = List.move({list: stepsJson, fromIndex, toIndex});
  form.setFieldValue('confirmationStepsForm.stepsJson', JSON.stringify(reorderedStepsJson));
  setTimeout(handleSubmit, 0);
};

const onDelete = ({form, handleSubmit, confirmationStepUuid}: any) => {
  let stepsJson = _.cloneDeep(JSON.parse(form.values.confirmationStepsForm.stepsJson));
  stepsJson = stepsJson.filter((step: any) => {
    return step.uuid !== confirmationStepUuid;
  });
  form.setFieldValue('confirmationStepsForm.stepsJson', JSON.stringify(stepsJson));
  setTimeout(handleSubmit, 0);
};

const getDocumentTemplateNameFromIdentifier = ({documentTemplates, identifier}: any) => {
  const documentTemplate = _.find(documentTemplates, (documentTemplate) => {
    return documentTemplate.identifier === identifier;
  });
  return documentTemplate.name;
};

const EllipseButton = ({form, handleSubmit, refetch, confirmationStepUuid}: any) => {
  const deleteConfirmationStepModal = useModal({name: 'Delete Confirmation Step Modal'});
  const popover = usePopover();
  return (
    <React.Fragment>
      <Popover.Content innerRef={popover.ref}>
        <ActionButton onPress={popover.handleToggle}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </ActionButton>
      </Popover.Content>
      <ActionMenuPopover
        popover={popover}
        placement={Popover.Positions.LeftEnd}
        width={200}
        offset={[10, 0]}
      >
        <ActionMenuPopover.MenuItem
          onPress={() => {
            deleteConfirmationStepModal.handleOpen();
          }}
        >
          Delete
        </ActionMenuPopover.MenuItem>
      </ActionMenuPopover>
      <DeleteModal
        key={deleteConfirmationStepModal.key}
        title={`Delete Step`}
        subtitle={`You can't undo this action.`}
        isOpen={deleteConfirmationStepModal.isOpen}
        handleClose={deleteConfirmationStepModal.handleClose}
        handleDelete={() => {
          onDelete({form, handleSubmit, confirmationStepUuid});
          deleteConfirmationStepModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const StepDetailsContent = ({confirmationStep, showValue, showValueTwo, documentName}: any) => {
  const valueIsCurrency = _.includes(
    [
      ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT,
      ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD,
    ],
    confirmationStep.kind,
  );

  return (
    <React.Fragment>
      <Row>
        <ConfirmationStepText>{confirmationStep.name}</ConfirmationStepText>
        <Space width={8} />
        {confirmationStep.show_by_default && (
          <DefaultBadge>
            <DefaultBadgeText>Default</DefaultBadgeText>
          </DefaultBadge>
        )}
      </Row>
      <Space height={8} />
      <Row>
        {documentName && (
          <React.Fragment>
            <ConfirmationStepDetailsText numberOfLines={1}>
              {documentName}
            </ConfirmationStepDetailsText>
            <Space width={8} />
          </React.Fragment>
        )}
        {showValue && (
          <React.Fragment>
            <ConfirmationStepDetailsText>
              {valueIsCurrency ? Currency.display(confirmationStep.value) : confirmationStep.value}
            </ConfirmationStepDetailsText>
            <Space width={8} />
          </React.Fragment>
        )}
        {showValueTwo && (
          <React.Fragment>
            <ConfirmationStepDetailsText>{confirmationStep.value_two}</ConfirmationStepDetailsText>
            <Space width={8} />
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

const StepDetails = ({confirmationStep, documentTemplates}: any) => {
  switch (confirmationStep.kind) {
    case ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT:
    case ConfirmationStepKind.QUOTE_STEP_DOCUMENT:
      return (
        <StepDetailsContent
          documentTemplates={documentTemplates}
          confirmationStep={confirmationStep}
          documentName={getDocumentTemplateNameFromIdentifier({
            documentTemplates,
            identifier: confirmationStep.value,
          })}
        />
      );
    case ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT:
      return (
        <StepDetailsContent
          documentTemplates={documentTemplates}
          confirmationStep={confirmationStep}
          showValue
        />
      );
    case ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD:
      return (
        <StepDetailsContent
          documentTemplates={documentTemplates}
          confirmationStep={confirmationStep}
          showValue
          showValueTwo
        />
      );
    default:
      return null;
  }
};

const ConfirmationStepItem = ({
  confirmationStepsKind,
  projectType,
  confirmationStepsId,
  confirmationStep,
  index,
  refetch,
  form,
  handleSubmit,
}: any) => {
  const editConfirmationStepModal = useModal({name: 'Edit Confirmation Step Modal'});

  return (
    <React.Fragment>
      <Container>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <ItemContainer index={index}>
          <LeftContainer>
            <StepDetails
              confirmationStep={confirmationStep}
              documentTemplates={projectType.organization.documentTemplates}
            />
          </LeftContainer>
          <ActionContainer>
            <IconButton onPress={editConfirmationStepModal.handleOpen}>
              <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
            </IconButton>
            <EllipseButton
              form={form}
              handleSubmit={handleSubmit}
              refetch={refetch}
              confirmationStepUuid={confirmationStep.uuid}
            />
          </ActionContainer>
        </ItemContainer>
      </Container>
      {confirmationStepsKind === ConfirmationStepsKind.QUOTE && (
        <EditQuoteStepModal
          projectType={projectType}
          confirmationStepsId={confirmationStepsId}
          confirmationStep={confirmationStep}
          handleClose={editConfirmationStepModal.handleClose}
          isOpen={editConfirmationStepModal.isOpen}
          key={editConfirmationStepModal.key}
          refetch={refetch}
        />
      )}
      {confirmationStepsKind === ConfirmationStepsKind.CONFIRMATION && (
        <EditConfirmationStepModal
          projectType={projectType}
          confirmationStepsId={confirmationStepsId}
          confirmationStep={confirmationStep}
          handleClose={editConfirmationStepModal.handleClose}
          isOpen={editConfirmationStepModal.isOpen}
          key={editConfirmationStepModal.key}
          refetch={refetch}
        />
      )}
    </React.Fragment>
  );
};

const ConfirmationStepsList = ({confirmationSteps, refetch, form, handleSubmit}: any) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const stepsJson = JSON.parse(form.values.confirmationStepsForm.stepsJson);
  return (
    <DragAndDropList
      isReordering={isReordering}
      onReorder={({fromIndex, toIndex}) => {
        handleReorderStart();
        onReorder({form, fromIndex, toIndex, handleSubmit});
        handleReorderEnd();
      }}
    >
      {stepsJson.map((step: any, index: any) => {
        return (
          <ConfirmationStepItem
            key={`${step.uuid}-${index}`}
            projectType={confirmationSteps.projectType}
            confirmationStepsKind={confirmationSteps.kind}
            confirmationStepsId={confirmationSteps.id}
            confirmationStep={step}
            index={index}
            refetch={refetch}
            form={form}
            handleSubmit={handleSubmit}
          />
        );
      })}
    </DragAndDropList>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationStepsList.fragment = gql`
  ${EditQuoteStepModal.fragment}
  ${EditConfirmationStepModal.fragment}

  fragment ConfirmationStepsList on ConfirmationSteps {
    id
    kind
    stepsJson
    projectType {
      id
      ...EditQuoteStepModal
      ...EditConfirmationStepModal
      organization {
        id
        documentTemplates: documentTemplatesByCategory(categories: ["JOB"]) {
          id
          identifier
          name
        }
      }
    }
  }
`;

export default ConfirmationStepsList;
