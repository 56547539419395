// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import {PageLoadingIndicator} from 'modules/App/components';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const CompanySettingsPage = ({
  HeaderComponent,
  title,
  description,
  children,
  hasNoChildrenStyle,
}: {
  HeaderComponent?: React.FC;
  title: string;
  description: string;
  children: React.ReactNode;
  hasNoChildrenStyle?: boolean;
  isResponsive?: boolean;
}) => {
  const {navigator} = useNavigationDOM();
  const {data, loading} = useQuery(CompanySettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'settings'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const {isEnabledMultipleWarehouse} = data.viewer.viewingOrganization.features;

  return (
    <SettingsPage
      title={title}
      description={description}
      HeaderComponent={HeaderComponent}
      hasNoChildrenStyle={hasNoChildrenStyle}
      isResponsive
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Company Settings'},
      ]}
      tabs={[
        {
          label: 'Business Info',
          url: '/settings/company/business-info',
        },
        {
          label: 'Branding',
          url: '/settings/company/branding',
        },
        {
          label: 'Branches',
          url: '/settings/company/branches',
        },
        ...List.insertIf(isEnabledMultipleWarehouse, {
          label: 'Warehouses',
          url: '/settings/company/warehouses',
        }),
        {
          label: 'Referrals',
          url: '/settings/company/referrals',
        },
        {
          label: 'Crew App',
          url: '/settings/company/crew-app',
        },
        {
          label: 'Payments',
          url: '/settings/company/payments',
        },
        {
          label: 'Accounting',
          url: '/settings/company/accounting',
        },
        {
          label: 'Claims',
          url: '/settings/company/claims',
        },
      ]}
    >
      {children}
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanySettingsPage.query = gql`
  query CompanySettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledMultipleWarehouse: isEnabled(feature: "MULTIPLE_WAREHOUSE")
        }
      }
    }
  }
`;

CompanySettingsPage.TitleText = SettingsPage.TitleText;
CompanySettingsPage.DescriptionText = SettingsPage.DescriptionText;

export default CompanySettingsPage;
