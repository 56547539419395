// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {CommunicationModel, UserModel} from '@supermove/models';

// App
import CommunicationsListItemCall from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsListItemCall';
import CommunicationsListItemEmail from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsListItemEmail';
import CommunicationsListItemTextMessage from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsListItemTextMessage';

const CommunicationsListItem = ({
  communication,
  customerId,
  viewer,
}: {
  communication: CommunicationModel;
  customerId: string;
  viewer: UserModel;
}) => {
  if (communication.email) {
    return (
      <CommunicationsListItemEmail
        communication={communication}
        direction={communication.direction}
        viewer={viewer}
      />
    );
  }

  if (communication.textMessage) {
    return (
      <CommunicationsListItemTextMessage
        communication={communication}
        customerId={customerId}
        direction={communication.direction}
      />
    );
  }

  if (communication.call) {
    return (
      <CommunicationsListItemCall
        communication={communication}
        direction={communication.direction}
      />
    );
  }

  return null;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationsListItem.fragment = gql`
  ${CommunicationsListItemEmail.fragment}
  ${CommunicationsListItemTextMessage.fragment}
  ${CommunicationsListItemCall.fragment}

  fragment CommunicationsListItem_Viewer on User {
    id
    ...CommunicationsListItemEmail_Viewer
  }

  fragment CommunicationsListItem_Communication on Communication {
    id
    direction
    ...CommunicationsListItemTextMessage
    ...CommunicationsListItemEmail_Email
    ...CommunicationsListItemCall
    email {
      id
    }
    textMessage {
      id
    }
    call {
      id
    }
  }
`;

export default CommunicationsListItem;
