// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover} from '@supermove/hooks';
import {CrewModel, UserModel} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import CrewSlotDriverPopover from 'modules/Dispatch/Crew/components/CrewSlotDriverPopover';

const Row = Styled.View`
  flex-direction: row;
`;

const CrewUsersAndDriversSelectionsContainer = Styled.View`
  margin-horizontal: 16px;
  padding-horizontal: 8px;
  padding-bottom: ${(props) => (props as any).paddingBottom};
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const SelectedUsersListItemContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const MoverInformation = Styled.View`
  flex-direction: row;
  flex: 1;
`;

const RemoveMoverButton = Styled.Button`
  box-shadow: none;
  height: 16px;
  width: 16px;
  background-color: ${colors.gray.border};
  border-radius: 8px;
`;

const MoverName = Styled.H7`
  ${fontWeight(500)}
`;

const MoverBadge = Styled.View<{color: string}>`
  height: 16px;
  background-color: ${(props) => (props as any).color};
  align-items: center;
  justify-content: center;
  padding-horizontal: 4px;
  border-radius: 2px;
  margin-vertical: 2px;
`;

const MoverBadgeText = Styled.H8<{color: string}>`
  ${fontWeight(500)}
  color: ${(props) => (props as any).color};
  margin-top: 1px;
  text-transform: capitalize;
`;

const SelectTruckButton = Styled.Button`
  height: auto;
  background-color: ${colors.white};
  box-shadow: none;
`;

const SelectTruckButtonText = Styled.H8<{disabled: boolean}>`
  color: ${({disabled}) => (disabled ? colors.gray.tertiary : colors.blue.interactive)};
  ${fontWeight(700)}
  text-decoration: ${({disabled}) => (disabled ? '' : 'underline')};
  text-decoration-color: ${colors.blue.interactive};
`;

const ListEmptyText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const NameAndBadgesContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const SelectedUsersListItem = ({
  user,
  crew,
  form,
  setIsClosable,
  userCrewSlotForm,
  handleRemoveUser,
  index,
}: {
  user: UserModel;
  crew: CrewModel;
  form: any;
  setIsClosable: (isClosable: boolean) => void;
  userCrewSlotForm: any;
  handleRemoveUser: () => void;
  index: number;
}) => {
  const selectTruckPopover = usePopover();
  const jobUser = _.find(crew.job.jobUsers, ['userId', _.toNumber(user.id)]);
  const isJobUserOnTrip = _.get(jobUser, 'isOnTrip');
  const moverPosition = jobUser ? jobUser.moverPosition : user.moverPosition;

  return (
    <React.Fragment>
      <Space height={8} />
      <SelectedUsersListItemContainer>
        <MoverInformation>
          {isJobUserOnTrip ? (
            <TextTooltip
              isEnabledMobileToast={false}
              text={'A trip driver can only be removed by editing the trip.'}
            >
              <Row>
                <Space width={2} />
                <Icon source={Icon.Lock} size={13} color={colors.gray.secondary} />
                <Space width={2} />
              </Row>
            </TextTooltip>
          ) : (
            <RemoveMoverButton onPress={() => handleRemoveUser()}>
              <Icon source={Icon.Times} size={12} color={colors.gray.secondary} />
            </RemoveMoverButton>
          )}
          <Space width={4} />
          <NameAndBadgesContainer>
            <MoverName numberOfLines={1}>{user.fullName}</MoverName>
            <Space width={4} />
            <MoverBadge color={colors.getBackgroundColor(colors.gray.secondary)}>
              <MoverBadgeText color={colors.gray.secondary}>{moverPosition.name}</MoverBadgeText>
            </MoverBadge>
            <Space width={4} />
          </NameAndBadgesContainer>
        </MoverInformation>
        {user.hasDriverMoverPosition && (
          <React.Fragment>
            <Space width={4} />
            <Popover.Content innerRef={selectTruckPopover.ref}>
              <SelectTruckButton
                onPress={() => {
                  // This prevents the parent popover from closing when the user
                  // closes the child popover and/or clicks outside of the popovers
                  setIsClosable(false);

                  // Using the set timeout prevents the popover from relocating
                  setTimeout(() => selectTruckPopover.handleToggle(), 0);
                }}
                disabled={isJobUserOnTrip}
              >
                <SelectTruckButtonText disabled={isJobUserOnTrip}>
                  {_.get(userCrewSlotForm, 'truck')
                    ? userCrewSlotForm.truck.name
                    : _.get(userCrewSlotForm, 'slot')
                      ? `Slot ${userCrewSlotForm.slot.index}`
                      : 'Select Truck'}
                </SelectTruckButtonText>
              </SelectTruckButton>
            </Popover.Content>
            <CrewSlotDriverPopover
              isOpen={selectTruckPopover.isOpen}
              handleOpen={selectTruckPopover.handleOpen}
              handleClose={selectTruckPopover.handleClose}
              popoverRef={selectTruckPopover.ref}
              driver={user}
              crew={crew}
              form={form}
              onComponentDidHide={() => setIsClosable(true)}
              index={index}
            />
          </React.Fragment>
        )}
      </SelectedUsersListItemContainer>
    </React.Fragment>
  );
};

const CrewUsersAndDriversSelections = ({
  crew,
  form,
  selectedUsers,
  setIsClosable,
  handleRemoveUserId,
}: {
  crew: CrewModel;
  form: any;
  selectedUsers: UserModel[];
  setIsClosable: () => void;
  handleRemoveUserId: (userId: string) => void;
}) => {
  const crewSlotsById = _.groupBy(crew.crewSlots, 'id');
  const crewSlotFormsField = 'assignUsersToCrewWithCrewSlotsForm.crewSlotForms';
  const crewSlotForms = _.get(form.values, crewSlotFormsField).map((crewSlotForm: any) => {
    const crewSlots = crewSlotsById[crewSlotForm.crewSlotId];
    return {
      ...crewSlotForm,
      truck: crewSlots ? crewSlots[0].truck : null,
      slot: crewSlots ? crewSlots[0].slot : null,
    };
  });
  const sortedSelectedUsers = _.sortBy(
    selectedUsers,
    (user) => !_.get(user, 'hasDriverMoverPosition'),
    (user) => _.get(user, 'fullName'),
  );

  return (
    <CrewUsersAndDriversSelectionsContainer>
      {crew.organization.features.isEnabledProjectPageAllSections ? (
        <ScrollView style={{height: 70}} contentContainerStyle={{paddingBottom: 8}}>
          {_.isEmpty(sortedSelectedUsers) ? (
            <ListEmptyText>No selected movers</ListEmptyText>
          ) : (
            sortedSelectedUsers.map((user: UserModel, index) => {
              return (
                <SelectedUsersListItem
                  key={user.id}
                  user={user}
                  form={form}
                  crew={crew}
                  setIsClosable={setIsClosable}
                  userCrewSlotForm={_.find(
                    crewSlotForms,
                    (crewSlotForm) => String(crewSlotForm.driverId) === String(user.id),
                  )}
                  handleRemoveUser={() => handleRemoveUserId(user.id)}
                  index={index}
                />
              );
            })
          )}
        </ScrollView>
      ) : (
        <FlatList
          listKey={'crew-users-and-drivers-selections'}
          data={sortedSelectedUsers}
          keyExtractor={(user: any, index: any) => `${user.id}-${index}`}
          renderItem={({item: user, index}: {item: UserModel; index: number}) => {
            return (
              <SelectedUsersListItem
                user={user}
                form={form}
                crew={crew}
                setIsClosable={setIsClosable}
                userCrewSlotForm={_.find(
                  crewSlotForms,
                  (crewSlotForm) => String(crewSlotForm.driverId) === String(user.id),
                )}
                handleRemoveUser={() => handleRemoveUserId(user.id)}
                index={index}
              />
            );
          }}
          ListEmptyComponent={() => <ListEmptyText>No selected movers</ListEmptyText>}
          contentContainerStyle={{paddingBottom: 8}}
          style={{height: 70}}
        />
      )}
    </CrewUsersAndDriversSelectionsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewUsersAndDriversSelections.fragment = gql`
  ${CrewSlotDriverPopover.fragment}

  fragment CrewUsersAndDriversSelections_Crew on Crew {
    id
    organization {
      id
      features {
        isEnabledProjectPageAllSections: isEnabled(feature: "PROJECT_PAGE_ALL_SECTIONS")
      }
    }
    job {
      id
      jobUsers {
        id
        userId
        isOnTrip
        moverPosition {
          id
          name
        }
      }
    }
    crewSlots {
      id
      truck {
        id
        name
      }
      slot {
        id
        index
      }
      driverId
    }
    ...CrewSlotDriverPopover
  }

  fragment CrewUsersAndDriversSelections_Users on User {
    id
    fullName
    hasDriverMoverPosition
    moverPosition {
      id
      name
    }
  }
`;

export default CrewUsersAndDriversSelections;
