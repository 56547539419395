// Libararies
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, usePopover, useSidebar, useResponsive} from '@supermove/hooks';
import {Location, Job, JobModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import CapacityCalendarTableDispatch from 'modules/Calendar/Capacity/components/CapacityCalendarTableDispatch';
import JobTagsButton from 'modules/Calendar/Capacity/components/JobTagsButton';
import JobDispatchNotesPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchNotesPopover';
import JobArrivalTime from 'modules/Job/JobCard/components/JobArrivalTime';
import JobDispatchNotes from 'modules/Job/JobCard/components/JobDispatchNotes';
import JobDispatchStatus from 'modules/Job/JobCard/components/JobDispatchStatus';
import JobProjectComments from 'modules/Job/JobCard/components/JobProjectComments';

// Styled Components
const Container = Styled.View`
  flex: 1;
`;

const TableHeaderContainer = Styled.View`
  flex-direction: column;
  align-items: flex-start;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectTypeColorIndicator = Styled.Text<{color: string}>`
  color: ${({color}) => color};
`;

const LinkWrapper = Styled.ButtonV2`
  flex: 1;
`;

const DispatchNotesContainer = Styled.View`
  max-width: 120px;
`;

// Shared Table Components
const JobName = ({job}: any) => (
  <React.Fragment>
    <ProjectTypeColorIndicator color={job.project.projectType.color}>•</ProjectTypeColorIndicator>
    <Space width={6} />
    {job.project.projectType.name} {job.identifier}
  </React.Fragment>
);

const JobOriginDestination = ({job}: any) => (
  <React.Fragment>
    {Location.getDisplayCityState(job.startLocation)}
    {' - '}
    {Location.getDisplayCityState(job.endLocation)}
  </React.Fragment>
);

const JobTags = ({job, refetch}: any) => {
  return (
    <React.Fragment>
      <Space height={6} />
      <Row>
        <JobTagsButton job={job} refetch={refetch} />
      </Row>
    </React.Fragment>
  );
};

const JobClientName = ({job, responsive, navigator}: any) => {
  const {client} = job.project;
  const isPrimaryClientNameDifferent = client.name !== client.primaryContact.fullName;
  const clientDisplayText = `${client.name}${
    isPrimaryClientNameDifferent ? ` (${client.primaryContact.fullName})` : ''
  }`;
  return (
    <LinkWrapper onPress={() => navigator.push(`/clients/${job.project.client.uuid}`)}>
      <Table.CellLink responsive={responsive} numberOfLines={1}>
        {clientDisplayText}
      </Table.CellLink>
    </LinkWrapper>
  );
};

const JobProjectCommentsWithSidebar = ({job}: any) => {
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });
  return <JobProjectComments job={job} sidebar={projectCommentsSidebar} hideTooltip />;
};

const JobNotes = ({job}: any) => {
  const jobDispatchNotesPopover = usePopover();
  return (
    <React.Fragment>
      <JobDispatchNotes
        job={job}
        popover={jobDispatchNotesPopover}
        numberOfLines={1}
        isResponsive
        hideIcon
        style={{width: '100%'}}
      />
      <JobDispatchNotesPopover
        job={job}
        popover={jobDispatchNotesPopover}
        placement={Popover.Positions.Left}
        refetch={() => null}
      />
    </React.Fragment>
  );
};

// Table Helper Methods
const getJobWeightText = ({job}: any) => `${job.project.weight} lbs`;
const getJobVolumeText = ({job}: any) => `${job.project.volume} ft³`;
const getJobTruckNames = ({job}: any) => {
  const truckNamesText = job.jobTrucks.map((jobTruck: any) => jobTruck.truck.name).join('\n');
  return truckNamesText || 'No trucks assigned';
};
const getJobCrewMemberNames = ({job}: any) => {
  const moverNamesText = job.assignedJobUsers
    .map((jobUser: any) => jobUser.user.fullName)
    .join('\n');
  return moverNamesText || 'No movers assigned';
};

const getCapacityCalendarJobsListTableColumnDefinitions = ({
  navigator,
  refetch,
  responsive,
}: any) => [
  {
    flex: 0.2,
    headerContent: () => {
      return null;
    },
    cellContent: ({item: job}: {item: JobModel}) => {
      return (
        <Table.PreventPropagationContainer>
          <Row>
            <JobDispatchStatus job={job} />
          </Row>
          <JobTags job={job} refetch={refetch} />
        </Table.PreventPropagationContainer>
      );
    },
  },
  {
    flex: 2.5,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Job</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Job Type</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}: {item: JobModel}) => {
      return (
        <Container>
          <Row>
            <Table.CellText numberOfLines={1}>
              <JobName job={job} />
            </Table.CellText>
          </Row>
          <Row>
            <Space width={13} />
            <Table.CellSubtext numberOfLines={1}>{job.jobType.name}</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Origin & Destination</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Mileage</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}: {item: JobModel}) => {
      return (
        <Container>
          <Row>
            <Icon source={Icon.MapPin} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellText numberOfLines={1}>
              <JobOriginDestination job={job} />
            </Table.CellText>
          </Row>
          <Row>
            <Icon source={Icon.Road} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{Job.getTotalDistanceText(job)}</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return (
        <Container>
          <Table.HeaderText numberOfLines={1}>Customer Name</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Weight / Volume</Table.HeaderSubtext>
        </Container>
      );
    },
    cellContent: ({item: job}: {item: JobModel}) => {
      return (
        <Container>
          <Row>
            <Icon source={Icon.User} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <JobClientName job={job} responsive={responsive} navigator={navigator} />
          </Row>
          <Row>
            <Icon source={Icon.WeightHanging} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{getJobWeightText({job})}</Table.CellSubtext>
            <Space width={12} />
            <Icon source={Icon.RulerTriangle} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{getJobVolumeText({job})}</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 1.75,
    minWidth: 120,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Arrival Time</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Est. Hours</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}: {item: JobModel}) => {
      return (
        <Container>
          <Row>
            <Icon source={Icon.Clock} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellText>
              <JobArrivalTime job={job} isLarge />
            </Table.CellText>
          </Row>
          <Row>
            <Icon source={Icon.HourglassClock} color={colors.gray.secondary} size={10} />
            <Space width={6} />
            <Table.CellSubtext>{Job.getDisplayEstimateHours(job)}</Table.CellSubtext>
          </Row>
        </Container>
      );
    },
  },
  {
    flex: 0.75,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Trucks</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Movers</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}: {item: JobModel}) => {
      return (
        <Container>
          <TextTooltip
            isEnabledMobileToast={false}
            text={getJobTruckNames({job})}
            placement={'left'}
          >
            <Row>
              <Icon source={Icon.Truck} color={colors.gray.secondary} size={10} />
              <Space width={6} />
              <Table.CellText>{Job.getDispatchJobTrucksCount(job)}</Table.CellText>
            </Row>
          </TextTooltip>
          <Space width={8} />
          <TextTooltip
            isEnabledMobileToast={false}
            text={getJobCrewMemberNames({job})}
            placement={'left'}
          >
            <Row>
              <Icon source={Icon.User} color={colors.gray.secondary} size={12} />
              <Space width={6} />
              <Table.CellSubtext>{Job.getDispatchJobUsersCount(job)}</Table.CellSubtext>
            </Row>
          </TextTooltip>
        </Container>
      );
    },
  },
  {
    flex: 1.75,
    headerContent: () => {
      return (
        <TableHeaderContainer>
          <Table.HeaderText numberOfLines={1}>Comments</Table.HeaderText>
          <Table.HeaderSubtext numberOfLines={1}>Notes</Table.HeaderSubtext>
        </TableHeaderContainer>
      );
    },
    cellContent: ({item: job}: {item: JobModel}) => {
      return (
        <Table.PreventPropagationContainer>
          <Row>
            <JobProjectCommentsWithSidebar job={job} />
          </Row>
          <Space height={8} />
          <Row>
            <DispatchNotesContainer>
              <JobNotes job={job} />
            </DispatchNotesContainer>
          </Row>
        </Table.PreventPropagationContainer>
      );
    },
  },
];

const CapacityCalendarJobsListTable = ({
  selectedDate,
  organization,
  isJobsListTableExpanded,
  refetchDayView,
  setTotalJobsForDay,
  isTrainingOnly,
}: any) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {
    loading,
    data,
    refetch: refetchJobsListTable,
  } = useQuery(CapacityCalendarJobsListTable.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
      date: selectedDate,
      projectTypes: params.projectTypes,
      projectTagIds: params.projectTagIds,
      jobTagIds: params.jobTagIds,
      salespersons: URL.decodeEmptyStringToNull(params.salespersons),
      searchQuery: params.searchQuery,
      isTrainingOnly: isTrainingOnly || false,
    },
  });
  const refetch = ({shouldFetchJobsListTable = false} = {}) => {
    refetchDayView();
    // Instead of refetching here in every case, we return data from
    // useSetCrewSlotsToCrewMutation and useSetCrewSlotsToCrewMutation in order to
    // update the frontend without needing to reach the loading state. See those
    // files for the data we are returning
    if (shouldFetchJobsListTable) {
      refetchJobsListTable();
    }
  };
  const columnDefinitions = getCapacityCalendarJobsListTableColumnDefinitions({
    navigator,
    refetch,
    responsive,
  });

  if (loading) {
    return <Table.SkeletonLoader columnDefinitions={columnDefinitions} numberOfRows={5} />;
  }

  if (data) {
    setTotalJobsForDay(data.filteredJobsV2.length);
  }
  const emptyStateText = `No ${isTrainingOnly ? 'Training ' : ''}Jobs`;
  return (
    <Table
      columnDefinitions={columnDefinitions}
      emptyStateText={emptyStateText}
      items={data.filteredJobsV2}
      isDense
      itemKey={'id'}
      onRowPress={(job) => navigator.push(`/jobs/${job.uuid}`)}
      isClickable
      rowHook={{
        // The component doesn't need to use the hook or the argument, but we need to pass them in
        hook: () => {},
        hookArgument: {},
        Component: CapacityCalendarTableDispatch,
        componentProps: {
          isVisible: isJobsListTableExpanded,
          viewer: data.viewer,
          refetch,
        },
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarJobsListTable.query = gql`
  ${CapacityCalendarTableDispatch.fragment}
  ${Job.getCalendarPrimaryStatusText.fragment}
  ${Job.getDispatchJobTrucksCount.fragment}
  ${Job.getDispatchJobUsersCount.fragment}
  ${Job.getDisplayEstimateHours.fragment}
  ${Job.getTotalDistanceText.fragment}
  ${JobArrivalTime.fragment}
  ${JobDispatchNotes.fragment}
  ${JobDispatchNotesPopover.fragment}
  ${JobDispatchStatus.fragment}
  ${JobProjectComments.fragment}
  ${JobTagsButton.fragment}
  ${Location.getDisplayCityState.fragment}

  query CapacityCalendarJobsListTable(
    $slugs: [String!]!,
    $date: String!,
    $projectTypes: [Int!],
    $projectTagIds: [Int!],
    $jobTagIds: [Int!],
    $salespersons: [Int],
    $searchQuery: String,
    $isTrainingOnly: Boolean,
  ) {
    ${gql.query}
    filteredJobsV2(
      slugs: $slugs,
      date: $date,
      projectTypes: $projectTypes,
      projectTagIds: $projectTagIds,
      jobTagIds: $jobTagIds,
      salespersons: $salespersons,
      searchQuery: $searchQuery,
      isTrainingOnly: $isTrainingOnly,
    ) {
      id
      uuid
      identifier
      organization {
        id
        isPrimary
      }
      jobTrucks {
        id
        truck {
          id
          name
        }
      }
      assignedJobUsers {
        id
        user {
          id
          fullName
        }
      }
      jobType {
        id
        name
      }
      project {
        id
        weight
        volume
        projectType {
          id
          name
          color
        }
        client {
          id
          uuid
          name
          primaryContact {
            id
            fullName
          }
        }
      }
      startLocation {
        id
        ...Location_getDisplayCityState
      }
      endLocation {
        id
        ...Location_getDisplayCityState
      }
      ...CapacityCalendarTableDispatch_Job
      ...Job_getDispatchJobTrucksCount
      ...Job_getDispatchJobUsersCount
      ...Job_getDisplayEstimateHours
      ...Job_getTotalDistanceText
      ...JobArrivalTime
      ...JobDispatchNotes
      ...JobDispatchNotesPopover
      ...JobDispatchStatus
      ...JobProjectComments
      ...JobTagsButton
    }
    viewer {
      id
      ...CapacityCalendarTableDispatch_User
    }
  }
`;

CapacityCalendarJobsListTable.fragment = gql`
  fragment CapacityCalendarJobsListTable on Organization {
    id
    slug
    isPrimary
  }
`;

export default CapacityCalendarJobsListTable;
