// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Call, CallModel, CommunicationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import CallDirection from '@shared/modules/Call/enums/CallDirection';
import CallStatus from '@shared/modules/Call/enums/CallStatus';
import {CommunicationDirectionType} from '@shared/modules/Communication/enums/CommunicationDirection';
import CommunicationItemStatus from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationItemStatus';
import CommunicationsListItemWrapper from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsListItemWrapper';

const SubjectText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color};
`;

const Text = Styled.Text<{color: string}>`
  ${Typography.Responsive.Body}
  color: ${({color}) => color};
`;

const CallStatusToIcon = (call: CallModel) => {
  if ([CallStatus.ERROR, CallStatus.CANCELLED].includes(call.status)) {
    return {
      icon: Icon.PhoneMissed,
      color: colors.red.warning,
    };
  } else if (call.status === CallStatus.MISSED) {
    return {
      icon: Icon.PhoneSlash,
      color: colors.orange.status,
    };
  } else if (call.direction === CallDirection.INBOUND) {
    return {
      icon: Icon.InboxIn,
      color: colors.green.status,
    };
  } else {
    return {
      icon: Icon.InboxOut,
      color: colors.blue.interactive,
    };
  }
};

const CommunicationsListItemCall = ({
  communication,
  direction,
}: {
  communication: CommunicationModel;
  direction: CommunicationDirectionType;
}) => {
  const responsive = useResponsive();
  const {call} = communication;
  if (!call) {
    return null;
  }

  const isInbound = call.direction === CallDirection.INBOUND;

  return (
    <CommunicationsListItemWrapper
      direction={direction}
      sentInfo={<CommunicationItemStatus communication={communication} responsive={responsive} />}
      displayTime={Call.getDisplayTime(call)}
    >
      <SubjectText
        responsive={responsive}
        color={isInbound ? colors.black : colors.gray.background}
      >
        {Call.getLeadDisplayName(call)}
      </SubjectText>
      <Text responsive={responsive} color={isInbound ? colors.black : colors.gray.background}>
        {call.organization.name}
      </Text>
    </CommunicationsListItemWrapper>
  );
};

CommunicationsListItemCall.fragment = gql`
  ${Call.getLeadDisplayName.fragment}
  ${Call.getDisplayTime.fragment}
  ${CommunicationItemStatus.fragment}

  fragment CommunicationsListItemCall on Communication {
    id
    ...CommunicationItemStatus
    call {
      id
      direction
      organization {
        id
        name
      }
      ...Call_getDisplayTime
      ...Call_getLeadDisplayName
    }
  }
`;

export default CommunicationsListItemCall;
