// Libraries
import React from 'react';

// Components
import Drawer from '@shared/design/components/Drawer';
import GlobalSearchContent from 'modules/App/Global/components/GlobalSearchContent';
import MobileHeader from 'modules/App/Global/components/MobileHeader';

const MobileSearchDrawer = ({isOpen, handleClose}: any) => {
  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} shouldCloseOnClickOutside={false}>
      <MobileHeader handleClose={handleClose} title={'Search'} />
      <GlobalSearchContent handleClose={handleClose} />
    </Drawer>
  );
};

export default MobileSearchDrawer;
