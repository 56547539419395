// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobModel} from '@supermove/models';
import {fontWeight, colors, typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
  align-self: stretch;
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const FlatListSpace = Styled.View`
  height: 10px;
`;

const Item = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
  width: 100px;
`;

const Text = Styled.H6`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
`;

const Name = Styled.H6`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isCompact ? typography.h7() : '')}
`;

const Quantity = Styled.H6`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
`;

const Price = Styled.H6`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isCompact ? typography.h7() : '')}
  color: ${colors.gray.secondary};
`;

const EmptyContainer = Styled.View`
  padding-horizontal: 12px;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ListHeader = ({isCompact, job}: any) => {
  const showPrice = job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;

  return (
    <Header>
      <Cell
        style={{
          flex: 1,
          minWidth: 140,
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Text vars={{isCompact}}>Equipment</Text>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
          minWidth: 200,
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Text vars={{isCompact}}>Quantity Requested</Text>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Text vars={{isCompact}}>Left on Site</Text>
      </Cell>
      {showPrice && (
        <Cell
          style={{
            alignItems: 'flex-end',
          }}
        >
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Text vars={{isCompact}}>Price</Text>
        </Cell>
      )}
    </Header>
  );
};

const CommercialEquipmentItem = ({isCompact, commercialEquipment, job}: any) => {
  const showPrice = job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;

  return (
    <Item>
      <Cell
        style={{
          flex: 1,
          minWidth: 140,
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Name vars={{isCompact}}>{commercialEquipment.name}</Name>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Quantity vars={{isCompact}}>{commercialEquipment.quantityRequested}</Quantity>
      </Cell>
      <Cell
        style={{
          alignItems: 'flex-end',
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Quantity vars={{isCompact}}>{commercialEquipment.quantityDelivered}</Quantity>
      </Cell>
      {showPrice && (
        <Cell
          style={{
            alignItems: 'flex-end',
          }}
        >
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Price vars={{isCompact}}>{Currency.display(commercialEquipment.price)}</Price>
        </Cell>
      )}
    </Item>
  );
};

type CommercialEquipmentsListProps = {
  isCompact?: boolean;
  job: JobModel;
  style?: React.CSSProperties;
};

const CommercialEquipmentsList = ({
  isCompact = false,
  job,
  style = {},
}: CommercialEquipmentsListProps) => {
  return (
    <Container style={style}>
      <FlatList
        listKey={'CommercialEquipmentsList'}
        data={job.commercialCatalog.commercialEquipments}
        keyExtractor={(commercialEquipment, index) => String(index)}
        renderItem={({item: commercialEquipment}) => (
          <CommercialEquipmentItem
            isCompact={isCompact}
            commercialEquipment={commercialEquipment}
            job={job}
          />
        )}
        CellRendererComponent={FlatListCell}
        ItemSeparatorComponent={FlatListSpace}
        ListHeaderComponent={() => (
          <React.Fragment>
            <ListHeader isCompact={isCompact} job={job} />
            <FlatListSpace />
          </React.Fragment>
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No Equipment</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialEquipmentsList.fragment = gql`
  fragment CommercialEquipmentsList on Job {
    id
    organization {
      id
      features {
        isEnabledShowEquipmentAndMaterialsPriceOnDocuments: isEnabled(
          feature: "SHOW_EQUIPMENT_AND_MATERIALS_PRICE_ON_DOCUMENTS"
        )
      }
    }
    commercialCatalog {
      id
      commercialEquipments {
        id
        name
        quantityDelivered
        quantityRequested
        price
      }
    }
  }
`;

export default CommercialEquipmentsList;
