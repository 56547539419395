// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import {PageLoadingIndicator} from 'modules/App/components';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import OrganizationSettingsStaffMoversContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffMoversContent';
import OrganizationSettingsStaffOfficeStaffContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContent';
import OrganizationSettingsStaffOfficeStaffContentV2 from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContentV2';
import OrganizationSettingsStaffPermissionsContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffPermissionsContent';
import OrganizationSettingsStaffPositionsContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffPositionsContent';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const getTabDefinitions = ({isEnabledOfficeInviteStaff}: {isEnabledOfficeInviteStaff: boolean}) => [
  {
    label: 'Office Staff & Admins',
    url: '/settings/staff/office-staff',
    component: isEnabledOfficeInviteStaff ? (
      <OrganizationSettingsStaffOfficeStaffContentV2 />
    ) : (
      <OrganizationSettingsStaffOfficeStaffContent />
    ),
    pageTitle: '',
    pageDescription: '',
  },
  {
    label: 'Crew Members',
    url: '/settings/staff/movers',
    component: <OrganizationSettingsStaffMoversContent />,
    pageTitle: '',
    pageDescription: '',
  },
  {
    label: 'Positions',
    url: '/settings/staff/positions',
    component: <OrganizationSettingsStaffPositionsContent />,
    pageTitle: 'Positions',
    pageDescription: '',
  },
  {
    label: 'Permissions',
    url: '/settings/staff/permissions',
    component: <OrganizationSettingsStaffPermissionsContent />,
    pageTitle: 'Permissions',
    pageDescription: 'Determine how you communicate with your Movers.',
  },
];

const StaffSettingsPageTabContent = ({tabs, currentTabIndex}: any) => {
  return tabs[currentTabIndex].component;
};

const StaffSettingsPage = () => {
  const {navigator} = useNavigationDOM();
  const {data} = useQuery(StaffSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (!data) {
    return (
      <SidebarPageV2 selected={'settings'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const tabs = getTabDefinitions({
    isEnabledOfficeInviteStaff: data.viewer.viewingOrganization.features.isEnabledOfficeInviteStaff,
  });
  const currentTabIndex = _.findIndex(tabs, (tab) => navigator.location.pathname.includes(tab.url));

  return (
    <SettingsPage
      isResponsive
      title={tabs[currentTabIndex].pageTitle}
      description={tabs[currentTabIndex].pageDescription}
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Staff'},
      ]}
      tabs={tabs}
      scrollViewContentContainerStyle={{flex: 1}}
    >
      <StaffSettingsPageTabContent tabs={tabs} currentTabIndex={currentTabIndex} />
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffSettingsPage.query = gql`
  query StaffSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledOfficeInviteStaff: isEnabled(feature: "OFFICE_INVITE_STAFF")
        }
      }
    }
  }
`;

export default StaffSettingsPage;
