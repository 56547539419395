// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobUser, JobUserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import JobUserAvatar from './JobUserAvatar';
import PlaceholderJobUserAvatar from './PlaceholderJobUserAvatar';

const Container = Styled.View`
  flex-direction: row;
  margin-left: ${(props) => ((props as any).hasMargin ? 5 : 0)}px;
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const getPlaceholderAvatars = ({job}: any) => {
  const jobUsersCount = job.activeJobUsers.length;
  if (jobUsersCount >= job.crewSize) {
    return null;
  } else {
    return (
      // @ts-expect-error TS(2769): No overload matches this call.
      <Container hasMargin={jobUsersCount > 0}>
        {_.range(job.crewSize - jobUsersCount).map((index) => (
          <PlaceholderJobUserAvatar
            key={index}
            isLast={index === job.crewSize - jobUsersCount - 1}
          />
        ))}
      </Container>
    );
  }
};

type JobUsersListProps = {
  isCompact?: boolean;
  job: any;
  style?: any;
  onPressJobUser?: (jobUser: JobUserModel) => void;
  header?: React.ReactElement;
};

const JobUsersList = ({
  isCompact = false,
  header,
  job,
  style = {},
  onPressJobUser,
}: JobUsersListProps) => (
  <FlatList
    listKey={'job-users-list'}
    horizontal
    data={JobUser.getJobUsersForJob({job, jobUsers: job.activeJobUsers})}
    keyExtractor={(jobUser: any) => String(jobUser.id)}
    style={style}
    renderItem={({item: jobUser, index}: any) => (
      <JobUserAvatar
        isLast={index === job.activeJobUsers.length - 1}
        jobUser={jobUser}
        onPress={onPressJobUser}
      />
    )}
    ListEmptyComponent={() =>
      isCompact ? (
        <EmptyContainer>
          <EmptyMessage>No crew members</EmptyMessage>
        </EmptyContainer>
      ) : null
    }
    ListHeaderComponent={header}
    ListFooterComponent={!isCompact ? getPlaceholderAvatars({job}) : null}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUsersList.fragment = gql`
  ${JobUserAvatar.fragment}

  fragment JobUsersList on Job {
    id
    crewSize
    calendarPrimaryStatus
    activeJobUsers {
      id
      jobId
      userId
      status
      ...JobUserAvatar
    }
  }
`;

export default JobUsersList;
