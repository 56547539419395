// Supermove
import {useMountEffect, useEffect, useNavigationDOM, useRef, useToast} from '@supermove/hooks';
import {AccountGrant} from '@supermove/models';

// App
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import {CreateAccountGrantFormType} from '@shared/modules/AccountGrant/forms/CreateAccountGrantForm';
import useCreateAccountGrantMutation from '@shared/modules/AccountGrant/hooks/useCreateAccountGrantMutation';

const useConnectAccountGrant = ({
  onSuccess,
  redirectPath,
  createAccountGrantForm,
}: {
  onSuccess: () => void;
  redirectPath: string;
  createAccountGrantForm: CreateAccountGrantFormType;
}) => {
  const {params} = useNavigationDOM();
  const redirectUri = useRef('');
  const errorConnectingAccount = useToast({
    ToastComponent: ErrorToast,
    message: 'Something went wrong connecting your email account',
    duration: Infinity,
  });
  const cannotConnectSupermoveEmailToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Supermove email addresses cannot be connected',
    duration: Infinity,
  });
  const duplicateEmailToast = useToast({
    ToastComponent: ErrorToast,
    message: 'This email address is already connected to another account',
    duration: Infinity,
  });

  const clearUrlParams = () => {
    const url = new URL(window.location.href);
    const withoutParams = url.origin + url.pathname;
    window.history.replaceState({}, document.title, withoutParams);
  };

  const {form, submitting, handleSubmit} = useCreateAccountGrantMutation({
    createAccountGrantForm,
    onSuccess: () => {
      clearUrlParams();
      onSuccess();
    },
    onError: (errors) => {
      if (errors?.[0]?.message?.includes('Disallowed email address')) {
        cannotConnectSupermoveEmailToast.handleToast();
      } else if (errors?.[0]?.message?.includes('Duplicate email address')) {
        duplicateEmailToast.handleToast();
      } else {
        errorConnectingAccount.handleToast();
      }
      clearUrlParams();
    },
  });

  useEffect(() => {
    const redirectUrl = new URL(
      redirectPath,
      `${window.location.protocol}//${window.location.host}`,
    );
    const uri = redirectUrl.toString();
    redirectUri.current = uri;
  }, [redirectPath]);

  useMountEffect(() => {
    if (params.code) {
      form.setFieldValue('nylasCode', params.code);
      form.setFieldValue('redirectUri', redirectUri.current);
      setImmediate(handleSubmit);
    }
  });

  const connectAccountGrant = () => {
    AccountGrant.navigateToNylasOauthUrl(redirectUri.current);
  };

  return {
    connectAccountGrant,
    submitting,
  };
};

export default useConnectAccountGrant;
