// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import IconButton from '@shared/design/components/IconButton';
import Line from 'modules/App/components/Line';

const ProjectInfoContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: center;
  margin-horizontal: 30px;
  justify-content: ${({responsive}) => (responsive.desktop ? 'flex-start' : 'space-between')};
`;

// Placeholder to make justify content act correctly
const RightHeaderPlaceholder = Styled.View`
  width: 40px;  
`;

const RightHeaderContainer = Styled.View`
  align-items: flex-end;
  flex: 1;
`;

const HeaderText = Styled.Text<{responsive: ResponsiveType}>`
  ${({responsive}) => (responsive.mobile ? Typography.Responsive.Heading2 : Typography.Responsive.PageHeading)}
`;

const EmailProjectInfo = ({
  project,
  responsive,
  handleClose,
  HeadingRight,
}: {
  project: ProjectModel;
  responsive: ResponsiveType;
  handleClose: () => void;
  HeadingRight?: () => JSX.Element;
}) => {
  const projectName = Project.getName(project);
  return (
    <React.Fragment>
      <ProjectInfoContainer responsive={responsive}>
        <IconButton
          source={responsive.mobile ? Icon.ChevronLeft : Icon.Xmark}
          onPress={handleClose}
          isSecondary
          size={20}
        />
        <Space width={responsive.desktop ? 16 : 8} />
        <HeaderText responsive={responsive}>{projectName}</HeaderText>
        {HeadingRight ? (
          <RightHeaderContainer>
            <HeadingRight />
          </RightHeaderContainer>
        ) : (
          <RightHeaderPlaceholder />
        )}
      </ProjectInfoContainer>
      <Space height={20} />
      <Line />
    </React.Fragment>
  );
};
// --------------------------------------------------
// Data
// --------------------------------------------------
EmailProjectInfo.fragment = gql`
  ${Project.getName.fragment}

  fragment EmailProjectInfo on Project {
    id
    ...Project_getName
  }
`;

export default EmailProjectInfo;
