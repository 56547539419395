// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useToggle} from '@supermove/hooks';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useUpdateCodatIntegrationMutation from '@shared/modules/Integration/hooks/useUpdateCodatIntegrationMutation';
import AccountingIntegrationCurrencyPanel from 'modules/Organization/Settings/Company/components/AccountingIntegrationCurrencyPanel';
import AccountingIntegrationPaymentPanel from 'modules/Organization/Settings/Company/components/AccountingIntegrationPaymentPanel';
import CodatAutomaticExportSettingsPanel from 'modules/Organization/Settings/Company/components/CodatAutomaticExportSettingsPanel';
import CodatExportSettingsPanel from 'modules/Organization/Settings/Company/components/CodatExportSettingsPanel';
import CodatSalesTaxSettingsPanel from 'modules/Organization/Settings/Company/components/CodatSalesTaxSettingsPanel';
import ProjectDepartmentSettingsPanel from 'modules/Organization/Settings/Company/components/ProjectDepartmentSettingsPanel';
import ShipperAddressSettingsPanel from 'modules/Organization/Settings/Company/components/ShipperAddressSettingsPanel';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

import CodatTrackingCategorySettingsPanel from './components/CodatTrackingCategorySettingsPanel';

const AccountingGeneralContent = ({organization, refetch}: any) => {
  const codatExportSettingsToggle = useToggle();
  const codatAutomaticExportSettingsToggle = useToggle();
  const codatTrackingCategorySettingsToggle = useToggle();
  const rutterShipperAddressSettingsToggle = useToggle();
  const rutterProjectDepartmentSettingsToggle = useToggle();
  const codatSalesTaxSettingsToggle = useToggle();
  const accountingIntegrationCurrencyToggle = useToggle();
  const accountingIntegrationPaymentToggle = useToggle();
  const codatIntegrationForm = CodatIntegrationForm.edit(organization.codatIntegrationByUuid);
  const {form, submitting, handleSubmit} = useUpdateCodatIntegrationMutation({
    codatIntegrationForm,
    onSuccess: () => {
      refetch();
      codatExportSettingsToggle.handleToggleOff();
      codatAutomaticExportSettingsToggle.handleToggleOff();
      codatTrackingCategorySettingsToggle.handleToggleOff();
      codatSalesTaxSettingsToggle.handleToggleOff();
      accountingIntegrationCurrencyToggle.handleToggleOff();
      rutterProjectDepartmentSettingsToggle.handleToggleOff();
      rutterShipperAddressSettingsToggle.handleToggleOff();
      accountingIntegrationPaymentToggle.handleToggleOff();
    },
    onError: (errors: any) => {
      codatExportSettingsToggle.handleToggleOn();
      codatAutomaticExportSettingsToggle.handleToggleOn();
      codatTrackingCategorySettingsToggle.handleToggleOn();
      codatSalesTaxSettingsToggle.handleToggleOn();
      accountingIntegrationCurrencyToggle.handleToggleOn();
      rutterProjectDepartmentSettingsToggle.handleToggleOn();
      rutterShipperAddressSettingsToggle.handleToggleOn();
      accountingIntegrationPaymentToggle.handleToggleOn();

      console.log({errors});
    },
  });
  const codatIntegration = organization.codatIntegrationByUuid;
  const {isEnabledRutterPaymentMethod} = organization.features;
  const showSalesTaxSettings = [
    'SUPERMOVE_INTEGRATION_CODAT_QUICKBOOKS_ONLINE',
    'SUPERMOVE_INTEGRATION_RUTTER_QUICKBOOKS_ONLINE',
  ].includes(codatIntegration.sourceKind);
  return (
    <ScrollView>
      <EditPanel
        index={0}
        bodyComponentProps={{codatIntegration}}
        editBodyComponentProps={{form, codatIntegration}}
        BodyComponent={CodatExportSettingsPanel}
        EditBodyComponent={CodatExportSettingsPanel.Edit}
        title={'Default Accounting Items For Exports'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={form.handleReset}
        isEditing={codatExportSettingsToggle.isOn}
        handleEdit={codatExportSettingsToggle.handleToggleOn}
        handleClose={codatExportSettingsToggle.handleToggleOff}
        width={900}
      />
      <Space height={20} />
      <EditPanel
        index={1}
        bodyComponentProps={{codatIntegration}}
        editBodyComponentProps={{form, codatIntegration}}
        BodyComponent={CodatAutomaticExportSettingsPanel}
        EditBodyComponent={CodatAutomaticExportSettingsPanel.Edit}
        title={'Exports'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={() => {}}
        isEditing={codatAutomaticExportSettingsToggle.isOn}
        handleEdit={codatAutomaticExportSettingsToggle.handleToggleOn}
        handleClose={codatAutomaticExportSettingsToggle.handleToggleOff}
        width={900}
      />
      {organization.features.isEnabledRutterShipperAddress && (
        <React.Fragment>
          <Space height={20} />
          <EditPanel
            index={2}
            bodyComponentProps={{codatIntegration}}
            editBodyComponentProps={{form, codatIntegration}}
            BodyComponent={ShipperAddressSettingsPanel}
            EditBodyComponent={ShipperAddressSettingsPanel.Edit}
            title={'Invoice'}
            handleSave={handleSubmit}
            isSubmitting={submitting}
            handleCancel={() => {}}
            isEditing={rutterShipperAddressSettingsToggle.isOn}
            handleEdit={rutterShipperAddressSettingsToggle.handleToggleOn}
            handleClose={rutterShipperAddressSettingsToggle.handleToggleOff}
            width={900}
          />
        </React.Fragment>
      )}
      <Space height={20} />
      <EditPanel
        index={3}
        bodyComponentProps={{codatIntegration}}
        editBodyComponentProps={{form, codatIntegration}}
        BodyComponent={CodatTrackingCategorySettingsPanel}
        EditBodyComponent={CodatTrackingCategorySettingsPanel.Edit}
        title={'Tracking Category'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={() => {}}
        isEditing={codatTrackingCategorySettingsToggle.isOn}
        handleEdit={codatTrackingCategorySettingsToggle.handleToggleOn}
        handleClose={codatTrackingCategorySettingsToggle.handleToggleOff}
        width={900}
      />
      {organization.features.isEnabledRutterDepartment && (
        <React.Fragment>
          <Space height={20} />
          <EditPanel
            index={4}
            bodyComponentProps={{codatIntegration}}
            editBodyComponentProps={{form, codatIntegration}}
            BodyComponent={ProjectDepartmentSettingsPanel}
            EditBodyComponent={ProjectDepartmentSettingsPanel.Edit}
            title={'Department'}
            handleSave={handleSubmit}
            isSubmitting={submitting}
            handleCancel={() => {}}
            isEditing={rutterProjectDepartmentSettingsToggle.isOn}
            handleEdit={rutterProjectDepartmentSettingsToggle.handleToggleOn}
            handleClose={rutterProjectDepartmentSettingsToggle.handleToggleOff}
            width={900}
          />
        </React.Fragment>
      )}
      <Space height={20} />
      <EditPanel
        index={5}
        bodyComponentProps={{codatIntegration}}
        editBodyComponentProps={{form, codatIntegration, isEnabledRutterPaymentMethod}}
        BodyComponent={AccountingIntegrationPaymentPanel}
        EditBodyComponent={AccountingIntegrationPaymentPanel.Edit}
        title={'Payment'}
        handleSave={handleSubmit}
        isSubmitting={submitting}
        handleCancel={() => {}}
        isEditing={accountingIntegrationPaymentToggle.isOn}
        handleEdit={accountingIntegrationPaymentToggle.handleToggleOn}
        handleClose={accountingIntegrationPaymentToggle.handleToggleOff}
        width={900}
      />
      {showSalesTaxSettings ? (
        <React.Fragment>
          <Space height={20} />
          <EditPanel
            index={6}
            bodyComponentProps={{codatIntegration}}
            editBodyComponentProps={{form, codatIntegration}}
            BodyComponent={CodatSalesTaxSettingsPanel}
            EditBodyComponent={CodatSalesTaxSettingsPanel.Edit}
            title={'Sales Tax'}
            handleSave={handleSubmit}
            isSubmitting={submitting}
            handleCancel={() => {}}
            isEditing={codatSalesTaxSettingsToggle.isOn}
            handleEdit={codatSalesTaxSettingsToggle.handleToggleOn}
            handleClose={codatSalesTaxSettingsToggle.handleToggleOff}
            width={900}
          />
        </React.Fragment>
      ) : null}
      {organization.features.isEnabledRutterCurrency && (
        <React.Fragment>
          <Space height={20} />
          <EditPanel
            index={7}
            bodyComponentProps={{codatIntegration}}
            editBodyComponentProps={{form, codatIntegration}}
            BodyComponent={AccountingIntegrationCurrencyPanel}
            EditBodyComponent={AccountingIntegrationCurrencyPanel.Edit}
            title={'Currency'}
            handleSave={handleSubmit}
            isSubmitting={submitting}
            handleCancel={() => {}}
            isEditing={accountingIntegrationCurrencyToggle.isOn}
            handleEdit={accountingIntegrationCurrencyToggle.handleToggleOn}
            handleClose={accountingIntegrationCurrencyToggle.handleToggleOff}
            width={900}
          />
        </React.Fragment>
      )}
    </ScrollView>
  );
};

const AccoutingGeneralLoading = () => {
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2739): Type '{ index: number; bodyComponentProps: {}; edi... Remove this comment to see the full error message */}
      <EditPanel
        index={0}
        bodyComponentProps={{}}
        editBodyComponentProps={{}}
        BodyComponent={CodatExportSettingsPanel.Loading}
        title={'Default Accounting Items For Exports'}
        handleCancel={() => {}}
        width={900}
        isDisabled
      />
      <Space height={20} />
      {/* @ts-expect-error TS(2739): Type '{ index: number; bodyComponentProps: {}; edi... Remove this comment to see the full error message */}
      <EditPanel
        index={1}
        bodyComponentProps={{}}
        editBodyComponentProps={{}}
        BodyComponent={CodatAutomaticExportSettingsPanel.Loading}
        title={'Exports'}
        handleCancel={() => {}}
        width={900}
        isDisabled
      />
      <Space height={20} />
      {/* @ts-expect-error TS(2739): Type '{ isDisabled: true; index: number; bodyCompo... Remove this comment to see the full error message */}
      <EditPanel
        isDisabled
        index={2}
        bodyComponentProps={{}}
        editBodyComponentProps={{}}
        BodyComponent={CodatTrackingCategorySettingsPanel.Loading}
        title={'Default Accounting Items For Exports'}
        width={900}
      />
      {/* TODO: show this once the flag is on everywhere
      <Space height={20} />
      <EditPanel
        isDisabled
        index={3}
        bodyComponentProps={{}}
        editBodyComponentProps={{}}
        BodyComponent={CodatSalesTaxSettingsPanel.Loading}
        title={'Sales Tax'}
        width={900}
      /> */}
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyIntegrationsAccountingGeneralPage = () => {
  const {navigator, params} = useNavigationDOM();
  const {uuid} = params;
  const {loading, data, refetch} = useQuery(
    OrganizationSettingsCompanyIntegrationsAccountingGeneralPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {
        uuid,
      },
    },
  );

  return (
    <SettingsPage
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {
          name: 'Company Settings',
          onPress: () => navigator.push('/settings/company/business-info'),
        },
        {name: 'Accounting', onPress: () => navigator.push('/settings/company/accounting')},
        {name: 'Accounting Exports'},
      ]}
      tabs={[
        {
          label: 'General',
          url: `/settings/company/accounting/${params.uuid}/general`,
        },
        {
          label: 'Import',
          url: `/settings/company/accounting/${params.uuid}/imports`,
        },
        {
          label: 'Logs',
          url: `/settings/company/accounting/${params.uuid}/logs`,
        },
        {
          label: 'Exports',
          url: `/settings/company/accounting/${params.uuid}/exports`,
        },
      ]}
    >
      <Space height={20} />

      {loading ? (
        <AccoutingGeneralLoading />
      ) : (
        <AccountingGeneralContent
          organization={data.viewer.viewingOrganization}
          refetch={refetch}
        />
      )}
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyIntegrationsAccountingGeneralPage.query = gql`
  ${CodatExportSettingsPanel.fragment}
  ${CodatIntegrationForm.edit.fragment}
  ${CodatAutomaticExportSettingsPanel.fragment}
  ${CodatTrackingCategorySettingsPanel.fragment}
  ${AccountingIntegrationCurrencyPanel.fragment}
  ${AccountingIntegrationPaymentPanel.fragment}

  query OrganizationSettingsCompanyIntegrationsAccountingGeneralPage(
    $uuid: String!
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        codatIntegrationByUuid(uuid: $uuid){
          id
          sourceKind
          ...CodatExportSettingsPanel
          ...CodatAutomaticExportSettingsPanel
          ...CodatIntegrationForm_edit
          ...CodatTrackingCategorySettingsPanel
          ...AccountingIntegrationCurrencyPanel
          ...AccountingIntegrationPaymentPanel
        }
        features {
          isEnabledRutterCurrency: isEnabled(feature: "RUTTER_CURRENCY")
          isEnabledRutterDepartment: isEnabled(feature: "RUTTER_DEPARTMENT")
          isEnabledRutterShipperAddress: isEnabled(feature: "RUTTER_SHIPPER_ADDRESS")
          isEnabledRutterPaymentMethod: isEnabled(feature: "RUTTER_PAYMENT_METHOD")
        }
      }
    }
  } 
`;

export default OrganizationSettingsCompanyIntegrationsAccountingGeneralPage;
