// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ResponsiveType,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {EmailTemplateModel, Project, ProjectModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Line from '@shared/design/components/Line';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import useSendEmailForProjectMutationV1 from '@shared/modules/Email/hooks/useSendEmailForProjectMutationV1';
import EmailProjectInfo from 'modules/Communication/Email/components/EmailProjectInfo';
import EmailGrantExpiredBanner from 'modules/Email/Project/components/EmailGrantExpiredBanner';
import ProjectEmailFields from 'modules/Email/Project/components/ProjectEmailFields';
import EmailAttachmentsSelectorWithDrawer from 'modules/File/Attachment/components/EmailAttachmentsSelectorWithDrawer';

const Container = Styled.View`
  flex: 1;
`;

const Section = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.mobile ? 20 : 30)}px;
`;

const MobileSendContainer = Styled.View`
  background-color: ${colors.white};
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const Row = Styled.View`
`;

const SendButton = ({
  isLoading,
  handleSubmit,
  isDisabled,
  responsive,
}: {
  isLoading: boolean;
  handleSubmit: () => void;
  isDisabled?: boolean;
  responsive: ResponsiveType;
}) => {
  return (
    <Section responsive={responsive}>
      <Button
        text={'Send'}
        onPress={handleSubmit}
        isSubmitting={isLoading}
        isDisabled={isDisabled}
        isWidthOfContainer
        isLarge
      />
    </Section>
  );
};

const getContentWidth = (responsive: ResponsiveType) => {
  if (responsive.desktop) {
    return '60%';
  }
  if (responsive.tablet) {
    return '80%';
  }
  return '100%';
};

const SendEmailForProjectContent = ({
  viewer,
  project,
  emailTemplate,
  onSuccess,
}: {
  viewer: UserModel;
  project: ProjectModel;
  emailTemplate: EmailTemplateModel;
  onSuccess: () => void;
}) => {
  const responsive = useResponsive();

  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);

  const {form, submitting, handleSubmit} = useSendEmailForProjectMutationV1({
    project,
    viewerId: viewer.id,
    emailTemplate,
    onSuccess,
    onError: (errors) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <ScrollView
        style={{flex: 1}}
        contentContainerStyle={{
          paddingBottom: responsive.mobile ? 0 : 40,
          width: getContentWidth(responsive),
          alignSelf: 'center',
        }}
      >
        <Section responsive={responsive}>
          {responsive.mobile ? null : (
            <React.Fragment>
              <Space height={24} />
              <Title responsive={responsive}>Email Draft</Title>
            </React.Fragment>
          )}
          <EmailGrantExpiredBanner viewer={viewer} spacingAboveIfVisible={16} emailType={'new'} />
          <Space height={16} />
          <ProjectEmailFields
            field={'emailForm'}
            form={form}
            viewerId={viewer.id}
            project={project}
            setIsGeneratingPreview={setIsGeneratingPreview}
          />
          <Space height={40} />
          <Row>
            <EmailAttachmentsSelectorWithDrawer form={form} project={project} field={'emailForm'} />
          </Row>
        </Section>
        <Space height={responsive.mobile ? 16 : 40} />
        {responsive.mobile ? null : (
          <SendButton
            isLoading={submitting}
            isDisabled={isGeneratingPreview}
            handleSubmit={handleSubmit}
            responsive={responsive}
          />
        )}
      </ScrollView>
      {responsive.mobile ? (
        <MobileSendContainer>
          <Line />
          <Space height={16} />
          <SendButton
            isLoading={submitting}
            isDisabled={isGeneratingPreview}
            handleSubmit={handleSubmit}
            responsive={responsive}
          />
          <Space height={16} />
        </MobileSendContainer>
      ) : null}
    </React.Fragment>
  );
};

const EmailComposeContent = ({
  viewer,
  project,
  emailTemplate,
}: {
  viewer: UserModel;
  project: ProjectModel;
  emailTemplate: EmailTemplateModel;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <Container>
      <Space height={24} />
      <EmailProjectInfo project={project} responsive={responsive} handleClose={navigator.goBack} />
      <SendEmailForProjectContent
        viewer={viewer}
        project={project}
        emailTemplate={emailTemplate}
        onSuccess={navigator.goBack}
      />
    </Container>
  );
};

const ComposeEmailPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(ComposeEmailPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: params.projectUuid,
      emailTemplateUuid: params.emailTemplateUuid || '',
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <EmailComposeContent
            viewer={data.viewer}
            project={data.project}
            emailTemplate={data.emailTemplate}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ComposeEmailPage.query = gql`
  ${Project.getName.fragment}
  ${EmailAttachmentsSelectorWithDrawer.fragment}
  ${ProjectEmailFields.fragment}
  ${useSendEmailForProjectMutationV1.fragment}
  ${EmailGrantExpiredBanner.fragment}

  query ComposeEmailPage($projectUuid: String!, $emailTemplateUuid: String!) {
    ${gql.query}
    viewer {
      id
      ...EmailGrantExpiredBanner
    }
    project(uuid: $projectUuid) {
      id
      uuid
      ...Project_getName
      ...useSendEmailForProjectMutationV1_Project
      ...EmailAttachmentsSelectorWithDrawer
      ...ProjectEmailFields
    }
    emailTemplate(uuid: $emailTemplateUuid) {
      id
      ...useSendEmailForProjectMutationV1_EmailTemplate
    }
  }
`;

export default ComposeEmailPage;
