// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSheet, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Sheet from '@shared/design/components/Sheet';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import Line from 'modules/App/components/Line';
import ProjectWidgetsMenu from 'modules/Project/V2/Show/components/widgets/ProjectWidgetsMenu';

const COLOR = colors.white;
const MAX_OPACITY = 0.9;
const DISPLAY_ICON_COUNT = 5;
const ICON_SPACING = 16;

const getIconButtonWidth = () => {
  // There is a full space between each icon and a half space on each edge
  const spacesWidth = ICON_SPACING * DISPLAY_ICON_COUNT;
  const spaceForIcons = window.innerWidth - spacesWidth;
  return spaceForIcons / DISPLAY_ICON_COUNT;
};

const getEdgeColor = ({iconWidth, offset}: any) => {
  const iconCenter = iconWidth / 2;
  if (offset > iconCenter) {
    return colors.alpha(COLOR, MAX_OPACITY);
  }

  const opacityRatio = offset / iconCenter;
  return colors.alpha(COLOR, opacityRatio * MAX_OPACITY);
};

const getOpacityGradient = ({scrollEvent}: any) => {
  if (!scrollEvent) {
    // If there is no scrollEvent, we are at the front without any scrolling yet
    return `linear-gradient(90deg, transparent 0%, transparent 80%, ${colors.alpha(
      COLOR,
      MAX_OPACITY,
    )} 100%)`;
  }

  const position = scrollEvent.nativeEvent.contentOffset.x;
  const toolbarWidth = scrollEvent.nativeEvent.layoutMeasurement.width;
  const iconWidth = getIconButtonWidth();
  const frontColor = getEdgeColor({iconWidth, offset: position});
  const backColor = getEdgeColor({iconWidth, offset: toolbarWidth - position});
  return `linear-gradient(90deg, ${frontColor} 0%, transparent 20%, transparent 80%, ${backColor} 100%)`;
};

const ProjectWidgetsContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${COLOR};
`;

const FadeOverlay = Styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80%;
  align-self: flex-end;
  background-image: ${({
    // @ts-expect-error TS(2339): Property 'scrollEvent' does not exist on type 'The... Remove this comment to see the full error message
    scrollEvent,
  }) => getOpacityGradient({scrollEvent})};
`;

const Container = Styled.View`
`;

const FlashIndicator = Styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.red.warning};
  position: absolute;
  top: 7px;
  right: 43px;
`;

const IconButton = ({icon, onPress, isDisabled}: any) => {
  return (
    <TertiaryButton
      onPress={onPress}
      isDisabled={isDisabled}
      style={{paddingVertical: 12, width: getIconButtonWidth()}}
    >
      <Icon
        source={icon}
        color={isDisabled ? colors.gray.tertiary : colors.blue.interactive}
        size={20}
        style={{height: 20, width: 20}}
      />
    </TertiaryButton>
  );
};

const WidgetsMenuButton = ({isDisabled, menuSheet}: any) => {
  return <IconButton icon={Icon.Grid2} isDisabled={isDisabled} onPress={menuSheet.handleOpen} />;
};

const WidgetsMenuSheet = ({project, urlFilters, menuSheet}: any) => {
  return (
    <Sheet headerText={'Menu'} isOpen={menuSheet.isOpen} handleClose={menuSheet.handleClose}>
      <ScrollView contentContainerStyle={{flex: 1}}>
        <ProjectWidgetsMenu project={project} urlFilters={urlFilters} menuSheet={menuSheet} />
        <Space height={16} />
      </ScrollView>
    </Sheet>
  );
};
const WidgetButton = ({kind, urlFilters, isDisabled, isFlashing}: any) => {
  return (
    <Container>
      {isFlashing && <FlashIndicator />}
      <IconButton
        icon={ProjectWidgetKind.getIcon(kind)}
        isDisabled={isDisabled}
        onPress={() => urlFilters.handleUpdate({widget: kind})}
      />
    </Container>
  );
};

const ProjectWidgetsToolbar = ({project, urlFilters, isDisabled}: any) => {
  const {organization} = project;
  const [scrollEvent, setScrollEvent] = useState();
  const menuSheet = useSheet({name: 'Project Widgets Menu Sheet'});

  return (
    <React.Fragment>
      <ProjectWidgetsContainer>
        <Space width={8} />
        <WidgetsMenuButton isDisabled={isDisabled} menuSheet={menuSheet} />
        <Space width={8} />
        <Line style={{width: 1, height: 40, zIndex: 99}} />
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={{paddingVertical: 8}}
          // @ts-expect-error
          onScroll={setScrollEvent}
          scrollEventThrottle={1}
        >
          {ProjectWidgetKind.getOrganizationWidgets({organization}).map((kind) => {
            const hasOpenConversation =
              kind === 'CONVERSATIONS' && ProjectWidgetKind.getHasOpenConversation({project});
            return (
              <React.Fragment key={kind}>
                <Space width={8} />
                <WidgetButton
                  kind={kind}
                  urlFilters={urlFilters}
                  isDisabled={isDisabled}
                  isFlashing={hasOpenConversation}
                />
                <Space width={8} />
              </React.Fragment>
            );
          })}
        </ScrollView>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <FadeOverlay pointerEvents={'none'} scrollEvent={scrollEvent} />
      </ProjectWidgetsContainer>
      <WidgetsMenuSheet project={project} urlFilters={urlFilters} menuSheet={menuSheet} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectWidgetsToolbar.fragment = gql`
  ${ProjectWidgetKind.getOrganizationWidgets.fragment}
  ${ProjectWidgetKind.getHasOpenConversation.fragment}
  ${ProjectWidgetsMenu.fragment}

  fragment ProjectWidgetsToolbar on Project {
    id
    uuid
    organization {
      id
      ...ProjectWidgetKind_getOrganizationWidgets
    }
    ...ProjectWidgetsMenu
    ...ProjectWidgetKind_hasOpenConversation
  }
`;

export default ProjectWidgetsToolbar;
