// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import Styled from '@supermove/components/src/Styled';
import {CommunicationKindModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Tag from '@shared/design/components/Tag';

const Container = Styled.View`
  background-color: ${colors.white};
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
`;

export const allTags: CommunicationKindModel[] = ['EMAIL', 'SMS', 'CALL'];
const tagsToDisplay: Record<CommunicationKindModel, string> = {
  EMAIL: 'Email',
  SMS: 'SMS',
  CALL: 'Call',
};

const CommunicationsWidgetTags = ({
  activeTags,
  handleChangeTags,
}: {
  activeTags: CommunicationKindModel[];
  handleChangeTags: (newActiveTags: CommunicationKindModel[]) => void;
}) => {
  const onPressTag = (tag: CommunicationKindModel) => {
    if (activeTags.includes(tag)) {
      handleChangeTags(activeTags.filter((t) => t !== tag));
    } else {
      handleChangeTags([...activeTags, tag]);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Space width={12} />
        {allTags.map((tag) => {
          const isSelected = activeTags.includes(tag);
          return (
            <React.Fragment key={tag}>
              <Tag
                label={tagsToDisplay[tag]}
                isSelected={isSelected}
                onPress={() => onPressTag(tag)}
              />
              <Space width={12} />
            </React.Fragment>
          );
        })}
      </Container>
    </React.Fragment>
  );
};

export default CommunicationsWidgetTags;
