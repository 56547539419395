// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SectionedDropdownButton from '@shared/design/components/Button/SectionedDropdownButton';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import EmptyState from '@shared/design/components/EmptyState';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import AddTimesheetBillableBlockModal from '@shared/modules/Timesheet/components/AddTimesheetBillableBlockModal';
import AddTimesheetPayrollBlockModal from '@shared/modules/Timesheet/components/AddTimesheetPayrollBlockModal';
import TimesheetPayrollEntries from '@shared/modules/Timesheet/components/TimesheetPayrollEntries';
import useCreateJobEventJobTimesheetUpdatedMutation from '@shared/modules/Timesheet/hooks/useCreateJobEventJobTimesheetUpdatedMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import JobTimetable from 'modules/App/components/JobTimetable';
import JobReportBlock from 'modules/Job/V2/Move/components/JobReportBlock';
import JobTimesheetBillableEntries from 'modules/Project/V2/Show/Blocks/Job/components/JobTimesheetBillableEntries';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import UpdateCommercialReportMoveTimesheetModalV2 from 'modules/ReportMove/Commercial/components/UpdateCommercialReportMoveTimesheetModalV2';
import FinalizeReportMoveModalV2 from 'modules/ReportMove/components/FinalizeReportMoveModalV2';
import UnfinalizeReportMoveModalV2 from 'modules/ReportMove/components/UnfinalizeReportMoveModalV2';

const Row = Styled.View`
flex-direction: row;
align-items: center;
`;

const ContentContainer = Styled.View`
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 24 : 16)}px;
`;

const TimesheetHeader = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
`;

const getActions = ({
  job,
  navigator,
  updateCommercialReportMoveTimesheetModal,
  unfinalizeReportMoveModal,
  finalizeReportMoveModal,
}: any) => {
  return {
    // Basic Actions
    viewGpsTracking: {
      text: 'View GPS Tracking',
      onPress: () => navigator.push(`/jobs/${job.uuid}/timesheet/audit`),
    },
    editReport: {
      text: 'Edit Report',
      onPress: () => navigator.push(`/move-reports/${job.reportMove.uuid}/show`),
    },

    // Additional Actions
    editTimesheet: {
      text: 'Edit Timesheet',
      onPress: updateCommercialReportMoveTimesheetModal.handleOpen,
    },
    finalizeReport: {
      text: job.reportMove?.isFinal ? 'Unlock Report' : 'Finalize Report',
      // A report move is not finalizable if the job is not finalizable.
      // A job is not finalizable if it is the last job and the project is not finalizable.
      // A project is not finalizable if there is an outstanding balance.
      // This tooltip is designed to push people towards the invoice flow.
      isDisabled: !job.isFinalizable,
      tooltip: !job.isFinalizable ? 'Cannot finalize report due to invoice having a balance' : '',
      onPress: job.reportMove?.isFinal
        ? unfinalizeReportMoveModal.handleOpen
        : finalizeReportMoveModal.handleOpen,
    },
  };
};

const getIsViewGpsTrackingOnly = ({job, viewer, isEnabledTimesheetsV2}: any) => {
  const isAdmin = UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewer.role);
  const hasAdditionalActions = Job.getIsCommercial(job) || job.hasJobFeatureCrewPerMoverTimesheet;
  return !Job.getIsAfterMove(job) || (!hasAdditionalActions && !isAdmin) || isEnabledTimesheetsV2;
};

const getDropdownOptions = ({
  job,
  viewer,
  navigator,
  updateCommercialReportMoveTimesheetModal,
  unfinalizeReportMoveModal,
  finalizeReportMoveModal,
  isEnabledTimesheetsV2,
}: any) => {
  const actions = getActions({
    job,
    navigator,
    updateCommercialReportMoveTimesheetModal,
    unfinalizeReportMoveModal,
    finalizeReportMoveModal,
  });
  const hasAdditionalActions = Job.getIsCommercial(job) || job.hasJobFeatureCrewPerMoverTimesheet;
  const {isAuthorizedAccountingActions} = viewer || {};

  // Only audit timesheet is available until after the move or isEnabledTimesheetsV2
  if (getIsViewGpsTrackingOnly({job, viewer, isEnabledTimesheetsV2})) {
    return [actions.viewGpsTracking];
  }

  // If we are after the move...
  if (!hasAdditionalActions) {
    return [
      {
        name: 'Timesheet',
        actions: [actions.viewGpsTracking],
      },
      {
        name: 'Report',
        actions: [actions.editReport],
      },
    ];
  }

  return [
    {
      name: 'Timesheet',
      actions: [actions.viewGpsTracking, ...List.insertIf(!job.isFinal, actions.editTimesheet)],
    },
    {
      name: 'Report',
      actions: [
        actions.editReport,
        ...List.insertIf(isAuthorizedAccountingActions, actions.finalizeReport),
      ],
    },
  ];
};

const ActionsDropdown = ({job, viewer, refetch, responsive, isEnabledTimesheetsV2}: any) => {
  const {navigator} = useNavigationDOM();

  const updateCommercialReportMoveTimesheetModal = useModal({
    name: 'Update Commercial Report Move Timesheet Modal',
    onClose: refetch,
    enableTracking: true,
  });
  const finalizeReportMoveModal = useModal({
    name: 'Finalize Report Move Modal',
    enableTracking: true,
  });
  const unfinalizeReportMoveModal = useModal({
    name: 'Unfinalize Report Move Modal',
    enableTracking: true,
  });

  const dropdownOptions = getDropdownOptions({
    job,
    viewer,
    navigator,
    updateCommercialReportMoveTimesheetModal,
    finalizeReportMoveModal,
    unfinalizeReportMoveModal,
    isEnabledTimesheetsV2,
  });

  const dropdownProps = {
    text: 'Actions',
    isSmall: true,
    menuWidth: 180,
    menuPosition: DropdownButton.MENU_POSITION.RIGHT,
  };

  return (
    <React.Fragment>
      {getIsViewGpsTrackingOnly({job, viewer, isEnabledTimesheetsV2}) ? (
        <DropdownButton
          {...dropdownProps}
          actions={dropdownOptions}
          ButtonComponent={responsive.desktop ? Button : MobileProjectBlockHeader.EllipsisButton}
        />
      ) : (
        <SectionedDropdownButton
          {...dropdownProps}
          // @ts-expect-error
          sections={
            responsive.desktop
              ? dropdownOptions
              : dropdownOptions.map((section) => ({...section, label: (section as any).name}))
          }
          ButtonComponent={responsive.desktop ? Button : MobileProjectBlockHeader.EllipsisButton}
        />
      )}
      <UpdateCommercialReportMoveTimesheetModalV2
        jobUuid={job.uuid}
        isOpen={updateCommercialReportMoveTimesheetModal.isOpen}
        handleClose={updateCommercialReportMoveTimesheetModal.handleClose}
      />
      {job.reportMove && (
        <React.Fragment>
          <FinalizeReportMoveModalV2
            reportMoveId={job.reportMove.id}
            isOpen={finalizeReportMoveModal.isOpen}
            handleClose={finalizeReportMoveModal.handleClose}
            onSuccess={refetch}
          />
          <UnfinalizeReportMoveModalV2
            reportMoveId={job.reportMove.id}
            isOpen={unfinalizeReportMoveModal.isOpen}
            handleClose={unfinalizeReportMoveModal.handleClose}
            onSuccess={refetch}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const PayrollTimesheets = ({
  job,
  addTimesheetPayrollBlockModal,
  refetch,
  responsive,
  viewer,
}: any) => {
  return (
    <React.Fragment>
      <TimesheetPayrollEntries
        job={job}
        paddingHorizontal={responsive.desktop ? 24 : 16}
        onUpdate={refetch}
        viewerRole={viewer.role}
      />
      <AddTimesheetPayrollBlockModal
        key={addTimesheetPayrollBlockModal.key}
        isOpen={addTimesheetPayrollBlockModal.isOpen}
        handleClose={addTimesheetPayrollBlockModal.handleClose}
        job={job}
        onUpdate={refetch}
      />
    </React.Fragment>
  );
};

const BillableTimesheets = ({job, addTimesheetBillableBlockModal, refetch, responsive}: any) => {
  const {handleSubmit} = useCreateJobEventJobTimesheetUpdatedMutation({
    jobEventForm: JobEventForm.new({jobId: job.id}),
    onSuccess: () => {},
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <JobTimesheetBillableEntries
        job={job}
        refetch={refetch}
        responsive={responsive}
        onUpdate={handleSubmit}
        contentContainerStyle={{paddingHorizontal: 24}}
      />
      <AddTimesheetBillableBlockModal
        key={addTimesheetBillableBlockModal.key}
        isOpen={addTimesheetBillableBlockModal.isOpen}
        handleClose={addTimesheetBillableBlockModal.handleClose}
        job={job}
        refetch={refetch}
        onUpdate={handleSubmit}
      />
    </React.Fragment>
  );
};

const TimesheetsContent = ({
  job,
  viewer,
  refetch,
  urlFilters,
  isEnabledTimesheetsV2,
  responsive,
}: any) => {
  const isPayrollTimesheet = urlFilters.get('timesheet') === 'PAYROLL';
  const addTimesheetBillableBlockModal = useModal({name: 'Add Timesheet Billable Block Modal'});
  const addTimesheetPayrollBlockModal = useModal({name: 'Add Timesheet Payroll Block Modal'});

  if (!isEnabledTimesheetsV2) {
    return <JobReportBlock job={job} viewer={viewer} refetch={refetch} sectionIndex={0} />;
  }

  if (_.isEmpty(job.timesheetBillableEntries)) {
    return (
      <EmptyState title={'No timesheets'} message={'Add movers to view and edit timesheets.'} />
    );
  }

  return (
    <React.Fragment>
      <ContentContainer responsive={responsive}>
        <ContextSwitcher
          isFullWidth
          contextDefinitions={[
            {
              label: `Job Timesheet`,
              isSelected: !isPayrollTimesheet,
              onPress: () => {
                urlFilters.handleUpdate({timesheet: 'BILLABLE'});
              },
            },
            {
              label: `Crew Hours`,
              isSelected: isPayrollTimesheet,
              onPress: () => {
                urlFilters.handleUpdate({timesheet: 'PAYROLL'});
              },
            },
          ]}
        />
      </ContentContainer>
      <Space height={24} />
      <Row style={{paddingHorizontal: responsive.desktop ? 24 : 16}}>
        <TimesheetHeader responsive={responsive}>
          {isPayrollTimesheet ? 'Crew Hours' : 'Job Timesheet'}
        </TimesheetHeader>
        <Space style={{flex: 1}} />
        <Button
          iconLeft={Icon.Plus}
          text={'Add Time'}
          onPress={
            isPayrollTimesheet
              ? addTimesheetPayrollBlockModal.handleOpen
              : addTimesheetBillableBlockModal.handleOpen
          }
        />
      </Row>
      <Space height={responsive.desktop ? 24 : 12} />
      {isPayrollTimesheet ? (
        <PayrollTimesheets
          job={job}
          addTimesheetPayrollBlockModal={addTimesheetPayrollBlockModal}
          refetch={refetch}
          responsive={responsive}
          viewer={viewer}
        />
      ) : (
        <BillableTimesheets
          job={job}
          addTimesheetBillableBlockModal={addTimesheetBillableBlockModal}
          refetch={refetch}
          responsive={responsive}
        />
      )}
      <Space height={24} />
      <ContentContainer responsive={responsive}>
        <JobTimetable
          job={job}
          hideWorkTimesheet
          HeaderComponent={() => {
            return (
              <React.Fragment>
                <TimesheetHeader responsive={responsive}>Tablet GPS Tracking</TimesheetHeader>
                <Space height={8} />
              </React.Fragment>
            );
          }}
        />
      </ContentContainer>
    </React.Fragment>
  );
};

const JobTimesheetsBlockContent = ({
  job,
  viewer,
  refetch,
  urlFilters,
  isEnabledTimesheetsV2,
  responsive,
}: any) => {
  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={TimesheetsContent}
          bodyComponentProps={{job, viewer, refetch, urlFilters, isEnabledTimesheetsV2, responsive}}
          ActionButtonComponent={ActionsDropdown}
          actionButtonComponentProps={{job, viewer, refetch, responsive, isEnabledTimesheetsV2}}
          title={'Timesheets'}
          style={{flex: 1, width: '100%'}}
          // @ts-expect-error TS(2322): Type '{ paddingHorizontal: number; } | null' is no... Remove this comment to see the full error message
          bodyStyle={isEnabledTimesheetsV2 ? {paddingHorizontal: 0} : null}
        />
      ) : (
        <React.Fragment>
          <MobileProjectBlockHeader
            title={'Timesheets'}
            ActionsComponent={ActionsDropdown}
            actionsComponentProps={{job, viewer, refetch, responsive}}
            style={{paddingHorizontal: 16}}
          />
          <TimesheetsContent
            job={job}
            viewer={viewer}
            refetch={refetch}
            urlFilters={urlFilters}
            isEnabledTimesheetsV2={isEnabledTimesheetsV2}
            responsive={responsive}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const JobTimesheetsBlockQuery = ({
  jobUuid,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  urlFilters,
  index,
  isEnabledTimesheetsV2,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={JobTimesheetsBlock.query}
      layoutKey={_.get(blockToPositionY, ProjectBlockKind.Job.TIMESHEETS) || layoutKey}
      queryVariables={{jobUuid, isEnabledTimesheetsV2}}
      handleSetPositionY={handleSetPositionY}
      style={responsive.desktop ? null : {paddingVertical: 24}}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}: any) => {
        return (
          <JobTimesheetsBlockContent
            job={data.job}
            viewer={data.viewer}
            refetch={refetch}
            urlFilters={urlFilters}
            isEnabledTimesheetsV2={isEnabledTimesheetsV2}
            responsive={responsive}
          />
        );
      }}
    </ProjectBlockWrapper>
  );
};

const JobTimesheetsBlock = ({
  project,
  jobUuid,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  urlFilters,
  index,
  isEnabledTimesheetsV2,
  projectBlockKind,
}: any) => {
  return (
    <JobTimesheetsBlockQuery
      key={project.jobUsersHash}
      jobUuid={jobUuid}
      blockToPositionY={blockToPositionY}
      handleSetPositionY={handleSetPositionY}
      layoutKey={layoutKey}
      urlFilters={urlFilters}
      index={index}
      isEnabledTimesheetsV2={isEnabledTimesheetsV2}
      projectBlockKind={projectBlockKind}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimesheetsBlock.listener = gql`
  fragment JobTimesheetsBlock_listener on Project {
    id
    jobUsersHash
  }
`;

JobTimesheetsBlock.query = gql`
  ${JobReportBlock.fragment}
  ${Job.getIsAfterMove.fragment}
  ${Job.getIsCommercial.fragment}
  ${AddTimesheetBillableBlockModal.fragment}
  ${JobTimesheetBillableEntries.fragment}
  ${TimesheetPayrollEntries.fragment}
  ${JobTimetable.fragment}

  query JobTimesheetsBlock($jobUuid: String!, $isEnabledTimesheetsV2: Boolean!) {
    ${gql.query}

    viewer {
      id
      role
      isAuthorizedAccountingActions
      ...JobReportBlock_Viewer @skip(if: $isEnabledTimesheetsV2)
    }

    job(uuid: $jobUuid) {
      id
      uuid
      isFinal
      isFinalizable
      hasJobFeatureCrewPerMoverTimesheet: hasJobFeature(kind: "CREW_PER_MOVER_TIMESHEET")
      timesheetBillableEntries {
        id
      }
      reportMove {
        id
        uuid
        isFinal
      }
      ...JobReportBlock_Job @skip(if: $isEnabledTimesheetsV2)
      ...Job_getIsAfterMove
      ...Job_getIsCommercial
      ...JobTimetable
      ...AddTimesheetBillableBlockModal @include(if: $isEnabledTimesheetsV2)
      ...JobTimesheetBillableEntries @include(if: $isEnabledTimesheetsV2)
      ...TimesheetPayrollEntries @include(if: $isEnabledTimesheetsV2)
    }
  }
`;

export default JobTimesheetsBlock;
