// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {UrlFiltersType} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import Line from '@shared/design/components/Line';
import MobileCapacityCalendarDayJobsLists from 'modules/Calendar/Capacity/components/MobileCapacityCalendarDayJobsLists';
import MobileCapacityCalendarHeader from 'modules/Calendar/Capacity/components/MobileCapacityCalendarHeader';
import {CapacityCalendarUrlFiltersType} from 'modules/Calendar/Capacity/types/CapacityCalendarUrlFiltersType';

const MobileCapacityCalendarDay = ({
  organization,
  urlFilters,
  refetch,
}: {
  organization: OrganizationModel;
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  refetch: () => void;
}) => {
  return (
    <React.Fragment>
      <MobileCapacityCalendarHeader
        organization={organization}
        urlFilters={urlFilters}
        refetch={refetch}
        isDay
      />
      <Line />
      <MobileCapacityCalendarDayJobsLists organization={organization} refetch={refetch} />
    </React.Fragment>
  );
};

MobileCapacityCalendarDay.fragment = gql`
  ${MobileCapacityCalendarHeader.fragment}
  ${MobileCapacityCalendarDayJobsLists.fragment}

  fragment MobileCapacityCalendarDay on Organization {
    id
    ...MobileCapacityCalendarHeader
    ...MobileCapacityCalendarDayJobsLists
  }
`;

export default MobileCapacityCalendarDay;
