// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {Dashboard, DashboardModel} from '@supermove/models';
import {Linking} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';
import {existenceFilter, List} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Tag from '@shared/design/components/Tag';
import TextTooltip from '@shared/design/components/TextTooltip';
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
`;

const Column = Styled.View`
  flex-direction: column;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TagsContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
`;

type DashboardsPageViewPanelHeaderProps = {
  dashboard: DashboardModel;
  isNewExploVariables: boolean;
  handleEdit: () => void;
  handleRemove: () => void;
  handleResetFilters: () => void;
  handleSaveFilters: () => void;
};

const DashboardsPageViewPanelHeader = ({
  dashboard,
  isNewExploVariables,
  handleEdit,
  handleRemove,
  handleResetFilters,
  handleSaveFilters,
}: DashboardsPageViewPanelHeaderProps) => {
  const {viewer} = useAppContext();
  const responsive = useResponsive();
  const actionsPopover = usePopover();

  const hasStaffAdminPermissions = UserRole.hasStaffAdminPermissions(viewer?.role);

  const resetAndUpdateViewActions = !responsive.desktop
    ? [
        {
          text: 'Reset Filters',
          isDisabled: !Dashboard.isDashboardFiltersDifferentFromGlobalDashboard(dashboard),
          onPress: handleResetFilters,
        },
        {
          text: 'Save Filters',
          isDisabled: !isNewExploVariables,
          onPress: handleSaveFilters,
        },
      ]
    : [];

  const actions = [
    ...List.insertIf(hasStaffAdminPermissions, {text: 'Edit report details', onPress: handleEdit}),
    ...resetAndUpdateViewActions,
    ...List.insertIf(hasStaffAdminPermissions, {
      text: 'Remove report',
      onPress: handleRemove,
      color: colors.red.warning,
    }),
  ];

  const isTagsPresent = !!dashboard.dashboardTags?.length;
  const isHelpArticleUrlPresent = !!dashboard.globalDashboard?.helpArticleUrl && responsive.desktop;

  const isResetFiltersDisabled =
    !Dashboard.isDashboardFiltersDifferentFromGlobalDashboard(dashboard);

  return (
    <Container>
      <Column>
        <Row>
          <Row style={{flex: 1, justifyContent: 'flex-start'}}>
            <Title responsive={responsive}>{dashboard.name}</Title>
            <Space width={4} />
            {!!actions.length && (
              <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
                <IconButton
                  onPress={actionsPopover.handleToggle}
                  isSecondary
                  source={Icon.EllipsisV}
                />
              </ActionMenuPopover>
            )}
          </Row>
          {responsive.desktop ? (
            <Row>
              <TextTooltip
                text={isResetFiltersDisabled ? 'No filters are applied.' : undefined}
                placement={'top'}
                isEnabledMobileToast={false}
              >
                <Container>
                  <TertiaryButton
                    iconLeft={Icon.ArrowRotateLeft}
                    text='Reset Filters'
                    isSmall
                    isDisabled={isResetFiltersDisabled}
                    onPress={handleResetFilters}
                  />
                </Container>
              </TextTooltip>
              <Space width={16} />
              <TextTooltip
                text={!isNewExploVariables ? 'No filters are applied.' : undefined}
                placement={'top'}
                isEnabledMobileToast={false}
              >
                <Container>
                  <SecondaryButton
                    iconLeft={Icon.Check}
                    text='Save Filters'
                    isSmall
                    isDisabled={!isNewExploVariables}
                    onPress={handleSaveFilters}
                  />
                </Container>
              </TextTooltip>
            </Row>
          ) : (
            <Row>
              {!!dashboard.globalDashboard?.helpArticleUrl && (
                <TertiaryButton
                  iconLeft={Icon.QuestionCircle}
                  iconSize={16}
                  text='Help'
                  onPress={() => {
                    if (dashboard.globalDashboard?.helpArticleUrl) {
                      Linking.openURL(dashboard.globalDashboard?.helpArticleUrl);
                    }
                  }}
                />
              )}
            </Row>
          )}
        </Row>
        {!!dashboard.description && (
          <Column>
            <Space height={12} />
            <Description>{dashboard.description}</Description>
          </Column>
        )}
        {(isTagsPresent || isHelpArticleUrlPresent) && (
          <Column>
            <Space height={12} />
            <Row>
              <TagsContainer style={{flex: 1}}>
                {isTagsPresent &&
                  dashboard.dashboardTags?.filter(existenceFilter).map((dashboardTag) => (
                    <React.Fragment key={dashboardTag.tag?.id}>
                      <Tag label={dashboardTag.tag?.name} />
                      <Space width={8} />
                    </React.Fragment>
                  ))}
              </TagsContainer>
              {isHelpArticleUrlPresent && (
                <TertiaryButton
                  iconLeft={Icon.QuestionCircle}
                  iconSize={16}
                  isDisabled={!dashboard.globalDashboard?.helpArticleUrl}
                  text='Help'
                  onPress={() => {
                    if (dashboard.globalDashboard?.helpArticleUrl) {
                      Linking.openURL(dashboard.globalDashboard?.helpArticleUrl);
                    }
                  }}
                />
              )}
            </Row>
          </Column>
        )}
      </Column>
    </Container>
  );
};

DashboardsPageViewPanelHeader.fragment = gql`
  ${Dashboard.isDashboardFiltersDifferentFromGlobalDashboard.fragment}
  fragment DashboardsPageViewPanelHeaderFragment on Dashboard {
    id
    name
    description
    dashboardCategoryId
    exploVariables
    helpArticleUrl
    dashboardTags {
      id
      tag {
        id
        name
      }
    }
    globalDashboard {
      id
      name
      description
      dashboardCategoryId
      helpArticleUrl
      exploVariables
    }
    ...Dashboard_isDashboardFiltersDifferentFromGlobalDashboard
  }
`;

export default DashboardsPageViewPanelHeader;
