// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  PaginationType,
  ResponsiveType,
  useDebouncedCallback,
  useModal,
  useNavigationDOM,
  usePagination,
  useQuery,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {
  BillItemType,
  BillItemTypeModel,
  BillRuleTypeModel,
  Formula,
  FormulaModel,
  OrganizationModel,
} from '@supermove/models';
import {NavigationType} from '@supermove/navigation/src/NavigationTypes';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SearchBar from '@shared/design/components/SearchBar';
import Table from '@shared/design/components/Table';
import TableBuilder from '@shared/design/components/Table/components/TableBuilder';
import TextTooltip from '@shared/design/components/TextTooltip';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CannotDeleteFormulaModal from 'modules/Organization/Settings/Billing/Formulas/components/CannotDeleteFormulaModal';
import DeleteFormulaModal from 'modules/Organization/Settings/Billing/Formulas/components/DeleteFormulaModal';
import OrganizationSettingsBillingSettingsPage from 'modules/Organization/Settings/Billing/components/OrganizationSettingsBillingSettingsPage';

const LoadingContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ContentContainer = Styled.View`
  max-width: 1000px;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const UsedInLabel = ({count, label}: {count: number; label: string}) => {
  const responsive = useResponsive();
  return (
    <TableBuilder.PrimaryText responsive={responsive}>
      {pluralize(label, count, true)}
    </TableBuilder.PrimaryText>
  );
};

type UsedInItemType = BillItemTypeModel | BillRuleTypeModel | FormulaModel;

const UsedInTooltip = <T extends UsedInItemType>({
  items,
  label,
  handleItemPress,
  getItemText,
}: {
  items: T[];
  label: string;
  handleItemPress: (item: T) => void;
  getItemText: (item: T) => string;
}) => {
  if (items.length === 0) {
    return <UsedInLabel count={0} label={label} />;
  }

  return (
    <Tooltip
      overlay={
        <Container>
          {items.map((item) => (
            <TertiaryButton
              key={item.id}
              textColor={colors.white}
              onPress={() => handleItemPress(item)}
              text={getItemText(item)}
            />
          ))}
        </Container>
      }
    >
      <Container>
        <UsedInLabel count={items.length} label={label} />
      </Container>
    </Tooltip>
  );
};

const UsedInBillItemTypes = ({formula}: {formula: FormulaModel}) => {
  const {navigator} = useNavigationDOM();
  const billItemTypes = formula.billItemTypes.filter((billItemType) => !billItemType.isParent);
  return (
    <UsedInTooltip
      label={'Bill Item'}
      items={billItemTypes}
      handleItemPress={(billItemType: BillItemTypeModel) =>
        navigator.pushNewTab(
          `/settings/billing/billing-libraries/${billItemType.billingLibrary.uuid}/items/fees`,
        )
      }
      getItemText={(billItemType: BillItemTypeModel) =>
        `${BillItemType.getDisplayName(billItemType)} (${billItemType.billingLibrary.name})`
      }
    />
  );
};

const UsedInBillRuleTypes = ({formula}: {formula: FormulaModel}) => {
  const {navigator} = useNavigationDOM();
  const {billRuleTypes} = formula;
  return (
    <UsedInTooltip
      label={'Bill Rule'}
      items={billRuleTypes}
      handleItemPress={(billRuleType: BillRuleTypeModel) =>
        navigator.pushNewTab(
          `/settings/billing/billing-libraries/${billRuleType.billingLibrary.uuid}/items/fees`,
        )
      }
      getItemText={(billRuleType: BillRuleTypeModel) =>
        `${billRuleType.name} (${billRuleType.billingLibrary.name})`
      }
    />
  );
};

const UsedInFormulas = ({formula}: {formula: FormulaModel}) => {
  const {navigator} = useNavigationDOM();
  const {consumingFormulas} = formula;
  return (
    <UsedInTooltip
      label='Formula'
      items={consumingFormulas}
      handleItemPress={(cosumingFormula: FormulaModel) =>
        navigator.pushNewTab(`/settings/billing/formulas/${cosumingFormula.uuid}/edit`)
      }
      getItemText={(cosumingFormula: FormulaModel) => cosumingFormula.name}
    />
  );
};

const getColumnDefinitions = ({
  responsive,
  navigator,
  userId,
  refetch,
}: {
  responsive: ResponsiveType;
  navigator: NavigationType;
  userId: string;
  refetch: () => void;
}) => {
  return [
    {
      width: 280,
      headerLabel: 'Name/Description',
      cellComponent: (formula: FormulaModel) => (
        <TextTooltip
          isEnabledMobileToast={false}
          text={`${formula.name}:\n${formula.description}`}
          style={{maxWidth: '100%'}}
        >
          <Container style={{flex: 1}}>
            <Row style={{flex: 1}}>
              <TableBuilder.PrimaryText responsive={responsive} numberOfLines={1}>
                {formula.name}
              </TableBuilder.PrimaryText>
            </Row>
            {!!formula.description && (
              <React.Fragment>
                <Space height={4} />
                <Row style={{flex: 1}}>
                  <TableBuilder.SecondaryText responsive={responsive} numberOfLines={1}>
                    {formula.description}
                  </TableBuilder.SecondaryText>
                </Row>
              </React.Fragment>
            )}
          </Container>
        </TextTooltip>
      ),
      mobileOptions: {
        isInHeader: true,
      },
    },
    {
      flex: 1,
      headerLabel: 'Input',
      cellComponent: (formula: FormulaModel) => {
        return (
          <TextTooltip
            text={Formula.getFormulaString(formula)}
            numberOfLines={10}
            style={{maxWidth: 500}}
            isEnabledMobileToast={false}
          >
            <TableBuilder.PrimaryText responsive={responsive} numberOfLines={2}>
              {Formula.getFormulaString(formula)}
            </TableBuilder.PrimaryText>
          </TextTooltip>
        );
      },
    },
    {
      width: 160,
      headerLabel: 'Used In',
      cellComponent: (formula: FormulaModel) => {
        return (
          <Container>
            <UsedInFormulas formula={formula} />
            <UsedInBillItemTypes formula={formula} />
            <UsedInBillRuleTypes formula={formula} />
          </Container>
        );
      },
    },
    {
      width: 160,
      headerLabel: 'Last Updated',
      cellText: (formula: FormulaModel) => Formula.getDisplayUpdatedAt(formula),
      secondary: {
        cellText: (formula: FormulaModel) => formula.updatedBy?.fullName || '',
      },
    },
    {
      width: 80,
      actions: (formula: FormulaModel) => {
        const isDeleteable =
          formula.billItemTypes.length === 0 &&
          formula.billRuleTypes.length === 0 &&
          formula.consumingFormulas.length === 0;
        return [
          {
            desktopIcon: Icon.Pen,
            text: 'Edit',
            onPress: () => navigator.push(`/settings/billing/formulas/${formula.uuid}/edit`),
          },
          {
            text: 'Delete',
            style: {color: colors.red.warning},
            onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
            actionHook: {
              hook: useModal,
              hookArgument: {name: 'Delete Formula Modal'},
              renderComponent: ({
                hookKey,
                isOpen,
                handleClose,
              }: {
                hookKey: string;
                isOpen: boolean;
                handleClose: () => void;
              }) => {
                if (formula.isDefaultFormula) {
                  return (
                    <CautionModal
                      title={'Cannot delete formula.'}
                      message={`This is a default formula. It can be modified, but not deleted.`}
                      isOpen={isOpen}
                      handlePressOutside={handleClose}
                      handlePrimaryAction={handleClose}
                      primaryActionText={'Close'}
                    />
                  );
                }
                if (!isDeleteable) {
                  return (
                    <CannotDeleteFormulaModal
                      key={hookKey}
                      formulaUuid={formula.uuid}
                      isOpen={isOpen}
                      handleClose={handleClose}
                    />
                  );
                }
                return (
                  <DeleteFormulaModal
                    key={hookKey}
                    formulaUuid={formula.uuid}
                    userId={userId}
                    isOpen={isOpen}
                    handleClose={handleClose}
                    refetch={refetch}
                  />
                );
              },
            },
          },
        ];
      },
    },
  ];
};

const OrganizationSettingsBillingFormulasPageContent = ({
  organization,
  userId,
  refetch,
  pagination,
  search,
  setSearch,
}: {
  organization: OrganizationModel;
  userId: string;
  refetch: () => void;
  pagination: PaginationType;
  search: string;
  setSearch: (query: string) => void;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  // @ts-expect-error Property 'formulasPaginatedList' does not exist on type 'OrganizationModel'.
  const {formulasPaginatedList} = organization;
  const {formulas} = formulasPaginatedList;
  return (
    <ContentContainer>
      <Container style={responsive.desktop ? {} : {paddingHorizontal: 12}}>
        <HeaderText responsive={responsive}>Billing Formulas</HeaderText>
        <Space height={12} />
        <Row>
          <SearchBar
            isResponsive
            onChangeText={setSearch}
            placeholder='Search by formula name or identifier'
            style={{width: '500px'}}
            defaultValue={search}
          />
          <Space style={{flex: 1, minWidth: 16}} />
          <Button
            iconLeft={Icon.Plus}
            text={'Create'}
            isResponsive
            onPress={() => navigator.push(`/settings/billing/formulas/new`)}
          />
        </Row>
      </Container>
      <Space height={16} />
      <Table
        columnDefinitions={getColumnDefinitions({refetch, navigator, userId, responsive})}
        items={formulas}
        emptyStateText={'No formulas found'}
      />
      <Space height={20} />
      <PaginationBar pagination={pagination} />
      <Space height={36} />
    </ContentContainer>
  );
};

const PageLoader = () => (
  <LoadingContainer>
    <PageLoadingIndicator />
  </LoadingContainer>
);

const OrganizationSettingsBillingFormulasPage = () => {
  const responsive = useResponsive();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const {loading, data, refetch} = useQuery(OrganizationSettingsBillingFormulasPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 50,
      },
      search,
    },
  });
  const handleSearch = useDebouncedCallback((query) => {
    setSearch(query);
    setCurrentPage(1);
  }, 300);
  const pagination = usePagination({
    currentPage,
    paginationMetadata:
      data?.viewer?.viewingOrganization?.formulasPaginatedList?.paginationMetadata || {},
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });
  return (
    <Loading loading={loading} as={PageLoader}>
      {() => (
        <OrganizationSettingsBillingSettingsPage
          contentContainerStyle={responsive.desktop ? {} : {paddingLeft: 0, paddingRight: 0}}
        >
          <OrganizationSettingsBillingFormulasPageContent
            organization={data.viewer.viewingOrganization}
            userId={data.viewer.id}
            refetch={refetch}
            pagination={pagination}
            search={search}
            setSearch={handleSearch}
          />
        </OrganizationSettingsBillingSettingsPage>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsBillingFormulasPage.query = gql`
  ${Formula.getFormulaString.fragment}
  ${Formula.getDisplayUpdatedAt.fragment}
  ${BillItemType.getDisplayName.fragment}
  ${usePagination.fragment}
  query OrganizationSettingsBillingFormulasPage(
    $pagination: PaginationInput!,
    $search: String!
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        formulasPaginatedList(
          pagination: $pagination,
          search: $search
        ) {
          formulas: results {
          id
          name
          uuid
          description
          isDefaultFormula
          updatedBy {
            id
            fullName
          }
          billItemTypes {
            id
            name
            isParent
            billingLibrary {
              id
              uuid
              name
            }
            ...BillItemType_getDisplayName
          }
          billRuleTypes {
            id
            name
            billingLibrary {
              id
              uuid
              name
            }
          }
          consumingFormulas {
            id
            name
            uuid
          }
          ...Formula_getDisplayUpdatedAt
          ...Formula_getFormulaString
          }
          paginationMetadata {
            totalResults
            ...usePagination
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsBillingFormulasPage;
