// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useQuery} from '@supermove/hooks';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import UserForm from '@shared/modules/User/forms/UserForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import UserProfileDrawerFields from 'modules/Organization/Settings/Users/components/UserProfileDrawerFields';

const UserProfilePreviewDrawerContent = ({user}: any) => {
  const userForm = UserForm.edit(user);
  const form = useForm({
    initialValues: {
      // @ts-expect-error TS(2345): Argument of type '{ userId: any; organizationId: a... Remove this comment to see the full error message
      userForm: UserForm.toForm(userForm),
    },
  });

  return <UserProfileDrawerFields form={form} field={'userForm'} isDisabled />;
};

const UserProfilePreviewDrawer = ({isOpen, handleClose, userUuid}: any) => {
  const {data, loading} = useQuery(UserProfilePreviewDrawer.query, {
    variables: {userUuid},
  });

  return (
    <DrawerWithClose isOpen={isOpen} handleClose={handleClose} headerText={'Profile Preview'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <UserProfilePreviewDrawerContent user={data.user} />;
        }}
      </Loading>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserProfilePreviewDrawer.query = gql`
  ${UserForm.edit.fragment}
  query UserProfilePreviewDrawer($userUuid: String!) {
    ${gql.query}
    user(uuid: $userUuid) {
      id
      ...UserForm_edit
    }
  }
`;

export default UserProfilePreviewDrawer;
