// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigationDOM} from '@supermove/hooks';
import {ClientModel} from '@supermove/models';
import {Phone} from '@supermove/utils';

// Components
import GlobalSearchResultBuilder, {
  ResponsiveRow,
  ResponsiveDivider,
} from 'modules/App/Global/components/GlobalSearchResultBuilder';

const Header = ({client}: {client: ClientModel}) => {
  const responsive = useResponsive();
  return (
    <GlobalSearchResultBuilder.NameText responsive={responsive}>
      {`${client.name}`}
    </GlobalSearchResultBuilder.NameText>
  );
};

const Details = ({client}: {client: ClientModel}) => {
  const responsive = useResponsive();
  return (
    <ResponsiveRow style={responsive.mobile ? {flex: 1} : null} responsive={responsive}>
      <GlobalSearchResultBuilder.Detail icon={Icon.User}>
        {client.primaryContact.fullName}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.Phone}>
        {Phone.display(client.primaryContact.phoneNumber)}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.Envelope} style={{flex: 1}}>
        {client.primaryContact.email}
      </GlobalSearchResultBuilder.Detail>
    </ResponsiveRow>
  );
};

const GlobalSearchResultClient = ({
  client,
  handleClose,
}: {
  client: ClientModel;
  handleClose: () => void;
}) => {
  const {navigator} = useNavigationDOM();
  const onPress = () => {
    navigator.push(`/clients/${client.uuid}`);
    handleClose();
  };
  return (
    <GlobalSearchResultBuilder.Container onPress={onPress}>
      <Header client={client} />
      <Space height={8} />
      <Details client={client} />
    </GlobalSearchResultBuilder.Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalSearchResultClient.fragment = gql`
  fragment GlobalSearchResultClient on Client {
    id
    uuid
    name
    primaryContact {
      id
      phoneNumber
      email
      fullName
    }
    organization {
      id
      company {
        id
        name
      }
    }
  }
`;

export default GlobalSearchResultClient;
