// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';

// App
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import CommunicationsWidget from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsWidget';
import ProjectAttachmentsWidget from 'modules/Project/V2/Show/components/widgets/ProjectAttachmentsWidget';
import ProjectCommentsWidget from 'modules/Project/V2/Show/components/widgets/ProjectCommentsWidget';
import ProjectDocumentsWidget from 'modules/Project/V2/Show/components/widgets/ProjectDocumentsWidget';
import ProjectEmailsWidgetV2 from 'modules/Project/V2/Show/components/widgets/ProjectEmailsWidgetV2';
import ProjectEventLogWidget from 'modules/Project/V2/Show/components/widgets/ProjectEventLogWidget';
import ProjectSmsWidget from 'modules/Project/V2/Show/components/widgets/ProjectSmsWidget';
import ProjectTasksWidget from 'modules/Project/V2/Show/components/widgets/ProjectTasksWidget';
import ProjectWorkflowsWidget from 'modules/Project/V2/Show/components/widgets/ProjectWorkflowsWidget';

const handleCloseWidget = ({urlFilters}: any) => {
  return urlFilters.handleReset({
    section: urlFilters.get('section'),
    block: urlFilters.get('block'),
    jobUuid: urlFilters.get('jobUuid'),
    showCancelledJobs: urlFilters.get('showCancelledJobs'),
  });
};

const ProjectWidgetContent = ({project, setIsClosable, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();

  if (params.widget === 'MENU' && responsive.desktop) {
    urlFilters.handleUpdate({widget: null});
  }

  switch (params.widget) {
    case ProjectWidgetKind.ATTACHMENTS:
      return <ProjectAttachmentsWidget project={project} />;
    case ProjectWidgetKind.COMMENTS:
      return <ProjectCommentsWidget projectUuid={project.uuid} setIsClosable={setIsClosable} />;
    case ProjectWidgetKind.DOCUMENTS:
      return <ProjectDocumentsWidget projectUuid={project.uuid} urlFilters={urlFilters} />;
    case ProjectWidgetKind.CONVERSATIONS:
      return <CommunicationsWidget project={project} />;
    case ProjectWidgetKind.EMAILS:
      return <ProjectEmailsWidgetV2 project={project} />;
    case ProjectWidgetKind.SMS:
      return <ProjectSmsWidget project={project} />;
    case ProjectWidgetKind.TASKS:
      return <ProjectTasksWidget projectUuid={project.uuid} urlFilters={urlFilters} />;
    case ProjectWidgetKind.EVENT_LOG:
      return <ProjectEventLogWidget projectUuid={project.uuid} urlFilters={urlFilters} />;
    case ProjectWidgetKind.WORKFLOWS:
      return <ProjectWorkflowsWidget projectUuid={project.uuid} urlFilters={urlFilters} />;
    default:
      return null;
  }
};

ProjectWidgetContent.handleCloseWidget = handleCloseWidget;

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectWidgetContent.fragment = gql`
  ${ProjectAttachmentsWidget.fragment}
  ${ProjectEmailsWidgetV2.fragment}
  ${ProjectSmsWidget.fragment}

  fragment ProjectWidgetContent on Project {
    id
    uuid
    owningOrganization {
      id
      features {
        isEnabledOfficeProjectPageEmailWidgetV2: isEnabled(
          feature: "OFFICE_PROJECT_PAGE_EMAIL_WIDGET_V2"
        )
      }
    }
    ...ProjectAttachmentsWidget
    ...ProjectEmailsWidgetV2
    ...ProjectSmsWidget
  }
`;

export default ProjectWidgetContent;
