// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, usePopover} from '@supermove/hooks';
import {UserFlowStepModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import IconButton from '@shared/design/components/IconButton';
import Text from '@shared/design/components/Text';
import DocumentTemplatePreview from 'modules/Job/V2/Move/components/DocumentTemplatePreview';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
  padding: 12px 16px;
  border-radius: 8px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  align-items: center;
  background-color: ${colors.white};
`;

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ButtonGroup = Styled.View`
  flex-direction: row;
`;

const Touchable = Styled.Touchable``;

const TitleLink = Styled.Text`
  ${Typography.Responsive.Link}
`;

const CreateDocumentFlowRunStepsListItem = ({
  index,
  userFlowStep,
  handleDelete,
}: {
  index: number;
  userFlowStep: UserFlowStepModel;
  handleDelete: () => void;
}) => {
  const documentTemplatePreviewDrawer = useDrawer({
    name: 'Document Template Preview Drawer',
  });

  const actionsPopover = usePopover();
  const actions = [{text: 'Delete', onPress: handleDelete}];

  return (
    <React.Fragment>
      <Container>
        <Column>
          <Row>
            <Touchable onPress={documentTemplatePreviewDrawer.handleOpen}>
              <Text.Link>{userFlowStep.documentTemplate.name}</Text.Link>
            </Touchable>
          </Row>
        </Column>
        <Space width={16} />
        <ButtonGroup>
          <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
            <IconButton onPress={actionsPopover.handleToggle} isSecondary source={Icon.EllipsisV} />
          </ActionMenuPopover>
        </ButtonGroup>
      </Container>
      <DrawerWithClose
        key={documentTemplatePreviewDrawer.key}
        isOpen={documentTemplatePreviewDrawer.isOpen}
        handleClose={documentTemplatePreviewDrawer.handleClose}
        headerText={`Preview: ${userFlowStep.documentTemplate.name}`}
        width={900}
        bodyProps={{bodyScrollStyle: {flex: 1}}}
      >
        <DocumentTemplatePreview documentTemplateUuid={userFlowStep.documentTemplate.uuid} />
      </DrawerWithClose>
    </React.Fragment>
  );
};

CreateDocumentFlowRunStepsListItem.fragment = gql`
  fragment CreateDocumentFlowRunStepsListItem on UserFlowStep {
    id
    documentTemplate {
      id
      uuid
      name
      description
    }
  }
`;

export default CreateDocumentFlowRunStepsListItem;
