// Libraries
import React from 'react';

// App
import {FlatList, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
import {JobRequestItem} from 'modules/JobRequest/components';

const Container = Styled.View`
  margin-bottom: 120px;
`;

const Indicator = Styled.Loading`
`;

const JobRequest = Styled.View`
  margin-top: 10px;
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const Requests = ({jobRequests}: {jobRequests: any[]}) => (
  <FlatList
    listKey={'job-requests-list'}
    data={jobRequests}
    keyExtractor={(jobRequest) => jobRequest.id}
    renderItem={({item: jobRequest, index}) => (
      <JobRequest>
        <JobRequestItem jobRequest={jobRequest} />
      </JobRequest>
    )}
    ListEmptyComponent={() => (
      <EmptyContainer>
        <EmptyMessage>There are no pending move requests</EmptyMessage>
      </EmptyContainer>
    )}
  />
);

const JobRequestsList = () => (
  <Query fetchPolicy={'network-only'} query={JobRequestsList.query}>
    {({loading, data}: any) => (
      <Container>
        {loading ? (
          <Indicator color={colors.gray.secondary} size={'large'} style={{marginTop: 20}} />
        ) : (
          <Requests jobRequests={data.jobRequests} />
        )}
      </Container>
    )}
  </Query>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestsList.query = gql`
  ${JobRequestItem.fragment}

  query JobRequestsList {
    ${gql.query}
    jobRequests {
      id
      preferredDate
      preferredStartTime
      ...JobRequestItem
    }
  }
`;

export default JobRequestsList;
