// Libararies
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const Title = Styled.Text`
    ${Typography.Responsive.Heading2}
    color: ${colors.gray.secondary}
    text-align: center;
`;

const Subtitle = Styled.Text`
    ${Typography.Responsive.Body}
    color: ${colors.gray.tertiary}
    text-align: center;
`;

const CommunicationsInboxProjectConversationNoResults = ({
  responsive,
}: {
  responsive: ResponsiveType;
}) => {
  return (
    <Container>
      <Title responsive={responsive}>No results found.</Title>
      <Space height={8} />
      <Subtitle responsive={responsive}>Filter conversations by emails, sms, calls. </Subtitle>
    </Container>
  );
};
export default CommunicationsInboxProjectConversationNoResults;
