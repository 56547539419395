// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {ScrollView, Space, Styled} from '@supermove/components';
import {ResponsiveType, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {ViewStyleProp} from '@supermove/styles/types';

// Components
import {BreadcrumbType} from '@shared/design/components/Breadcrumbs';
import SinglePaneLayout from '@shared/design/components/Layout/SinglePaneLayout';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';

const PageContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const PageContentHeaderContainer = Styled.View<{
  isResponsive?: boolean;
  responsive: ResponsiveType;
}>`
  padding-horizontal: ${({isResponsive, responsive}) => (isResponsive && !responsive.desktop ? 16 : 0)}px;
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  max-width: 700px;
`;

interface SettingsPageProps {
  children: React.ReactNode;
  breadcrumbs: BreadcrumbType[];
  tabs?: any[]; // TODO: Replace with TabType[], Tabs doesn't expect tab.url
  title?: string;
  description?: string;
  scrollViewStyle?: ViewStyleProp;
  scrollViewContentContainerStyle?: ViewStyleProp;
  hasSubTabs?: boolean;
  hasNoChildrenStyle?: boolean;
  isResponsive?: boolean;
  HeaderComponent?: React.FC | null;
  headerComponentProps?: object;
}

const PageContent = ({
  children,
  title,
  description,
  scrollViewStyle,
  scrollViewContentContainerStyle,
  hasSubTabs,
  hasNoChildrenStyle,
  isResponsive,
  HeaderComponent,
  headerComponentProps,
}: Omit<SettingsPageProps, 'tabs' | 'breadcrumbs'>) => {
  const responsive = useResponsive();

  return (
    <PageContentContainer>
      {hasNoChildrenStyle ? (
        children
      ) : (
        <ScrollView
          style={
            scrollViewStyle || {
              flex: 1,
              paddingHorizontal:
                hasSubTabs || (isResponsive && !responsive.desktop) ? '0px' : '24px',
            }
          }
          contentContainerStyle={scrollViewContentContainerStyle}
        >
          {HeaderComponent ? (
            <HeaderComponent {...headerComponentProps} />
          ) : (
            <PageContentHeaderContainer isResponsive={isResponsive} responsive={responsive}>
              {!!title && (
                <React.Fragment>
                  <Space height={32} />
                  <Title responsive={responsive}>{title}</Title>
                </React.Fragment>
              )}
              {!!description && (
                <React.Fragment>
                  <Space height={12} />
                  <Description responsive={responsive}>{description}</Description>
                </React.Fragment>
              )}
              {!hasSubTabs && <Space height={24} />}
            </PageContentHeaderContainer>
          )}
          {children}
          <Space height={32} />
        </ScrollView>
      )}
    </PageContentContainer>
  );
};

const SettingsPage = ({
  children,
  breadcrumbs,
  tabs = [],
  title = '',
  description = '',
  scrollViewStyle,
  scrollViewContentContainerStyle,
  hasSubTabs = false,
  hasNoChildrenStyle = false,
  isResponsive = false,
  HeaderComponent = null,
  headerComponentProps = {},
}: SettingsPageProps) => {
  const {navigator} = useNavigationDOM();
  const currentTabIndex = _.findIndex(tabs, (tab) => navigator.location.pathname.includes(tab.url));

  return (
    <SidebarPageV2 selected={'settings'}>
      <SinglePaneLayout
        breadcrumbs={breadcrumbs}
        tabOptions={{
          tabs,
          handlePressTab: (tab) => navigator.push(tab.url),
          activeTabIndex: currentTabIndex,
        }}
        paneDefinition={{
          style: {flex: 1},
          bodyContentComponent: (
            <PageContent
              title={title}
              description={description}
              scrollViewStyle={scrollViewStyle}
              scrollViewContentContainerStyle={scrollViewContentContainerStyle}
              hasSubTabs={hasSubTabs}
              hasNoChildrenStyle={hasNoChildrenStyle}
              isResponsive={isResponsive}
              HeaderComponent={HeaderComponent}
              headerComponentProps={headerComponentProps}
            >
              {children}
            </PageContent>
          ),
        }}
      />
    </SidebarPageV2>
  );
};

SettingsPage.TitleText = Title;
SettingsPage.DescriptionText = Description;
SettingsPage.PageContent = PageContent;

export default SettingsPage;
