// Libraries
import React from 'react';

// App
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import WarehouseSettingsPageContent from 'modules/Organization/Settings/Storage/Warehouses/WarehouseSettingsPageContent';

const OrganizationSettingsCompanyWarehousesPage = () => {
  return (
    <CompanySettingsPage
      title={'Warehouses'}
      description={'Manage warehouses and the branch they belong to.'}
    >
      <WarehouseSettingsPageContent isCompanySetting />
    </CompanySettingsPage>
  );
};

export default OrganizationSettingsCompanyWarehousesPage;
