// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useRef,
  useEffect,
  useDebouncedCallback,
  useState,
  usePopover,
  useModal,
} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import PreviewBillForm from '@shared/modules/Billing/forms/PreviewBillForm';
import usePreviewBillMutation from '@shared/modules/Billing/hooks/usePreviewBillMutation';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import SelectBillTemplateModal from 'modules/Bill/components/SelectBillTemplateModal';
import InvoiceBillingFieldsItems from 'modules/Storage/components/InvoiceBillingFieldsItems';

const Container = Styled.View`
`;

const ZIndexContainer = Styled.View<{index?: number}>`
  z-index: ${({index = 0}) => 500 - index};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const AddBillPopover = ({popover, selectBillTemplateModal, handleAddBill}: any) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.BottomStart}
      width={200}
      offset={[0, 4]}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          handleAddBill();
          popover.handleClose();
        }}
      >
        Add new bill
      </ActionMenuPopover.MenuItem>
      <Space height={4} />
      <ActionMenuPopover.MenuItem
        onPress={() => {
          selectBillTemplateModal.handleOpen();
          popover.handleClose();
        }}
      >
        Select bill template
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const AddBillButton = ({form, project, setCurrentBillIndex}: any) => {
  const addBillPopover = usePopover();
  const selectBillTemplateModal = useModal({
    name: 'Select Bill Template Modal',
    enableTracking: true,
  });

  const {billTypes} = project.projectType.billingLibrary;

  const handleAddBill = (billTypeId = null) => {
    const billForms = _.get(form.values, 'invoiceForm.billForms');
    if (billTypeId) {
      const billType = _.find(billTypes, ({id}) => id === billTypeId);
      form.setFieldValue(`invoiceForm.billForms`, [
        ...billForms,
        BillForm.newFromBillType(project, {billType}),
      ]);
    } else {
      form.setFieldValue(`invoiceForm.billForms`, [...billForms, BillForm.new(project)]);
    }

    setCurrentBillIndex(billForms.length);
    selectBillTemplateModal.handleClose();
  };

  return (
    <React.Fragment>
      <Popover.Content innerRef={addBillPopover.ref}>
        <SecondaryButton
          onPress={addBillPopover.handleToggle}
          iconLeft={Icon.Plus}
          text={`Add Bill`}
        />
      </Popover.Content>
      <AddBillPopover
        popover={addBillPopover}
        selectBillTemplateModal={selectBillTemplateModal}
        handleAddBill={handleAddBill}
      />
      <SelectBillTemplateModal
        key={selectBillTemplateModal.key}
        isOpen={selectBillTemplateModal.isOpen}
        handleClose={selectBillTemplateModal.handleClose}
        project={project}
        handleAddBill={handleAddBill}
      />
    </React.Fragment>
  );
};

const InvoiceBillingFields = ({
  form,
  project,
  viewer,
  setGrandTotalLoading,
  grandTotalLoading,
}: any) => {
  const ref = useRef();
  const debounced = useDebouncedCallback((handleSubmit) => {
    handleSubmit();
  }, 1000);

  const previewBillForm = PreviewBillForm.toForm({
    projectId: project.id,
    billForms: form.values.invoiceForm.billForms,
  });

  const {form: previewForm, handleSubmit} = usePreviewBillMutation({
    previewBillForm,
    onSuccess: ({billTotalsPreview}: any) => {
      setGrandTotalLoading(false);
      form.setFieldValue('invoiceForm.billTotalsPreview', billTotalsPreview);
    },
    onError: (errors: any) => console.log({errors}),
  });

  const [currentBillIndex, setCurrentBillIndex] = useState(0);

  useEffect(() => {
    // Set the current previewBillForm from current form after a change
    const newPreviewForm = PreviewBillForm.toForm({
      projectId: project.id,
      billForms: form.values.invoiceForm.billForms,
    });
    // If there is a change in billForm[index] we call the mutation to update invoice totals
    if (
      ref.current &&
      !_.isEmpty((ref.current as any).billForms) &&
      ((ref.current as any).billForms.length !== newPreviewForm.billForms.length ||
        (!_.isEmpty((ref.current as any).billForms[currentBillIndex]) &&
          !_.isEmpty(newPreviewForm.billForms) &&
          (!_.isEqual(
            (ref.current as any).billForms[currentBillIndex].billItemFormsPreSubtotal,
            newPreviewForm.billForms[currentBillIndex].billItemFormsPreSubtotal,
          ) ||
            !_.isEqual(
              (ref.current as any).billForms[currentBillIndex].billItemFormsPostSubtotal,
              newPreviewForm.billForms[currentBillIndex].billItemFormsPostSubtotal,
            ))))
    ) {
      setGrandTotalLoading(true);
      // @ts-expect-error TS(2322): Type '{ projectId: any; billForms: any; }' is not ... Remove this comment to see the full error message
      ref.current = newPreviewForm;
      previewForm.setFieldValue('previewBillForm.billForms', newPreviewForm.billForms);
      // Debounce the mutation and keep the ref so rerendering doesn't remove the debounce
      setTimeout(
        () =>
          debounced(() => {
            setGrandTotalLoading(true);
            handleSubmit();
          }),
        0,
      );
    } else if (_.isEqual(ref.current, newPreviewForm)) {
      // @ts-expect-error TS(2322): Type '{ projectId: any; billForms: any; }' is not ... Remove this comment to see the full error message
      ref.current = newPreviewForm;
    } else {
      // Edge case for first load, we want to call preview on edit
      // @ts-expect-error TS(2322): Type '{ projectId: any; billForms: any; }' is not ... Remove this comment to see the full error message
      ref.current = newPreviewForm;
      handleSubmit();
    }
  }, [
    previewBillForm,
    ref,
    form.values.invoiceForm.billForms,
    handleSubmit,
    previewForm,
    project.id,
    debounced,
    setGrandTotalLoading,
    currentBillIndex,
  ]);

  return (
    <Container>
      <HeaderText>Invoice Items</HeaderText>
      <Space height={12} />
      {form.values.invoiceForm.billForms.map((bill: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <ZIndexContainer index={index}>
              <InvoiceBillingFieldsItems
                form={form}
                bill={project.currentPrimaryBill}
                billFormField={`invoiceForm.billForms[${index}]`}
                project={project}
                viewer={viewer}
                index={index}
                submitting={grandTotalLoading}
                setCurrentBillIndex={setCurrentBillIndex}
                currentBillIndex={currentBillIndex}
              />
            </ZIndexContainer>
            <Space height={12} />
          </React.Fragment>
        );
      })}
      <Space height={16} />
      <Row>
        <AddBillButton form={form} project={project} setCurrentBillIndex={setCurrentBillIndex} />
      </Row>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceBillingFields.fragment = gql`
  ${InvoiceBillingFieldsItems.fragment}
  ${BillForm.new.fragment}
  ${BillForm.newFromBillType.fragment}
  ${SelectBillTemplateModal.fragment}

  fragment InvoiceBillingFields on Project {
    id
    currentPrimaryBill {
      id
      isPrimary
      ...InvoiceBillBillItemsList
    }
    projectType {
      id
    }
    ...SelectBillTemplateModal
    ...InvoiceBillingFieldsItems
    ...BillForm_new
    ...BillForm_newFromBillType
  }
`;

export default InvoiceBillingFields;
