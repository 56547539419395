// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PageLoader, ScrollView, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {
  ResponsiveType,
  useNavigationDOM,
  useResponsive,
  useUrlFilters,
  usePagination,
} from '@supermove/hooks';
import {UserFlowModel, UserFlowRunModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Line from 'modules/App/components/Line';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import DocumentsSettingsDocumentFlowRunsPageContent from 'modules/Organization/Settings/Document/components/DocumentsSettingsDocumentFlowRunsPageContent';
import DocumentsSettingsDocumentFlowRunsPageHeader from 'modules/Organization/Settings/Document/components/DocumentsSettingsDocumentFlowRunsPageHeader';
import {OrganizationSettingsDocumentFlowRunsFiltersType} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowRunsFiltersType';

const Body = Styled.View`
  padding-vertical: 24px;
  max-width: 1200px;
  flex: 1;
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const BreadcrumbsContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-vertical: 4px;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
`;

const DocumentsSettingsDocumentFlowRunsPage = () => {
  const responsive = useResponsive();
  const {params, navigator} = useNavigationDOM();

  const urlFilters = useUrlFilters<OrganizationSettingsDocumentFlowRunsFiltersType>({
    getRoute: () => `/settings/documents/document-flows/${params.userFlowUuid}/runs`,
    filterKeys: ['searchQuery', 'projectTypeIds', 'statuses', 'page'],
  });

  const currentPage = _.toNumber(urlFilters.get('page')) || 1;

  const {data, loading} = useQuery<{
    userFlow: UserFlowModel;
    paginatedList: {
      userFlowRuns: UserFlowRunModel[];
    };
  }>(DocumentsSettingsDocumentFlowRunsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userFlowUuid: params.userFlowUuid,
      searchQuery: urlFilters.get('searchQuery'),
      projectTypeIds: urlFilters.get('projectTypeIds'),
      statuses: urlFilters.get('statuses'),
      pagination: {
        page: currentPage,
        resultsPerPage: responsive.desktop ? 10 : 5,
      },
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page: number) => {
      urlFilters.handleUpdate({page});
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <PageContainer>
        <PageLoader loading={loading} data={data}>
          {({loadedData}) => {
            return (
              <React.Fragment>
                <Space height={16} />
                <BreadcrumbsContainer responsive={responsive}>
                  <Breadcrumbs
                    breadcrumbs={[
                      {name: 'Settings', onPress: () => navigator.push('/settings')},
                      {name: 'Documents', onPress: () => navigator.push('/settings/documents')},
                      {
                        name: 'Flows',
                        onPress: () => navigator.push('/settings/documents/document-flows'),
                      },
                    ]}
                    mobileOptions={{showPreviousScreenOnly: true}}
                  />
                </BreadcrumbsContainer>
                <Space height={16} />
                <Line />
                <ScrollView
                  style={{backgroundColor: colors.gray.background}}
                  contentContainerStyle={{flex: 1}}
                >
                  <Body>
                    <DocumentsSettingsDocumentFlowRunsPageHeader
                      title={loadedData.userFlow.name}
                      urlFilters={urlFilters}
                    />
                    <Space height={24} />
                    <DocumentsSettingsDocumentFlowRunsPageContent
                      userFlowRuns={loadedData.paginatedList.userFlowRuns}
                      pagination={pagination}
                    />
                    <Space height={32} />
                  </Body>
                </ScrollView>
              </React.Fragment>
            );
          }}
        </PageLoader>
      </PageContainer>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentsSettingsDocumentFlowRunsPage.query = gql`
  ${usePagination.fragment}
  ${DocumentsSettingsDocumentFlowRunsPageContent.fragment}

  query DocumentsSettingsDocumentFlowRunsPage(
    $userFlowUuid: String!
    $projectTypeIds: [String!]
    $statuses: [String!]
    $searchQuery: String
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    userFlow(uuid: $userFlowUuid) {
      id
      name
    }

    paginatedList: filteredUserFlowRunsPaginatedList(
      userFlowUuid: $userFlowUuid
      projectTypeIds: $projectTypeIds
      statuses: $statuses
      searchQuery: $searchQuery
      pagination: $pagination
    ) {
      userFlowRuns: results {
        id
        ...DocumentsSettingsDocumentFlowRunsPageContent
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

export default DocumentsSettingsDocumentFlowRunsPage;
