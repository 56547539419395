// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {PopoverType, ResponsiveType} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import Sheet from '@shared/design/components/Sheet';
import {ReplyType} from 'modules/Communication/Email/utils/replyType';

const PopoverBody = Styled.View<{responsive: ResponsiveType}>`
    width: 200px;
    border-radius: 4px;
    border: ${({responsive}) => (responsive.desktop ? `1px solid ${colors.gray.border}` : '0px')};
    background-color: ${colors.white};
    padding-vertical: 12px;
    overflow: visible;
    width: 100%;
`;

const Item = Styled.Text`
    ${Typography.Responsive.Body}
`;

interface EmailReplyPopoverProps {
  responsive: ResponsiveType;
  popover: PopoverType;
  handleRespond: (replyType: ReplyType) => void;
}

const LineItem = ({
  handleRespond,
  text,
  responsive,
}: {
  handleRespond: () => void;
  text: string;
  responsive: ResponsiveType;
}) => {
  return (
    <TertiaryButton
      onPress={handleRespond}
      style={{
        flex: 1,
        paddingHorizontal: 12,
        justifyContent: 'flex-start',
      }}
    >
      <Item responsive={responsive}>{text}</Item>
    </TertiaryButton>
  );
};

const LineItemSpacing = ({responsive}: {responsive: ResponsiveType}) => {
  if (responsive.desktop) {
    return <Space height={12} />;
  }
  return (
    <React.Fragment>
      <Space height={12} />
      <Line />
      <Space height={12} />
    </React.Fragment>
  );
};

const EmailReplyPopoverContent = ({
  responsive,
  handleRespond,
}: {
  responsive: ResponsiveType;
  handleRespond: (replyType: ReplyType) => void;
}) => {
  return (
    <PopoverBody responsive={responsive}>
      {responsive.desktop ? null : (
        <React.Fragment>
          <LineItem
            handleRespond={() => handleRespond('ReplyAll')}
            text='Reply all'
            responsive={responsive}
          />
          <LineItemSpacing responsive={responsive} />
        </React.Fragment>
      )}
      <LineItem handleRespond={() => handleRespond('Reply')} text='Reply' responsive={responsive} />
      <LineItemSpacing responsive={responsive} />
      <LineItem
        handleRespond={() => handleRespond('Forward')}
        text='Forward'
        responsive={responsive}
      />
    </PopoverBody>
  );
};

const EmailReplyPopover = ({
  responsive,
  popover,
  handleRespond: handleRespondProp,
}: EmailReplyPopoverProps) => {
  const handleRespond = (replyType: ReplyType) => {
    popover.handleClose();
    handleRespondProp(replyType);
  };

  return responsive.desktop ? (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 8]}
    >
      <EmailReplyPopoverContent responsive={responsive} handleRespond={handleRespond} />
    </Popover>
  ) : (
    <Sheet.PreventPropagationContainer>
      <Sheet
        isOpen={popover.isOpen}
        handleClose={popover.handleClose}
        HeaderRightComponent={() => (
          <IconButton onPress={popover.handleClose} source={Icon.Xmark} size={20} isSecondary />
        )}
        headerText={'Actions'}
        isFixedHeight={false}
        headerContainerStyle={{backgroundColor: colors.white}}
      >
        <EmailReplyPopoverContent responsive={responsive} handleRespond={handleRespond} />
      </Sheet>
    </Sheet.PreventPropagationContainer>
  );
};

export default EmailReplyPopover;
