// Library
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';
import UserRole from '@shared/modules/User/enums/UserRole';
import {CommunicationInboxProjectListUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';

interface CommunicationInboxUserCompanySelectProps {
  urlFilters: CommunicationInboxProjectListUrlFilterType;
  responsive: ResponsiveType;
  viewer: UserModel;
}

const Container = Styled.View<{responsive: ResponsiveType}>`
  margin-horizontal: 24px;
  background-color: ${colors.white}
  margin-top: ${({responsive}) => (responsive.mobile ? '16px' : '0')};
`;

const tabs = {
  user: {
    key: 'user',
    label: 'My Conversations',
  },
  company: {
    key: 'company',
    label: 'Company Conversations',
  },
};

const CommunicationInboxUserCompanySelect = ({
  urlFilters,
  responsive,
  viewer,
}: CommunicationInboxUserCompanySelectProps) => {
  const hasEditPermissions = _.includes(UserRole.ADMIN_ROLES_PLUS_SUPER, viewer.role);

  if (!hasEditPermissions) {
    return null;
  }

  // On mobile, this only shows on the conversations list panel
  if (responsive.mobile && urlFilters.get('projectUuid')) {
    return null;
  }

  return (
    <Container responsive={responsive}>
      <Tabs
        tabs={[tabs.user, tabs.company]}
        handlePressTab={({key}) => {
          if (key === tabs.user.key) {
            urlFilters.handleUpdate({users: 'self'});
          } else {
            urlFilters.handleUpdate({users: 'all'});
          }
        }}
        activeTabIndex={urlFilters.get('users') === 'all' ? 1 : 0}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxUserCompanySelect.fragment = gql`
  fragment CommunicationInboxUserCompanySelect on User {
    id
    role
  }
`;

export default CommunicationInboxUserCompanySelect;
