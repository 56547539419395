// Libraries
import _ from 'lodash';
import React, {useState} from 'react';

// Supermove
import {DragAndDropList, Form, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useDrawer, useMemo} from '@supermove/hooks';
import {DocumentTemplateModel, OrganizationModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import {UserFlowFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowForm';
import UserFlowStepForm, {
  UserFlowStepFormToFormType,
} from '@shared/modules/UserFlows/forms/UserFlowStepForm';
import DocumentFlowStepDrawer from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/components/DocumentFlowStepDrawer';
import DocumentFlowStepsListItem from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/components/DocumentFlowStepsListItem';

const handleReorder = ({
  handleReorderStart,
  handleReorderEnd,
  form,
  field,
  fromIndex,
  toIndex,
}: {
  handleReorderStart: () => void;
  handleReorderEnd: () => void;
  form: Form<{userFlowForm: UserFlowFormToFormType}>;
  field: string;
  fromIndex: number;
  toIndex: number;
}) => {
  handleReorderStart();
  const userFlowStepForms = _.get<UserFlowStepFormToFormType[]>(form.values, field, []);
  const reorderedUserFlowStepForms = List.move({
    list: userFlowStepForms,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedUserFlowStepForms);
  handleReorderEnd();
};

const getFilteredDocumentTemplates = (
  documentTemplates: DocumentTemplateModel[],
  userFlowStepForms: UserFlowStepFormToFormType[],
  selectedUserFlowStepForm: UserFlowStepFormToFormType | null,
) => {
  // Filtering out the selected document template from the list of document templates to prevent duplicates
  // Except the one that being selected for editing
  const usedDocumentTemplateIds = userFlowStepForms.map(
    (userFlowStep) => userFlowStep.documentTemplateId,
  );
  return documentTemplates
    .filter(
      (documentTemplate) =>
        selectedUserFlowStepForm?.documentTemplateId === documentTemplate.id ||
        !usedDocumentTemplateIds.includes(documentTemplate.id),
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};

const DocumentFlowSteps = ({
  userFlowId,
  organization,
  form,
  field,
}: {
  userFlowId: string | undefined;
  organization: OrganizationModel;
  form: Form<{userFlowForm: UserFlowFormToFormType}>;
  field: string;
}) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();

  const [selectedUserFlowStep, setSelectedUserFlowStep] =
    useState<UserFlowStepFormToFormType | null>(null);
  const createDocumentFlowStepDrawer = useDrawer({name: 'Create Document Flow Step Drawer'});

  const userFlowStepForms = _.get<UserFlowStepFormToFormType[]>(form.values, field, []);

  const handleAddStep = () => {
    setSelectedUserFlowStep(UserFlowStepForm.new({userFlowId}));
    createDocumentFlowStepDrawer.handleOpen();
  };

  const handleSave = (userFlowStep: UserFlowStepFormToFormType) => {
    if (selectedUserFlowStep && userFlowStepForms.includes(selectedUserFlowStep)) {
      const updatedUserFlowSteps = userFlowStepForms.map((step) =>
        step.documentTemplateId === selectedUserFlowStep.documentTemplateId ? userFlowStep : step,
      );
      form.setFieldValue(field, updatedUserFlowSteps);
    } else {
      form.setFieldValue(field, [...userFlowStepForms, userFlowStep]);
    }
    form.setTouched({[field]: true});
    setSelectedUserFlowStep(null);
    createDocumentFlowStepDrawer.handleClose();
  };

  const handleEdit = (index: number) => {
    setSelectedUserFlowStep(userFlowStepForms[index]);
    createDocumentFlowStepDrawer.handleOpen();
  };

  const handleDelete = (index: number) => {
    form.setFieldValue(
      field,
      userFlowStepForms.filter((_, i) => i !== index),
    );
  };

  const documentTemplatesMap = _.keyBy(organization.documentTemplates, 'id');
  const filteredDocumentTemplates = useMemo(
    () =>
      getFilteredDocumentTemplates(
        organization.documentTemplates,
        userFlowStepForms,
        selectedUserFlowStep,
      ),
    [organization.documentTemplates, userFlowStepForms, selectedUserFlowStep],
  );

  return (
    <React.Fragment>
      <DragAndDropList
        isReordering={isReordering}
        onReorder={({fromIndex, toIndex}) =>
          handleReorder({
            handleReorderStart,
            handleReorderEnd,
            form,
            field,
            fromIndex,
            toIndex,
          })
        }
        spaceBetweenItems={8}
      >
        {userFlowStepForms
          .filter((userFlowStepForm) => !!userFlowStepForm.documentTemplateId)
          .map((userFlowStepForm, index) => (
            <DocumentFlowStepsListItem
              key={`${userFlowStepForm.documentTemplateId}-${index}`}
              index={index}
              userFlowStepForm={userFlowStepForm}
              documentTemplate={documentTemplatesMap[userFlowStepForm.documentTemplateId!]}
              handleEdit={() => handleEdit(index)}
              handleDelete={() => handleDelete(index)}
            />
          ))}
      </DragAndDropList>
      <TertiaryButton text={'Add Document'} iconLeft={Icon.Plus} onPress={handleAddStep} />
      {selectedUserFlowStep && (
        <DocumentFlowStepDrawer
          key={selectedUserFlowStep.userFlowStepId}
          isOpen={createDocumentFlowStepDrawer.isOpen}
          userFlowStep={selectedUserFlowStep}
          documentTemplates={filteredDocumentTemplates}
          handleSave={handleSave}
          handleClose={() => {
            setSelectedUserFlowStep(null);
            createDocumentFlowStepDrawer.handleClose();
          }}
        />
      )}
    </React.Fragment>
  );
};

DocumentFlowSteps.fragment = gql`
  ${DocumentFlowStepDrawer.fragment}
  ${DocumentFlowStepsListItem.fragment}
  fragment DocumentFlowSteps on Organization {
    id
    documentTemplates {
      id
      ...DocumentFlowStepsListItem
      ...DocumentFlowStepDrawer
    }
  }
`;

export default DocumentFlowSteps;
