// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {BillItemType, BillItemTypeModel} from '@supermove/models';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import CannotDeleteModal from 'modules/Organization/Settings/Billing/components/CannotDeleteModal';

const LoadingModal = () => (
  <SmallModal isOpen>
    <Space height={24} />
    <PageLoadingIndicator />
    <Space height={24} />
  </SmallModal>
);

const CannotDeleteFormulaModal = ({
  formulaUuid,
  isOpen,
  handleClose,
}: {
  formulaUuid: string;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const {loading, data} = useQuery(CannotDeleteFormulaModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {formulaUuid},
    skip: !isOpen,
  });
  if (!isOpen) return null;
  return (
    <Loading loading={loading} as={LoadingModal}>
      {() => (
        <CannotDeleteModal
          titleText='Unable to delete'
          description='The formula is connected to active bill items or bill rules or nested formulas and cannot be deleted.'
          listItems={[
            ...data.formula.billItemTypes.filter(
              (billItemType: BillItemTypeModel) => !billItemType.isParent,
            ),
            ...data.formula.billRuleTypes,
            ...data.formula.consumingFormulas,
          ]}
          cannotDeleteModal={{handleClose, isOpen}}
          // TODO(jholston): Since this is a bit messy, using any for now.
          getListItemText={(billItemTypeOrBillRuleType: any) =>
            billItemTypeOrBillRuleType.moverPosition
              ? BillItemType.getDisplayName(billItemTypeOrBillRuleType)
              : billItemTypeOrBillRuleType.name
          }
          getListItemUrl={(billItemType: any) =>
            `/settings/billing/billing-libraries/${billItemType.billingLibrary.uuid}/items/fees`
          }
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CannotDeleteFormulaModal.query = gql`
  ${BillItemType.getDisplayName.fragment}
  query CannotDeleteFormulaModal ($formulaUuid: String!) {
    ${gql.query}
    formula: formulaByUuid(uuid: $formulaUuid) {
      id
      consumingFormulas {
        id
        name
      }
      billItemTypes {
        id
        name
        isParent
        billingLibrary {
          id
          uuid
        }
        ...BillItemType_getDisplayName
      }
      billRuleTypes {
        id
        name
        billingLibrary {
          id
          uuid
        }
      }
    }
  }
`;

export default CannotDeleteFormulaModal;
