// Libraries
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, PopoverType, ResponsiveType, useEffect, useForm} from '@supermove/hooks';
import {OrganizationModel, ProjectTypeModel} from '@supermove/models';
import {Typography} from '@supermove/styles';
import Datetime, {MomentType} from '@supermove/utils/src/Datetime';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import {CommunicationInboxProjectListUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

interface ConversationInboxProjectListFilterPopoverProps {
  activeFilterCount: number;
  popover: PopoverType;
  urlFilters: CommunicationInboxProjectListUrlFilterType;
  organization: OrganizationModel;
  responsive: ResponsiveType;
}

interface FilterPopoverForm {
  fromDate?: MomentType;
  toDate?: MomentType;
  projectTypeIds: string[];
  projectTagIds: string[];
}

const ContentContainer = Styled.View<{responsive: ResponsiveType; index: number}>`
  padding-horizontal:${({responsive}) => (responsive.desktop ? 0 : 16)}px;
  z-index: ${({index}) => 100 - index}
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-top: 32px;
`;

const FilterContainer = Styled.View<{index: number}>`
  flex-direction: row;
  z-index: ${({index}) => 100 - index}
`;

const ButtonContainer = Styled.View<{responsive: ResponsiveType; index: number}>`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-horizontal:${({responsive}) => (responsive.desktop ? 0 : 16)}px;
  z-index: ${({index}) => 100 - index}
`;

const ProjectTagFilter = ({
  organization,
  form,
  index,
}: {
  organization: OrganizationModel;
  form: Form<FilterPopoverForm>;
  index: number;
}) => {
  const filterByArchivedTag = organization.companySettings.tags.filter((tag) => !tag.isArchived);
  const projectTags = filterByArchivedTag.filter((tag) => tag.category === TagCategory.PROJECT);

  const tagOptions = projectTags
    ? projectTags.map((tag) => ({
        value: tag.id,
        label: `${tag.emoji} ${tag.name}`,
      }))
    : [];

  return (
    <TagDropdownInputField
      options={tagOptions}
      placeholder={'Select project tags'}
      label={'Project Tags'}
      index={index}
      value={form.values?.projectTagIds}
      onChangeValue={(projectTagIds: string) => {
        form.setFieldValue('projectTagIds', projectTagIds);
      }}
    />
  );
};

const FromDateFilter = ({form, index}: {form: Form<FilterPopoverForm>; index: number}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={'fromDate'}
      label={'Start Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ToDateFilter = ({form, index}: {form: Form<FilterPopoverForm>; index: number}) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={'toDate'}
      label={'End Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        position: 'bottom-end',
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ProjectTypeFilter = ({
  index,
  form,
  projectTypes,
}: {
  index: number;
  form: Form<FilterPopoverForm>;
  projectTypes: ProjectTypeModel[];
}) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownCheckboxInput}
      name={'projectTypeIds'}
      isResponsive
      label={'Project Types'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: projectTypes.map((projectType) => ({
          label: projectType.name,
          value: projectType.id,
        })),
        placeholder: 'Select project types',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        usePills: true,
        isResponsive: true,
      }}
    />
  );
};

const ProjectFilters = ({
  organization,
  form,
}: {
  organization: OrganizationModel;
  form: Form<FilterPopoverForm>;
}) => {
  const {projectTypesForFilters} = organization;

  return (
    <React.Fragment>
      <Space height={8} />
      <FilterContainer index={1}>
        <FromDateFilter form={form} index={2} />
        <Space width={8} />
        <BodyText>-</BodyText>
        <Space width={8} />
        <ToDateFilter form={form} index={3} />
      </FilterContainer>
      <Space height={8} />
      <ProjectTypeFilter projectTypes={projectTypesForFilters} form={form} index={4} />
      <Space height={8} />
      <ProjectTagFilter organization={organization} form={form} index={5} />
      <Space height={24} />
    </React.Fragment>
  );
};

const initialFormValues: FilterPopoverForm = {
  projectTypeIds: [],
  projectTagIds: [],
};

const getValuesFromFilters = (urlFilters: CommunicationInboxProjectListUrlFilterType) => {
  return {
    fromDate: urlFilters.get('fromDate')
      ? Datetime.fromDate(urlFilters.get('fromDate'))
      : undefined,
    toDate: urlFilters.get('toDate') ? Datetime.fromDate(urlFilters.get('toDate')) : undefined,
    projectTypeIds: urlFilters.get('projectTypeIds') ?? [],
    projectTagIds: urlFilters.get('projectTagIds') ?? [],
  };
};

const ConversationInboxProjectListFilterPopover = ({
  activeFilterCount,
  popover,
  organization,
  responsive,
  urlFilters,
}: ConversationInboxProjectListFilterPopoverProps) => {
  const form = useForm<FilterPopoverForm>({
    initialValues: getValuesFromFilters(urlFilters),
  });
  const handleApply = () => {
    urlFilters.handleUpdate({
      fromDate: form.values.fromDate ? Datetime.toDate(form.values.fromDate) : undefined,
      toDate: form.values.toDate ? Datetime.toDate(form.values.toDate) : undefined,
      projectTypeIds: form.values.projectTypeIds,
      projectTagIds: form.values.projectTagIds,
    });
    popover.handleClose();
  };

  useEffect(() => {
    // Reset form values to URL when popover is closed
    if (!popover.isOpen) {
      form.setValues(getValuesFromFilters(urlFilters));
    }
  }, [popover.isOpen]);

  return (
    <PopoverFilter
      activeFiltersCount={activeFilterCount}
      hideActionButtons
      popover={popover}
      isScrolling
      responsive={responsive}
      handleApply={popover.handleClose}
      handleClear={() => form.setValues(initialFormValues)}
    >
      <React.Fragment>
        <ContentContainer responsive={responsive} index={0}>
          <ProjectFilters organization={organization} form={form} />
        </ContentContainer>
        <ButtonContainer responsive={responsive} index={1}>
          <TertiaryButton text={'Cancel'} onPress={popover.handleClose} />
          <Space width={48} />
          <Button text={'Apply'} onPress={handleApply} iconLeft={Icon.Check} />
        </ButtonContainer>
        <Space height={16} />
      </React.Fragment>
    </PopoverFilter>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConversationInboxProjectListFilterPopover.fragment = gql`
  ${TagDropdownInputField.fragment}

  fragment ConversationInboxProjectListFilterPopover on Organization {
    id
    projectTypesForFilters(category: "MOVE") {
      id
      name
    }
    companySettings {
      tags {
        id
        name
        emoji
        isArchived
        category
        ...TagDropdownInputField
      }
    }
  }
`;

export default ConversationInboxProjectListFilterPopover;
