// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useTextInput, useForm, useEffect, Form, useResponsive} from '@supermove/hooks';
import {Formula, OrganizationModel, OrganizationVariable} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import {EvaluateFormulaStringInput} from '@shared/formulas/src/library/EvaluateFormulaStringInput';
import {fixedArityOne} from '@shared/formulas/src/library/arity';
import {ensureString} from '@shared/formulas/src/library/types';
import evaluateFormulaString, {getVariable} from '@shared/formulas/src/logic/evaluateFormulaString';
import ExtractFormulaIdentifiersPlugin from '@shared/formulas/src/plugins/ExtractFormulaIdentifiersPlugin';
import ExtractVariableNamesPlugin from '@shared/formulas/src/plugins/ExtractVariableNamesPlugin';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import {FormulaFormTypeToForm} from '@shared/modules/Billing/forms/FormulaForm';
import Line from 'modules/App/components/Line';
import FormulaEditorDocumentationSection from 'modules/Organization/Settings/Billing/Formulas/components/FormulaEditorDocumentationSection';

const Container = Styled.View`
`;

const ErrorContainer = Styled.View`
  height: 36px;
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const DebugSectionLabel = Styled.Text`
  ${Typography.Body3}
  padding-horizontal: 8px;
`;

const DebugSectionText = Styled.Text`
  ${Typography.Body3}
  background: ${colors.gray.background};
  padding-horizontal: 8px;
`;

const handleInsertTextAtIndex = ({form, field, formulaString, text, startIndex, endIndex}: any) => {
  const firstFormulaHalf = formulaString.slice(0, startIndex);
  const secondFormulaHalf = formulaString.slice(endIndex);
  const newFormulaString = `${firstFormulaHalf}${text}${secondFormulaHalf}`;
  form.setFieldValue(`${field}.formulaString`, newFormulaString);
};

const FormulaParserSection = ({formulaAST}: any) => {
  return (
    <Container>
      <DebugSectionLabel>Parsed Formula AST</DebugSectionLabel>
      <DebugSectionText>{JSON.stringify(formulaAST)}</DebugSectionText>
    </Container>
  );
};

const FormulaEvaluatorSection = ({formulaResult, variablesUsed}: any) => {
  return (
    <Container>
      <DebugSectionLabel>Formula Result</DebugSectionLabel>
      <DebugSectionText>{JSON.stringify(formulaResult)}</DebugSectionText>
      {variablesUsed.length ? (
        <React.Fragment>
          <DebugSectionLabel>Variables Used</DebugSectionLabel>
          {variablesUsed.map((variable: any) => {
            return <DebugSectionText key={variable}>{variable}</DebugSectionText>;
          })}
        </React.Fragment>
      ) : null}
    </Container>
  );
};

const getCustomFunctionImplementations = (variables: any, formulas: any) => {
  const customFunctionImplementations = {};
  if (variables.length > 0) {
    (customFunctionImplementations as any).var = {
      checkArity: fixedArityOne('var'),
      checkTypes: ensureString('var'),
      call: (varName: any) => {
        const variable = getVariable(varName, variables);
        if (
          variable &&
          [VariableFormat.DROPDOWN_STRING, VariableFormat.STRING].includes(variable.format)
        ) {
          return `{${varName}}`;
        }
        return 0;
      },
    };
    (customFunctionImplementations as any).value = {
      checkArity: fixedArityOne('value'),
      checkTypes: ensureString('value'),
      call: (varName: any) => {
        const variable = getVariable(varName, variables);
        if (
          variable &&
          [VariableFormat.DROPDOWN_STRING, VariableFormat.STRING].includes(variable.format)
        ) {
          return `{${varName}}`;
        }
        return 0;
      },
    };
    (customFunctionImplementations as any).formula = {
      checkArity: fixedArityOne('formula'),
      checkTypes: ensureString('formula'),
      call: (formulaIdentifier: any) => {
        const formula = formulas.find((formula: any) => formula.identifier === formulaIdentifier);
        // TODO: recursive formula eval
        if (formula) {
          const {formulaResult} = evaluateFormulaString({
            formula: Formula.getFormulaString(formula),
            variables,
            plugins: [],
            customFunctionImplementations: {
              var: (customFunctionImplementations as any).var,
              value: (customFunctionImplementations as any).value,
              formula: {
                checkArity: fixedArityOne('formula'),
                checkTypes: ensureString('formula'),
                // we can't really do recursive nested formula references on the FE so we'll return zero one formula down
                call: (formulaIdentifier) => {
                  return 0;
                },
              },
            },
          });
          return formulaResult;
        }
        return 0;
      },
    };

    return customFunctionImplementations;
  }
};

const FormulaEditorDebugSection = ({formulaString, variables, formulas}: any) => {
  const customFunctionImplementations = getCustomFunctionImplementations(variables, formulas);
  const {formulaAST, formulaResult, plugins} = evaluateFormulaString(
    new EvaluateFormulaStringInput(
      formulaString,
      variables,
      [new ExtractVariableNamesPlugin(formulas)],
      customFunctionImplementations,
    ),
  );
  const variablesUsed = plugins[0].getResult();

  return (
    <Container style={{height: 200}}>
      <ScrollView>
        <FormulaParserSection formulaAST={formulaAST} />
        <FormulaEvaluatorSection formulaResult={formulaResult} variablesUsed={variablesUsed} />
      </ScrollView>
    </Container>
  );
};

const getVariableValues = (variablesUsed: any, variables: any, formVariables: any) => {
  return variablesUsed.reduce((acc: any, variableIdentifier: any) => {
    const variable =
      variables.find((variable: any) => variable.identifier === variableIdentifier) || {};
    const formVariable = formVariables[variableIdentifier];
    acc[variableIdentifier] = {
      ...variable,
      value: formVariable?.value ? formVariable.value : '',
    };
    return acc;
  }, {});
};

const FormulaEditor = ({
  form,
  field,
  variables,
  isDebugMode,
  organization,
}: {
  form: Form<{formulaForm: FormulaFormTypeToForm}>;
  field: string;
  variables: OrganizationVariable[];
  isDebugMode: boolean;
  organization: OrganizationModel;
}) => {
  const responsive = useResponsive();
  const {formulas} = organization;
  const formulaString = _.get(form.values, `${field}.formulaString`);
  const formulaStringInput = useTextInput();
  const [selection, setSelection] = useState({start: 0, end: 0});
  const [variablesUsed, setVariablesUsed] = useState<string[]>([]);
  const customFunctionImplementations = getCustomFunctionImplementations(variables, formulas);
  const {formulaError, plugins} = evaluateFormulaString(
    new EvaluateFormulaStringInput(
      formulaString,
      variables,
      [
        // @ts-expect-error TS(2741): Property 'variableNames' is missing in type 'Extra... Remove this comment to see the full error message
        new ExtractFormulaIdentifiersPlugin(formulas, _.get(form.values, `${field}.identifier`)),
        new ExtractVariableNamesPlugin(formulas),
      ],
      customFunctionImplementations,
    ),
  );
  const latestVarsUsed = plugins[1].getResult();
  useEffect(() => {
    if (!formulaError && !_.isEqual(latestVarsUsed, variablesUsed)) {
      setVariablesUsed(latestVarsUsed);
    }
  }, [latestVarsUsed]); // eslint-disable-line react-hooks/exhaustive-deps
  const formulaServerError = _.get(form.errors, `${field}.astJson`);
  const hasError = (!!formulaString && !!formulaError) || !!formulaServerError;
  const scratchpadForm = useForm({
    initialValues: {formulaResult: 0, variables: getVariableValues(variablesUsed, variables, {})},
  });
  return (
    <Container style={{flex: 1}}>
      <Container style={{flex: 1, minHeight: '72px'}}>
        <FieldInput
          {...form}
          isResponsive
          name={`${field}.formulaString`}
          input={{
            required: !formulaString,
            innerRef: formulaStringInput.ref,
            multiline: true,
            placeholder: 'Enter formula',
            style: {
              height: '100%',
              minHeight: 36,
              padding: 16,
              paddingTop: 8,
              paddingBottom: 8,
              borderWidth: 0,
            },
            onSelectionChange: (e: any) => setSelection(e.nativeEvent.selection),
            selection,
          }}
          style={{flex: 1}}
        />
        <ErrorContainer
          style={{
            backgroundColor: formulaString ? 'transparent' : colors.alpha(colors.yellow.hover, 0.1),
          }}
        >
          {hasError && (
            <FieldInput.CaptionText responsive={responsive} numberOfLines={1} hasErrors>
              {formulaServerError || formulaError}
            </FieldInput.CaptionText>
          )}
        </ErrorContainer>
      </Container>
      <Container style={{minHeight: '372px', maxHeight: '600px', flexGrow: 1}}>
        <Line />
        <FormulaEditorDocumentationSection
          handleInsertText={(text: any) => {
            handleInsertTextAtIndex({
              form,
              field,
              formulaString,
              text,
              startIndex: selection.start,
              endIndex: selection.end,
            });
            const updatedCursorPosition = selection.start + text.length;
            formulaStringInput.handleFocus();
            // ensures that the positioning of the cursor is the last event.
            setImmediate(() =>
              setSelection({start: updatedCursorPosition, end: updatedCursorPosition}),
            );
          }}
          variablesUsed={variablesUsed}
          scratchpadForm={scratchpadForm}
          variables={variables}
          selection={selection}
          formulaStringInput={formulaStringInput}
          formulaString={formulaString}
          organization={organization}
        />
        {isDebugMode && (
          <React.Fragment>
            <Line />
            <FormulaEditorDebugSection
              formulaString={formulaString}
              variables={variables}
              formulas={formulas}
            />
          </React.Fragment>
        )}
      </Container>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FormulaEditor.fragment = gql`
  ${FormulaEditorDocumentationSection.fragment}
  fragment FormulaEditor on Variable {
    id
    format
    ...FormulaEditorDocumentationSection_Variable
  }
  fragment FormulaEditor_Organization on Organization {
    id
    formulas {
      id
      identifier
      astJson
    }
    ...FormulaEditorDocumentationSection_Organization
  }
`;

export default FormulaEditor;
