// Library
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

// Prioritize giving CustomerName space if both are too big,
// But make sure this has at least enough space to show the default name style
const ProjectTitle = Styled.Text`
    ${Typography.Responsive.Label};
    flex: 1;
    min-width: 100px;
`;

const CustomerName = Styled.Text`
    ${Typography.Responsive.MicroLabel};
    flex-shrink: 1;
`;

const ConversationProjectAndCustomerName = ({
  responsive,
  project,
}: {
  responsive: ResponsiveType;
  project: ProjectModel;
}) => {
  return (
    <Container>
      <ProjectTitle responsive={responsive} numberOfLines={1}>
        {Project.getName(project)}
      </ProjectTitle>
      <Space width={8} />
      <CustomerName responsive={responsive} numberOfLines={1}>
        Customer: {project.client.primaryContact.fullName}
      </CustomerName>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConversationProjectAndCustomerName.fragment = gql`
  ${Project.getName.fragment}
  fragment ConversationProjectAndCustomerName on Project {
    id
    ...Project_getName
    client {
      id
      primaryContact {
        id
        fullName
      }
    }
  }
`;

export default ConversationProjectAndCustomerName;
