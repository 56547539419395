// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useNavigationDOM,
  UrlFiltersType,
  ScrollViewType,
} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import CapacityCalendarDayPickerBlock from 'modules/Calendar/Capacity/components/CapacityCalendarDayPickerBlock';
import CapacityProjectListFilters from 'modules/Calendar/Capacity/components/CapacityProjectListFilters';
import MobileCapacityCalendarDayNavigator from 'modules/Calendar/Capacity/components/MobileCapacityCalendarDayNavigator';
import MobileCapacityCalendarDayNotesButton from 'modules/Calendar/Capacity/components/MobileCapacityCalendarDayNotesButton';
import MobileCapacityCalendarMonthNavigator from 'modules/Calendar/Capacity/components/MobileCapacityCalendarMonthNavigator';
import {CapacityCalendarUrlFiltersType} from 'modules/Calendar/Capacity/types/CapacityCalendarUrlFiltersType';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const NavigationHeaderContainer = Styled.View`
  padding-horizontal: 16px;
  padding-vertical: 12px;
  background-color: ${colors.gray.background};
`;

const ALL_ORGANIZATIONS = 'ALL_ORGANIZATIONS';

const handlePressBranch = ({
  organization,
  urlFilters,
}: {
  organization: OrganizationModel;
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
}) => {
  const currentSlugs = urlFilters.get('slugs') || [];
  const isAllOrganizations = currentSlugs.includes(ALL_ORGANIZATIONS);
  if (isAllOrganizations) {
    return urlFilters.handleUpdate({slugs: [organization.slug]});
  }

  const updatedSlugs = _.xor(currentSlugs, [organization.slug]);
  if (_.some(updatedSlugs)) {
    return urlFilters.handleUpdate({slugs: updatedSlugs});
  }

  return urlFilters.handleUpdate({slugs: [ALL_ORGANIZATIONS]});
};

const getBranchActions = ({
  urlFilters,
  organization,
}: {
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  organization: OrganizationModel;
}) => {
  return [
    {
      text: 'All Branches',
      onPress: () => urlFilters.handleUpdate({slugs: [ALL_ORGANIZATIONS]}),
    },
    ..._.sortBy(
      [
        ...organization.company.organizations.map((organization) => ({
          text: organization.name,
          onPress: () => handlePressBranch({organization, urlFilters}),
        })),
      ],
      'text',
    ),
  ] as {text: string; onPress: () => void}[];
};

const getValuesForBranchActions = ({
  urlFilters,
  organization,
}: {
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  organization: OrganizationModel;
}) => {
  const slugs = urlFilters.get('slugs') || [];
  const {organizations} = organization.company;
  if (slugs && Array.isArray(slugs)) {
    return slugs.map((slug: string) => {
      if (slug === ALL_ORGANIZATIONS) {
        return 'All Branches';
      }
      const organization = organizations.find((organization) => organization.slug === slug);
      return organization!.name;
    });
  }
  return [];
};

const getViewRoute = ({
  urlFilters,
  isDay,
}: {
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  isDay: boolean;
}) => {
  const {date, slugs} = urlFilters.getFilters();
  return URL.getUrlFromVariables(`/moves/capacity/calendar/${isDay ? 'month' : 'day'}`, {
    date,
    slugs,
  });
};

const NavigationDropdown = ({
  label,
  sheetLabel,
  value,
  actions,
}: {
  label: string;
  sheetLabel: string;
  value: string | string[];
  actions: {text: string; onPress: () => void}[];
}) => {
  return (
    <DropdownButton
      ButtonComponent={QuaternaryButton}
      buttonComponentProps={{textColor: colors.blue.interactive}}
      style={{flex: 1}}
      buttonStyle={{paddingLeft: 0, paddingRight: 0}}
      isWidthOfContainer
      isResponsive
      isSmall
      text={label}
      sheetLabel={sheetLabel}
      value={value}
      actions={actions}
    />
  );
};

const ViewDropdown = ({
  urlFilters,
  isDay,
}: {
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  isDay: boolean;
}) => {
  const {navigator} = useNavigationDOM();
  const currentView = isDay ? 'Day' : 'Month';

  return (
    <NavigationDropdown
      label={`View: ${currentView}`}
      sheetLabel={'View'}
      value={currentView}
      actions={[
        {
          text: 'Month',
          onPress: () => isDay && navigator.push(getViewRoute({urlFilters, isDay})),
        },
        {
          text: 'Day',
          onPress: () => !isDay && navigator.push(getViewRoute({urlFilters, isDay})),
        },
      ]}
    />
  );
};

const MonthViewHeader = ({
  urlFilters,
  organization,
  todayPositionY,
  scrollView,
}: {
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  organization: OrganizationModel;
  todayPositionY: number;
  scrollView: ScrollViewType;
}) => {
  const slugs = urlFilters.get('slugs') || [];

  return (
    <NavigationHeaderContainer>
      <Row>
        <MobileCapacityCalendarMonthNavigator urlFilters={urlFilters} />
        <Space flex={1} />
        <SecondaryButton
          text={'Today'}
          onPress={() => {
            if (urlFilters.get('date')) {
              urlFilters.handleUpdate({
                date: 'today',
              });
            } else {
              scrollView.handleScrollTo({y: todayPositionY, animated: true});
            }
          }}
          isResponsive
          isSmall
        />
      </Row>
      <Space height={12} />
      <Row>
        <ViewDropdown urlFilters={urlFilters} isDay={false} />
        {organization.canViewOtherBranchesData && (
          <NavigationDropdown
            label={
              slugs.includes(ALL_ORGANIZATIONS) ? 'All Branches' : `Branches (${slugs.length})`
            }
            sheetLabel={'Branches'}
            value={getValuesForBranchActions({urlFilters, organization})}
            actions={getBranchActions({urlFilters, organization})}
          />
        )}
      </Row>
    </NavigationHeaderContainer>
  );
};

const DayViewHeader = ({
  urlFilters,
  organization,
  refetch,
}: {
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  const handleUpdateQuery = useDebouncedCallback((text) => {
    urlFilters.handleUpdate({searchQuery: text});
  }, 350);

  return (
    <NavigationHeaderContainer>
      <Row>
        <MobileCapacityCalendarDayNavigator urlFilters={urlFilters} organization={organization} />
        <Space flex={1} />
        <Row>
          <ViewDropdown urlFilters={urlFilters} isDay />
        </Row>
      </Row>
      <Space height={12} />
      <Row>
        <SearchBar
          onChangeText={handleUpdateQuery}
          placeholder={'Search'}
          style={{width: '100%'}}
          containerStyle={{flex: 1}}
          defaultValue={urlFilters.get('searchQuery') as string}
          isResponsive
        />
        <Space width={16} />
        <CapacityProjectListFilters
          handleUpdateParam={({paramKey, paramValue}: {paramKey: string; paramValue: string}) => {
            urlFilters.handleUpdate({[paramKey]: paramValue});
          }}
          organization={organization}
          handleClearProjectsListFilters={() =>
            urlFilters.handleReset({
              searchQuery: urlFilters.get('searchQuery'),
              slugs: urlFilters.get('slugs'),
              date: urlFilters.get('date'),
            })
          }
        />
        <Space width={16} />
        <MobileCapacityCalendarDayNotesButton
          urlFilters={urlFilters}
          organization={organization}
          refetch={refetch}
        />
      </Row>
    </NavigationHeaderContainer>
  );
};

type DayViewHeaderProps = {
  isDay: true;
  todayPositionY?: never;
  scrollView?: never;
  refetch: () => void;
};
type MonthViewHeaderProps = {
  isDay?: never;
  todayPositionY: number;
  scrollView: ScrollViewType;
  refetch?: never;
};
type MobileCapacityCalendarHeaderProps = {
  organization: OrganizationModel;
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
} & (DayViewHeaderProps | MonthViewHeaderProps);
const MobileCapacityCalendarHeader = ({
  organization,
  urlFilters,
  isDay,
  todayPositionY,
  scrollView,
  refetch,
}: MobileCapacityCalendarHeaderProps) => {
  if (isDay) {
    return <DayViewHeader urlFilters={urlFilters} organization={organization} refetch={refetch} />;
  }

  return (
    <MonthViewHeader
      urlFilters={urlFilters}
      organization={organization}
      todayPositionY={todayPositionY}
      scrollView={scrollView}
    />
  );
};

MobileCapacityCalendarHeader.fragment = gql`
  ${CapacityCalendarDayPickerBlock.fragment}
  ${CapacityProjectListFilters.fragment}
  ${MobileCapacityCalendarDayNavigator.fragment}
  ${MobileCapacityCalendarDayNotesButton.fragment}

  fragment MobileCapacityCalendarHeader on Organization {
    id
    canViewOtherBranchesData
    ...CapacityCalendarDayPickerBlock
    ...CapacityProjectListFilters
    ...MobileCapacityCalendarDayNavigator
    ...MobileCapacityCalendarDayNotesButton
  }
`;

export default MobileCapacityCalendarHeader;
