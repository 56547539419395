// Library
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {DrawerType, ResponsiveType, TabsType} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

import IconButton from '@shared/design/components/IconButton';
import {CommunicationInboxConversationUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';

interface CommunicationInboxConversationMobileHeaderProps {
  responsive: ResponsiveType;
  project?: ProjectModel;
  urlFilters: CommunicationInboxConversationUrlFilterType;
  mobilePaneTabs?: TabsType;
  projectInformationDrawer?: DrawerType;
}

const Container = Styled.View`
    background-color: ${colors.white}
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-bottom: 0;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const RightPlaceholder = Styled.View`
  width: 40px;
`;

const CommunicationInboxConversationMobileHeader = ({
  responsive,
  project,
  urlFilters,
  mobilePaneTabs,
  projectInformationDrawer,
}: CommunicationInboxConversationMobileHeaderProps) => {
  return (
    <Container>
      <IconButton
        onPress={() => {
          urlFilters.handleUpdate({projectUuid: undefined});
          mobilePaneTabs?.handleChangeIndex(0);
        }}
        source={Icon.ChevronLeft}
        isSecondary
      />
      {project ? <HeaderText responsive={responsive}>{Project.getName(project)}</HeaderText> : null}
      {project?.organization.features.isEnabledConversationsPageProjectInformation ? (
        <IconButton
          onPress={() => {
            projectInformationDrawer?.handleOpen();
          }}
          source={Icon.ArrowLeftToLine}
          size={18}
          isSecondary
        />
      ) : (
        <RightPlaceholder />
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxConversationMobileHeader.fragment = gql`
  ${Project.getName.fragment}

  fragment CommunicationInboxConversationMobileHeader on Project {
    id
    organization {
      id
      features {
        isEnabledConversationsPageProjectInformation: isEnabled(
          feature: "CONVERSATIONS_PAGE_PROJECT_INFORMATION"
        )
      }
    }
    ...Project_getName
  }
`;

export default CommunicationInboxConversationMobileHeader;
