// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive, UrlFiltersType, ScrollViewType} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import IconButton from '@shared/design/components/IconButton';
import {CapacityCalendarUrlFiltersType} from 'modules/Calendar/Capacity/types/CapacityCalendarUrlFiltersType';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const MonthText = Styled.Text`
  ${Typography.Responsive.Subheading};
  width: 160px;
  text-align: center;
`;

const MobileCapacityCalendarMonthNavigator = ({
  urlFilters,
  scrollView,
}: {
  urlFilters: UrlFiltersType<CapacityCalendarUrlFiltersType>;
  scrollView?: ScrollViewType;
}) => {
  const responsive = useResponsive();
  const filterDate = urlFilters.get('date');
  const date = !filterDate || filterDate === 'today' ? Datetime.startOfMonth : filterDate;

  return (
    <Row>
      <IconButton
        source={IconButton.SOURCE.ArrowLeft}
        isSecondary
        onPress={() => {
          urlFilters.handleUpdate({
            date: Datetime.previousMonth(date),
          });
          scrollView?.handleScrollToTop({animated: true});
        }}
      />
      <MonthText responsive={responsive}>
        {Datetime.convertToDisplayDate(date, 'MMMM YYYY')}
      </MonthText>
      <IconButton
        source={IconButton.SOURCE.ArrowRight}
        isSecondary
        onPress={() => {
          urlFilters.handleUpdate({
            date: Datetime.nextMonth(date),
          });
          scrollView?.handleScrollToTop({animated: true});
        }}
      />
    </Row>
  );
};

export default MobileCapacityCalendarMonthNavigator;
