// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useToast} from '@supermove/hooks';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import EmailTemplatePageContentV2 from 'modules/Organization/Settings/Email/componentsV2/EmailTemplatePageContentV2';
import useUpdateEmailTemplateForm from 'modules/Organization/Settings/Email/logic/useUpdateEmailTemplateForm';

const EditEmailTemplateContent = ({organization, emailTemplate}: any) => {
  const {navigator} = useNavigationDOM();
  const updateEmailTemplateSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Email template updated',
  });
  const {form, submitting, handleSubmit} = useUpdateEmailTemplateForm({
    emailTemplate: {...emailTemplate},
    onSuccess: () => {
      updateEmailTemplateSuccessToast.handleToast();
      navigator.push('/settings/communication/email-templates');
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <React.Fragment>
      <EmailTemplatePageContentV2
        organization={organization}
        title={'Edit Email Template'}
        submitButtonText={'Save'}
        form={form}
        handleSubmit={handleSubmit}
        isSubmitting={submitting}
        isKindEditable={false}
      />
    </React.Fragment>
  );
};

const EditEmailTemplatePage = () => {
  const {
    params: {emailTemplateUuid},
  } = useNavigationDOM();
  const {loading, data} = useQuery(EditEmailTemplatePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: emailTemplateUuid,
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <EditEmailTemplateContent
            organization={data.emailTemplate.organization}
            emailTemplate={data.emailTemplate}
          />
        )}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditEmailTemplatePage.query = gql`
  ${EmailTemplatePageContentV2.fragment}
  ${useUpdateEmailTemplateForm.fragment}

  query EditEmailTemplatePage($uuid: String!) {
    ${gql.query}
    emailTemplate(uuid: $uuid) {
      id
      organization {
        id
        ...EmailTemplatePageContentV2
      }
      ...useUpdateEmailTemplateForm
    }
  }
`;

export default EditEmailTemplatePage;
