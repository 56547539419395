// Library
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {
  ResponsiveType,
  TabsType,
  useDebouncedCallback,
  useResponsive,
  useDrawer,
  DrawerType,
} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import Line from '@shared/design/components/Line';
import SearchBar from '@shared/design/components/SearchBar';
import {PageLoadingIndicator} from 'modules/App/components';
import CommunicationInboxConversationMobileHeader from 'modules/Communication/CommunicationInbox/ProjectConversation/components/CommunicationInboxConversationMobileHeader';
import CommunicationInboxConversationPopoverOptions from 'modules/Communication/CommunicationInbox/ProjectConversation/components/CommunicationInboxConversationPopoverOptions';
import CommunicationInboxProjectInformation from 'modules/Communication/CommunicationInbox/ProjectConversation/components/CommunicationInboxProjectInformation';
import CommunicationsInboxProjectConversationContent from 'modules/Communication/CommunicationInbox/ProjectConversation/components/CommunicationsInboxProjectConversationContent';
import {CommunicationInboxConversationUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';
import CommunicationWidgetTags from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationWidgetTags';

interface CommunicationInboxProjectConversationProps {
  responsive: ResponsiveType;
  urlFilters: CommunicationInboxConversationUrlFilterType;
  mobilePaneTabs?: TabsType;
  isEnabledConversationsPageProjectInformation?: boolean;
}

const ContentRow = Styled.View`
  flexDirection: row;
  flex: 1;
`;

const Container = Styled.View`
    flex: 1;
`;

const Column = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  background-color:${colors.white}
`;

const NoConversationSelectedContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray.background}
`;

const NoConversationSelectedTitle = Styled.Text`
    ${Typography.Responsive.Heading2}
    color: ${colors.gray.secondary}
`;

const NoConversationSelectedSubtitle = Styled.Text`
    ${Typography.Responsive.Body}
    color: ${colors.gray.tertiary}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SearchBarContainer = Styled.View`
  flex: 1;
  background-color: ${colors.white}
  padding-left: 16px;
`;

const CommunicationInboxProjectConversationContent = ({
  project,
  urlFilters,
  responsive,
}: {
  project: ProjectModel;
  urlFilters: CommunicationInboxConversationUrlFilterType;
  responsive: ResponsiveType;
}) => {
  return (
    <Container>
      <CommunicationsInboxProjectConversationContent
        project={project}
        urlFilters={urlFilters}
        responsive={responsive}
      />
    </Container>
  );
};

const NoProjectSelected = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <NoConversationSelectedContainer>
      <NoConversationSelectedTitle responsive={responsive}>
        No conversation selected
      </NoConversationSelectedTitle>
      <Space height={16} />
      <NoConversationSelectedSubtitle>
        Select a conversation to view all emails, SMS, and calls associated with it.
      </NoConversationSelectedSubtitle>
    </NoConversationSelectedContainer>
  );
};

const Error = ({message = 'Error fetching conversation'}: {message?: string}) => {
  return (
    <NoConversationSelectedContainer>
      <NoConversationSelectedTitle>Error</NoConversationSelectedTitle>
      <Space height={16} />
      <NoConversationSelectedSubtitle>{message}</NoConversationSelectedSubtitle>
    </NoConversationSelectedContainer>
  );
};

const Header = ({
  urlFilters,
  responsive,
  project,
}: {
  urlFilters: CommunicationInboxConversationUrlFilterType;
  project?: ProjectModel;
  responsive: ResponsiveType;
}) => {
  const handleChangeQuery = useDebouncedCallback((query) => {
    urlFilters.handleUpdate({communicationSearchQuery: query});
  }, 250);

  return (
    <HeaderContainer>
      <Space height={16} />
      <Row>
        <SearchBarContainer>
          <SearchBar
            onChangeText={(text) => handleChangeQuery(text)}
            placeholder={'Search'}
            containerStyle={{flex: 1}}
            style={{width: '100%'}}
            defaultValue={''}
            isClearable
            isResponsive
          />
        </SearchBarContainer>
        <CommunicationInboxConversationPopoverOptions
          responsive={responsive}
          projectUuid={project?.uuid}
        />
      </Row>
      <Space height={14} />
      <CommunicationWidgetTags
        activeTags={urlFilters.get('communicationTags') ?? []}
        handleChangeTags={(newTags) => urlFilters.handleUpdate({communicationTags: newTags})}
      />
      <Space height={8} />
      <Line />
    </HeaderContainer>
  );
};

const CommunicationInboxProjectConversationBody = ({
  project,
  urlFilters,
  mobilePaneTabs,
  projectInformationDrawer,
}: {
  project: ProjectModel;
  urlFilters: CommunicationInboxConversationUrlFilterType;
  mobilePaneTabs?: TabsType;
  projectInformationDrawer?: DrawerType;
}) => {
  const responsive = useResponsive();
  const headerProps = {
    urlFilters,
    responsive,
  };

  return (
    <Column>
      {responsive.mobile ? (
        <CommunicationInboxConversationMobileHeader
          project={project}
          responsive={responsive}
          urlFilters={urlFilters}
          mobilePaneTabs={mobilePaneTabs}
          projectInformationDrawer={projectInformationDrawer}
        />
      ) : null}
      <Header {...headerProps} project={project} />
      <CommunicationInboxProjectConversationContent
        project={project}
        urlFilters={urlFilters}
        responsive={responsive}
      />
    </Column>
  );
};

const ProjectConversationWithProjectInformation = ({
  project,
  urlFilters,
  mobilePaneTabs,
}: {
  project: ProjectModel;
  urlFilters: CommunicationInboxConversationUrlFilterType;
  mobilePaneTabs?: TabsType;
}) => {
  const responsive = useResponsive();
  const projectInformationDrawer = useDrawer({name: 'Conversation Project Information Drawer'});

  return (
    <ContentRow>
      <CommunicationInboxProjectConversationBody
        project={project}
        urlFilters={urlFilters}
        mobilePaneTabs={mobilePaneTabs}
        projectInformationDrawer={projectInformationDrawer}
      />
      {responsive.desktop ? (
        <React.Fragment>
          <Line isVertical />
          <CommunicationInboxProjectInformation
            project={project}
            isOpen={projectInformationDrawer.isOpen}
            handleOpen={projectInformationDrawer.handleOpen}
            handleClose={projectInformationDrawer.handleClose}
          />
        </React.Fragment>
      ) : (
        <DrawerWithClose
          isOpen={projectInformationDrawer.isOpen}
          handleClose={projectInformationDrawer.handleClose}
          headerText={`Project ${project.identifier}`}
        >
          <CommunicationInboxProjectInformation
            project={project}
            handleOpen={projectInformationDrawer.handleOpen}
            handleClose={projectInformationDrawer.handleClose}
            isOpen
          />
        </DrawerWithClose>
      )}
    </ContentRow>
  );
};

const PendingState = ({
  urlFilters,
  isEnabledConversationsPageProjectInformation,
  children,
}: {
  urlFilters: CommunicationInboxConversationUrlFilterType;
  isEnabledConversationsPageProjectInformation?: boolean;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  return (
    <ContentRow>
      <Column>
        <Header responsive={responsive} urlFilters={urlFilters} />
        {children}
      </Column>
      {responsive.desktop && isEnabledConversationsPageProjectInformation && (
        <React.Fragment>
          <Line isVertical />
          <CommunicationInboxProjectInformation
            isOpen={false}
            handleOpen={() => {}}
            handleClose={() => {}}
          />
        </React.Fragment>
      )}
    </ContentRow>
  );
};

const CommunicationInboxProjectConversation = ({
  responsive,
  urlFilters,
  mobilePaneTabs,
  isEnabledConversationsPageProjectInformation,
}: CommunicationInboxProjectConversationProps) => {
  const projectUuid = urlFilters.get('projectUuid');
  const {loading, data, refetch, error} = useQuery<{project: ProjectModel}>(
    CommunicationInboxProjectConversation.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        projectUuid,
      },
      skip: !projectUuid,
    },
  );

  const pendingStateProps = {
    urlFilters,
    isEnabledConversationsPageProjectInformation,
  };

  if (!projectUuid) {
    return (
      <PendingState {...pendingStateProps}>
        <NoProjectSelected responsive={responsive} />
      </PendingState>
    );
  }

  if (error) {
    return (
      <PendingState {...pendingStateProps}>
        <Error />
      </PendingState>
    );
  }

  if (!data || loading) {
    return (
      <PendingState {...pendingStateProps}>
        <PageLoadingIndicator />
      </PendingState>
    );
  }

  return (
    <React.Fragment>
      {isEnabledConversationsPageProjectInformation ? (
        <ProjectConversationWithProjectInformation
          project={data.project}
          urlFilters={urlFilters}
          mobilePaneTabs={mobilePaneTabs}
        />
      ) : (
        <CommunicationInboxProjectConversationBody
          project={data.project}
          urlFilters={urlFilters}
          mobilePaneTabs={mobilePaneTabs}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxProjectConversation.query = gql`
  ${CommunicationsInboxProjectConversationContent.fragment}
  ${CommunicationInboxConversationMobileHeader.fragment}
  ${CommunicationInboxProjectInformation.fragment}

  query CommunicationInboxProjectConversation(
    $projectUuid: String!,
  ) {
    ${gql.query}
      project(uuid: $projectUuid) {
        id
        uuid
        ...CommunicationInboxConversationMobileHeader
        ...CommunicationsInboxProjectConversationContent
        ...CommunicationInboxProjectInformation
      }
  }
`;

export default CommunicationInboxProjectConversation;
