// Library
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Conversation, ConversationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import CommunicationInboxTopLine from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxTopLine';
import ConversationProjectAndCustomerName from 'modules/Communication/CommunicationInbox/ConversationList/components/ConversationProjectAndCustomerName';

const Container = Styled.View`
`;

const BodyContainer = Styled.View`
  flex-direction: row;
`;

const Body = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  flex: 1;
`;

const CommunicationInboxTextItem = ({
  responsive,
  conversation,
}: {
  responsive: ResponsiveType;
  conversation: ConversationModel;
}) => {
  if (!conversation.textMessage) {
    return null;
  }
  const {sentAt, body} = conversation.textMessage;
  const fromName =
    conversation.recentCommunicationDirection === Conversation.RecentCommunicationDirection.INBOUND
      ? conversation.textMessage.creator.fullName
      : conversation.textMessage.recipient.fullName;

  return (
    <Container>
      <CommunicationInboxTopLine
        conversation={conversation}
        responsive={responsive}
        fromName={fromName}
        sentAt={sentAt}
      />
      <Space height={8} />
      <ConversationProjectAndCustomerName project={conversation.project} responsive={responsive} />
      <Space height={8} />
      <BodyContainer>
        <Body responsive={responsive} numberOfLines={2}>
          {body}
        </Body>
      </BodyContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxTextItem.fragment = gql`
  ${ConversationProjectAndCustomerName.fragment}
  ${CommunicationInboxTopLine.fragment}

  fragment CommunicationInboxTextItem on Conversation {
    id
    ...CommunicationInboxTopLine
    recentCommunicationDirection
    textMessage {
      id
      body
      sentAt
      creator {
        id
        fullName
      }
      recipient {
        id
        fullName
      }
    }
    project {
      id
      ...ConversationProjectAndCustomerName
    }
  }
`;

export default CommunicationInboxTextItem;
