// Libraries
import React from 'react';

// Supermove
import {Sidebar, Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {Location, Container} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';
import StorageContainerSidebarCollectionTable from 'modules/Storage/components/StorageContainerSidebarCollectionTable';
import UpdateContainerModal from 'modules/Storage/components/UpdateContainerModal';

const View = Styled.View`
`;

const SidebarContainer = Styled.View`
  flex: 1;
  background-color: ${colors.white}
  width: 650px;
  padding-horizontal: 24px;
  max-height: 160px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
`;

const ContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
  width: 650px;
  padding-horizontal: 24px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const StorageContainerSidebarTitle = Styled.Text`
  ${Typography.Heading5}
  text-align: left;
  color: ${colors.gray.primary}
`;

const ContainerInfoText = Styled.Text`
  ${Typography.Body3}
  text-align: left;
  color: ${colors.gray.primary}
`;

const ContainerInfoIconText = Styled.Text`
  ${Typography.Body3}
  text-align: left;
  color: ${colors.gray.secondary}
`;

const ItemInfoContainer = Styled.View`
  flex-direction: row;
  width: 120px;
`;

const CloseButton = Styled.ButtonV2`
`;

const StorageContainerSidebarHeader = ({container, refetch, viewer, handleClose}: any) => {
  const updateContainerModal = useModal({name: 'Edit Container Modal', enableTracking: true}); // eslint-disable-line

  return (
    <View>
      <Space height={24} />
      <Row>
        <StorageContainerSidebarTitle
          numberOfLines={1}
        >{`${container.name}`}</StorageContainerSidebarTitle>
        <Space width={16} />
        <TertiaryButton
          onPress={updateContainerModal.handleOpen}
          iconLeft={Icon.Pen}
          text={'Edit Container'}
        />
        <Space style={{flex: 1}} />
        <CloseButton onPress={handleClose}>
          <Icon source={Icon.Times} size={20} color={colors.gray.primary} />
        </CloseButton>
        <UpdateContainerModal
          isOpen={updateContainerModal.isOpen}
          handleClose={updateContainerModal.handleClose}
          refetch={refetch}
          viewer={viewer}
          container={container}
        />
      </Row>
      <Space height={8} />
      {container.locationKind === ContainerLocationKind.CUSTOM ? (
        <Row>
          <ItemInfoContainer>
            <Icon
              source={Icon.MapMarker}
              size={14}
              style={{width: '14px', marginTop: '2px'}}
              color={colors.gray.secondary}
            />
            <Space width={4} />
            <ContainerInfoIconText>Address</ContainerInfoIconText>
          </ItemInfoContainer>
          <ContainerInfoText>
            {container.customLocation ? Location.getDisplayLocation(container.customLocation) : '-'}
          </ContainerInfoText>
        </Row>
      ) : (
        <React.Fragment>
          <Row>
            <ItemInfoContainer>
              <Icon
                source={Icon.DoorClosed}
                size={14}
                style={{width: '14px', marginTop: '2px'}}
                color={colors.gray.secondary}
              />
              <Space width={4} />
              <ContainerInfoIconText>Warehouse</ContainerInfoIconText>
            </ItemInfoContainer>
            <ContainerInfoText>
              {container.warehouse ? `${container.warehouse.name}` : '--'}
            </ContainerInfoText>
          </Row>
          <Space height={8} />
          <Row>
            <ItemInfoContainer>
              <Icon
                source={Icon.MapPin}
                size={14}
                style={{width: '14px', marginTop: '2px'}}
                color={colors.gray.secondary}
              />
              <Space width={4} />
              <ContainerInfoIconText>Coordinates</ContainerInfoIconText>
            </ItemInfoContainer>
            <ContainerInfoText>{Container.getDisplayCoordinates(container)}</ContainerInfoText>
          </Row>
        </React.Fragment>
      )}
      <Space height={8} />
      <Row>
        <ItemInfoContainer>
          <Icon
            source={Icon.StickyNote}
            size={14}
            style={{width: '14px', marginTop: '2px'}}
            color={colors.gray.secondary}
          />
          <Space width={4} />
          <ContainerInfoIconText>Notes</ContainerInfoIconText>
        </ItemInfoContainer>
        <ContainerInfoText>{container.notes ? `${container.notes}` : '--'}</ContainerInfoText>
      </Row>
      <Space height={24} />
    </View>
  );
};

const StorageContainerSidebarContent = ({isOpen, handleClose, container, refetch, viewer}: any) => {
  return (
    <Sidebar.Content
      isOpen={isOpen}
      onClose={handleClose}
      position={'right'}
      shouldCloseOnClickOutside
    >
      <SidebarContainer>
        <StorageContainerSidebarHeader
          container={container}
          refetch={refetch}
          viewer={viewer}
          handleClose={handleClose}
        />
      </SidebarContainer>
      <ContentContainer>
        <Space height={16} />
        <StorageContainerSidebarCollectionTable container={container} />
      </ContentContainer>
    </Sidebar.Content>
  );
};

const StorageContainerSidebar = ({isOpen, handleClose, containerUuid, refetch, viewer}: any) => {
  const {
    loading,
    data,
    refetch: sidebarRefetch,
  } = useQuery(StorageContainerSidebar.query, {
    fetchPolicy: 'cache-and-network',
    variables: {containerUuid},
    skip: !isOpen,
  });

  if (loading || !data) {
    return <View />;
  }

  return (
    <StorageContainerSidebarContent
      isOpen={isOpen}
      handleClose={handleClose}
      container={data.containerByUuid}
      refetch={() => {
        sidebarRefetch();
        refetch();
      }}
      viewer={viewer}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageContainerSidebar.query = gql`
  ${UpdateContainerModal.fragment}
  ${Location.getDisplayLocation.fragment}
  ${Container.getDisplayCoordinates.fragment}
  ${StorageContainerSidebarCollectionTable.fragment}

  query StorageContainerSidebar($containerUuid: String!) {
    ${gql.query}
    containerByUuid(containerUuid: $containerUuid){
      id
      notes
      warehouse {
        id
        name
      }
      customLocation {
        id
        ...Location_getDisplayLocation
      }
      ...Container_getDisplayCoordinates
      ...UpdateContainerModal
      ...StorageContainerSidebarCollectionTable
    }
  }
`;

export default StorageContainerSidebar;
