// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMemo, useMountEffect, useResponsive, useScrollView, useState} from '@supermove/hooks';
import {TextMessageTemplateModel, UserModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextMessageSystemKind from '@shared/modules/Sms/enums/TextMessageSystemKind';
import SendTextMessageToUserForm from '@shared/modules/Sms/forms/SendTextMessageToUserForm';
import useSendTextMessageToUserMutation from '@shared/modules/Sms/hooks/useSendTextMessageToUserMutation';
import EvaluateAndReplaceVariablesForm from '@shared/modules/Variable/forms/EvaluateAndReplaceVariablesForm';
import useEvaluateAndReplaceVariablesMutation from '@shared/modules/Variable/hooks/useEvaluateAndReplaceVariablesMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CustomerChatTextMessagesList from 'modules/Chat/Customer/components/CustomerChatTextMessagesList';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const EmptyStateContainer = Styled.View`
  align-items: center;
  padding: 16px;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const FooterContainer = Styled.View`
  background-color: ${colors.white};
`;

const IconButtonV2 = Styled.ButtonV2`
  height: 28px;
  width: 28px;
  border-radius: 14px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'backgroundColor' does not exist on type ... Remove this comment to see the full error message
    backgroundColor,
  }) => backgroundColor || colors.blue.interactive};
  align-items: center;
  justify-content: center;
`;

const TextTemplateContainer = Styled.View`
  flex-direction: row;
  padding-horizontal: 16px;
  padding-top: 8px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: flex-end;
`;

const TextTemplateDropdownContainer = Styled.View`
  height: 72px;
  padding: 16px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const TextTemplateDropdownRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LoadingContainer = Styled.View`
  height: 130px;
  justify-content: center;
  align-items: center;
`;

const PaddingContainer = Styled.View`
  padding-horizontal: 16px;
`;

const CharacterCountText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color || colors.gray.secondary};
`;

const getPlaceholderText = ({customer}: any) => {
  if (customer.phoneNumber) {
    return 'Send a message...';
  }
  return 'Please add a phone number';
};

const NoPhoneNumber = ({handleMissingPhoneNumber, customer}: any) => {
  const responsive = useResponsive();
  return (
    <EmptyState
      style={{paddingHorizontal: 16}}
      title={'Send an SMS'}
      MessageComponent={() => (
        <Text responsive={responsive}>
          <LinkText responsive={responsive} onPress={handleMissingPhoneNumber}>
            Add a phone number
          </LinkText>
          {` to send an SMS to ${customer.fullName}.`}
        </Text>
      )}
    />
  );
};

const NoMessages = ({customer}: any) => {
  const responsive = useResponsive();
  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>No messages</TitleText>
      <Space height={16} />
      <Text
        responsive={responsive}
        style={{textAlign: 'center'}}
      >{`Use the field below to send a text message to ${customer.fullName}.`}</Text>
    </EmptyStateContainer>
  );
};

const Body = ({customer, handleMissingPhoneNumber, textMessagesScrollView}: any) => {
  if (!customer.phoneNumber) {
    return (
      <BodyContainer style={{justifyContent: 'center'}}>
        <NoPhoneNumber handleMissingPhoneNumber={handleMissingPhoneNumber} customer={customer} />
      </BodyContainer>
    );
  }
  const hasMessages = !_.isEmpty(customer.allTextMessages);
  if (!hasMessages) {
    return (
      <BodyContainer style={{justifyContent: 'center'}}>
        <NoMessages customer={customer} />
      </BodyContainer>
    );
  }
  return (
    <CustomerChatTextMessagesList
      textMessages={customer.allTextMessages}
      customerId={customer.id}
      textMessagesScrollView={textMessagesScrollView}
      organization={customer.organization}
    />
  );
};

const CommunicationChatDrawerTextTemplateDropdown = ({
  form,
  textMessageTemplates,
  handlePreviewSubmit,
  previewTextTemplateForm,
  setIsUsingTemplate,
  responsive,
}: any) => {
  const textMessageTemplateMap = Object.fromEntries(
    textMessageTemplates.map((textMessageTemplate: any) => [
      textMessageTemplate.id,
      textMessageTemplate.body,
    ]),
  );

  const sortedCustomTemplates = useMemo(() => {
    return textMessageTemplates
      .filter((textMessageTemplate: any) => {
        return textMessageTemplate.systemTemplateKind === TextMessageSystemKind.CUSTOM;
      })
      .map((textMessageTemplate: any) => {
        return {
          label: textMessageTemplate.name,
          value: _.toNumber(textMessageTemplate.id),
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }, [textMessageTemplates]);

  return (
    <TextTemplateDropdownContainer>
      <TextTemplateDropdownRowContainer>
        <FieldInput
          {...form}
          index={0}
          name={'sendTextMessageToUserForm.textMessageTemplateId'}
          component={DropdownInput}
          input={{
            menuPlacement: 'top',
            isPortaled: true,
            isSearchable: !responsive.mobile,
            // Filter out system template kinds from sms templates
            options: sortedCustomTemplates,
            placeholder: 'Select an SMS template',
            setFieldValue: form.setFieldValue,
            onChangeValue: (value: any) => {
              // When value is selected, we need to look in to the text message
              // template id -> body object to find the string
              previewTextTemplateForm.setFieldValue(
                'evaluateAndReplaceVariablesForm.text',
                textMessageTemplateMap[value],
              );

              // Resets the text template id if the user wipes out the body
              if (_.isEmpty(value)) {
                form.setFieldValue('sendTextMessageToUserForm.textMessageTemplateId', null);
              }

              setTimeout(() => handlePreviewSubmit(), 0);
            },
            style: {width: '100%'},
          }}
          style={{flex: 1}}
        />
        <Space style={{width: 16}} />
        <IconButtonV2
          onPress={() => {
            form.setFieldValue('sendTextMessageToUserForm.textMessageTemplateId', null);
            setIsUsingTemplate(false);
          }}
          backgroundColor={colors.gray.border}
        >
          <Icon source={Icon.Times} size={14} color={colors.gray.tertiary} />
        </IconButtonV2>
      </TextTemplateDropdownRowContainer>
    </TextTemplateDropdownContainer>
  );
};

const CommunicationChatDrawerFooterV2 = ({
  form,
  handleSubmit,
  handlePreviewSubmit,
  submitting,
  customer,
  previewTextTemplateForm,
  previewSubmitting,
  responsive,
  textMessageTemplates,
}: any) => {
  const field = 'sendTextMessageToUserForm.body';

  const [isUsingTemplate, setIsUsingTemplate] = useState(false);

  const body = _.get(form.values, field);

  const isDisabled = !body || submitting;

  return (
    <FooterContainer>
      {isUsingTemplate && (
        <CommunicationChatDrawerTextTemplateDropdown
          form={form}
          textMessageTemplates={textMessageTemplates}
          handlePreviewSubmit={handlePreviewSubmit}
          previewTextTemplateForm={previewTextTemplateForm}
          setIsUsingTemplate={setIsUsingTemplate}
          responsive={responsive}
        />
      )}
      {previewSubmitting ? (
        <LoadingContainer>
          <PageLoadingIndicator />
        </LoadingContainer>
      ) : (
        <PaddingContainer>
          <FieldInput.Memoized
            {...form}
            index={1}
            name={field}
            isResponsive
            input={{
              placeholder: getPlaceholderText({customer}),
              multiline: true,
              style: {
                fontWeight: '500',
                fontSize: responsive.desktop ? '14px' : '16px',
                color: colors.gray.primary,
                backgroundColor: colors.white,
                paddingLeft: 0,
                paddingRight: 0,
                borderWidth: 0,
                borderRadius: 0,
                paddingVertical: 18,
                minHeight: 80,
              },
            }}
          />
        </PaddingContainer>
      )}
      <TextTemplateContainer>
        <TertiaryButton
          text={'Use Template'}
          isResponsive
          onPress={() => {
            setIsUsingTemplate(!isUsingTemplate);
          }}
        />
        <Space style={{flex: 1}} />
        <CharacterCountText
          responsive={responsive}
          color={body.length > 1500 ? colors.red.warning : colors.gray.secondary}
        >
          {`${body.length}/1500`}
        </CharacterCountText>
        <Space width={24} />
        <IconButtonV2
          onPress={handleSubmit}
          disabled={isDisabled}
          backgroundColor={isDisabled ? colors.gray.border : colors.blue.interactive}
        >
          <Icon source={Icon.ArrowUp} size={14} color={colors.white} />
        </IconButtonV2>
      </TextTemplateContainer>
    </FooterContainer>
  );
};

const SmsEditorContent = ({
  customer,
  viewer,
  handleMissingPhoneNumber,
  refetch,
  projectId,
  textMessageTemplates,
  selectedTextMessageTemplate,
}: {
  customer: UserModel;
  viewer: UserModel;
  handleMissingPhoneNumber: () => void;
  refetch: () => void;
  projectId: string;
  textMessageTemplates: TextMessageTemplateModel[];
  selectedTextMessageTemplate: TextMessageTemplateModel | null;
}) => {
  const responsive = useResponsive();
  const textMessagesScrollView = useScrollView();
  const sendTextMessageToUserForm = SendTextMessageToUserForm.newForCommunicationChatButton({
    organizationId: customer.organization.id,
    projectId,
    recipient: customer,
    sender: viewer,
  });
  const resetForm = () => form.setFieldValue('sendTextMessageToUserForm.body', '');
  const {form, handleSubmit, submitting} = useSendTextMessageToUserMutation({
    sendTextMessageToUserForm,
    onSuccess: () => {
      refetch();
      resetForm();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const evaluateAndReplaceVariablesForm = EvaluateAndReplaceVariablesForm.new({
    projectId,
    organizationId: customer.organization.id,
  });

  const {
    form: previewTextTemplateForm,
    handleSubmit: handlePreviewSubmit,
    submitting: previewSubmitting,
  } = useEvaluateAndReplaceVariablesMutation({
    evaluateAndReplaceVariablesForm,
    onSuccess: ({text}: any) => {
      const bodyHeader = _.get(form.values, 'sendTextMessageToUserForm.bodyHeader');
      form.setFieldValue('sendTextMessageToUserForm.body', bodyHeader + text);
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  useMountEffect(() => {
    if (selectedTextMessageTemplate && selectedTextMessageTemplate.body !== '') {
      previewTextTemplateForm.setFieldValue(
        'evaluateAndReplaceVariablesForm.text',
        selectedTextMessageTemplate.body,
      );

      setTimeout(() => handlePreviewSubmit(), 0);
    }
  });

  return (
    <Container>
      <Body
        customer={customer}
        handleMissingPhoneNumber={handleMissingPhoneNumber}
        textMessagesScrollView={textMessagesScrollView}
      />
      {!!customer.phoneNumber && (
        <React.Fragment>
          <Line />
          <CommunicationChatDrawerFooterV2
            form={form}
            previewTextTemplateForm={previewTextTemplateForm}
            handleSubmit={handleSubmit}
            handlePreviewSubmit={handlePreviewSubmit}
            previewSubmitting={previewSubmitting}
            submitting={submitting}
            customer={customer}
            textMessagesScrollView={textMessagesScrollView}
            responsive={responsive}
            textMessageTemplates={textMessageTemplates}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

const SmsEditor = ({
  user,
  viewer,
  handleMissingPhoneNumber,
  refetch,
  projectId,
  textMessageTemplates,
  selectedTextMessageTemplate,
}: any) => {
  return (
    <SmsEditorContent
      customer={user}
      viewer={viewer}
      handleMissingPhoneNumber={handleMissingPhoneNumber}
      refetch={refetch}
      projectId={projectId}
      textMessageTemplates={textMessageTemplates}
      selectedTextMessageTemplate={selectedTextMessageTemplate}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SmsEditor.fragment = gql`
  ${CustomerChatTextMessagesList.fragment}
  ${SendTextMessageToUserForm.newForCommunicationChatButton.fragment}

  fragment SmsEditor_User on User {
    id
    fullName
    phoneNumber
    organization {
      id
    }
    allTextMessages {
      id
      ...CustomerChatTextMessagesList
    }
    ...SendTextMessageToUserForm_newForCommunicationChatButton
  }

  fragment SmsEditor_TextMessageTemplate on TextMessageTemplate {
    id
    name
    body
    systemTemplateKind
  }

  fragment SmsEditor_Viewer on User {
    id
    fullName
  }
`;

export default SmsEditor;
