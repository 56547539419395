// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive, ResponsiveType} from '@supermove/hooks';
import {CrewModel, SlotModel, TruckModel, UserModel} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

const ListEmptySection = Styled.View<{responsive: ResponsiveType}>`
  background-color: ${colors.orange.accent};
  padding-horizontal: 12px;
  padding-vertical: ${(props) => (props.responsive.mobile ? '12px' : '8px')};
  border-radius: 2px;
  margin-horizontal: 16px;
`;

const ListEmptyText = Styled.H8`
  color: ${colors.orange.status};
  ${fontWeight(500)}
`;

const CrewSlotFormItemContainer = Styled.Button<{
  isHighlighted: boolean;
  responsive: ResponsiveType;
}>`
  background-color: ${(props) => (props.isHighlighted ? colors.hover : colors.white)};
  flex-direction: row;
  padding-vertical: ${(props) => (props.responsive.mobile ? '12px' : '8px')};
  box-shadow: none;
  height: auto;
  justify-content: flex-start;
  border-radius: 0px;
`;

const RadioButtonContainer = Styled.View`
  height: 16px;
  width: 16px;
  border-width: 1px;
  border-color: ${colors.gray.tertiary};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray.background};
`;

const RadioButtonCenter = Styled.View<{isSelected: boolean}>`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${[(props) => props.isSelected && colors.blue.interactive]}
`;

const TruckNameText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CrewSlotNameText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const handleSetDriverToCrewSlotForm = ({
  field,
  form,
  crewSlotId,
  driverId,
}: {
  field: string;
  form: any;
  crewSlotId: string;
  driverId?: string;
}) => {
  const updatedCrewSlotForms = _.get(form.values, field).map((crewSlotForm: any) => {
    const isSelectedCrewSlotForDriver = String(crewSlotForm.crewSlotId) === String(crewSlotId);
    const isPreviousCrewSlotForDriver = String(crewSlotForm.driverId) === String(driverId);
    // When a driver for a crewSlot is updated, we have to look through all the
    // crewSlotForms. This is because if the driver is being reassigned from another
    // truck, we need to make sure to remove the driver from that previous truck.
    return {
      ...crewSlotForm,
      driverId: isSelectedCrewSlotForDriver
        ? driverId
        : !crewSlotForm.driverId || isPreviousCrewSlotForDriver
          ? null
          : crewSlotForm.driverId,
    };
  });
  form.setFieldValue(field, updatedCrewSlotForms);
};

const RadioButton = ({isSelected}: {isSelected: boolean}) => {
  return (
    <RadioButtonContainer>
      <RadioButtonCenter isSelected={isSelected} />
    </RadioButtonContainer>
  );
};

const ListEmptyComponent = () => {
  const responsive = useResponsive();
  return (
    <ListEmptySection responsive={responsive}>
      <ListEmptyText>
        There are no trucks assigned to this job. Please assign trucks first.
      </ListEmptyText>
    </ListEmptySection>
  );
};

const CrewSlotFormItem = ({
  form,
  field,
  crewSlotForm,
  truck,
  slot,
  driver,
  handleClose,
}: {
  form: any;
  field: string;
  crewSlotForm: any;
  truck?: TruckModel;
  slot?: SlotModel;
  driver: UserModel;
  handleClose: () => void;
}) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const isDriver = String(crewSlotForm.driverId) === String(driver.id);
  const onSetDriverToCrewSlot = () => {
    handleClose();
    // This handler triggers a re-render for the popover which relocates the popover
    // for a split second. Using the set timeout to make it async allows the popover
    // to fully hide first.
    setTimeout(
      () =>
        handleSetDriverToCrewSlotForm({
          form,
          field,
          crewSlotId: crewSlotForm.crewSlotId,
          driverId: isDriver ? undefined : driver.id,
        }),
      100,
    );
  };

  return (
    <CrewSlotFormItemContainer
      ref={ref}
      isHighlighted={isHovered || isDriver}
      onPress={onSetDriverToCrewSlot}
      responsive={responsive}
    >
      <Space width={16} />
      <RadioButton isSelected={isDriver} />
      {!!truck && (
        <React.Fragment>
          <Space width={8} />
          <TruckNameText>{truck.name}</TruckNameText>
          {!!truck.size && <TruckNameText>{`, ${truck.size}`}</TruckNameText>}
        </React.Fragment>
      )}
      {!!slot && (
        <React.Fragment>
          <Space width={8} />
          <CrewSlotNameText>{`Slot ${slot.index}`}</CrewSlotNameText>
        </React.Fragment>
      )}
    </CrewSlotFormItemContainer>
  );
};

const CrewCrewSlotsList = ({
  driver,
  crew,
  form,
  handleClose,
  index,
}: {
  driver: UserModel;
  crew: CrewModel;
  form: any;
  handleClose: () => void;
  index: number;
}) => {
  const crewSlotsById = _.groupBy(crew.crewSlots, 'id');
  const field = 'assignUsersToCrewWithCrewSlotsForm.crewSlotForms';
  const crewSlotFormsFilteredByHasTruck = _.get(form.values, field).filter((crewSlotForm: any) =>
    _.has(crewSlotsById, [crewSlotForm.crewSlotId, '0', 'truck']),
  );
  const crewSlotFormsFilteredByHasTruckSorted = _.sortBy(
    crewSlotFormsFilteredByHasTruck,
    // The driver's current truck is first, then sort all other trucks by name
    [
      (crewSlotForm) => String(crewSlotForm.driverId) !== String(driver.id),
      (crewSlotForm) => _.get(crewSlotsById, [crewSlotForm.crewSlotId, '0', 'truck', 'name']),
    ],
  );

  return (
    <FlatList
      listKey={`crew-crew-slots-list-${driver.id}-${index}`}
      data={crewSlotFormsFilteredByHasTruckSorted}
      keyExtractor={(crewSlotForm) => crewSlotForm.crewSlotId}
      renderItem={({item: crewSlotForm}) => {
        return (
          <CrewSlotFormItem
            crewSlotForm={crewSlotForm}
            // @ts-expect-error Fix type when getting truck
            truck={_.get(crewSlotsById, `${crewSlotForm.crewSlotId}.0.truck`)}
            // @ts-expect-error Fix type when getting slot
            slot={_.get(crewSlotsById, `${crewSlotForm.crewSlotId}.0.slot`)}
            driver={driver}
            form={form}
            field={field}
            handleClose={handleClose}
          />
        );
      }}
      ListEmptyComponent={() => <ListEmptyComponent />}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewCrewSlotsList.fragment = gql`
  fragment CrewCrewSlotsList on Crew {
    id
    crewSlots {
      id
      truck {
        id
        name
        size
      }
      driver {
        id
        fullName
      }
      slot {
        id
        index
      }
    }
  }
`;

export default CrewCrewSlotsList;
