// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UrlFiltersType, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {OrganizationModel, ProjectModel, UserModel} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {Linking} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import ProjectBlockKind, {
  ProjectBlockKindType,
} from '@shared/modules/Project/enums/ProjectBlockKind';
import Banner from 'modules/App/components/Banner';
import Line from 'modules/App/components/Line';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import ProjectHeader from 'modules/Project/V2/Show/components/ProjectHeader';
import ProjectNavigation from 'modules/Project/V2/Show/components/ProjectNavigation';
import ProjectSection from 'modules/Project/V2/Show/components/ProjectSection';
import ProjectSectionV1 from 'modules/Project/V2/Show/components/ProjectSectionV1';
import ProjectWidgets from 'modules/Project/V2/Show/components/ProjectWidgets';
import ProjectJobsBar from 'modules/Project/V2/components/ProjectJobsBar';

const VerticalDivider = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border}
`;

const DesktopPageContainer = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const ContentBodyContainer = Styled.View`
  flex: 1;
`;

type NavigationSectionType = {
  name: string;
  blocks: ProjectBlockKindType[];
};

const handleMovesNavigation = ({
  project,
  navigator,
}: {
  project: ProjectModel;
  navigator: SupermoveNavigatorType;
}) => {
  if (project.organization.settings.isMovesTabPrimary) {
    return navigator.push('/moves/capacity/calendar');
  }
  return navigator.push('/moves/list');
};

const handleJobNavigation = ({
  jobUuid,
  urlFilters,
  navigationSections,
  project,
}: {
  jobUuid: string;
  urlFilters: UrlFiltersType;
  navigationSections: NavigationSectionType[];
  project: ProjectModel;
}) => {
  const jobsSections = navigationSections.filter((section: NavigationSectionType) =>
    _.includes(ProjectBlockKind.JobBlocks, section.blocks[0]),
  );
  const currentJobsSection = jobsSections.filter(
    (jobsSection: NavigationSectionType) => jobsSection.name === urlFilters.get('section'),
  )[0];

  const {isEnabledProjectPageAllSections} = project.organization.features;
  if (currentJobsSection) {
    return urlFilters.handleUpdate({
      jobUuid,
      block: currentJobsSection.blocks[0],
    });
  }

  const section = jobsSections[0];
  return urlFilters.handleUpdate({
    jobUuid,
    section: isEnabledProjectPageAllSections ? undefined : section.name,
    block: section.blocks[0],
  });
};

const LoadingComponent = () => {
  return (
    <ContentContainer>
      <ProjectNavigation.SkeletonLoader />
      <VerticalDivider />
      <ContentBodyContainer>
        <ProjectHeader.SkeletonLoader />
        <Line />
        <ProjectJobsBar.SkeletonLoader />
        <Line />
      </ContentBodyContainer>
      <VerticalDivider />
      <ProjectWidgets.Placeholder />
    </ContentContainer>
  );
};

const ProjectPageV2SignupBanner = ({
  organization,
  isStaffAdmin,
}: {
  organization: OrganizationModel;
  isStaffAdmin: boolean;
}) => {
  const responsive = useResponsive();
  return organization && !organization.features.isEnabledProjectPageV2 && isStaffAdmin ? (
    <Banner
      color={colors.blue.accentDark}
      text={`To have this version be your organization's default view, contact us!`}
      responsive={responsive}
    >
      <Space width={16} />
      <Button
        isSmall
        text={'Contact Us'}
        onPress={() =>
          Linking.openURL(
            "mailto:accountmanagement@supermove.com?subject=I'm interested in the new project page&body=Hi, I would like my company to have the new project page as our default view.",
          )
        }
      />
    </Banner>
  ) : null;
};

const ShowProjectV2PageDesktopContent = ({
  navigationSections,
  project,
  urlFilters,
  refetch,
  refetchAndReset,
  viewer,
}: {
  navigationSections: NavigationSectionType[];
  project: ProjectModel;
  urlFilters: UrlFiltersType;
  refetch: () => void;
  refetchAndReset: () => void;
  viewer: UserModel;
}) => {
  const {params} = useNavigationDOM();

  return (
    <ContentContainer>
      <ProjectNavigation
        project={project}
        urlFilters={urlFilters}
        projectNavigationSections={navigationSections}
      />
      <VerticalDivider />
      <ContentBodyContainer>
        <ProjectHeader
          project={project}
          urlFilters={urlFilters}
          refetchAndReset={refetchAndReset}
        />
        <Line />
        <ProjectJobsBar
          project={project}
          activeJobUuid={urlFilters.get('jobUuid')}
          handlePressJob={({jobUuid}: {jobUuid: string}) =>
            handleJobNavigation({jobUuid, urlFilters, navigationSections, project})
          }
          urlFilters={urlFilters}
          refetch={refetchAndReset}
          viewer={viewer}
        />
        <Line />
        {project.organization.features.isEnabledProjectPageAllSections ? (
          <ProjectSection
            key={params.section}
            project={project}
            urlFilters={urlFilters}
            projectNavigationSections={navigationSections}
            pageRefetch={refetch}
            refetchAndReset={refetchAndReset}
          />
        ) : (
          <ProjectSectionV1
            key={params.section}
            project={project}
            urlFilters={urlFilters}
            projectNavigationSections={navigationSections}
            pageRefetch={refetch}
            refetchAndReset={refetchAndReset}
          />
        )}
      </ContentBodyContainer>
      <VerticalDivider />
      <ProjectWidgets project={project} urlFilters={urlFilters} />
    </ContentContainer>
  );
};

const ShowProjectV2PageDesktop = ({
  navigationSections,
  project,
  urlFilters,
  refetch,
  refetchAndReset,
  resetKey,
  isStaffAdmin,
  viewer,
}: {
  navigationSections: NavigationSectionType[];
  project: ProjectModel;
  urlFilters: UrlFiltersType;
  refetch: () => void;
  refetchAndReset: () => void;
  resetKey: number;
  isStaffAdmin: boolean;
  viewer: UserModel;
}) => {
  const {navigator} = useNavigationDOM();

  return (
    <DesktopPageContainer>
      <ProjectPageV2SignupBanner organization={project.organization} isStaffAdmin={isStaffAdmin} />
      <StandardOfficeHeader title={''}>
        <Breadcrumbs
          breadcrumbs={[
            {
              name: 'Moves',
              onPress: () => handleMovesNavigation({project, navigator}),
            },
            {name: `Project ${project.identifier}`},
          ]}
        />
      </StandardOfficeHeader>
      <ShowProjectV2PageDesktopContent
        key={resetKey}
        navigationSections={navigationSections}
        project={project}
        urlFilters={urlFilters}
        refetch={refetch}
        refetchAndReset={refetchAndReset}
        viewer={viewer}
      />
    </DesktopPageContainer>
  );
};

ShowProjectV2PageDesktop.LoadingComponent = LoadingComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowProjectV2PageDesktop.fragment = gql`
  ${ProjectHeader.fragment}
  ${ProjectJobsBar.fragment}
  ${ProjectNavigation.fragment}
  ${ProjectSection.fragment}
  ${ProjectSectionV1.fragment}
  ${ProjectWidgets.fragment}

  fragment ShowProjectV2PageDesktop on Project {
    id
    identifier
    organization {
      id
      slug
      features {
        isEnabledProjectPageV2: isEnabled(feature: "PROJECT_PAGE_V2")
        isEnabledProjectPageAllSections: isEnabled(feature: "PROJECT_PAGE_ALL_SECTIONS")
      }
      settings {
        id
        isMovesTabPrimary
      }
    }
    ...ProjectHeader
    ...ProjectJobsBar
    ...ProjectNavigation
    ...ProjectSection
    ...ProjectSectionV1
    ...ProjectWidgets
  }

  fragment ShowProjectV2PageDesktop_User on User {
    id
    ...ProjectJobsBar_User
  }
`;

export default ShowProjectV2PageDesktop;
