import {EmailModel} from '@supermove/models';

import {parseJsonRecipients} from '@shared/modules/Email/utils/parseJsonRecipients';

export const hasMultipleRecipients = (email: EmailModel): boolean => {
  const toRecipients = parseJsonRecipients(email.toEmailRecipientsJson);
  const ccRecipients = parseJsonRecipients(email.ccEmailRecipientsJson);
  const bccRecipients = parseJsonRecipients(email.bccEmailRecipientsJson);

  return toRecipients.length + ccRecipients.length + bccRecipients.length > 1;
};
