// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, MapType} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, ScrollViewType} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import EditLocationsListItem from 'modules/Project/V2/Edit/components/EditLocationsListItem';
import EditLocationsListReorderList from 'modules/Project/V2/Edit/components/EditLocationsListReorderList';

const IndexWrapper = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

const AddLocationContainer = Styled.View`
  align-items: center;
`;

const AddLocationLine = Styled.View`
  width: 1px;
  height: 24px;
  background-color: ${colors.gray.border};
`;

const handleAddLocation = ({form, field, index}: any) => {
  const locationFormsField = `${field}.locationForms`;
  const locationForms = [..._.get(form.values, locationFormsField)];
  locationForms.splice(index + 1, 0, LocationForm.new({kind: LocationKind.INTERMEDIATE}));
  form.setFieldValue(locationFormsField, locationForms);
};

const handleRemoveLocation = ({form, field, index}: any) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;
  const locationFormsField = `${field}.locationForms`;
  const locationForms = [..._.get(form.values, locationFormsField)];
  locationForms.splice(index, 1);
  setFieldValue(locationFormsField, locationForms);
};

const AddLocationButton = ({form, field, index, isLast, isDetached, isDisabled, onAdd}: any) => {
  return (
    <AddLocationContainer>
      {!isDetached && <AddLocationLine />}
      <SecondaryButton
        iconLeft={Icon.Plus}
        text={'Add Location'}
        onPress={() => {
          handleAddLocation({form, field, index});
          setImmediate(() => onAdd?.());
        }}
        isResponsive
        isDisabled={isDisabled}
      />
      {!isLast && !isDetached && <AddLocationLine />}
    </AddLocationContainer>
  );
};

const EditLocationsListEditList = ({
  form,
  field,
  jobsField,
  project,
  setLocationIndexToPositionY,
  focusIndex,
  isStops,
  showJobsCallout,
  isDisabled,
  disableWarehouse,
  map,
  scrollView,
}: any) => {
  const locationForms = _.get(form.values, `${field}.locationForms`);

  return (
    <React.Fragment>
      {project.organization.features.isEnabledCreateStorageMultipleWarehouse &&
        !disableWarehouse && (
          <React.Fragment>
            <IndexWrapper
              // setting index -1 here makes the index above the list below
              index={-1}
            >
              <EditLocationsListItem.WarehouseLocationItem
                field={field}
                form={form}
                project={project}
              />
            </IndexWrapper>
            <Space height={24} />
          </React.Fragment>
        )}
      {locationForms.map((locationForm: any, index: any) => {
        return (
          <IndexWrapper
            key={index}
            index={index}
            onLayout={({nativeEvent}) => {
              setLocationIndexToPositionY((locationIndexToPositionY: any) => ({
                ...locationIndexToPositionY,
                [index]: nativeEvent.layout.y,
              }));
            }}
          >
            <IndexWrapper index={0}>
              <EditLocationsListItem
                form={form}
                field={field}
                jobsField={jobsField}
                index={index}
                project={project}
                handleRemoveLocation={handleRemoveLocation}
                autoFocus={focusIndex === index}
                isStops={isStops}
                showJobsCallout={showJobsCallout}
                isDisabled={isDisabled}
                map={map}
              />
            </IndexWrapper>
            {isStops ? (
              <AddLocationButton
                form={form}
                field={field}
                index={index}
                isLast={index === locationForms.length - 1}
                isDisabled={isDisabled}
              />
            ) : (
              <Space height={24} />
            )}
          </IndexWrapper>
        );
      })}
      {(_.isEmpty(locationForms) || !isStops) && (
        <AddLocationButton
          form={form}
          field={field}
          index={locationForms.length - 1}
          isDetached
          onAdd={() => {
            scrollView?.handleScrollToEnd({animated: true});
          }}
        />
      )}
    </React.Fragment>
  );
};

const EditLocationsList = ({
  form,
  field,
  jobsField,
  project,
  isReordering,
  isDisabled,
  setLocationIndexToPositionY,
  scrollToIndex,
  isStops,
  showJobsCallout,
  disableWarehouse,
  map,
  scrollView,
}: {
  form: any;
  field: string;
  jobsField?: string;
  project: ProjectModel;
  isReordering: boolean;
  isDisabled?: boolean;
  setLocationIndexToPositionY: (locationIndexToPositionY: any) => void;
  scrollToIndex?: number;
  isStops?: boolean;
  showJobsCallout?: boolean;
  disableWarehouse?: boolean;
  map?: MapType;
  scrollView?: ScrollViewType;
  handleRemoveLocation?: ({form, field, index}: {form: Form; field: string; index: number}) => void;
}) => {
  return (
    <React.Fragment>
      {isReordering ? (
        <EditLocationsListReorderList
          form={form}
          field={field}
          project={project}
          handleRemoveLocation={handleRemoveLocation}
          isStops={isStops}
        />
      ) : (
        <EditLocationsListEditList
          form={form}
          field={field}
          jobsField={jobsField}
          project={project}
          setLocationIndexToPositionY={setLocationIndexToPositionY}
          focusIndex={scrollToIndex}
          isStops={isStops}
          showJobsCallout={showJobsCallout}
          isDisabled={isDisabled}
          disableWarehouse={disableWarehouse}
          map={map}
          scrollView={scrollView}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditLocationsList.fragment = gql`
  ${EditLocationsListItem.fragment}
  fragment EditLocationsList on Project {
    id
    ...EditLocationsListItem
    organization {
      id
      features {
        isEnabledCreateStorageMultipleWarehouse: isEnabled(
          feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
        )
      }
    }
  }
`;

export default EditLocationsList;
