// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useResponsive, useToggle} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';
import FieldValue from '@shared/design/components/Field/FieldValue';
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import RadioButton from '@shared/design/components/RadioButton';
import {EmailSenderKindDisplay} from '@shared/modules/Organization/enums/EmailSenderKind';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import OrganizationEmailSenderForm, {
  OrganizationEmailSenderFormType,
} from '@shared/modules/Organization/forms/OrganizationEmailSenderForm';
import useUpdateOrganizationEmailSenderMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationEmailSenderMutation';

const Column = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const EmailSenderKindPanelBody = ({
  organization,
  isViewingDependentBranch,
}: {
  organization: OrganizationModel;
  isViewingDependentBranch: boolean;
}) => {
  const {emailSenderKind} = organization.settings;
  return isViewingDependentBranch ? (
    <Callout text={'You are viewing a branch. To make changes, switch to the main branch.'} />
  ) : (
    <FieldValue isResponsive label={'Send from'} value={EmailSenderKindDisplay[emailSenderKind]} />
  );
};

const EmailSenderKindPanelEditBody = ({form}: {form: Form<OrganizationEmailSenderFormType>}) => {
  const field = 'emailSenderKind' as const;
  const emailSenderKind = _.get(form.values, field);

  return (
    <React.Fragment>
      <RadioButton
        isOn={emailSenderKind === 'ORGANIZATION'}
        onPress={() => form.setFieldValue(field, 'ORGANIZATION')}
        label={'Send from a single company email account'}
      />
      <Space height={16} />
      <RadioButton
        isOn={emailSenderKind === 'USER'}
        onPress={() => form.setFieldValue(field, 'USER')}
        label={'Send from an individual connected email account'}
        hint={
          'If the sender does not have a connected email account, emails will be sent using the connected company email account.'
        }
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const Header = ({
  isEditing,
  handleEdit,
  isViewingDependentBranch,
  hasEditPermissions,
}: {
  isEditing: boolean;
  handleEdit: () => void;
  isViewingDependentBranch: boolean;
  hasEditPermissions: boolean;
}) => {
  const responsive = useResponsive();
  return (
    <Panel.Header>
      <Column style={{flex: 1}}>
        <Row style={{alignItems: 'center'}}>
          <Panel.HeaderText responsive={responsive}>Sender</Panel.HeaderText>
          <Space style={{flex: 1, minWidth: 12}} />
          {hasEditPermissions && !isViewingDependentBranch && (
            <EditPanel.EditButton
              isEditing={isEditing}
              handleEdit={handleEdit}
              isDisabled={false}
            />
          )}
        </Row>
        <Panel.Text responsive={responsive} style={{color: colors.gray.secondary}}>
          {'Set up the sender email account for outgoing emails.'}
        </Panel.Text>
      </Column>
    </Panel.Header>
  );
};

const EmailSenderKindPanel = ({
  organization,
  index,
  refetch,
  hasEditPermissions,
}: {
  organization: OrganizationModel;
  index: number;
  refetch: () => void;
  hasEditPermissions: boolean;
}) => {
  const emailSenderKindToggle = useToggle({name: 'Email Sender Kind Toggle'});
  const {form, handleSubmit, submitting} = useUpdateOrganizationEmailSenderMutation({
    organizationEmailSenderForm: OrganizationEmailSenderForm.edit(organization),
    onSuccess: () => {
      refetch();
      emailSenderKindToggle.handleToggleOff();
    },
    onError: (errors) => console.log({errors}),
  });
  const isViewingDependentBranch = !OrganizationKind.getIsIndependent(organization.kind);

  return (
    <EditPanel
      index={index}
      BodyComponent={EmailSenderKindPanelBody}
      bodyComponentProps={{organization, isViewingDependentBranch}}
      EditBodyComponent={EmailSenderKindPanelEditBody}
      editBodyComponentProps={{form, field: 'organizationSenderKindForm'}}
      HeaderComponent={Header}
      headerComponentProps={{
        isEditing: emailSenderKindToggle.isOn,
        handleEdit: emailSenderKindToggle.handleToggleOn,
        isViewingDependentBranch,
        hasEditPermissions,
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={emailSenderKindToggle.isOn}
      handleEdit={emailSenderKindToggle.handleToggleOn}
      handleClose={emailSenderKindToggle.handleToggleOff}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailSenderKindPanel.fragment = gql`
  fragment EmailSenderKindPanel on Organization {
    id
    kind
    settings {
      id
      emailSenderKind
    }
  }
`;

export default EmailSenderKindPanel;
