// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import DocumentItemsList from '@shared/modules/Document/components/DocumentItemsList';
import DocumentV2InputCommercialCatalogEquipment from '@shared/modules/Document/components/DocumentV2InputCommercialCatalogEquipment';
import DocumentV2InputCommercialCatalogMaterial from '@shared/modules/Document/components/DocumentV2InputCommercialCatalogMaterial';
import DocumentV2InputPerMoverTimesheet from '@shared/modules/Document/components/DocumentV2InputPerMoverTimesheet';
import DocumentV2TimesheetInformation from '@shared/modules/Document/components/DocumentV2TimesheetInformation';
import DocumentV2ValuationCoverage from '@shared/modules/Document/components/DocumentV2ValuationCoverage';
import DocumentV2ValuationDeclarationTable from '@shared/modules/Document/components/DocumentV2ValuationDeclarationTable';
import ProjectDocumentV2BillingInformation from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2BillingInformation';
import ProjectDocumentV2CartonSummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2CartonSummary';
import ProjectDocumentV2CustomerSignatureInput from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2CustomerSignatureInput';
import ProjectDocumentV2DocumentHeaderOrganization from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2DocumentHeaderOrganization';
import ProjectDocumentV2GeneralInventoryItems from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2GeneralInventoryItems';
import ProjectDocumentV2GeneralInventorySummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2GeneralInventorySummary';
import ProjectDocumentV2InventorySummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2InventorySummary';
import ProjectDocumentV2OrganizationLogo from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2OrganizationLogo';
import ProjectDocumentV2OrganizationSignature from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2OrganizationSignature';
import ProjectDocumentV2SurveyItems from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2SurveyItems';
import ProjectDocumentV2SurveyRoomsSummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2SurveyRoomsSummary';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const DocumentTemplatePreviewContentContainer = Styled.View`
  padding: 32px;
`;

const TemplateContainerMargin = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const TemplateContainer = Styled.View`
  padding: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.border};
  width: 800px;
  margin: 32px;
`;

const DocumentPreviewRendererContainer = Styled.View`
  height: 100%;
`;

const getUniqueItemKindsFromDocumentItems = ({documentItems}: any) => {
  const itemKinds = documentItems.map((item: any) => item.itemKind);
  return _.uniq(itemKinds);
};

const getFilteringQueryVariables = ({documentItems}: any) => {
  const itemKinds = getUniqueItemKindsFromDocumentItems({documentItems});
  return {
    hasBillingInformation: itemKinds.includes(DocumentItemKindV2.BILLING_INFORMATION),
    hasCustomerSignatureInput: itemKinds.includes(DocumentItemKindV2.INPUT_SIGNATURE),
    hasValuationDeclarationTable: itemKinds.includes(
      DocumentItemKindV2.VALUATION_DECLARATION_TABLE,
    ),
    hasTimesheetInformation: itemKinds.includes(DocumentItemKindV2.TIMESHEET_INFORMATION),
    hasInventorySummary: itemKinds.includes(DocumentItemKindV2.INVENTORY_SUMMARY),
    hasInputPerMoverTimesheet: itemKinds.includes(DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET),
    hasInputCommercialCatalogEquipment: itemKinds.includes(
      DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_EQUIPMENTS,
    ),
    hasInputCommercialCatalogMaterial: itemKinds.includes(
      DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_MATERIALS,
    ),
    hasInputValuationCoverage: itemKinds.includes(DocumentItemKindV2.INPUT_VALUATION_COVERAGE),
    hasDocumentHeader: itemKinds.includes(DocumentItemKindV2.DOCUMENT_HEADER),
    hasOrganizationLogo: itemKinds.includes(DocumentItemKindV2.LOGO),
    hasOrganizationSignature: itemKinds.includes(DocumentItemKindV2.ORGANIZATION_SIGNATURE),
    hasSurveyItems: itemKinds.includes(DocumentItemKindV2.SURVEY_ITEMS_SUMMARY),
    hasSurveyRoomsSummary: itemKinds.includes(DocumentItemKindV2.SURVEY_ROOMS_SUMMARY),
    hasCartonSummary: itemKinds.includes(DocumentItemKindV2.SURVEY_CARTONS_SUMMARY),
    hasGeneralInventorySummary: itemKinds.includes(DocumentItemKindV2.GENERAL_INVENTORY_SUMMARY),
    hasInventoryItems: itemKinds.includes(DocumentItemKindV2.INVENTORY_ITEMS_SUMMARY),
  };
};

// @ts-expect-error TS(7023): 'getDocumentItems' implicitly has return type 'any... Remove this comment to see the full error message
const getDocumentItems = (documentItem: any) => {
  if (documentItem.documentItems) {
    return _.flatMap(documentItem.documentItems, getDocumentItems);
  }
  return [documentItem];
};

const getAllDocumentItems = (documentContentJson: any) => {
  return _.flatMap(documentContentJson.documentItems, getDocumentItems);
};

const DocumentTemplatePreviewLoader = () => {
  return (
    <React.Fragment>
      <Space height={48} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} isFullWidth />
    </React.Fragment>
  );
};

const DocumentTemplatePreviewContent = ({
  documentContentJson,
  jobUuid,
  style,
  organization,
}: any) => {
  const {loading, data} = useQuery(DocumentTemplatePreviewContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid,
      ...getFilteringQueryVariables({documentItems: getAllDocumentItems(documentContentJson)}),
    },
  });
  return (
    <DocumentTemplatePreviewContentContainer style={style || {flex: 1}}>
      {loading ? (
        <DocumentTemplatePreviewLoader />
      ) : (
        // Only show real job data if the job exists, otherwise preview the template json
        <DocumentItemsList
          documentItems={documentContentJson.documentItems}
          job={data.job}
          isPreview={!data.job}
          isEnabledDocumentPerPositionTimesheetTotals={
            organization.features.isEnabledDocumentPerPositionTimesheetTotals
          }
          isEnabledDocumentPerPositionTimesheetTimes={
            organization.features.isEnabledDocumentPerPositionTimesheetTimes
          }
        />
      )}
    </DocumentTemplatePreviewContentContainer>
  );
};

const DocumentTemplatePreview = ({
  documentTemplateUuid,
  jobUuid = '',
  isContentOnly,
  style,
}: any) => {
  const {loading, data} = useQuery(DocumentTemplatePreview.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: documentTemplateUuid,
      // If jobUuid is null, the documentContentJson will be returned
      jobUuid,
    },
  });

  // TODO(dan) Refactor this to make content more accessible
  return (
    <DocumentPreviewRendererContainer>
      {loading ? (
        <DocumentTemplatePreviewContentContainer style={style || {flex: 1}}>
          <DocumentTemplatePreviewLoader />
        </DocumentTemplatePreviewContentContainer>
      ) : (
        <React.Fragment>
          {isContentOnly ? (
            <DocumentTemplatePreviewContent
              documentContentJson={Json.toObject(
                data.documentTemplate.activeOrMostRecentDocumentTemplateVersion
                  .documentContentJsonPreview,
              )}
              jobUuid={jobUuid}
              organization={data.documentTemplate.organization}
              style={style}
            />
          ) : (
            <TemplateContainerMargin>
              <ScrollView horizontal>
                <TemplateContainer>
                  <ScrollView contentContainerStyle={{flex: 1}}>
                    <DocumentTemplatePreviewContent
                      documentContentJson={Json.toObject(
                        data.documentTemplate.activeOrMostRecentDocumentTemplateVersion
                          .documentContentJsonPreview,
                      )}
                      jobUuid={jobUuid}
                      style={style}
                      organization={data.documentTemplate.organization}
                    />
                  </ScrollView>
                </TemplateContainer>
              </ScrollView>
            </TemplateContainerMargin>
          )}
        </React.Fragment>
      )}
    </DocumentPreviewRendererContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

// Fetches the documentTemplate and contentJson
DocumentTemplatePreview.query = gql`
  query DocumentTemplatePreview(
    $uuid: String!,
    $jobUuid: String!,
  ) {
    ${gql.query}
    documentTemplate(uuid: $uuid) {
      id
      uuid
      activeOrMostRecentDocumentTemplateVersion {
        id
        documentContentJsonPreview(jobUuid: $jobUuid)
      }
      organization {
          id
          features {
            isEnabledDocumentPerPositionTimesheetTotals: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TOTALS")
            isEnabledDocumentPerPositionTimesheetTimes: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TIMES")
          }
        }
    }
  }
`;

// Fetches the neccesary fragments to display the blocks
DocumentTemplatePreviewContent.query = gql`
  ${ProjectDocumentV2BillingInformation.fragment}
  ${ProjectDocumentV2CustomerSignatureInput.fragment}
  ${DocumentV2ValuationDeclarationTable.fragment}
  ${DocumentV2TimesheetInformation.fragment}
  ${ProjectDocumentV2InventorySummary.fragment}
  ${DocumentV2InputPerMoverTimesheet.fragment}
  ${DocumentV2InputCommercialCatalogEquipment.fragment}
  ${DocumentV2InputCommercialCatalogMaterial.fragment}
  ${DocumentV2ValuationCoverage.fragment}
  ${ProjectDocumentV2DocumentHeaderOrganization.fragment}
  ${ProjectDocumentV2OrganizationLogo.fragment}
  ${ProjectDocumentV2OrganizationSignature.fragment}
  ${ProjectDocumentV2SurveyItems.fragment}
  ${ProjectDocumentV2SurveyRoomsSummary.fragment}
  ${ProjectDocumentV2CartonSummary.fragment}
  ${ProjectDocumentV2GeneralInventorySummary.fragment}
  ${ProjectDocumentV2GeneralInventoryItems.fragment}
  query DocumentTemplatePreviewContent(
    $jobUuid: String!,
    $hasBillingInformation: Boolean!
    $hasCustomerSignatureInput: Boolean!
    $hasValuationDeclarationTable: Boolean!
    $hasTimesheetInformation: Boolean!
    $hasInventorySummary: Boolean!
    $hasInputPerMoverTimesheet: Boolean!
    $hasInputCommercialCatalogEquipment: Boolean!
    $hasInputCommercialCatalogMaterial: Boolean!
    $hasInputValuationCoverage: Boolean!
    $hasDocumentHeader: Boolean!
    $hasOrganizationLogo: Boolean!
    $hasOrganizationSignature: Boolean!
    $hasSurveyItems: Boolean!
    $hasSurveyRoomsSummary: Boolean!
    $hasCartonSummary: Boolean!
    $hasGeneralInventorySummary: Boolean!
    $hasInventoryItems: Boolean!
  ) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        ...ProjectDocumentV2BillingInformation @include(if: $hasBillingInformation)
        ...ProjectDocumentV2CustomerSignatureInput @include(if: $hasCustomerSignatureInput)
        ...ProjectDocumentV2InventorySummary @include(if: $hasInventorySummary)
        ...ProjectDocumentV2DocumentHeaderOrganization @include(if: $hasDocumentHeader)
        ...ProjectDocumentV2OrganizationLogo @include(if: $hasOrganizationLogo)
        ...ProjectDocumentV2OrganizationSignature @include(if: $hasOrganizationSignature)
        ...ProjectDocumentV2SurveyItems @include(if: $hasSurveyItems)
        ...ProjectDocumentV2CartonSummary @include(if: $hasCartonSummary)
        ...ProjectDocumentV2SurveyRoomsSummary @include(if: $hasSurveyRoomsSummary)
      }
      ...DocumentV2TimesheetInformation @include(if: $hasTimesheetInformation)
      ...DocumentV2InputPerMoverTimesheet @include(if: $hasInputPerMoverTimesheet)
      ...DocumentV2ValuationDeclarationTable @include(if: $hasValuationDeclarationTable)
      ...DocumentV2InputCommercialCatalogEquipment @include(if: $hasInputCommercialCatalogEquipment)
      ...DocumentV2InputCommercialCatalogMaterial @include(if: $hasInputCommercialCatalogMaterial)
      ...DocumentV2ValuationCoverage @include(if: $hasInputValuationCoverage)
      ...ProjectDocumentV2GeneralInventorySummary @include(if: $hasGeneralInventorySummary)
      ...ProjectDocumentV2GeneralInventoryItems @include(if: $hasInventoryItems)
    }
  }
`;
export default DocumentTemplatePreview;
