/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import _ from 'lodash';

// Supermove
import {useEffect, useNavigationDOM, useState, FlatListType} from '@supermove/hooks';

// App
import {ProjectBlockKindType} from '@shared/modules/Project/enums/ProjectBlockKind';
import useProjectPageContext from 'modules/Project/V2/Show/hooks/useProjectPageContext';

const handleScrollToBlock = <T,>({
  block,
  allBlocks,
  flatList,
}: {
  block: ProjectBlockKindType;
  allBlocks: ProjectBlockKindType[];
  flatList: FlatListType<T>;
}) => {
  const index = allBlocks.indexOf(block);

  // During the initial render, there can be a moment when the
  // block is not found, in which case the index returns as -1.
  flatList.handleScrollToIndex({index: index < 0 ? 0 : index, animated: true});
};

const useProjectBlockAutoScroll = <T,>({
  allBlocks,
  flatList,
}: {
  allBlocks: ProjectBlockKindType[];
  flatList: FlatListType<T>;
}) => {
  const [positionY, setPositionY] = useState(0);
  const {params} = useNavigationDOM();
  const {isOnLoadScrollComplete, setIsOnLoadScrollComplete, projectBlockKindToHeight} =
    useProjectPageContext();
  const isAllBlocksLoaded = Object.keys(projectBlockKindToHeight).length === allBlocks.length;

  // Handle scrolling to the initial block if needed.
  useEffect(() => {
    if (
      params.block &&
      params.block !== allBlocks[0] &&
      // We need to wait for the blocks to load for FlatList to know
      // the scroll locations.
      isAllBlocksLoaded &&
      // If the initial url contains a block, but the user scrolls
      // before this useEffect runs, we will forgo this step so that
      // the auto-scrolling doesn't interfere with the user's scroll.
      !isOnLoadScrollComplete
    ) {
      // We can just scroll and the scroll handlers will take care
      // of setting isOnLoadScrollComplete to true.
      handleScrollToBlock({block: params.block, allBlocks, flatList});
      setIsOnLoadScrollComplete(true);
    }
  }, [isAllBlocksLoaded]);

  const getBlockOfPositionY = (y: number): ProjectBlockKindType | null => {
    const {block} = allBlocks.reduce<{block: ProjectBlockKindType | null; offset: number}>(
      (result, currentBlock) => {
        const currentOffset = result.offset + _.get(projectBlockKindToHeight, currentBlock, 0);
        if (!result.block && currentOffset > y) {
          return {block: currentBlock, offset: currentOffset};
        }
        return {block: result.block, offset: currentOffset};
      },
      {block: null, offset: 0},
    );

    return block;
  };

  // When the block changes scroll to it if the user
  // is not already on it.
  useEffect(() => {
    const currentBlock = getBlockOfPositionY(positionY);
    if (currentBlock !== params.block) {
      if (params.block === allBlocks[0]) {
        flatList.handleScrollToTop({animated: true});
      } else {
        handleScrollToBlock({block: params.block, allBlocks, flatList});
      }
    }
  }, [params.block]);

  return {
    setPositionY,
    getBlockOfPositionY,
  };
};

export default useProjectBlockAutoScroll;
