// Libraries
import React from 'react';

// Supermove
import {FlatList, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {CrewModel, EmployeeScheduleModel, UserModel} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import CrewUsersAndDriversSchedulesListItem from 'modules/Dispatch/Crew/components/CrewUsersAndDriversSchedulesListItem';

const ScheduleDayLabelsContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${colors.white};
  padding-top: 5px;
  padding-bottom: 4px;
`;

const ScheduleDay = Styled.H8`
  font-size: 11px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  width: 16px;
  text-align: center;
`;

const ScheduleDayLabels = () => {
  return (
    <ScheduleDayLabelsContainer>
      <ScheduleDay>M</ScheduleDay>
      <ScheduleDay>T</ScheduleDay>
      <ScheduleDay>W</ScheduleDay>
      <ScheduleDay>Th</ScheduleDay>
      <ScheduleDay>F</ScheduleDay>
      <ScheduleDay>S</ScheduleDay>
      <ScheduleDay>Su</ScheduleDay>
      <Space width={20} />
    </ScheduleDayLabelsContainer>
  );
};

const CrewUsersAndDriversSchedulesList = ({
  crew,
  selectedUsers,
  userSchedules,
  handleAddUserId,
  handleRemoveUserId,
}: {
  crew: CrewModel;
  selectedUsers: UserModel[];
  userSchedules: EmployeeScheduleModel[];
  handleAddUserId: (userId: string) => void;
  handleRemoveUserId: (userId: string) => void;
}) => {
  const selectedUserIds = selectedUsers.map((user: any) => String(user.id));

  return (
    <React.Fragment>
      {crew.organization.features.isEnabledProjectPageAllSections ? (
        <React.Fragment>
          <ScheduleDayLabels />
          <ScrollView>
            {userSchedules.map((userSchedule: any) => {
              return (
                <CrewUsersAndDriversSchedulesListItem
                  key={userSchedule.user.id}
                  crew={crew}
                  userSchedule={userSchedule}
                  isSelected={selectedUserIds.includes(userSchedule.user.id)}
                  handleAddUser={() => handleAddUserId(userSchedule.user.id)}
                  handleRemoveUser={() => handleRemoveUserId(userSchedule.user.id)}
                />
              );
            })}
          </ScrollView>
        </React.Fragment>
      ) : (
        <FlatList
          listKey='crew-users-and-drivers-schedules-list'
          data={userSchedules}
          keyExtractor={(userSchedule: any) => String(userSchedule.user.id)}
          renderItem={({item: userSchedule}: {item: EmployeeScheduleModel}) => {
            return (
              <CrewUsersAndDriversSchedulesListItem
                crew={crew}
                userSchedule={userSchedule}
                isSelected={selectedUserIds.includes(userSchedule.user.id)}
                handleAddUser={() => handleAddUserId(userSchedule.user.id)}
                handleRemoveUser={() => handleRemoveUserId(userSchedule.user.id)}
              />
            );
          }}
          ListHeaderComponent={() => <ScheduleDayLabels />}
          stickyHeaderIndices={[0]}
          // Reducing windowSize makes selecting and unselecting much faster,
          // but when scrolling fast it will feel choppy
          windowSize={3}
          // The following three props should help optimize this flatlist, but
          // compared to windowSize the felt difference is very little
          maxToRenderPerBatch={7}
          // @ts-expect-error this doesn't exist, should be initialNumToRender
          initialNumberToRender={7}
          removeClippedSubviews
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewUsersAndDriversSchedulesList.fragment = gql`
  ${CrewUsersAndDriversSchedulesListItem.fragment}
  fragment CrewUsersAndDriversSchedulesList_Crew on Crew {
    id
    organization {
      id
      features {
        isEnabledProjectPageAllSections: isEnabled(feature: "PROJECT_PAGE_ALL_SECTIONS")
      }
    }
    ...CrewUsersAndDriversSchedulesListItem_Crew
  }

  fragment CrewUsersAndDriversSchedulesList_EmployeeSchedule on EmployeeSchedule {
    user {
      id
    }
    ...CrewUsersAndDriversSchedulesListItem_EmployeeSchedule
  }
`;

export default CrewUsersAndDriversSchedulesList;
