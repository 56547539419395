// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// Components
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import UserStatus from '@shared/modules/User/enums/UserStatus';
import UserForm from '@shared/modules/User/forms/UserForm';
import useUpdateUserMutation from '@shared/modules/User/hooks/useUpdateUserMutation';
import UserProfileDrawerFields from 'modules/Organization/Settings/Users/components/UserProfileDrawerFields';

const UpdateUserDrawer = ({user, isOpen, handleClose, handleSuccess, viewerRole}: any) => {
  const successToast = useToast({ToastComponent: SuccessToast});

  const userForm = UserForm.edit(user);
  const isPendingAcceptance = user.status === UserStatus.PENDING;
  const {form, submitting, handleSubmit} = useUpdateUserMutation({
    userForm,
    onSuccess: () => {
      handleSuccess();
      successToast.handleToast({message: `${_.get(form.values, 'userForm.names')} updated!`});
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Office Member'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <UserProfileDrawerFields
        form={form}
        field={'userForm'}
        isEnabledStatus={!isPendingAcceptance}
        viewerRole={viewerRole}
      />
    </DrawerWithAction>
  );
};
// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateUserDrawer.fragment = gql`
  ${UserForm.edit.fragment}

  fragment UpdateUserDrawer on User {
    id
    status
    ...UserForm_edit
  }
`;

export default UpdateUserDrawer;
