// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {ViewStyleProp} from '@supermove/styles/types';

// App
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import WorkflowStepForm from '@shared/modules/Workflow/forms/WorkflowStepForm';
import WorkflowStep from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStep';
import WorkflowTriggerSelector from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowTriggerSelector';

const ContentContainer = Styled.View`
  align-items: center;
`;

const AddWorkflowStepButtonContainer = Styled.ButtonV2`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.border};
`;

const AddWorkflowStepConnector = Styled.View`
  width: 2px;
  height: 6px;
  background-color: ${colors.gray.border};
`;

const handleAddWorkflowStep = ({form, field, index}: any) => {
  const workflowStepForms = _.get(form.values, `${field}.workflowStepForms`);
  const formsBeforeIndex = _.slice(workflowStepForms, 0, index);
  const formsAfterIndex = _.slice(workflowStepForms, index);
  const newForm = WorkflowStepForm.new({order: index, workflowStepActionKind: ''});
  const updatedList = [...formsBeforeIndex, newForm, ...formsAfterIndex];
  const updatedOrderedList = updatedList.map((workflowStepForm, index) => {
    return {
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      ...workflowStepForm,
      order: index,
    };
  });
  form.setFieldValue(`${field}.workflowStepForms`, updatedOrderedList);
};

const AddWorkflowStepButton = ({form, field, index}: any) => {
  const cannotUpdateTwoStepsAtOnceErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'You may only edit one step at a time.',
  });
  return (
    <AddWorkflowStepButtonContainer
      onPress={() => {
        if (form.values.workflowForm.isEditingStep) {
          return cannotUpdateTwoStepsAtOnceErrorToast.handleToast();
        }
        handleAddWorkflowStep({form, field, index});
        form.setFieldValue(`workflowForm.isEditingStep`, true);
      }}
    >
      <Icon source={Icon.Plus} size={12} color={colors.gray.secondary} />
    </AddWorkflowStepButtonContainer>
  );
};

const WorkflowStepsBuilder = ({
  form,
  field,
  workflow,
  handleSubmitValidateWorkflow,
  isPreview,
}: any) => {
  const workflowStepFormsField = `${field}.workflowStepForms`;
  const workflowStepForms = _.get(form.values, workflowStepFormsField);
  return (
    <React.Fragment>
      {workflowStepForms.map((workflowStepForm: any, index: any) => {
        const isLastItem = index === workflowStepForms.length - 1;
        return (
          <React.Fragment key={index}>
            {!isPreview && <AddWorkflowStepButton form={form} field={field} index={index} />}
            <AddWorkflowStepConnector />
            <WorkflowStep
              form={form}
              workflowStepsField={workflowStepFormsField}
              workflowStepIndex={index}
              workflow={workflow}
              handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
              isPreview={isPreview}
            />
            {(!isPreview || !isLastItem) && <AddWorkflowStepConnector />}
          </React.Fragment>
        );
      })}
      {!isPreview && (
        <AddWorkflowStepButton form={form} field={field} index={workflowStepForms.length} />
      )}
    </React.Fragment>
  );
};

const ContentWrapper = ({
  isScrollable,
  children,
  style,
}: {
  isScrollable?: boolean;
  children: React.ReactNode;
  style?: ViewStyleProp;
}) => {
  if (isScrollable) {
    return (
      <ScrollView contentContainerStyle={[{alignItems: 'center'}, style]}>{children}</ScrollView>
    );
  }
  return <ContentContainer style={style}>{children}</ContentContainer>;
};

const WorkflowBuilder = ({
  workflow,
  handleSubmitValidateWorkflow,
  form,
  isPreview,
  isScrollable,
  style,
}: any) => {
  const field = 'workflowForm';
  const isStepsEnabled = _.get(form.values, `${field}.isStepsEnabled`);

  return (
    <ContentWrapper isScrollable={isScrollable} style={style}>
      <Space height={16} />
      <WorkflowTriggerSelector
        form={form}
        field={field}
        workflow={workflow}
        isPreview={isPreview}
      />
      {isStepsEnabled && (
        <React.Fragment>
          <AddWorkflowStepConnector />
          <WorkflowStepsBuilder
            form={form}
            field={field}
            workflow={workflow}
            handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
            isPreview={isPreview}
          />
        </React.Fragment>
      )}
      <Space height={96} />
    </ContentWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowBuilder.fragment = gql`
  ${WorkflowStep.fragment}
  ${WorkflowTriggerSelector.fragment}

  fragment WorkflowBuilder on Workflow {
    id
    triggerIdentifier
    ...WorkflowStep
    ...WorkflowTriggerSelector
  }
`;

export default WorkflowBuilder;
