// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigationDOM} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// Components
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';
import GlobalSearchResultBuilder, {
  Row,
  ResponsiveRow,
  ResponsiveDivider,
} from 'modules/App/Global/components/GlobalSearchResultBuilder';

const Header = ({project}: {project: ProjectModel}) => {
  const {isCancelled} = project;
  const responsive = useResponsive();
  // Note: isCancelled values are based on our storage project status values
  const status = isCancelled ? 'Cancelled' : Project.getProjectStatus(project);
  const color = isCancelled ? colors.gray.secondary : Project.getProjectStatusColor(project);
  const icon = isCancelled ? Icon.XmarkLarge : Project.getProjectStatusIcon(project);
  return (
    <Row>
      <ResponsiveBadge iconLeft={icon} label={status} color={color} />
      <Space width={8} />
      <GlobalSearchResultBuilder.NameText responsive={responsive}>
        {Project.getDisplayIdentifierAndName(project)}
      </GlobalSearchResultBuilder.NameText>
    </Row>
  );
};

const Details = ({project}: {project: ProjectModel}) => {
  const responsive = useResponsive();

  return (
    <ResponsiveRow style={responsive.mobile ? {flex: 1} : null} responsive={responsive}>
      <GlobalSearchResultBuilder.Detail icon={Icon.Circle} iconColor={project.projectType.color}>
        {project.projectType.name}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.Calendar}>
        {Project.getDate(project)}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.User}>
        {project.client.name}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.Phone}>
        {Phone.display(project.client.primaryContact.phoneNumber)}
      </GlobalSearchResultBuilder.Detail>
      <ResponsiveDivider responsive={responsive} />
      <GlobalSearchResultBuilder.Detail icon={Icon.Envelope} style={{flex: 1}}>
        {project.client.primaryContact.email}
      </GlobalSearchResultBuilder.Detail>
    </ResponsiveRow>
  );
};

const GlobalSearchResultProject = ({
  project,
  handleClose,
}: {
  project: ProjectModel;
  handleClose: () => void;
}) => {
  const {navigator} = useNavigationDOM();
  const onPress = () => {
    navigator.push(`/projects/${project.uuid}`);
    handleClose();
  };
  return (
    <GlobalSearchResultBuilder.Container onPress={onPress}>
      <Header project={project} />
      <Space height={8} />
      <Details project={project} />
    </GlobalSearchResultBuilder.Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalSearchResultProject.fragment = gql`
  ${Project.getDate.fragment}
  ${Project.getProjectStatus.fragment}
  ${Project.getProjectStatusColor.fragment}
  ${Project.getProjectStatusIcon.fragment}
  ${Project.getDisplayIdentifierAndName.fragment}

  fragment GlobalSearchResultProject on Project {
    id
    uuid
    name
    identifier
    createdAt
    salesStatus
    startDate
    endDate
    isCancelled
    client {
      id
      uuid
      name
      primaryContact {
        id
        phoneNumber
        email
      }
    }
    projectType {
      id
      name
      color
    }
    ...Project_getDate
    ...Project_getProjectStatus
    ...Project_getProjectStatusColor
    ...Project_getProjectStatusIcon
    ...Project_getDisplayIdentifierAndName
  }
`;

export default GlobalSearchResultProject;
