// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {RefType, useResponsive} from '@supermove/hooks';
import {CommunicationModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import CommunicationsList from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsList';

const Container = Styled.View`
  flex: 1;
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
`;

const EmptyStateContainer = Styled.View`
  align-items: center;
  padding: 16px;
`;

const NoMessages = ({customer}: {customer: UserModel}) => {
  const responsive = useResponsive();
  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>No messages</TitleText>
      <Space height={16} />
      <Text responsive={responsive}>
        Use the field below to call, send email or text message to {customer.fullName}.
      </Text>
    </EmptyStateContainer>
  );
};

const CommunicationsWidgetBody = ({
  customer,
  conversations,
  conversationsScrollView,
  handleMissingPhoneNumber,
  viewer,
}: {
  customer: UserModel;
  conversations: CommunicationModel[];
  conversationsScrollView: {ref: RefType<typeof FlatList<CommunicationModel>>};
  handleMissingPhoneNumber: () => void;
  viewer: UserModel;
}) => {
  if (_.isEmpty(conversations)) {
    return (
      <Container style={{justifyContent: 'center'}}>
        <NoMessages customer={customer} />
      </Container>
    );
  }
  return (
    <CommunicationsList
      conversations={conversations}
      customerId={customer.id}
      conversationsScrollView={conversationsScrollView}
      viewer={viewer}
    />
  );
};

CommunicationsWidgetBody.fragment = gql`
  ${CommunicationsList.fragment}

  fragment CommunicationsWidgetBody_Viewer on User {
    id
    ...CommunicationsList_Viewer
  }

  fragment CommunicationsWidgetBody_Communication on Communication {
    id
    ...CommunicationsList_Communication
  }

  fragment CommunicationsWidgetBody_User on User {
    id
    fullName
  }
`;

export default CommunicationsWidgetBody;
