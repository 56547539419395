// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Form, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useMemo, useMountEffect, useResponsive, useState} from '@supermove/hooks';
import {UserModel, TextMessageTemplateModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import TextMessageSystemKind from '@shared/modules/Sms/enums/TextMessageSystemKind';
import SendTextMessageToUserForm from '@shared/modules/Sms/forms/SendTextMessageToUserForm';
import useSendTextMessageToUserMutation from '@shared/modules/Sms/hooks/useSendTextMessageToUserMutation';
import EvaluateAndReplaceVariablesForm from '@shared/modules/Variable/forms/EvaluateAndReplaceVariablesForm';
import useEvaluateAndReplaceVariablesMutation from '@shared/modules/Variable/hooks/useEvaluateAndReplaceVariablesMutation';

const Column = Styled.View`
`;

const IconButtonV2 = Styled.ButtonV2<{backgroundColor: string}>`
  height: 24px;
  width: 24px;
  border-radius: 14px;
  background-color: ${({backgroundColor}) => backgroundColor || colors.blue.interactive};
  align-items: center;
  justify-content: center;
`;

const TextTemplateContainer = Styled.View`
  flex-direction: row;
  padding-horizontal: 16px;
  padding-top: 8px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: flex-end;
`;

const TextTemplateDropdownContainer = Styled.View`
  height: 72px;
  padding: 16px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const TextTemplateDropdownRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LoadingContainer = Styled.View`
  height: 130px;
  justify-content: center;
  align-items: center;
`;

const PaddingContainer = Styled.View`
  padding-horizontal: 16px;
`;

const AddPhoneNumberText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const CharacterCountText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Body}
  color: ${({color}) => color || colors.gray.secondary};
`;

const getPlaceholderText = ({customer}: any) => {
  if (customer.phoneNumber) {
    return 'Reply with SMS';
  }
  return 'Please add a phone number';
};

const CommunicationChatDrawerTextTemplateDropdown = ({
  form,
  textMessageTemplates,
  handlePreviewSubmit,
  previewTextTemplateForm,
  setIsUsingTemplate,
  responsive,
}: {
  form: Form<{
    sendTextMessageToUserForm: {
      organizationId: string;
      recipientId: string;
      senderId: string;
      body: string;
      textMessageTemplateId: string;
      bodyHeader: string;
    };
  }>;
  textMessageTemplates: TextMessageTemplateModel[];
  handlePreviewSubmit: () => void;
  previewTextTemplateForm: Form<{
    evaluateAndReplaceVariablesForm: {
      text: string;
    };
  }>;
  setIsUsingTemplate: (isUsingTemplate: boolean) => void;
  responsive: ResponsiveType;
}) => {
  const textMessageTemplateMap = useMemo(
    () =>
      Object.fromEntries(
        textMessageTemplates.map((textMessageTemplate) => [
          textMessageTemplate.id,
          textMessageTemplate.body,
        ]),
      ),
    [textMessageTemplates],
  );

  const sortedCustomTemplates = useMemo(() => {
    return textMessageTemplates
      .filter((textMessageTemplate: any) => {
        return textMessageTemplate.systemTemplateKind === TextMessageSystemKind.CUSTOM;
      })
      .map((textMessageTemplate: any) => {
        return {
          label: textMessageTemplate.name,
          value: _.toNumber(textMessageTemplate.id),
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }, [textMessageTemplates]);

  return (
    <TextTemplateDropdownContainer>
      <TextTemplateDropdownRowContainer>
        <FieldInput
          {...form}
          index={0}
          name={'sendTextMessageToUserForm.textMessageTemplateId'}
          component={DropdownInput}
          input={{
            menuPlacement: 'top',
            isPortaled: true,
            isSearchable: !responsive.mobile,
            // Filter out system template kinds from sms templates
            options: sortedCustomTemplates,
            placeholder: 'Select an SMS template',
            setFieldValue: form.setFieldValue,
            onChangeValue: (value: any) => {
              // When value is selected, we need to look in to the text message
              // template id -> body object to find the string
              previewTextTemplateForm.setFieldValue(
                'evaluateAndReplaceVariablesForm.text',
                textMessageTemplateMap[value],
              );

              // Resets the text template id if the user wipes out the body
              if (_.isEmpty(value)) {
                form.setFieldValue('sendTextMessageToUserForm.textMessageTemplateId', null);
              }

              setImmediate(handlePreviewSubmit);
            },
            style: {width: '100%'},
          }}
          style={{flex: 1}}
        />
        <Space style={{width: 16}} />
        <IconButtonV2
          onPress={() => {
            form.setFieldValue('sendTextMessageToUserForm.textMessageTemplateId', null);
            setIsUsingTemplate(false);
          }}
          backgroundColor={colors.gray.border}
        >
          <Icon source={Icon.Times} size={14} color={colors.gray.tertiary} />
        </IconButtonV2>
      </TextTemplateDropdownRowContainer>
    </TextTemplateDropdownContainer>
  );
};

const CommunicationsWidgetFooterSendText = ({
  customer,
  viewer,
  refetch,
  projectId,
  textMessageTemplates,
  selectedTextMessageTemplate,
}: {
  customer: UserModel;
  viewer: UserModel;
  refetch: () => void;
  projectId: string;
  textMessageTemplates: any[];
  selectedTextMessageTemplate: any | null;
}) => {
  const responsive = useResponsive();
  const sendTextMessageToUserForm = SendTextMessageToUserForm.newForCommunicationChatButton({
    organizationId: customer.organization.id,
    projectId,
    recipient: customer,
    sender: viewer,
  });
  const resetForm = () => form.setFieldValue('sendTextMessageToUserForm.body', '');
  const {form, handleSubmit, submitting} = useSendTextMessageToUserMutation({
    sendTextMessageToUserForm,
    onSuccess: () => {
      refetch();
      resetForm();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
    refetchQueries: ['CommunicationInboxProjectList'],
  });

  const evaluateAndReplaceVariablesForm = EvaluateAndReplaceVariablesForm.new({
    projectId,
    organizationId: customer.organization.id,
  });

  const {
    form: previewTextTemplateForm,
    handleSubmit: handlePreviewSubmit,
    submitting: previewSubmitting,
  } = useEvaluateAndReplaceVariablesMutation({
    evaluateAndReplaceVariablesForm,
    onSuccess: ({text}: any) => {
      const bodyHeader = _.get(form.values, 'sendTextMessageToUserForm.bodyHeader');
      form.setFieldValue('sendTextMessageToUserForm.body', bodyHeader + text);
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  useMountEffect(() => {
    if (selectedTextMessageTemplate && selectedTextMessageTemplate.body !== '') {
      previewTextTemplateForm.setFieldValue(
        'evaluateAndReplaceVariablesForm.text',
        selectedTextMessageTemplate.body,
      );

      setTimeout(() => handlePreviewSubmit(), 0);
    }
  });

  const field = 'sendTextMessageToUserForm.body';

  const [isUsingTemplate, setIsUsingTemplate] = useState(false);

  const body = _.get(form.values, field);

  const noPhoneNumber = !customer.phoneNumber;
  const isDisabled = !body || submitting || noPhoneNumber;

  return (
    <Column style={{backgroundColor: noPhoneNumber ? colors.gray.border : undefined}}>
      {isUsingTemplate && (
        <CommunicationChatDrawerTextTemplateDropdown
          form={form}
          textMessageTemplates={textMessageTemplates}
          handlePreviewSubmit={handlePreviewSubmit}
          previewTextTemplateForm={previewTextTemplateForm}
          setIsUsingTemplate={setIsUsingTemplate}
          responsive={responsive}
        />
      )}
      {previewSubmitting ? (
        <LoadingContainer>
          <PageLoadingIndicator />
        </LoadingContainer>
      ) : (
        <PaddingContainer>
          {noPhoneNumber ? (
            <React.Fragment>
              <Space height={8} />
              <AddPhoneNumberText responsive={responsive}>
                Add a phone number to send an SMS
              </AddPhoneNumberText>
              <Space height={40} />
            </React.Fragment>
          ) : (
            <FieldInput.Memoized
              {...form}
              index={1}
              name={field}
              isResponsive
              input={{
                placeholder: getPlaceholderText({customer}),
                multiline: true,
                style: {
                  fontWeight: '500',
                  fontSize: responsive.desktop ? '14px' : '16px',
                  color: colors.gray.primary,
                  backgroundColor: colors.white,
                  paddingLeft: 0,
                  paddingRight: 0,
                  borderWidth: 0,
                  borderRadius: 0,
                  paddingVertical: 18,
                  minHeight: 80,
                },
              }}
            />
          )}
        </PaddingContainer>
      )}
      <TextTemplateContainer>
        <TertiaryButton
          text={'Use Template'}
          isResponsive
          isDisabled={submitting || noPhoneNumber}
          onPress={() => {
            setIsUsingTemplate(!isUsingTemplate);
          }}
        />
        <Space style={{flex: 1}} />
        <CharacterCountText
          responsive={responsive}
          color={body.length > 1500 ? colors.red.warning : colors.gray.secondary}
        >
          {`${body.length}/1500`}
        </CharacterCountText>
        <Space width={24} />
        <IconButtonV2
          onPress={handleSubmit}
          disabled={isDisabled}
          backgroundColor={noPhoneNumber ? colors.gray.tertiary : colors.blue.interactive}
        >
          <Icon source={Icon.ArrowUp} size={14} color={colors.white} />
        </IconButtonV2>
      </TextTemplateContainer>
    </Column>
  );
};

CommunicationsWidgetFooterSendText.fragment = gql`
  ${SendTextMessageToUserForm.newForCommunicationChatButton.fragment}

  fragment CommunicationsWidgetFooterSendText_User on User {
    id
    fullName
    phoneNumber
    organization {
      id
    }
    ...SendTextMessageToUserForm_newForCommunicationChatButton
  }

  fragment CommunicationsWidgetFooterSendText_TextMessageTemplate on TextMessageTemplate {
    id
    name
    body
    systemTemplateKind
  }

  fragment CommunicationsWidgetFooterSendText_Viewer on User {
    id
    fullName
  }
`;

export default CommunicationsWidgetFooterSendText;
