// Library
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Conversation, ConversationModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  align-self: baseline;
`;

const CommunicationStatusText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const ConversationItemStatus = ({
  responsive,
  conversation,
}: {
  responsive: ResponsiveType;
  conversation: ConversationModel;
}) => {
  const {icon, color, backgroundColor, text} = Conversation.getStatusInfo(conversation);
  return (
    <Container style={{backgroundColor}}>
      <Icon source={icon} color={color} />
      <Space width={4} />
      <CommunicationStatusText responsive={responsive} style={{color}}>
        {text}
      </CommunicationStatusText>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConversationItemStatus.fragment = gql`
  ${Conversation.getStatusInfo.fragment}
  fragment ConversationItemStatus on Conversation {
    id
    ...Conversation_getStatusInfo
  }
`;

export default ConversationItemStatus;
