// Library
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {ConversationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Line from '@shared/design/components/Line';
import CommunicationInboxCallItem from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxCallItem';
import CommunicationInboxEmailItem from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxEmailItem';
import CommunicationInboxTextItem from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxTextItem';

const Container = Styled.ButtonV2<{selected: boolean}>`
  padding-horizontal: 16px;
  background-color: ${({selected}) => (selected ? colors.blue.accent : colors.white)};
`;

const ContentContainer = Styled.View`
`;

const getComponent = (conversation: ConversationModel, responsive: ResponsiveType) => {
  const {email, textMessage, call} = conversation;
  if (email) {
    return () => (
      <CommunicationInboxEmailItem conversation={conversation} responsive={responsive} />
    );
  }
  if (textMessage) {
    return () => <CommunicationInboxTextItem conversation={conversation} responsive={responsive} />;
  }
  if (call) {
    return () => <CommunicationInboxCallItem conversation={conversation} responsive={responsive} />;
  }
  return () => null;
};

const CommunicationInboxConversationItem = ({
  responsive,
  conversation,
  handleProjectPress,
  isSelected,
}: {
  responsive: ResponsiveType;
  conversation: ConversationModel;
  handleProjectPress: () => void;
  isSelected: boolean;
}) => {
  const Component = getComponent(conversation, responsive);
  return (
    <React.Fragment>
      <Container selected={isSelected} onPress={handleProjectPress}>
        <Space height={16} />
        <ContentContainer>
          <Component />
        </ContentContainer>
        <Space height={12} />
      </Container>
      <Line />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxConversationItem.fragment = gql`
  ${CommunicationInboxEmailItem.fragment}
  ${CommunicationInboxTextItem.fragment}
  ${CommunicationInboxCallItem.fragment}

  fragment CommunicationInboxConversationItem on Conversation {
    id
    ...Conversation_getStatusInfo
    ...CommunicationInboxEmailItem
    ...CommunicationInboxTextItem
    ...CommunicationInboxCallItem
    email {
      id
    }
    textMessage {
      id
    }
    call {
      id
    }
  }
`;

export default CommunicationInboxConversationItem;
