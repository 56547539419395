// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useNavigationDOM} from '@supermove/hooks';

// App
import Tabs from '@shared/design/components/Tabs';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ProjectHeaderMobile from 'modules/Project/V2/Show/components/ProjectHeaderMobile';
import ProjectSection from 'modules/Project/V2/Show/components/ProjectSection';
import ProjectSectionV1 from 'modules/Project/V2/Show/components/ProjectSectionV1';
import ProjectWidgetsMobile from 'modules/Project/V2/Show/components/ProjectWidgetsMobile';
import ProjectWidgetsToolbar from 'modules/Project/V2/Show/components/ProjectWidgetsToolbar';
import ViewProjectJobs from 'modules/Project/V2/Show/components/ViewProjectJobs';
import useProjectPageAnalytics from 'modules/Project/V2/Show/hooks/useProjectPageAnalytics';

const Container = Styled.View`
`;

const FlexedContainer = Styled.View`
  flex: 1;
`;

const getTabs = ({navigationSections}: any) => {
  return navigationSections.map((section: any) => {
    return {
      key: _.lowerCase(section.name.replace(/\s/g, '_')),
      label: section.name,
      section,
    };
  });
};

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <ProjectHeaderMobile.LoadingComponent />
      <Container style={{paddingHorizontal: 16, paddingVertical: 12}}>
        <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.SubheadingText} />
      </Container>
      <Line />
      <Container style={{padding: 16}}>
        <SkeletonLoader isFullWidth height={200} />
        <Space height={16} />
        <SkeletonLoader isFullWidth height={200} />
        <Space height={16} />
        <SkeletonLoader isFullWidth height={200} />
      </Container>
    </React.Fragment>
  );
};

const ShowProjectV2PageMobileContent = ({
  navigationSections,
  project,
  urlFilters,
  refetch,
  refetchAndReset,
}: any) => {
  const {params} = useNavigationDOM();
  const {trackWidget} = useProjectPageAnalytics();
  const isViewingWidget = !!params.widget;
  const isViewingJob = !!params.jobUuid;
  const tabs = getTabs({navigationSections});
  const {isEnabledProjectPageAllSections} = project.organization.features;

  // Track anaylitcs for viewing a widget
  useEffect(() => {
    trackWidget();
  }, [params.widget, trackWidget]);

  return (
    <FlexedContainer>
      {isViewingWidget ? (
        <ProjectWidgetsMobile project={project} urlFilters={urlFilters} />
      ) : (
        <React.Fragment>
          <FlexedContainer>
            {isViewingJob ? (
              <ViewProjectJobs urlFilters={urlFilters} />
            ) : (
              <React.Fragment>
                <ProjectHeaderMobile
                  project={project}
                  refetch={refetch}
                  refetchAndReset={refetchAndReset}
                  urlFilters={urlFilters}
                />
                <Line />
                <Space height={12} />
                <Tabs
                  tabs={tabs}
                  // @ts-expect-error: Need to add definition for section to Tabs as type argument
                  handlePressTab={({section}) => {
                    urlFilters.handleReset(
                      {
                        section: isEnabledProjectPageAllSections ? undefined : section.name,
                        block: section.blocks[0],
                        showCancelledJobs: params.showCancelledJobs,
                      },
                      true,
                    );
                  }}
                  activeTabIndex={tabs.findIndex((tab: any) =>
                    _.some(tab.section.blocks, (block) => block === params.block),
                  )}
                  tabStyle={{paddingLeft: 24, paddingRight: 24}}
                  isSpacedTabs={false}
                />
                <Line />
                {isEnabledProjectPageAllSections ? (
                  <ProjectSection
                    project={project}
                    urlFilters={urlFilters}
                    projectNavigationSections={navigationSections}
                    pageRefetch={refetch}
                    refetchAndReset={refetchAndReset}
                  />
                ) : (
                  <ProjectSectionV1
                    project={project}
                    urlFilters={urlFilters}
                    projectNavigationSections={navigationSections}
                    pageRefetch={refetch}
                    refetchAndReset={refetchAndReset}
                    isAllBlocks
                  />
                )}
                <Line />
              </React.Fragment>
            )}
          </FlexedContainer>
        </React.Fragment>
      )}
      <Line />
      <ProjectWidgetsToolbar urlFilters={urlFilters} project={project} />
    </FlexedContainer>
  );
};

const ShowProjectV2PageMobile = ({
  navigationSections,
  project,
  urlFilters,
  refetch,
  refetchAndReset,
  resetKey,
}: any) => {
  return (
    <ShowProjectV2PageMobileContent
      key={resetKey}
      navigationSections={navigationSections}
      project={project}
      urlFilters={urlFilters}
      refetch={refetch}
      refetchAndReset={refetchAndReset}
    />
  );
};

ShowProjectV2PageMobile.LoadingComponent = LoadingComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowProjectV2PageMobile.fragment = gql`
  ${ProjectHeaderMobile.fragment}
  ${ProjectSection.fragment}
  ${ProjectSectionV1.fragment}
  ${ProjectWidgetsMobile.fragment}
  ${ProjectWidgetsToolbar.fragment}

  fragment ShowProjectV2PageMobile on Project {
    id
    organization {
      id
      features {
        isEnabledProjectPageAllSections: isEnabled(feature: "PROJECT_PAGE_ALL_SECTIONS")
      }
    }
    ...ProjectHeaderMobile
    ...ProjectSection
    ...ProjectSectionV1
    ...ProjectWidgetsMobile
    ...ProjectWidgetsToolbar
  }
`;

export default ShowProjectV2PageMobile;
