// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import Tabs from '@shared/design/components/Tabs';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeTab from '@shared/modules/Project/enums/ProjectTypeTab';

const getProjectTypesTab = (projectType: ProjectTypeModel) => {
  const {uuid, category} = projectType;

  return [
    {
      identifier: ProjectTypeTab.JOB_TYPES,
      label: 'Job Types',
      url: `/settings/projects/project-types/${uuid}/job-types`,
    },
    {
      identifier: ProjectTypeTab.VARIABLES,
      label: 'Variables',
      url: `/settings/projects/project-types/${uuid}/variables`,
    },
    ...List.insertIf(!projectType.organization.features.isEnabledAccountingSettingsV2, {
      identifier: ProjectTypeTab.BILLING,
      label: 'Billing',
      url: `/settings/projects/project-types/${uuid}/billing`,
    }),
    {
      identifier: ProjectTypeTab.SURVEYS,
      label: 'Surveys',
      url: `/settings/projects/project-types/${uuid}/surveys`,
    },
    ...List.insertIf(projectType.organization.settings.isCrewInventoryEnabled, {
      identifier: ProjectTypeTab.DRIVER_INVENTORY,
      label: 'Driver Inventory',
      url: `/settings/projects/project-types/${uuid}/driver-inventory`,
      isBeta: true,
    }),
    {
      identifier: ProjectTypeTab.QUOTES_AND_CONFIRMATIONS,
      label: 'Quotes & Confirmations',
      url: `/settings/projects/project-types/${uuid}/quotes-and-confirmations`,
    },
    {
      identifier: ProjectTypeTab.DOCUMENT_FLOWS,
      label: 'Document Flows',
      url: `/settings/projects/project-types/${uuid}/document-flows`,
    },
    {
      identifier: ProjectTypeTab.VALUATION_COVERAGE,
      label: 'Valuation Coverage',
      url: `/settings/projects/project-types/${uuid}/valuation-coverage`,
    },
    ...List.insertIf(
      projectType.organization.features.isEnabledAccountingSettingsV2 &&
        category === ProjectTypeCategory.MOVE,
      {
        identifier: ProjectTypeTab.PAYMENTS,
        label: 'Payments',
        url: `/settings/projects/project-types/${uuid}/payments`,
      },
    ),
    ...List.insertIf(projectType.organization.features.isEnabledAccountingSettingsV2, {
      identifier: ProjectTypeTab.ACCOUNTING,
      label: 'Accounting',
      url: `/settings/projects/project-types/${uuid}/accounting`,
    }),
  ];
};

const getCurrentTabIndex = ({tabs, selectedTab, navigator}: any) => {
  const indexOfSelectedTab = _.findIndex(tabs, {identifier: selectedTab});
  if (indexOfSelectedTab !== -1) {
    return indexOfSelectedTab;
  }
  return _.findIndex(tabs, {url: navigator.location.pathname});
};

const ProjectTypeSettingsPageNavigationTabs = ({
  projectType,
  selectedTab = undefined,
}: {
  projectType: ProjectTypeModel;
  selectedTab: (typeof ProjectTypeTab)[keyof typeof ProjectTypeTab] | undefined;
}) => {
  const {navigator} = useNavigationDOM();
  const tabs = getProjectTypesTab(projectType);

  return (
    <Tabs
      tabs={tabs}
      handlePressTab={(tab) => navigator.push(tab.url)}
      activeTabIndex={getCurrentTabIndex({tabs, selectedTab, navigator})}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeSettingsPageNavigationTabs.fragment = gql`
  fragment ProjectTypeSettingsPageNavigationTabs on ProjectType {
    id
    uuid
    category
    organization {
      id
      features {
        isEnabledAccountingSettingsV2: isEnabled(feature: "ACCOUNTING_SETTINGS_V2")
      }
      settings {
        id
        isCrewInventoryEnabled
      }
    }
  }
`;

export default ProjectTypeSettingsPageNavigationTabs;
