// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';
import {Currency, pluralize} from '@supermove/utils';

// App
import EmptyState from '@shared/design/components/EmptyState';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import {ProjectBlockKindType} from '@shared/modules/Project/enums/ProjectBlockKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const BLOCK_TITLE = 'Inventory Summary';

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
`;

const EmptyBody = () => {
  return (
    <EmptyState
      icon={Icon.BoxOpen}
      title={'No inventory summary to display'}
      message={'Once inventory is captured, the inventory summary will appear here.'}
      paddingVertical={24}
    />
  );
};

const SkeletonField = ({label}: {label: string}) => {
  return (
    <Column style={{flex: 1}}>
      <FieldValue.LabelText>{label}</FieldValue.LabelText>
      <Space height={8} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={144} />
    </Column>
  );
};

const SkeletonBody = () => {
  return (
    <React.Fragment>
      <Row>
        <SkeletonField label={'Est. Volume'} />
        <SkeletonField label={'Est. Weight'} />
        <SkeletonField label={'Est. Price'} />
      </Row>
      <Space height={16} />
      <Row>
        <SkeletonField label={'Rooms'} />
        <SkeletonField label={'Items'} />
        <SkeletonField label={'Notes'} />
      </Row>
    </React.Fragment>
  );
};

const SkeletonComponent = () => {
  return <ActionPanel BodyComponent={SkeletonBody} title={BLOCK_TITLE} style={{width: '100%'}} />;
};

const BodyComponent = ({project}: {project: ProjectModel}) => {
  const {driverInventory} = project;
  const {collection, notes, totalRoomCountForJobUuids} = driverInventory;
  const {
    totalPriceForFilteredItemsForJobUuids,
    totalVolumeForFilteredItemsForJobUuids,
    totalWeightForFilteredItemsForJobUuids,
    totalItemsCountForFilteredItemsForJobUuids,
  } = collection;
  return (
    <React.Fragment>
      <Row>
        <FieldValue
          style={{flex: 1}}
          label={'Est. Volume'}
          value={`${_.round(totalVolumeForFilteredItemsForJobUuids, 2)} cu ft`}
        />
        <Space width={16} />
        <FieldValue
          style={{flex: 1}}
          label={'Est. Weight'}
          value={`${_.round(totalWeightForFilteredItemsForJobUuids, 2)}  lbs`}
        />
        <Space width={16} />
        <FieldValue
          style={{flex: 1}}
          label={'Est. Price'}
          value={Currency.display(totalPriceForFilteredItemsForJobUuids)}
        />
      </Row>
      <Space height={16} />
      <Row>
        <FieldValue
          style={{flex: 1}}
          label={'Rooms'}
          value={pluralize('room', totalRoomCountForJobUuids, true)}
        />
        <Space width={16} />
        <FieldValue
          style={{flex: 1}}
          label={'Items'}
          value={pluralize('item', totalItemsCountForFilteredItemsForJobUuids, true)}
        />
        <Space width={16} />
        <FieldValue style={{flex: 1}} label={'Notes'} value={notes} empty={'None'} />
      </Row>
    </React.Fragment>
  );
};

const ProjectInventorySummaryBlockContent = ({project}: {project: ProjectModel}) => {
  return (
    <ActionPanel
      BodyComponent={BodyComponent}
      bodyComponentProps={{project}}
      EmptyBodyComponent={EmptyBody}
      isEmpty={_.isEmpty(project.driverInventory)}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ProjectInventorySummaryBlockContentMobile = ({project}: {project: ProjectModel}) => {
  return (
    <React.Fragment>
      <MobileProjectBlockHeader title={BLOCK_TITLE} />
      {_.isEmpty(project.driverInventory) ? <EmptyBody /> : <BodyComponent project={project} />}
    </React.Fragment>
  );
};

const ProjectInventorySummaryBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  projectBlockKind,
}: {
  project: ProjectModel;
  handleSetPositionY?: () => void;
  layoutKey?: string;
  index: number;
  projectBlockKind: ProjectBlockKindType;
}) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectInventorySummaryBlock.query}
      queryVariables={{
        projectUuid: project.uuid,
        jobUuids: project.activeMoveJobs.map((job: any) => job.uuid),
      }}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data}: any) => {
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectInventorySummaryBlockContent project={data.project} />
            ) : (
              <ProjectInventorySummaryBlockContentMobile project={data.project} />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectInventorySummaryBlock.fragment = gql`
  fragment ProjectInventorySummaryBlock on Project {
    id
    uuid
    activeMoveJobs {
      id
      uuid
    }
  }
`;

ProjectInventorySummaryBlock.query = gql`
  query ProjectInventorySummaryBlock($projectUuid: String!, $jobUuids: [String]) {
    ${gql.query}

    project(uuid: $projectUuid) {
      id
      uuid
      driverInventory {
        id
        uuid
        notes
        totalRoomCountForJobUuids(jobUuids: $jobUuids)
        collection {
          id
          totalPriceForFilteredItemsForJobUuids(jobUuids: $jobUuids)
          totalVolumeForFilteredItemsForJobUuids(jobUuids: $jobUuids)
          totalWeightForFilteredItemsForJobUuids(jobUuids: $jobUuids)
          totalItemsCountForFilteredItemsForJobUuids(jobUuids: $jobUuids)
        }
      }
    }
  }
`;

export default ProjectInventorySummaryBlock;
