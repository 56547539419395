// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';

// App
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeDayEstimateItem from 'modules/App/Day/components/ProjectTypeDayEstimateItem';
import ProjectTypeDayItem from 'modules/App/Day/components/ProjectTypeDayItem';

const Item = Styled.View`
  flex-direction: row;
`;

const Indicator = Styled.Loading`
  flex: 1;
`;

type OwnProps = {
  date: string;
  showEstimates?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MultibranchCalendarProjectTypeDaysSection.defaultProps;

// @ts-expect-error TS(7022): 'MultibranchCalendarProjectTypeDaysSection' implic... Remove this comment to see the full error message
const MultibranchCalendarProjectTypeDaysSection = ({date, showEstimates}: Props) => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(MultibranchCalendarProjectTypeDaysSection.query, {
    variables: {
      date,
      showEstimates, // Fetch jobs OR estimates, but there's no need to fetch both
      slugs: params.slugs || [],
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Indicator />;
  }

  // Once we find all the project type days based on all the slugs
  // We flatten them together into 1 list
  const projectTypeDays = (data.viewer.viewingOrganization?.daysForOrganizationSlugs || []).reduce(
    (projectTypeDays: any, day: any) => {
      if (Array.isArray(day.activeProjectTypeDays)) {
        return [...projectTypeDays, ...day.activeProjectTypeDays];
      } else {
        return projectTypeDays;
      }
    },
    [],
  );
  // We filter out branches and only keep
  const filteredProjectTypeDays = projectTypeDays.filter((projectTypeDay: any) => {
    return ![OrganizationKind.BRANCH, OrganizationKind.CONTRACTOR].includes(
      projectTypeDay.projectType.organization.kind,
    );
  });

  return (
    <FlatList
      listKey={'multi-branch-calendar-project-type-days'}
      horizontal
      data={_.sortBy(filteredProjectTypeDays, 'projectType.index')}
      keyExtractor={(projectTypeDay) => projectTypeDay.id}
      renderItem={({item: projectTypeDay, index}) =>
        projectTypeDay.projectType.category === ProjectTypeCategory.MOVE ? (
          <Item key={index}>
            {showEstimates ? (
              <ProjectTypeDayEstimateItem projectTypeDay={projectTypeDay} isMultiBranch />
            ) : (
              <ProjectTypeDayItem projectTypeDay={projectTypeDay} isMultiBranch />
            )}
            <Space width={5} />
          </Item>
        ) : null
      }
      contentContainerStyle={{
        paddingVertical: 10,
      }}
      style={{
        alignSelf: 'stretch',
      }}
    />
  );
};

MultibranchCalendarProjectTypeDaysSection.defaultProps = {
  showEstimates: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MultibranchCalendarProjectTypeDaysSection.query = gql`
  ${ProjectTypeDayItem.fragment}
  ${ProjectTypeDayEstimateItem.fragment}

  query MultibranchCalendarProjectTypeDaysSection($date: String!, $showEstimates: Boolean!, $slugs: [String]) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        daysForOrganizationSlugs(date: $date, slugs: $slugs) {
          id
          activeProjectTypeDays {
            id
            projectType {
              id
              index
              category
              organization {
                id
                kind
              }
            }
            ...ProjectTypeDayItem @skip(if: $showEstimates)
            ...ProjectTypeDayEstimateItem @include(if: $showEstimates)
          }
        }
      }
    }
  }
`;

export default MultibranchCalendarProjectTypeDaysSection;
