// Libraries
import React from 'react';

// Supermove
import {useSupportTools} from '@supermove/analytics';
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive, useSheet} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// Components
import Sheet from '@shared/design/components/Sheet';
import {getAdminAppUrl} from '@shared/modules/App/utils/admin/getAdminAppUrl';
import UserRole from '@shared/modules/User/enums/UserRole';
import GlobalNavigationSidebarItem from 'modules/App/Global/components/GlobalNavigationSidebarItem';
import Line from 'modules/App/components/Line';
import LogOutModal from 'modules/App/components/Sidebar/LogOutModal';
import {SidebarItemWithSSERefetch} from 'modules/App/components/Sidebar/SidebarItemWithSSERefetch';
import {getSidebarItems} from 'modules/App/components/Sidebar/data/SidebarData';
import useAppContext from 'modules/App/context/useAppContext';

const getNotificationsCount = ({key, data}: any) => {
  const {jobRequestCounter, viewer} = data;
  switch (key) {
    case 'leads':
    case 'projects':
    case 'moves':
      return jobRequestCounter.new;
    case 'tasks':
      return viewer.dueTasksCount;
    case 'conversations':
      return viewer.openConversationsCount;
    default:
      return 0;
  }
};

const Container = Styled.View<{desktop: boolean; isExpanded: boolean}>`
  width: ${({desktop, isExpanded}) => (!desktop ? '100%' : isExpanded ? '200px' : '64px')};
  border-right-width: ${({desktop}) => (!desktop ? 0 : 1)}px;
  border-color: ${colors.gray.border};
  ${({desktop}) => (!desktop ? 'flex: 1;' : '')}
`;

const MobileFooterContainer = Styled.View`
  padding-horizontal: 8px;
`;

const OptionButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-vertical: 12px;
`;

const OptionText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const Option = ({onPress, text, textColor}: any) => {
  return (
    <OptionButton onPress={onPress}>
      <OptionText style={{color: textColor || colors.gray.primary}}>{text}</OptionText>
    </OptionButton>
  );
};

const SidebarMobileFooter = ({viewer, handleClose}: any) => {
  const {navigator} = useNavigationDOM();
  const {globalNavigationTab} = useAppContext();
  const {handleOpen} = useSupportTools({isChatVisible: false});
  const userActionsSheet = useSheet({name: 'User Actions Sheet'});
  const companySlug = viewer.viewingOrganization?.company?.slug;
  const adminAppUrl = getAdminAppUrl({companySlug});

  return (
    <MobileFooterContainer>
      <Space height={8} />
      <GlobalNavigationSidebarItem
        to={'/settings'}
        name={'Settings'}
        source={Icon.Gear}
        notificationCount={0}
        isSelected={globalNavigationTab === 'settings'}
        handleClose={handleClose}
      />
      <Space height={4} />
      <GlobalNavigationSidebarItem
        onPress={handleOpen}
        name={'Help Center'}
        source={Icon.CommentQuestion}
        notificationCount={0}
        isSelected={false}
        handleClose={handleClose}
      />
      {viewer && (
        <React.Fragment>
          <Space height={4} />
          <GlobalNavigationSidebarItem
            onPress={userActionsSheet.handleOpen}
            name={viewer.fullName}
            source={Icon.User}
            notificationCount={0}
            isSelected={false}
          />
        </React.Fragment>
      )}
      <Space height={8} />
      <Sheet
        key={userActionsSheet.key}
        isOpen={userActionsSheet.isOpen}
        handleClose={userActionsSheet.handleClose}
        headerText={'Actions'}
        style={{paddingHorizontal: 16}}
      >
        <Option
          text={'Update account settings'}
          onPress={() => {
            navigator.push('/account/profile/personal-information');
            handleClose();
          }}
        />
        <Option
          text={'Go to help center'}
          onPress={() => {
            navigator.pushNewTab('https://help.supermove.com/');
            handleClose();
          }}
        />
        {viewer.role === UserRole.SUPER_ADMIN && (
          <Option
            text={'Go to admin settings'}
            onPress={() => {
              navigator.pushNewTab(adminAppUrl);
              handleClose();
            }}
          />
        )}
        <LogOutModal
          trigger={({handleOpen}: any) => {
            return <Option text={'Sign out'} textColor={colors.red.warning} onPress={handleOpen} />;
          }}
        />
      </Sheet>
    </MobileFooterContainer>
  );
};

const GlobalNavigationSidebar = ({handleClose}: any) => {
  const responsive = useResponsive();
  const {isGlobalNavSidebarExpanded, globalNavigationTab} = useAppContext();
  const {loading, data, startPolling, stopPolling, refetch} = useQuery(
    GlobalNavigationSidebar.query,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  return (
    <Container isExpanded={isGlobalNavSidebarExpanded} {...responsive}>
      <Loading loading={loading}>
        {() => (
          <React.Fragment>
            <ScrollView contentContainerStyle={{padding: 8}}>
              {getSidebarItems({
                viewer: data.viewer,
              }).map(({key, to, name, source, sseRefetchTrigger}, index) => {
                const notificationsCount = getNotificationsCount({
                  key,
                  data,
                  selected: globalNavigationTab,
                });
                const isSelected = key === globalNavigationTab;
                return (
                  <React.Fragment key={key}>
                    {index > 0 && <Space height={4} />}
                    {sseRefetchTrigger ? (
                      <SidebarItemWithSSERefetch
                        key={key}
                        count={notificationsCount}
                        selected={isSelected}
                        to={to}
                        name={name}
                        source={source}
                        startPolling={startPolling}
                        stopPolling={stopPolling}
                        refetch={refetch}
                        sseRefetchTrigger={sseRefetchTrigger}
                        loading={loading}
                        handleClose={handleClose}
                      />
                    ) : (
                      <GlobalNavigationSidebarItem
                        key={key}
                        notificationCount={notificationsCount}
                        isSelected={isSelected}
                        to={to}
                        name={name}
                        source={source}
                        isExpanded={isGlobalNavSidebarExpanded}
                        handleClose={handleClose}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ScrollView>
            {!responsive.desktop && (
              <React.Fragment>
                <Line />
                <SidebarMobileFooter viewer={data.viewer} handleClose={handleClose} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalNavigationSidebar.query = gql`
  ${getSidebarItems.fragment}

  query GlobalNavigationSidebar {
    ${gql.query}
    jobRequestCounter {
      new
    }
    viewer {
      id
      fullName
      role
      dueTasksCount
      openConversationsCount
      viewingOrganization {
        id
        company {
          id
          slug
        }
      }
      ...User_getSidebarItems
    }
  }
`;

export default GlobalNavigationSidebar;
