// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {PaymentMethodModel} from '@supermove/models';

// App
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';

const Row = Styled.View`
  display: flex;
  flex-direction: row;
`;

const PaymentMethodBadges = ({paymentMethods}: {paymentMethods: PaymentMethodModel[]}) => {
  const hasPaymentMethods = paymentMethods.length > 0;
  const ROW_SPACE = 8;
  return (
    <Row style={{flex: 1, flexWrap: 'wrap', marginBottom: hasPaymentMethods ? -1 * ROW_SPACE : 0}}>
      {paymentMethods.map((paymentMethod) => (
        <Row key={paymentMethod.id}>
          <ResponsiveBadge
            label={paymentMethod.name}
            key={paymentMethod.id}
            style={{marginBottom: ROW_SPACE}}
          />
          <Space width={8} />
        </Row>
      ))}
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentMethodBadges.fragment = gql`
  fragment PaymentMethodBadges on PaymentMethod {
    id
    name
  }
`;

export default PaymentMethodBadges;
