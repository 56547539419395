// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {ThreadModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import CustomerEmailThreadItemV2 from 'modules/Communication/Email/components/CustomerEmailThreadItemV2';
import ProjectSendEmailOptionsPopover from 'modules/Project/components/ProjectSendEmailOptionsPopover';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  overflow: auto;
`;

const LinkButton = Styled.ButtonV2`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const NoEmail = ({handleMissingContactInfo}: any) => {
  const responsive = useResponsive();

  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>Send an email</TitleText>
      <Space height={16} />
      <Row>
        <LinkButton onPress={handleMissingContactInfo}>
          <LinkText responsive={responsive}>Add your customer's email</LinkText>
        </LinkButton>
        <Text responsive={responsive}> to send emails.</Text>
      </Row>
    </EmptyStateContainer>
  );
};

const NoEmailThread = ({refetch}: {refetch: () => void}) => {
  const responsive = useResponsive();
  const projectSendEmailOptionsPopover = usePopover({name: 'Project Send Email Options Popover'});

  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>No emails</TitleText>
      <Space height={16} />
      <Text responsive={responsive}>Any emails you send will appear here.</Text>
      <React.Fragment>
        <Space height={16} />
        <ProjectSendEmailOptionsPopover
          actionMenuPopover={projectSendEmailOptionsPopover}
          refetch={refetch}
        >
          <Button
            iconLeft={Icon.Plus}
            text={'Send Email'}
            onPress={projectSendEmailOptionsPopover.handleOpen}
          />
        </ProjectSendEmailOptionsPopover>
      </React.Fragment>
    </EmptyStateContainer>
  );
};

const EmailThreadsListContent = ({
  emailThreads,
  customer,
  handleMissingContactInfo,
  setSelectedThread,
  refetch,
}: {
  emailThreads: ThreadModel[];
  customer: UserModel;
  handleMissingContactInfo: () => void;
  setSelectedThread: (thread: ThreadModel) => void;
  refetch: () => void;
}) => {
  if (!customer.email) {
    return <NoEmail handleMissingContactInfo={handleMissingContactInfo} />;
  }

  if (_.isEmpty(emailThreads)) {
    return <NoEmailThread refetch={refetch} />;
  }

  return (
    <Container>
      {emailThreads.map((emailThread: any, index: any) => (
        <CustomerEmailThreadItemV2
          key={index}
          emailThread={emailThread}
          setSelectedThread={setSelectedThread}
        />
      ))}
    </Container>
  );
};

const EmailThreadsListV2 = ({
  emailThreads,
  customer,
  handleMissingContactInfo,
  setSelectedThread,
  refetch,
}: {
  emailThreads: ThreadModel[];
  customer: UserModel;
  handleMissingContactInfo: () => void;
  setSelectedThread: (thread: ThreadModel) => void;
  refetch: () => void;
}) => {
  return (
    <EmailThreadsListContent
      emailThreads={emailThreads}
      customer={customer}
      handleMissingContactInfo={handleMissingContactInfo}
      setSelectedThread={setSelectedThread}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadsListV2.fragment = gql`
  ${CustomerEmailThreadItemV2.fragment}

  fragment EmailThreadsListV2_Thread on Thread {
    id
    name
    ...CustomerEmailThreadItemV2
  }

  fragment EmailThreadsListV2_User on User {
    id
    email
    organization {
      id
      sortedEmailTemplates {
        id
        name
        uuid
      }
    }
  }
`;

export default EmailThreadsListV2;
