// Libraries
import React from 'react';

// Supermove
import {useNavigationDOM} from '@supermove/hooks';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const DispatchPageHeader = () => {
  const {navigator} = useNavigationDOM();

  const breadcrumbs = [
    {name: 'Dispatch', onPress: () => navigator.push('/dispatch')},
    {name: 'Long Distance'},
  ];

  return (
    <StandardOfficeHeader
      title={''}
      showLine={false}
      // @ts-expect-error TS(2322): Type '{ marginVertical: string; marginHorizontal: ... Remove this comment to see the full error message
      officeHeaderStyle={{marginVertical: '10px', marginHorizontal: '16px'}}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} mobileOptions={{showPreviousScreenOnly: true}} />
    </StandardOfficeHeader>
  );
};

export default DispatchPageHeader;
