// Libraries
import React from 'react';

// Components
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import CrewJobUserItem from './CrewJobUserItem';

const JobUser = Styled.View`
  margin-top: ${(props) => ((props as any).isFirst ? 0 : 10)}px;
`;

const CrewJobUsersList = ({job}: any) => (
  <FlatList
    listKey={'crew-job-users-list'}
    data={job.jobUsers}
    keyExtractor={(jobUser: any) => `${jobUser.jobId}-${jobUser.userId}`}
    renderItem={({item: jobUser, index}: any) => (
      // @ts-expect-error TS(2769): No overload matches this call.
      <JobUser isFirst={index === 0}>
        <CrewJobUserItem
          schedulingType={job.organization.settings.schedulingType}
          jobUser={jobUser}
        />
      </JobUser>
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobUsersList.fragment = gql`
  ${CrewJobUserItem.fragment}

  fragment CrewJobUsersList on Job {
    id
    jobUsers: activeJobUsers {
      jobId
      userId
      ...CrewJobUserItem
    }
    organization {
      id
      settings {
        id
        schedulingType
      }
    }
  }
`;

export default CrewJobUsersList;
