// Libararies
import React from 'react';

// Supermove
import {Icon, PreventPropagation, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useNavigationDOM,
  usePopover,
  useEffect,
  ResponsiveType,
  useResponsive,
  UrlFiltersType,
} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import Table from '@shared/design/components/Table';
import DocumentFlowDetailsDrawer from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowDetailsDrawer';
import OrganizationSettingsDocumentFlowDeleteModal from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowDeleteModal';
import OrganizationSettingsDocumentFlowDuplicateModal from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowDuplicateModal';
import {OrganizationSettingsDocumentFlowsFiltersType} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFiltersType';

const Container = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 0)}px;
`;

const UserFlowActions = ({userFlow, refetch}: {userFlow: UserFlowModel; refetch: () => void}) => {
  const {navigator} = useNavigationDOM();

  const userFlowActionsPopover = usePopover();
  const deleteUserFlowModal = useModal({name: 'Delete Document Flow Modal'});
  const duplicateDocumentFlowModal = useModal({name: 'Duplicate Document Flow Modal'});

  const actions = [
    {
      text: 'View runs',
      onPress: () => {
        navigator.push(`/settings/documents/document-flows/${userFlow.uuid}/runs`);
      },
    },
    {
      text: 'Duplicate flow',
      onPress: duplicateDocumentFlowModal.handleOpen,
    },
    {
      text: 'Delete flow',
      color: colors.red.warning,
      onPress: deleteUserFlowModal.handleOpen,
    },
  ];

  return (
    <React.Fragment>
      <IconButton
        onPress={() => {
          navigator.push(`/settings/documents/document-flows/${userFlow.uuid}/edit`);
        }}
        source={Icon.Pen}
      />
      <Space width={8} />
      <ActionMenuPopover
        key={userFlowActionsPopover.key}
        popover={userFlowActionsPopover}
        actions={actions}
        width={200}
      >
        <IconButton
          onPress={userFlowActionsPopover.handleToggle}
          isSecondary
          source={Icon.EllipsisV}
        />
      </ActionMenuPopover>
      <PreventPropagation>
        <OrganizationSettingsDocumentFlowDeleteModal
          key={deleteUserFlowModal.key}
          userFlowUuid={userFlow.uuid}
          deleteUserFlowModal={deleteUserFlowModal}
          refetch={refetch}
        />
        <OrganizationSettingsDocumentFlowDuplicateModal
          key={duplicateDocumentFlowModal.key}
          userFlow={userFlow}
          isOpen={duplicateDocumentFlowModal.isOpen}
          handleClose={duplicateDocumentFlowModal.handleClose}
          onDuplicated={refetch}
        />
      </PreventPropagation>
    </React.Fragment>
  );
};

const getColumnDefinitions = ({refetch}: {refetch: () => void}) => {
  const projectTypesDisplay = (userFlow: UserFlowModel) =>
    userFlow.projectTypeUserFlows
      .map((projectTypeUserFlow) => projectTypeUserFlow.projectType.name)
      .join(', ');

  const documentTemplatesDisplay = (userFlow: UserFlowModel) =>
    userFlow.userFlowSteps.map((userFlowStep) => userFlowStep.documentTemplate.name).join(', ');

  return [
    {
      flex: 3,
      headerLabel: 'Name',
      cellText: (userFlow: UserFlowModel) => userFlow.name,
    },
    {
      flex: 3,
      headerLabel: 'Description',
      numberOfLines: 2,
      cellText: (userFlow: UserFlowModel) => userFlow.description,
      emptyText: '-',
    },
    {
      flex: 5,
      headerLabel: 'Project Types',
      cellText: projectTypesDisplay,
      tooltip: projectTypesDisplay,
      secondary: {
        headerLabel: 'Steps',
        cellText: documentTemplatesDisplay,
        tooltip: documentTemplatesDisplay,
      },
    },
    {
      flex: 2,
      headerLabel: 'Last Updated',
      cellText: (userFlow: UserFlowModel) => {
        return Datetime.convertToDisplayDatetime(userFlow.updatedAt);
      },
      secondary: {
        cellText: (userFlow: UserFlowModel) => {
          return userFlow.updatedBy.fullName;
        },
      },
    },
    {
      flex: 1,
      headerLabel: 'Actions',
      cellStyle: {
        alignItems: 'center',
      },
      cellComponent: (userFlow: UserFlowModel) => {
        return <UserFlowActions userFlow={userFlow} refetch={refetch} />;
      },
    },
  ];
};

const BASE_ROUTE = '/settings/documents/document-flows';

const DocumentsSettingsDocumentFlowsPageContent = ({
  urlFilters,
  userFlows,
  refetch,
}: {
  urlFilters: UrlFiltersType<OrganizationSettingsDocumentFlowsFiltersType>;
  userFlows: UserFlowModel[];
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const {params, navigator} = useNavigationDOM();
  const {userFlowUuid: selectedUserFlowUuid} = params;

  const documentFlowDetailsDrawer = useModal({name: 'Document Flow Details Drawer'});

  const clearSelection = () =>
    navigator.push(URL.getUrlFromVariables(BASE_ROUTE, urlFilters.getFilters()));

  useEffect(() => {
    const foundUserFlow = userFlows.find((userFlow) => userFlow.uuid === selectedUserFlowUuid);
    if (foundUserFlow && !documentFlowDetailsDrawer.isOpen) {
      documentFlowDetailsDrawer.handleOpen();
    } else if (!foundUserFlow) {
      clearSelection();
    }
  }, [selectedUserFlowUuid]);

  return (
    <Container responsive={responsive}>
      {!responsive.desktop && <Line />}
      <Table
        columnDefinitions={getColumnDefinitions({
          refetch,
        })}
        items={userFlows}
        itemKey={'id'}
        emptyStateText={'No document flows to display'}
        onRowPress={(userFlow) => {
          navigator.push(
            URL.getUrlFromVariables(`${BASE_ROUTE}/${userFlow.uuid}`, urlFilters.getFilters()),
          );
        }}
      />
      {!responsive.desktop && <Line />}
      <DocumentFlowDetailsDrawer
        key={documentFlowDetailsDrawer.key}
        userFlowUuid={selectedUserFlowUuid}
        isOpen={documentFlowDetailsDrawer.isOpen}
        handleClose={clearSelection}
      />
    </Container>
  );
};

DocumentsSettingsDocumentFlowsPageContent.query = gql`
  fragment DocumentsSettingsDocumentFlowsPageContent on UserFlow {
    id
    uuid
    name
    description
    projectTypeUserFlows {
      id
      projectType {
        id
        name
      }
    }
    userFlowSteps {
      id
      documentTemplate {
        id
        name
      }
    }
    updatedAt
    updatedBy {
      id
      fullName
    }
  }
`;

export default DocumentsSettingsDocumentFlowsPageContent;
