// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {ThreadModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CustomerEmailThreadItemAttachments from 'modules/Communication/Email/components/CustomerEmailThreadItemAttachments';

const DetailsText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const NameContainer = Styled.View`
  flex: 1;
`;

const DateContainer = Styled.View`
  flex-shrink: 0;
  margin-left: 4px;
  align-items: flex-end;
`;

const EmailThreadItem = Styled.ButtonV2<{hasError: boolean; isPending: boolean}>`
  background-color: ${({hasError, isPending}) => (hasError ? colors.orange.accent : isPending ? colors.gray.border : colors.white)};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmailThreadName = Styled.Text`
  ${Typography.Responsive.Label}
`;

const EmailMessageCount = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const CustomerEmailThreadItemV2 = ({
  emailThread,
  setSelectedThread,
}: {
  emailThread: ThreadModel;
  setSelectedThread: (thread: ThreadModel) => void;
}) => {
  const responsive = useResponsive();
  const mostRecentEmail = emailThread.allEmails[emailThread.allEmails.length - 1];
  const hasFromName = !!mostRecentEmail?.fromName;
  const hasDate = !!emailThread.mostRecentCommunicationDate;
  const hasError = mostRecentEmail?.status === 'ERROR';
  const isPending = ['PENDING', 'QUEUED'].includes(mostRecentEmail?.status);
  const emailCount = emailThread.allEmails.length;

  return (
    <EmailThreadItem
      disabled={isPending}
      onPress={() => setSelectedThread(emailThread)}
      hasError={hasError}
      isPending={isPending}
    >
      <Row>
        {hasError || isPending ? (
          <React.Fragment>
            <Icon
              source={hasError ? Icon.ExclamationTriangle : Icon.Loader}
              color={hasError ? colors.orange.status : colors.gray.secondary}
              size={14}
            />
            <Space width={8} />
          </React.Fragment>
        ) : null}
        <EmailThreadName responsive={responsive} numberOfLines={2}>
          {emailThread.name}
          {emailCount > 1 ? (
            <EmailMessageCount responsive={responsive}> ({emailCount})</EmailMessageCount>
          ) : null}
        </EmailThreadName>
      </Row>
      <Space height={8} />
      <Row>
        <NameContainer>
          <DetailsText responsive={responsive} numberOfLines={1}>
            {hasFromName ? mostRecentEmail.fromName : null}
          </DetailsText>
        </NameContainer>
        <DateContainer>
          <DetailsText responsive={responsive} numberOfLines={1}>
            {hasDate
              ? Datetime.convertToDisplayDatetime(emailThread.mostRecentCommunicationDate)
              : null}
          </DetailsText>
        </DateContainer>
      </Row>
      {mostRecentEmail?.emailAttachments?.length ? (
        <Row>
          <CustomerEmailThreadItemAttachments
            emailAttachments={mostRecentEmail.emailAttachments}
            emailStatus={mostRecentEmail.status}
          />
        </Row>
      ) : null}
    </EmailThreadItem>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerEmailThreadItemV2.fragment = gql`
  ${CustomerEmailThreadItemAttachments.fragment}

  fragment CustomerEmailThreadItemV2 on Thread {
    id
    name
    mostRecentCommunicationDate
    allEmails {
      id
      fromName
      status
      ...CustomerEmailThreadItemAttachments
    }
  }
`;

export default CustomerEmailThreadItemV2;
