// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, ResponsiveType, useResponsive, useState} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import FieldInput from '@shared/design/components/Field/FieldInput';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import {FormulaFormTypeToForm} from '@shared/modules/Billing/forms/FormulaForm';
import FormulaEditor from 'modules/Organization/Settings/Billing/Formulas/components/FormulaEditor';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
  padding-vertical: 16px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
  flex-direction: row;
  background-color: ${colors.white};
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const FormulaPage = ({
  form,
  organization,
  handleSubmit,
  handleClose,
  isSubmitting,
  title,
}: {
  form: Form<{formulaForm: FormulaFormTypeToForm}>;
  organization: OrganizationModel;
  handleSubmit: () => void;
  handleClose: () => void;
  isSubmitting: boolean;
  title: string;
}) => {
  const responsive = useResponsive();
  const [isDebugMode, setIsDebugMode] = useState(false);
  const field = 'formulaForm';
  const isMissingName = !_.get(form.values, `${field}.name`);
  const identifier = _.get(form.values, `${field}.identifier`);
  return (
    <Container>
      <Header responsive={responsive}>
        <IconButton source={Icon.Xmark} size={20} isSecondary onPress={handleClose} />
        <Space width={16} />
        <HeaderText
          responsive={responsive}
        >{`${title}${identifier ? ` - ${identifier}` : ''}`}</HeaderText>
        <Space flex={1} />
        <Button
          isSubmitting={isSubmitting}
          text={'Save'}
          iconLeft={Icon.Check}
          onPress={handleSubmit}
          onLongPress={() => setIsDebugMode(!isDebugMode)}
        />
      </Header>
      <Row>
        <FieldInput
          {...form}
          isResponsive
          name={`${field}.name`}
          input={{
            placeholder: 'Name',
            style: {
              paddingLeft: 0,
              paddingRight: 0,
              borderWidth: 0,
              flex: 1,
              backgroundColor: 'transparent',
            },
          }}
          style={{
            paddingHorizontal: 16,
            width: 260,
            alignSelf: 'flex-start',
            backgroundColor: isMissingName ? colors.alpha(colors.yellow.hover, 0.1) : colors.white,
          }}
        />
        <Line isVertical />
        <FieldInput
          {...form}
          isResponsive
          name={`${field}.description`}
          input={{
            placeholder: 'Description',
            style: {paddingLeft: 0, paddingRight: 0, borderWidth: 0, flex: 1},
          }}
          style={{paddingHorizontal: 16, flex: 1, alignSelf: 'flex-start'}}
        />
      </Row>
      <Line />
      <FormulaEditor
        form={form}
        field={'formulaForm'}
        variables={organization.allVariables}
        isDebugMode={isDebugMode}
        organization={organization}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FormulaPage.fragment = gql`
  ${FormulaEditor.fragment}
  fragment FormulaPage on Organization {
    id
    allVariables {
      id
      ...FormulaEditor
    }
    ...FormulaEditor_Organization
  }
`;

export default FormulaPage;
