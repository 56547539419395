// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import NylasAccountPanel from 'modules/User/Settings/Communication/Email/components/NylasAccountPanel';
import UserSettingsPageWrapper from 'modules/User/Settings/components/UserSettingsPageWrapper';

const Content = Styled.View`
`;

const EmailContent = ({
  redirectUri,
  redirectPath,
  viewer,
  refetch,
}: {
  redirectUri: string;
  redirectPath: string;
  viewer: any;
  refetch: () => void;
}) => {
  return (
    <Content>
      <NylasAccountPanel
        redirectUri={redirectUri}
        redirectPath={redirectPath}
        user={viewer}
        onDeactivate={() => refetch()}
        organizationId={viewer.viewingOrganization.id}
        userId={viewer.id}
      />
    </Content>
  );
};

const UserSettingsCommunicationEmailPage = () => {
  const {loading, data, refetch} = useQuery(UserSettingsCommunicationEmailPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  // Create redirect uri specific for this page.
  const redirectPath = '/account/communication/email';
  const redirectUrl = new URL(redirectPath, `${window.location.protocol}//${window.location.host}`);
  const redirectUri = redirectUrl.toString();

  return (
    <UserSettingsPageWrapper selectedLabel={'Communication/Email'} pageTitle={'Email'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <EmailContent
            redirectUri={redirectUri}
            redirectPath={redirectPath}
            viewer={data.viewer}
            refetch={refetch}
          />
        )}
      </Loading>
    </UserSettingsPageWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserSettingsCommunicationEmailPage.query = gql`
  ${NylasAccountPanel.fragment}

  query UserSettingsCommunicationEmailPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
      ...NylasAccountPanel
    }
  }
`;

export default UserSettingsCommunicationEmailPage;
