// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useInlineFormMutation,
  useModal,
  useNavigationDOM,
  usePopover,
  useResponsive,
  useState,
  ResponsiveType,
  PopoverType,
} from '@supermove/hooks';
import {Job, JobModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import Panel from '@shared/design/components/Panel';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import EditFieldTextAreaButton from '@shared/modules/App/components/EditFieldTextAreaButton';
import {ProjectBlockKindType} from '@shared/modules/Project/enums/ProjectBlockKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import SendJobDetailsToSpecificUsersModal from 'modules/Day/Jobs/components/SendJobDetailsToSpecificUsersModal';
import CreateCrewsForJobButton from 'modules/Job/V2/Move/components/CreateCrewsForJobButton';
import JobLocationsBlock from 'modules/Project/V2/Show/Blocks/Job/JobLocationsBlock';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobCrewsList from 'modules/Project/V2/Show/Blocks/Job/components/JobCrewsList';
import JobMissingDateModal from 'modules/Project/V2/Show/Blocks/Job/components/JobMissingDateModal';
import FieldValueExpandable from 'modules/Project/V2/Show/Blocks/components/FieldValueExpandable';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ResponsiveColumn = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: ${({responsive}) => (responsive.desktop ? 'column' : 'row')}
`;

const ContentLabel = Styled.Text`
  ${Typography.Subheading}
`;

const EmptyText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
`;

const StatusDot = Styled.View<{color: string}>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const StatusText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const DISPATCH_NOTES = 'dispatchNotes';
const CREW_NOTES = 'additionalNotes';

type JobDispatchBlockState = {dispatchNotes: boolean; additionalNotes: boolean};

const goToEditNotes = ({job, navigator, params}: any) => {
  Job.goToEditProjectJobs(job, navigator, {
    projectUuid: params.projectUuid,
    field: DISPATCH_NOTES,
  });
};

const SkeletonComponent = () => {
  return (
    <Panel>
      <Panel.Header>
        <Panel.HeaderText>Dispatch</Panel.HeaderText>
        <Space width={12} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
        <Space width={12} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={120} />
        <Space style={{flex: 1}} />
        <SecondaryButton iconLeft={Icon.Pen} text={'Edit Notes'} isSmall isDisabled />
        <Space width={12} />
        <SecondaryButton iconLeft={Icon.CommentSms} text={'Send SMS'} isSmall isDisabled />
      </Panel.Header>
      <Panel.Body>
        <Row>
          <Column style={{flex: 4}}>
            <ContentLabel>Notes</ContentLabel>
            <Space height={16} />
            <FieldValue.SkeletonLoader label={'Dispatch Notes'} width={144} />
            <Space height={16} />
            <FieldValue.SkeletonLoader label={'Crew Notes'} width={144} />
          </Column>
          <Space width={24} />
          <Column style={{flex: 5}}>
            <ContentLabel>Trucks & Movers</ContentLabel>
            <Space height={16} />
            <SkeletonLoader isFullWidth height={116} />
          </Column>
        </Row>
      </Panel.Body>
    </Panel>
  );
};

const DispatchStatus = ({text, isReady}: {text: string; isReady: boolean}) => {
  const responsive = useResponsive();
  return (
    <Row style={{alignItems: 'center'}}>
      <StatusDot color={isReady ? colors.green.status : colors.Pink600} />
      <Space width={8} />
      <StatusText responsive={responsive}>{text}</StatusText>
    </Row>
  );
};

const HeaderDispatchStatuses = ({job}: {job: JobModel}) => {
  return (
    <Row>
      <DispatchStatus
        text={`${job.numberOfAssignedTrucks}/${job.numberOfTrucks} Trucks`}
        isReady={job.numberOfAssignedTrucks >= job.numberOfTrucks}
      />
      <Space width={16} />
      <DispatchStatus
        text={`${job.employeeApprovalStatus.numberApproved}/${job.numberOfMovers} Confirmed Movers`}
        isReady={job.employeeApprovalStatus.isFullyApproved}
      />
    </Row>
  );
};

const EditDispatchNotesButton = ({job}: {job: JobModel}) => {
  const {navigator, params} = useNavigationDOM();

  return (
    <JobActionDisabledTooltip job={job}>
      <SecondaryButton
        iconLeft={Icon.Pen}
        text={'Edit Notes'}
        isSmall
        onPress={() => goToEditNotes({job, navigator, params})}
        isDisabled={job.isFinal || job.isCancelled}
      />
    </JobActionDisabledTooltip>
  );
};

const HeaderButtons = ({
  job,
  viewer,
  refetch,
  handleSms,
}: {
  job: JobModel;
  viewer: UserModel;
  refetch: () => void;
  handleSms: () => void;
}) => {
  return (
    <Row>
      <EditDispatchNotesButton job={job} />
      <Space width={12} />
      <JobActionDisabledTooltip job={job}>
        <SecondaryButton
          iconLeft={Icon.CommentSms}
          text={'Send SMS'}
          isSmall
          onPress={handleSms}
          isDisabled={job.isFinal || job.isCancelled}
        />
      </JobActionDisabledTooltip>
      {job.organization.hasMultipleOrganizations && (
        <React.Fragment>
          <Space width={12} />
          <CreateCrewsForJobButton
            job={job}
            viewer={viewer}
            refetch={refetch}
            isSmall
            isAsyncBillingEngine
          />
        </React.Fragment>
      )}
    </Row>
  );
};

const DispatchNotes = ({
  job,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  popover,
}: {
  job: JobModel;
  truncated: JobDispatchBlockState;
  setTruncated: (truncated: JobDispatchBlockState) => void;
  enabledToggle: JobDispatchBlockState;
  setEnabledToggle: (enabledToggle: JobDispatchBlockState) => void;
  popover: PopoverType;
}) => {
  const responsive = useResponsive();
  const {ref, isHovered} = EditFieldTextAreaButton.useHover();
  const {form, submitting, handleSubmit} = useInlineFormMutation({
    name: 'updateJobNotesForm',
    form: {
      jobId: job.id,
      notes: job.dispatchNotes,
    },
    mutation: gql`
      mutation jobDetailsBlockUpdateJobDescription($updateJobNotesForm: UpdateJobNotesForm!) {
        response: updateJobDispatchNotes(updateJobNotesForm: $updateJobNotesForm) {
          ${gql.errors}
          job {
            id
            dispatchNotes
          }
        }
      }
    `,
    onSuccess: () => {
      popover.handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <ResponsiveColumn responsive={responsive} ref={ref}>
      <FieldValueExpandable
        data={job}
        property={DISPATCH_NOTES}
        label={'Dispatch Notes'}
        numberOfLines={5}
        isTruncated={truncated[DISPATCH_NOTES]}
        isEnabledToggle={enabledToggle[DISPATCH_NOTES]}
        setTruncated={setTruncated}
        setEnabledToggle={setEnabledToggle}
        isResponsive
      />
      <EditFieldTextAreaButton
        popover={popover}
        form={form}
        field={'updateJobNotesForm.notes'}
        isSubmitting={submitting}
        handleSubmit={handleSubmit}
        buttonLeftOffset={103}
        placeholder={'Enter notes for dispatch'}
        drawerHeaderText={'Edit Dispatch Notes'}
        isHidden={responsive.desktop && !isHovered}
        isDisabled={job.isCancelled}
      />
    </ResponsiveColumn>
  );
};

const CrewNotes = ({
  job,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  popover,
}: {
  job: JobModel;
  truncated: JobDispatchBlockState;
  setTruncated: (truncated: JobDispatchBlockState) => void;
  enabledToggle: JobDispatchBlockState;
  setEnabledToggle: (enabledToggle: JobDispatchBlockState) => void;
  popover: PopoverType;
}) => {
  const responsive = useResponsive();
  const {ref, isHovered} = EditFieldTextAreaButton.useHover();
  const {form, submitting, handleSubmit} = useInlineFormMutation({
    name: 'updateJobNotesForm',
    form: {
      jobId: job.id,
      notes: job.additionalNotes,
    },
    mutation: gql`
      mutation jobDetailsBlockUpdateJobDescription($updateJobNotesForm: UpdateJobNotesForm!) {
        response: updateJobAdditionalNotes(updateJobNotesForm: $updateJobNotesForm) {
          ${gql.errors}
          job {
            id
            additionalNotes
          }
        }
      }
    `,
    onSuccess: () => {
      popover.handleClose();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <ResponsiveColumn responsive={responsive} ref={ref}>
      <FieldValueExpandable
        data={job}
        property={CREW_NOTES}
        label={'Crew Notes'}
        numberOfLines={5}
        isTruncated={truncated[CREW_NOTES]}
        isEnabledToggle={enabledToggle[CREW_NOTES]}
        setTruncated={setTruncated}
        setEnabledToggle={setEnabledToggle}
        isResponsive
      />
      <EditFieldTextAreaButton
        popover={popover}
        form={form}
        field={'updateJobNotesForm.notes'}
        isSubmitting={submitting}
        handleSubmit={handleSubmit}
        buttonLeftOffset={80}
        placeholder={'Enter notes for crew'}
        drawerHeaderText={'Edit Crew Notes'}
        isHidden={responsive.desktop && !isHovered}
        isDisabled={job.isCancelled}
      />
    </ResponsiveColumn>
  );
};

const NotesContent = ({
  job,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
}: {
  job: JobModel;
  truncated: JobDispatchBlockState;
  setTruncated: (truncated: JobDispatchBlockState) => void;
  enabledToggle: JobDispatchBlockState;
  setEnabledToggle: (enabledToggle: JobDispatchBlockState) => void;
}) => {
  const editDispathNotesPopover = usePopover({name: 'Edit Dispatch Notes Popover'});
  const editCrewNotesPopover = usePopover({name: 'Edit Crew Notes Popover'});

  return (
    <React.Fragment>
      <DispatchNotes
        key={editDispathNotesPopover.key}
        job={job}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
        popover={editDispathNotesPopover}
      />
      <Space height={16} />
      <CrewNotes
        key={editCrewNotesPopover.key}
        job={job}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
        popover={editCrewNotesPopover}
      />
    </React.Fragment>
  );
};

const EmptyTrucksAndMovers = ({
  job,
  viewer,
  refetch,
}: {
  job: JobModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  return (
    <React.Fragment>
      <EmptyText>No labor source. Add a labor source to begin dispatching.</EmptyText>
      <Space height={16} />
      <Row>
        <CreateCrewsForJobButton
          job={job}
          viewer={viewer}
          refetch={refetch}
          popoverPosition={Popover.Positions.BottomStart}
          isAsyncBillingEngine
          isFullButtonText
        />
      </Row>
    </React.Fragment>
  );
};

const TrucksAndMoversContent = ({
  job,
  viewer,
  refetch,
}: {
  job: JobModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  const {navigator} = useNavigationDOM();
  const tripUuid = _.get(job, 'project.shipment.trip.uuid');
  const hasCrew = !_.isEmpty(job.crews);

  return (
    <React.Fragment>
      <Row>
        <ContentLabel>Trucks & Movers</ContentLabel>
        {tripUuid && (
          <React.Fragment>
            <Space width={8} />
            <TertiaryButton
              style={{paddingHorizontal: 8}}
              onPress={() => {
                navigator.push(`/dispatch/long-distance/trips/${tripUuid}/shipments`);
              }}
            >
              <LinkText>View Trip</LinkText>
            </TertiaryButton>
          </React.Fragment>
        )}
      </Row>
      <Space height={16} />
      {!hasCrew ? (
        <EmptyTrucksAndMovers job={job} viewer={viewer} refetch={refetch} />
      ) : (
        <JobCrewsList job={job} viewer={viewer} refetch={refetch} />
      )}
    </React.Fragment>
  );
};

const DesktopJobDispatchContent = ({
  job,
  viewer,
  refetch,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
}: {
  job: JobModel;
  viewer: UserModel;
  refetch: () => void;
  truncated: JobDispatchBlockState;
  setTruncated: (truncated: JobDispatchBlockState) => void;
  enabledToggle: JobDispatchBlockState;
  setEnabledToggle: (enabledToggle: JobDispatchBlockState) => void;
}) => {
  return (
    <Row>
      <Column style={{flex: 4}}>
        <ContentLabel>Notes</ContentLabel>
        <Space height={16} />
        <NotesContent
          job={job}
          truncated={truncated}
          setTruncated={setTruncated}
          enabledToggle={enabledToggle}
          setEnabledToggle={setEnabledToggle}
        />
      </Column>
      <Space width={24} />
      <Column style={{flex: 5}}>
        <TrucksAndMoversContent job={job} viewer={viewer} refetch={refetch} />
      </Column>
    </Row>
  );
};

const MobileJobDispatchContent = ({
  job,
  viewer,
  refetch,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  handleSms,
}: {
  job: JobModel;
  viewer: UserModel;
  refetch: () => void;
  truncated: JobDispatchBlockState;
  setTruncated: (truncated: JobDispatchBlockState) => void;
  enabledToggle: JobDispatchBlockState;
  setEnabledToggle: (enabledToggle: JobDispatchBlockState) => void;
  handleSms: () => void;
}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const actionMenuPopover = usePopover();
  const laborSourcesPopover = usePopover();
  const jobMissingDateModal = useModal({name: 'Job Missing Date Modal'});
  const isContractor = viewer.viewingOrganization.id !== job.organization.id;

  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Dispatch`}
        ActionsComponent={() => (
          <ActionMenuPopover
            popover={actionMenuPopover}
            actions={[
              {
                text: 'Edit notes',
                onPress: () => goToEditNotes({job, navigator, params}),
              },
              {
                text: isContractor
                  ? `View labor sources (${job.crews.length ? job.crews.length - 1 : 0})`
                  : `Add labor source`,
                onPress: job.isUnscheduled
                  ? jobMissingDateModal.handleOpen
                  : laborSourcesPopover.handleOpen,
              },
              {
                text: 'Send SMS to crew',
                onPress: handleSms,
              },
            ]}
          >
            <JobActionDisabledTooltip job={job}>
              <MobileProjectBlockHeader.EllipsisButton
                onPress={() => setTimeout(actionMenuPopover.handleOpen, 0)}
                isDisabled={job.isFinal || job.isCancelled}
              />
            </JobActionDisabledTooltip>
          </ActionMenuPopover>
        )}
      >
        <HeaderDispatchStatuses job={job} />
      </MobileProjectBlockHeader>
      <NotesContent
        job={job}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
      />
      <Space height={24} />
      <TrucksAndMoversContent job={job} viewer={viewer} refetch={refetch} />
      <CreateCrewsForJobButton.LaborSourcesPopover
        key={laborSourcesPopover.key}
        popover={laborSourcesPopover}
        job={job}
        viewer={viewer}
        refetch={refetch}
        isContractor={isContractor}
        isAsyncBillingEngine
      />
      <JobMissingDateModal
        key={jobMissingDateModal.key}
        job={job}
        projectTypeId={job.project.projectTypeId}
        isOpen={jobMissingDateModal.isOpen}
        handleClose={jobMissingDateModal.handleClose}
        onSuccess={refetch}
        messageExtension={' before adding a labor source'}
      />
    </React.Fragment>
  );
};

const JobDispatchBlock = ({
  jobUuid,
  handleSetPositionY,
  layoutKey,
  index,
  pageRefetch,
  projectBlockKind,
}: {
  jobUuid: string;
  handleSetPositionY?: () => void;
  layoutKey?: string;
  index: number;
  pageRefetch: () => void;
  projectBlockKind: ProjectBlockKindType;
}) => {
  const responsive = useResponsive();
  const [truncated, setTruncated] = useState({[DISPATCH_NOTES]: true, [CREW_NOTES]: true});
  const [enabledToggle, setEnabledToggle] = useState({
    [DISPATCH_NOTES]: false,
    [CREW_NOTES]: false,
  });
  const sendJobDetailsModal = useModal({
    name: 'Send Job Details Modal',
  });
  const moversNotAssignedModal = useModal({
    name: 'Movers Not Assigned Modal',
  });

  return (
    <ProjectBlockWrapper
      index={index}
      query={JobDispatchBlock.query}
      queryVariables={{jobUuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data: {job, viewer}, refetch: blockRefetch}: any) => {
        const refetch = () => {
          blockRefetch();
          pageRefetch();
        };
        const hasMovers = !_.isEmpty(job.activeJobUsers);
        const handleSms = hasMovers
          ? sendJobDetailsModal.handleOpen
          : moversNotAssignedModal.handleOpen;

        const contentProps = {
          job,
          viewer,
          truncated,
          setTruncated,
          enabledToggle,
          setEnabledToggle,
          sendJobDetailsModal,
          moversNotAssignedModal,
          handleSms,
          refetch,
        };

        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ActionPanel
                HeaderActionComponent={HeaderDispatchStatuses}
                headerActionComponentProps={{job}}
                BodyComponent={DesktopJobDispatchContent}
                bodyComponentProps={contentProps}
                ActionButtonComponent={HeaderButtons}
                actionButtonComponentProps={contentProps}
                title={'Dispatch'}
                style={{flex: 1, width: '100%'}}
              />
            ) : (
              <MobileJobDispatchContent {...contentProps} />
            )}
            <SendJobDetailsToSpecificUsersModal
              key={sendJobDetailsModal.key}
              isOpen={sendJobDetailsModal.isOpen}
              job={job}
              onClose={() => {
                sendJobDetailsModal.handleClose();
                refetch();
              }}
            />
            <ActionModal
              icon={Icon.ExclamationTriangle}
              color={colors.orange.status}
              title={'Movers not assigned.'}
              message={'Once you assign movers, you will be able to text them.'}
              isOpen={moversNotAssignedModal.isOpen}
              handlePressOutside={moversNotAssignedModal.handleClose}
              handlePrimaryAction={moversNotAssignedModal.handleClose}
              primaryActionText={'Close'}
            />
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchBlock.query = gql`
  ${CreateCrewsForJobButton.fragment}
  ${SendJobDetailsToSpecificUsersModal.fragment}
  ${JobCrewsList.fragment}
  ${Job.getShowMovers.fragment}
  ${Job.getShowTrucks.fragment}
  ${JobMissingDateModal.fragment}
  ${JobLocationsBlock.listener}
  ${JobActionDisabledTooltip.fragment}

  query JobDispatchBlock($jobUuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
      ...CreateCrewsForJobButton_Viewer
      ...JobCrewsList_Viewer
    }
    job(uuid: $jobUuid) {
      id
      isFinal
      isCancelled
      isUnscheduled
      dispatchNotes
      additionalNotes
      numberOfAssignedTrucks
      numberOfTrucks
      project {
        id
        projectTypeId
        shipment {
          id
          trip {
            id
            uuid
          }
        }
        ...JobLocationsBlock_listener
      }
      employeeApprovalStatus {
        isFullyApproved
        numberApproved
      }
      activeJobUsers {
        id
      }
      organization {
        id
        hasMultipleOrganizations
      }
      ...CreateCrewsForJobButton_Job
      ...SendJobDetailsToSpecificUsersModal
      ...JobCrewsList
      ...Job_getShowMovers
      ...Job_getShowTrucks
      ...JobMissingDateModal
      ...JobActionDisabledTooltip
    }
  }
`;

export default JobDispatchBlock;
