// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import Line from '@shared/design/components/Line';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import CreateReferralTypeModal from 'modules/Organization/Settings/Company/components/CreateReferralTypeModal';
import ReferralSourcesTable from 'modules/Organization/Settings/Company/components/ReferralSourcesTable';

const CompanyReferralsContent = ({organization, refetch}: any) => {
  const responsive = useResponsive();
  const createReferralTypeModal = useModal({name: 'Create Referral Type Modal'});
  const {referralSources} = organization.settings;
  return (
    <React.Fragment>
      <Button
        iconLeft={Icon.Plus}
        text={'Add Referral Source'}
        onPress={createReferralTypeModal.handleOpen}
        style={{marginLeft: responsive.desktop ? 0 : 16}}
      />
      <Space height={32} />
      {!responsive.desktop && <Line />}
      <ReferralSourcesTable
        organization={organization}
        referralSources={referralSources}
        refetch={refetch}
      />
      <CreateReferralTypeModal
        createReferralTypeModal={createReferralTypeModal}
        refetch={refetch}
        referralSources={referralSources}
        organizationId={organization.id}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyReferralsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyReferralsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Referrals'}
      description={
        'Add referral sources to keep track of how customers are discovering your business.'
      }
    >
      <Loading loading={loading}>
        {() => (
          <CompanyReferralsContent
            organization={data.viewer.viewingOrganization}
            refetch={refetch}
          />
        )}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyReferralsPage.query = gql`
  ${ReferralSourcesTable.fragment}
  ${CreateReferralTypeModal.fragment}
  query OrganizationSettingsCompanyReferralsPage {
    ${gql.query}
    viewer {
        id
        viewingOrganization {
          id
          ...ReferralSourcesTable
          ...CreateReferralTypeModal
        }
      }
  }
`;

export default OrganizationSettingsCompanyReferralsPage;
