// Libraries
import React from 'react';

// Supermove
import {PageLoader} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import DocumentsSettingsDocumentLibraryPage from 'modules/Organization/Settings/Document/DocumentsSettingsDocumentLibraryPage';
import OrganizationSettingsDocumentTemplateSettingsContent from 'modules/Organization/Settings/Document/components/OrganizationSettingsDocumentTemplateSettingsContent';

const OrganizationSettingsDocumentTemplateSettingsPage = () => {
  const {loading, data, refetch} = useQuery<{viewer: UserModel}>(
    OrganizationSettingsDocumentTemplateSettingsPage.query,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  return (
    <PageLoader loading={loading} data={data}>
      {({loadedData: {viewer}}) => {
        return <DocumentsSettingsDocumentLibraryPage viewer={viewer} refetch={refetch} />;
      }}
    </PageLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsDocumentTemplateSettingsPage.query = gql`
  ${OrganizationSettingsDocumentTemplateSettingsContent.fragment}
  ${DocumentsSettingsDocumentLibraryPage.fragment}

  query OrganizationSettingsDocumentTemplateSettingsPage {
    ${gql.query}
    viewer {
      id
      ...OrganizationSettingsDocumentTemplateSettingsContent
    }
    ...DocumentsSettingsDocumentLibraryPage
  }
`;

export default OrganizationSettingsDocumentTemplateSettingsPage;
