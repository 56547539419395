// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import ProjectSizeInput from 'modules/Project/components/ProjectSizeInput';
import ProjectTypeDropdown from 'modules/Project/components/ProjectTypeDropdown';

const Row = Styled.View<{index?: number; mobile?: boolean}>`
  flex-direction: ${({mobile}) => (mobile ? 'column' : 'row')};
  z-index: ${({index = 0}) => 100 - index};
`;

const Column = Styled.View<{index?: number}>`
  z-index: ${({index = 0}) => 100 - index};
`;

const HeaderText = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Section = Styled.View<{index?: number}>`
  z-index: ${({index = 0}) => 200 - index};
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const getFieldName = ({field, name}: any) => {
  return field ? `${field}.${name}` : name;
};

const getOrganizationSelectorDropdownOptions = ({organization}: any) => {
  // By default, we want to hide dependent branches from the dropdown
  const organizationOptions = _.filter(organization.company.organizations, (organization) => {
    return (
      organization.features.isEnabledShowDependentBranchesInCreateProjectOrgSelector ||
      OrganizationKind.getIsIndependent(organization.kind)
    );
  });
  return _.sortBy(organizationOptions, ['name']).map((organization) => {
    return {
      label: organization.name,
      value: organization.id,
      organization,
    };
  });
};

const RowSpace = () => {
  const responsive = useResponsive();
  return <Space height={responsive.mobile ? 10 : 15} />;
};

const ProjectNameToggle = ({form, field}: any) => {
  const isNameVisible = _.get(form.values, `${field}.isNameVisible`);
  return (
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon
        source={isNameVisible ? Icon.Trash : Icon.Plus}
        size={14}
        color={colors.blue.interactive}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(`${field}.isNameVisible`, !isNameVisible);
          form.setFieldValue(`${field}.name`, '');
        }}
      >
        <ButtonText>{isNameVisible ? 'Remove project name' : 'Add project name'}</ButtonText>
      </Button>
    </Row>
  );
};

const getWarehouseDropdownOptions = ({organization}: any) => {
  const warehouseOptions = organization.companySettings.warehouses.map((warehouse: any) => {
    return {
      label: warehouse.name,
      value: warehouse.name,
      description: warehouse.location.address,
      warehouse,
    };
  });

  return warehouseOptions;
};

const WarehouseFields = ({form, organization, responsive, showOrganizationDropdown}: any) => {
  const warehouseLocationFieldName = `projectForm.jobForms.0.warehouseLocationForm`;
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Warehouse'}
        component={DropdownInput}
        name={`${warehouseLocationFieldName}.name`}
        index={0}
        isClearable
        input={{
          options: getWarehouseDropdownOptions({organization}),
          placeholder: `Select Warehouse`,
          style: {flex: 1},
          setFieldValue: form.setFieldValue,
          onChangeValue: (value: any, {warehouse}: any) => {
            form.setFieldValue(warehouseLocationFieldName, {
              address: warehouse.location.address,
              zipCode: warehouse.location.zipCode,
              latitude: warehouse.location.latitude,
              longitude: warehouse.location.longitude,
              city: warehouse.location.city,
              state: warehouse.location.state,
              country: warehouse.location.country,
              name: warehouse.name,
            });
          },
          components: {
            Option: DropdownInput.OptionWithDescription,
          },
        }}
      />
    </React.Fragment>
  );
};

type OwnProjectInfoFieldsProps = {
  form: any;
  field: string;
  disabled?: boolean;
  canEditProjectOrganization?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'ProjectInfoFieldsProps' circularly ref... Remove this comment to see the full error message
type ProjectInfoFieldsProps = OwnProjectInfoFieldsProps & typeof ProjectInfoFields.defaultProps;

// @ts-expect-error TS(7022): 'ProjectInfoFields' implicitly has type 'any' beca... Remove this comment to see the full error message
const ProjectInfoFields = ({
  isUpdate,
  field,
  form,
  organization,
  shouldResetJobDateOnProjectTypeChange,
  jobFormField,
  canEditProjectOrganization,
}: ProjectInfoFieldsProps) => {
  const responsive = useResponsive();
  const {isEnabledProjectIdentifierField, isEnabledCreateStorageMultipleWarehouse} =
    organization.features;
  const isNameVisibleField = getFieldName({field, name: 'isNameVisible'});
  const isNameVisible = _.get(form, `values.${isNameVisibleField}`, false);
  const primaryOrganization = organization.company.organizations.find(
    (organization: any) => organization.isPrimary,
  );
  const organizationSelectorDropdownOptions = getOrganizationSelectorDropdownOptions({
    organization,
  });

  const showOrganizationDropdown =
    canEditProjectOrganization && organizationSelectorDropdownOptions.length > 1;

  return (
    <React.Fragment>
      <Row index={0} {...responsive}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <HeaderText vars={responsive}>Project Information</HeaderText>
        {responsive.mobile ? <Space height={10} /> : <Space style={{flex: 1}} />}
        <ProjectNameToggle form={form} field={field} />
      </Row>
      {showOrganizationDropdown && (
        <React.Fragment>
          <Space height={20} />
          <Row index={1} {...responsive}>
            <Section index={0} style={{flex: 1}}>
              <FieldInput
                {...form}
                label={'Branch'}
                component={DropdownInput}
                name={'projectForm.primaryLaborSourceOrganizationId'}
                index={0}
                input={{
                  options: organizationSelectorDropdownOptions,
                  placeholder: `Select organization`,
                  style: {flex: 1},
                  setFieldValue: form.setFieldValue,
                  onChangeValue: (value: any, {organization}: any) => {
                    const newProjectForm = ProjectForm.resetWithNewOrganization(
                      _.get(form.values, 'projectForm'),
                      {
                        organizationForCreateProjectId:
                          organization.kind !== OrganizationKind.FRANCHISE
                            ? primaryOrganization.id
                            : value,
                        organizationForLaborSourceId: value,
                        organization,
                      },
                    );
                    form.setFieldValue('projectForm', newProjectForm);
                  },
                }}
                style={
                  responsive.desktop
                    ? {
                        width: isEnabledCreateStorageMultipleWarehouse ? 250 : 510,
                      }
                    : null
                }
              />
            </Section>
            {isEnabledCreateStorageMultipleWarehouse && (
              <React.Fragment>
                <Space width={10} height={10} />
                <Section index={1} style={{flex: 1}}>
                  <WarehouseFields
                    form={form}
                    organization={organization}
                    responsive={responsive}
                    showOrganizationDropdown={showOrganizationDropdown}
                  />
                </Section>
              </React.Fragment>
            )}
          </Row>
        </React.Fragment>
      )}
      {isEnabledCreateStorageMultipleWarehouse && !showOrganizationDropdown && (
        <React.Fragment>
          <Space width={10} height={10} />
          <Section index={1} style={{flex: 1}}>
            <WarehouseFields
              form={form}
              organization={organization}
              responsive={responsive}
              showOrganizationDropdown={showOrganizationDropdown}
            />
          </Section>
        </React.Fragment>
      )}
      {isNameVisible && (
        <React.Fragment>
          <RowSpace />
          <Row index={2} {...responsive}>
            <FieldInput.Memoized
              {...form}
              name={`${field}.name`}
              label={'Project Name'}
              input={{
                placeholder: 'Enter project name',
              }}
              style={{
                width: responsive.desktop ? 510 : '100%',
              }}
            />
          </Row>
        </React.Fragment>
      )}
      <RowSpace />
      <Row index={3} {...responsive}>
        <Section data-test-id='form-project-type-dropdown-section' index={0} style={{flex: 1}}>
          <ProjectTypeDropdown
            organization={organization}
            field={'projectForm'}
            form={form}
            shouldResetJobDateOnProjectTypeChange={shouldResetJobDateOnProjectTypeChange}
            jobFormField={jobFormField}
          />
        </Section>
        <Space width={10} height={10} />
        <Section data-test-id='form-project-size-dropdown-section' index={1} style={{flex: 1}}>
          <ProjectSizeInput organization={organization} field={'projectForm'} form={form} />
        </Section>
      </Row>
      {isEnabledProjectIdentifierField && (
        <React.Fragment>
          <RowSpace />
          <Row index={4} {...responsive}>
            <FieldInput.Memoized
              {...form}
              name={`${field}.identifier`}
              label={'Project Identifier'}
              input={{
                disabled: isUpdate,
                required: !_.get(form, `values.${field}.identifier`),
                placeholder: 'Enter project identifier',
              }}
              style={{
                width: responsive.desktop ? 510 : '100%',
              }}
            />
          </Row>
        </React.Fragment>
      )}
      <RowSpace />
      <Column index={5} {...responsive}>
        <FieldInput.LabelText>Project Description (shown to customer)</FieldInput.LabelText>
        <Space height={4} />
        <ResponsiveTextInput.Memoized
          form={form}
          field={`${field}.description`}
          minHeight={100}
          input={{
            style: {
              width: responsive.desktop ? 510 : undefined,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 9,
              paddingBottom: 9,
            },
            placeholder: 'Enter a description for the project. This will be shown to the customer.',
          }}
        />
      </Column>
    </React.Fragment>
  );
};

ProjectInfoFields.defaultProps = {
  disabled: false,
  canEditProjectOrganization: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectInfoFields.fragment = gql`
  ${ProjectTypeDropdown.fragment}
  ${ProjectSizeInput.fragment}
  ${ProjectForm.resetWithNewOrganization.fragment}

  fragment ProjectInfoFields on Organization {
    id
    features {
      isEnabledProjectIdentifierField: isEnabled(feature: "PROJECT_IDENTIFIER_FIELD")
      isEnabledCreateStorageMultipleWarehouse: isEnabled(
        feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
      )
    }
    company {
      id
      organizations {
        id
        name
        kind
        isPrimary
        features {
          isEnabledShowDependentBranchesInCreateProjectOrgSelector: isEnabled(
            feature: "SHOW_DEPENDENT_BRANCHES_IN_CREATE_PROJECT_ORG_SELECTOR"
          )
        }
        ...ProjectForm_resetWithNewOrganization
      }
    }
    companySettings {
      warehouses {
        id
        name
        location {
          id
          address
          zipCode
          city
          latitude
          longitude
          state
          country
        }
      }
    }
    ...ProjectTypeDropdown
    ...ProjectSizeInput
  }
`;

export default ProjectInfoFields;
