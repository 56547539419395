// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import GlobalSearchResultBuilder from 'modules/App/Global/components/GlobalSearchResultBuilder';
import Line from 'modules/App/components/Line';

const RESULTS_INCREMENT_DEFAULT = 10;

const ResultGroupHeadingContainer = Styled.View`
  flex-direction: row;
  align-items: baseline;
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const ResultGroupHeadingText = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.secondary};
`;

const ResultGroupSubheadingText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const ResultGroupLoadMoreContainer = Styled.View`
  padding-vertical: 8px;
  padding-horizontal: 12px;
`;

const NoResultsContainer = Styled.View`
   padding-horizontal: 16px;
`;

const NoResultsText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const NoResults = ({query}: {query: string}) => {
  return (
    <NoResultsContainer>
      <NoResultsText>{`No results found for "${query}"`}</NoResultsText>
      <Space height={16} />
    </NoResultsContainer>
  );
};

interface GlobalSearchResultGroupType<T> {
  handleClose: () => void;
  resultsData: T[];
  ResultComponent: React.FC<any>;
  groupTitle: string;
  keyExtractor: (item: T) => string;
  itemPropName: string;
  query: string;
  isLoading: boolean;
}

export const GlobalSearchResultGroupSeparator = () => {
  return (
    <React.Fragment>
      <Space height={8} />
      <Line />
      <Space height={4} />
    </React.Fragment>
  );
};

const GlobalSearchResultGroup = <T,>({
  handleClose,
  resultsData,
  ResultComponent,
  groupTitle,
  keyExtractor,
  itemPropName,
  query,
  isLoading,
}: GlobalSearchResultGroupType<T>) => {
  const responsive = useResponsive();
  const [visibleResultsCount, setVisibleResultsCount] = useState(RESULTS_INCREMENT_DEFAULT);

  const remainingResultsCount = resultsData.length - visibleResultsCount;
  const showLoadMore = remainingResultsCount > 0;
  const loadMoreIncrement = Math.min(remainingResultsCount, RESULTS_INCREMENT_DEFAULT);
  const hasNoResults = resultsData.length === 0;
  const visibleResultsDisplayCount = Math.min(visibleResultsCount, resultsData.length);
  const totalResultsDisplayCount = GlobalSearchResultBuilder.getDisplayCount(resultsData.length);

  if (isLoading) {
    return (
      <React.Fragment>
        <GlobalSearchResultBuilder.LoadingRow />
        <Line />
        <GlobalSearchResultBuilder.LoadingRow />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ResultGroupHeadingContainer>
        <ResultGroupHeadingText responsive={responsive}>{groupTitle}</ResultGroupHeadingText>
        <Space width={8} />
        <ResultGroupSubheadingText responsive={responsive}>
          {`(showing ${visibleResultsDisplayCount} of ${totalResultsDisplayCount} results)`}
        </ResultGroupSubheadingText>
      </ResultGroupHeadingContainer>
      {hasNoResults && <NoResults query={query} />}
      {resultsData.slice(0, visibleResultsCount).map((item) => {
        // Create dynamic props object based on itemPropName
        const itemProps = {
          [itemPropName]: item,
          handleClose,
        };

        return <ResultComponent key={keyExtractor(item)} {...itemProps} />;
      })}
      {showLoadMore && (
        <ResultGroupLoadMoreContainer>
          <TertiaryButton
            text={`Load ${loadMoreIncrement} more`}
            iconRight={Icon.ChevronDown}
            onPress={() => setVisibleResultsCount(visibleResultsCount + loadMoreIncrement)}
            isLarge={responsive.mobile}
          />
        </ResultGroupLoadMoreContainer>
      )}
    </React.Fragment>
  );
};

export default GlobalSearchResultGroup;
