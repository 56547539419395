// Libraries
import React from 'react';

// App
import Modal from '@shared/design/components/Modal';
import GlobalSearchContent from 'modules/App/Global/components/GlobalSearchContent';

const GlobalSearchModal = ({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
  return (
    <Modal
      width={800}
      isResponsive
      isOpen={isOpen}
      handlePressOutside={handleClose}
      screenContainerStyle={{
        justifyContent: 'flex-start',
        paddingBottom: 0,
      }}
    >
      <GlobalSearchContent handleClose={handleClose} />
    </Modal>
  );
};

export default GlobalSearchModal;
