import React from 'react';

// Supermove
import {useContext, useState} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

interface ProjectPageContextType {
  projectBlockKindToHeight: {[key: string]: number};
  setProjectBlockKindToHeight: React.Dispatch<React.SetStateAction<{[key: string]: number}>>;
  isOnLoadScrollComplete: boolean;
  setIsOnLoadScrollComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectPageContext = React.createContext<ProjectPageContextType | null>(null);

export const ProjectPageContextProvider = ({children}: {children: React.ReactNode}) => {
  const [projectBlockKindToHeight, setProjectBlockKindToHeight] = useState({});
  const [isOnLoadScrollComplete, setIsOnLoadScrollComplete] = useState<boolean>(false);

  const contextValue = {
    projectBlockKindToHeight,
    setProjectBlockKindToHeight,
    isOnLoadScrollComplete,
    setIsOnLoadScrollComplete,
  };

  return <ProjectPageContext.Provider value={contextValue}>{children}</ProjectPageContext.Provider>;
};

const useProjectPageContext = () => {
  const context = useContext(ProjectPageContext);
  invariant(context, 'useProjectPageContext must be used within a ProjectPageContextProvider');

  return context;
};

export default useProjectPageContext;
