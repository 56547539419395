// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import Panel from '@shared/design/components/Panel';
import Switch from '@shared/design/components/Switch';
import TextTooltip from '@shared/design/components/TextTooltip';
import OrganizationTipOptionsForm from '@shared/modules/Organization/forms/OrganizationTipOptionsForm';
import TipOptionForm from '@shared/modules/Organization/forms/TipOptionForm';
import useUpdateOrganizationTipOptionsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationTipOptionsMutation';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsShouldSkipTipPromptMutation from '@shared/modules/Settings/hooks/useToggleSettingsShouldSkipTipPromptMutation';
import TipOption from 'modules/Organization/Settings/Company/components/TipOption';

const View = Styled.View``;

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const onReorder = ({form, field, fromIndex, toIndex, handleSubmit}: any) => {
  const forms = _.cloneDeep(_.get(form.values, field));
  const reorderedForms = List.move({
    list: forms,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedForms);
  setTimeout(handleSubmit, 0);
};

const AddTipOptionButton = ({
  form,
  field,
  setIndexOfEdit,
  isViewingChildBranch,
  isEditingTipOption,
  isTipLimitReached,
}: any) => (
  <View style={{width: 'fit-content'}}>
    <TextTooltip
      isEnabledMobileToast={false}
      text={isTipLimitReached ? 'Can only add up to 5 options.' : ''}
    >
      <View>
        <TertiaryButton
          text={'Add Tip Option'}
          iconLeft={Icon.Plus}
          isDisabled={isViewingChildBranch || isEditingTipOption || isTipLimitReached}
          onPress={() => {
            const existingForms = _.get(form.values, field);
            form.setFieldValue(field, [...existingForms, TipOptionForm.new()]);
            setIndexOfEdit(existingForms.length);
          }}
        />
      </View>
    </TextTooltip>
  </View>
);

const TipOptionsPanel = ({index, organization, refetch, isViewingChildBranch}: any) => {
  const responsive = useResponsive();
  const settingsForm = SettingsForm.edit(organization.settings);
  const toggleSettingsShouldSkipTipPromptMutation = useToggleSettingsShouldSkipTipPromptMutation({
    settingsForm,
    onSuccess: refetch,
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const [indexOfEdit, setIndexOfEdit] = useState<number | null>(null);
  const isEditingTipOption = indexOfEdit !== null;
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const organizationTipOptionsForm = OrganizationTipOptionsForm.edit(organization);
  const {form, handleSubmit} = useUpdateOrganizationTipOptionsMutation({
    organizationTipOptionsForm,
    onSuccess: () => {
      refetch();
      handleReorderEnd();
      setIndexOfEdit(null);
    },
    onError: (errors: any) => {
      console.log(errors);
      handleReorderEnd();
    },
  });

  const field = 'organizationTipOptionsForm.tipOptionForms';
  const tipOptionForms = _.get(form.values, field);
  const isTipOptionsEnabled = !organization.shouldSkipTipPrompt;
  const isPanelBodyHidden = !isTipOptionsEnabled && !isViewingChildBranch;
  const isTipLimitReached = tipOptionForms.length === 4;

  return (
    <Panel width={responsive.desktop ? Panel.WIDTH.DEFAULT : undefined} index={index}>
      <Panel.Header style={isPanelBodyHidden ? {borderWidth: 0} : {}}>
        <Column>
          <Row style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <Panel.HeaderText>Tips</Panel.HeaderText>
            <Space width={16} />
            <Switch
              disabled={isViewingChildBranch}
              isOn={isTipOptionsEnabled}
              onChange={toggleSettingsShouldSkipTipPromptMutation.handleSubmit}
              labelRight={organization.shouldSkipTipPrompt ? 'Disabled' : 'Enabled'}
            />
          </Row>
          <Space height={8} />
          <Panel.Text style={{color: colors.gray.secondary}}>
            {`Tips will be shown, in this order, to the customer while they are making a payment in the Crew App.`}
          </Panel.Text>
        </Column>
      </Panel.Header>
      {!isPanelBodyHidden && (
        <Panel.Body>
          {isViewingChildBranch && (
            <Callout
              text={`Tips are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make changes.`}
            />
          )}
          {isViewingChildBranch && isTipOptionsEnabled && <Space height={16} />}
          {isTipOptionsEnabled && (
            <React.Fragment>
              {!_.isEmpty(tipOptionForms) && (
                <React.Fragment>
                  <DragAndDropList
                    isDisabledWithVisibleIcons={isViewingChildBranch}
                    isReordering={isReordering}
                    indexOfEdit={indexOfEdit}
                    onReorder={({fromIndex, toIndex}) => {
                      handleReorderStart();
                      onReorder({
                        form,
                        field,
                        fromIndex,
                        toIndex,
                        handleSubmit,
                      });
                    }}
                  >
                    {tipOptionForms.map((tipOptionForm: any, index: any) => {
                      return index === indexOfEdit ? (
                        <TipOption.Edit
                          key={`${tipOptionForm.amount}-${index}`}
                          index={index}
                          form={form}
                          field={field}
                          setIndexOfEdit={setIndexOfEdit}
                          isViewingChildBranch={isViewingChildBranch}
                        />
                      ) : (
                        <TipOption
                          key={`${tipOptionForm.amount}-${index}`}
                          index={index}
                          tipOptionForm={tipOptionForm}
                          form={form}
                          field={field}
                          handleSubmit={handleSubmit}
                          setIndexOfEdit={setIndexOfEdit}
                          responsive={responsive}
                          isDisabled={isViewingChildBranch || isEditingTipOption}
                        />
                      );
                    })}
                  </DragAndDropList>
                  <TipOption.NoTip
                    isDisabled={isViewingChildBranch}
                    responsive={responsive}
                    index={tipOptionForms.length}
                  />
                  <Space height={16} />
                </React.Fragment>
              )}
              <AddTipOptionButton
                form={form}
                field={field}
                setIndexOfEdit={setIndexOfEdit}
                isViewingChildBranch={isViewingChildBranch}
                isEditingTipOption={isEditingTipOption}
                isTipLimitReached={isTipLimitReached}
              />
            </React.Fragment>
          )}
        </Panel.Body>
      )}
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TipOptionsPanel.fragment = gql`
  ${OrganizationTipOptionsForm.edit.fragment}
  ${SettingsForm.edit.fragment}
  fragment TipOptionsPanel on Organization {
    id
    shouldSkipTipPrompt
    company {
      id
      primaryOrganization {
        id
        name
      }
    }
    settings {
      id
      ...SettingsForm_edit
    }
    ...OrganizationTipOptionsForm_edit
  }
`;

export default TipOptionsPanel;
