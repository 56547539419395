// Libraries
import React from 'react';

// Supermove
import Space from '@supermove/components/src/Space';
import {AccountGrantKindModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import Text from '@shared/design/components/Text';
import {ACCOUNT_GRANT_EXPIRES_SOON_DAYS} from 'modules/Organization/Settings/Communication/constants/accountGrantExpiration';

const getWarningText = (daysUntilExpiration: number, grantKind: AccountGrantKindModel) => {
  if (grantKind === 'ORGANIZATION') {
    return `The authentication for this company email account expires in ${daysUntilExpiration} ${pluralize('day', daysUntilExpiration)}. Reconnect now to keep sending emails with the company email address.`;
  }
  return `The authentication for your email account expires in ${daysUntilExpiration} ${pluralize('day', daysUntilExpiration)}. Reconnect now to keep sending emails your email address.`;
};

const getErrorText = (statusUpdatedAt: string, grantKind: AccountGrantKindModel) => {
  const dateExpired = Datetime.convertToDisplayDatetime(
    statusUpdatedAt,
    Datetime.DISPLAY_SHORT_DATE,
  );
  const timeExpired = Datetime.convertToDisplayDatetime(statusUpdatedAt, Datetime.DISPLAY_TIME);
  if (grantKind === 'ORGANIZATION') {
    return `The authentication for this company email account expired on ${dateExpired} at ${timeExpired}. All emails will be sent from the Supermove email address until reconnected.`;
  }
  return `The authentication for your email account expired on ${dateExpired} at ${timeExpired}. All emails will be sent from the company address until it's reconnected.`;
};

const AccountCalloutBanner = ({
  connectAccountGrant,
  expiredAt,
  hasEditPermissions,
  daysUntilExpiration,
  grantKind,
  spacingHeightIfVisible = 32,
}: {
  connectAccountGrant: () => void;
  expiredAt?: string;
  hasEditPermissions: boolean;
  daysUntilExpiration?: number;
  grantKind: AccountGrantKindModel;
  spacingHeightIfVisible?: number;
}) => {
  if (expiredAt) {
    return (
      <React.Fragment>
        <ErrorCallout>
          <Text.Body style={{color: colors.red.warning}}>
            {getErrorText(expiredAt, grantKind)}
            {hasEditPermissions ? (
              <React.Fragment>
                {' '}
                <Text.Link onPress={connectAccountGrant}>Click to reconnect</Text.Link>
              </React.Fragment>
            ) : (
              ''
            )}
          </Text.Body>
        </ErrorCallout>
        {spacingHeightIfVisible ? <Space height={spacingHeightIfVisible} /> : null}
      </React.Fragment>
    );
  }
  if (daysUntilExpiration && daysUntilExpiration <= ACCOUNT_GRANT_EXPIRES_SOON_DAYS) {
    return (
      <React.Fragment>
        <WarningCallout>
          <Text.Body style={{color: colors.orange.status}}>
            {getWarningText(daysUntilExpiration, grantKind)}
            {hasEditPermissions ? (
              <React.Fragment>
                {' '}
                <Text.Link onPress={connectAccountGrant}>Click to reconnect</Text.Link>
              </React.Fragment>
            ) : (
              ''
            )}
          </Text.Body>
        </WarningCallout>
        <Space height={32} />
      </React.Fragment>
    );
  }

  return null;
};

export default AccountCalloutBanner;
