// Library
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {Conversation} from '@supermove/models';

// App
import Tabs from '@shared/design/components/Tabs';
import {CommunicationInboxProjectListUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';

interface CommunicationInboxProjectStatusTabsProps {
  urlFilters: CommunicationInboxProjectListUrlFilterType;
  conversationCount?: number;
  openCount?: number;
}

const Container = Styled.View`
  margin-horizontal: 16px;
`;

const tabs = {
  all: {
    key: 'all',
    label: 'All',
  },
  open: {
    key: 'open',
    label: 'Follow Up',
  },
};

const CommunicationInboxProjectStatusTabs = ({
  urlFilters,
  conversationCount,
  openCount,
}: CommunicationInboxProjectStatusTabsProps) => {
  return (
    <Container>
      <Tabs
        tabs={[
          {...tabs.open, count: openCount},
          {...tabs.all, count: conversationCount},
        ]}
        handlePressTab={({key}) => {
          if (key === tabs.all.key) {
            // All is the default, so we don't need to set it
            urlFilters.handleUpdate({conversationStatus: undefined});
          } else {
            urlFilters.handleUpdate({conversationStatus: Conversation.ConversationStatus.OPEN});
          }
        }}
        activeTabIndex={
          urlFilters.get('conversationStatus') === Conversation.ConversationStatus.OPEN ? 0 : 1
        }
        TabComponent={Tabs.SecondaryTab}
        tabStyle={{flex: 1}}
        isSpacedTabs
        isFullWidth
      />
    </Container>
  );
};

export default CommunicationInboxProjectStatusTabs;
