// Library
import React from 'react';

// Supermove
import {Styled, Popover, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useDebouncedCallback, usePopover} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import ConversationInboxProjectListFilterPopover from 'modules/Communication/CommunicationInbox/ConversationList/components/ConversationInboxProjectListFilterPopover';
import {CommunicationInboxProjectListUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';

interface CommunicationInboxProjectListSearchFilterProps {
  responsive: ResponsiveType;
  urlFilters: CommunicationInboxProjectListUrlFilterType;
  organization: OrganizationModel;
}

const Container = Styled.View`
  padding-horizontal: 8px;
  flex-direction: row;
`;

const CommunicationInboxProjectListSearchFilter = ({
  responsive,
  urlFilters,
  organization,
}: CommunicationInboxProjectListSearchFilterProps) => {
  const handleChangeQuery = useDebouncedCallback((query) => {
    urlFilters.handleUpdate({projectSearchQuery: query});
  }, 250);
  const communicationInboxProjectListFilterPopover = usePopover();
  const numberOfFiltersApplied = urlFilters.getFilterCount({
    filterKeys: ['fromDate', 'toDate', 'projectTypeIds', 'projectTagIds'],
  });

  return (
    <Container>
      <SearchBar
        onChangeText={(text) => handleChangeQuery(text)}
        placeholder={'Search by customer name or project details'}
        containerStyle={{flex: 1}}
        style={{width: '100%'}}
        defaultValue={urlFilters.get('projectSearchQuery') ?? ''}
        isClearable
        isResponsive
      />
      <React.Fragment>
        <Space width={8} />
        <Popover.Content innerRef={communicationInboxProjectListFilterPopover.ref}>
          <SecondaryButton
            text={`(${numberOfFiltersApplied})`}
            iconLeft={Icon.Filter}
            onPress={communicationInboxProjectListFilterPopover.handleToggle}
            isResponsive
          />
        </Popover.Content>
        <ConversationInboxProjectListFilterPopover
          activeFilterCount={numberOfFiltersApplied}
          popover={communicationInboxProjectListFilterPopover}
          urlFilters={urlFilters}
          responsive={responsive}
          organization={organization}
        />
        <Space width={8} />
      </React.Fragment>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxProjectListSearchFilter.fragment = gql`
  ${ConversationInboxProjectListFilterPopover.fragment}
  fragment CommunicationInboxProjectListSearchFilter on Organization {
    id
    ...ConversationInboxProjectListFilterPopover
  }
`;

export default CommunicationInboxProjectListSearchFilter;
