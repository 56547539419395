// Libraries
import React from 'react';

// Supermove
import {FlatList, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import CrewPayableUserDayItem from './CrewPayableUserDayItem';
import CrewPayableUserDaysListHeader from './CrewPayableUserDaysListHeader';

const Item = Styled.View`
`;

const EmptyContainer = Styled.View`
  padding: 40px;
  align-items: center;
`;

const EmptyMessage = Styled.H6`
  color: ${colors.gray.secondary};
`;

const CrewPayableUserDaysList = ({
  payableUserDays,
  refetch,
}: {
  payableUserDays: any[];
  refetch: () => void;
}) => {
  const responsive = useResponsive();

  return (
    <ScrollView
      horizontal
      contentContainerStyle={{
        width: responsive.desktop ? '100%' : undefined,
      }}
    >
      <FlatList
        listKey={'crew-payable-user-days-list'}
        initialNumToRender={20}
        data={payableUserDays}
        keyExtractor={(payableUserDay) => payableUserDay.id}
        renderItem={({item: payableUserDay, index}) => (
          <Item>
            <CrewPayableUserDayItem
              isFirst={index === 0}
              isLast={index === payableUserDays.length - 1}
              index={index}
              payableUserDay={payableUserDay}
              refetch={refetch}
            />
          </Item>
        )}
        ListHeaderComponent={() => <CrewPayableUserDaysListHeader />}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No hours worked during these dates.</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPayableUserDaysList.fragment = gql`
  ${CrewPayableUserDayItem.fragment}

  fragment CrewPayableUserDaysList on PayableUserDay {
    id
    ...CrewPayableUserDayItem
  }
`;

export default CrewPayableUserDaysList;
