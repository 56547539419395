// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import DateFieldComponent from '@shared/design/components/Field/DateField';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import ProjectAttachmentUploader from '@shared/modules/File/components/ProjectAttachmentUploader';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import StatusBadge from 'modules/App/components/StatusBadge';
import ClaimStatusDropdown from 'modules/Project/Claim/components/ClaimStatusDropdown';
import ClaimTypeDropdown from 'modules/Project/Claim/components/ClaimTypeDropdown';
import ProjectClientCommunicationButtons from 'modules/Project/components/ProjectClientCommunicationButtons';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SectionHeader = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const ClaimTypeColor = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const IndexedContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const ClaimInformation = ({label, information, style}: any) => {
  return <FieldValue label={label} value={information} isResponsive empty={'None'} style={style} />;
};

const ProjectIdentifier = ({project}: any) => {
  return <ClaimInformation label={'Project'} information={`Project ${project.identifier}`} />;
};

const ClientName = ({project}: any) => {
  return <ClaimInformation label={'Client'} information={project.client.name} />;
};

const ClaimType = ({form, field, project, index, isViewMode, claim, responsive}: any) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <React.Fragment>
          <FieldValue.LabelText isResponsive responsive={responsive}>
            Claim Type
          </FieldValue.LabelText>
          <FieldValue.LabelSpace isResponsive responsive={responsive} />
          <Row>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <ClaimTypeColor color={claim.claimType.color} />
            <Space width={8} />
            <FieldValue.ValueText responsive={responsive}>
              {claim.claimType.name}
            </FieldValue.ValueText>
          </Row>
        </React.Fragment>
      ) : (
        <ClaimTypeDropdown
          form={form}
          field={field}
          organization={project.organization}
          index={index}
        />
      )}
    </React.Fragment>
  );
};

const ClaimStatus = ({form, field, project, index, isViewMode, claim, responsive}: any) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <React.Fragment>
          <FieldValue.LabelText isResponsive responsive={responsive}>
            Status
          </FieldValue.LabelText>
          <FieldValue.LabelSpace isResponsive responsive={responsive} />
          <Row>
            <StatusBadge
              color={claim.claimStatus.color}
              label={claim.claimStatus.name}
              size={StatusBadge.SIZE.LARGE}
            />
          </Row>
        </React.Fragment>
      ) : (
        <ClaimStatusDropdown
          form={form}
          field={field}
          organization={project.organization}
          index={index}
          isDefaulted
        />
      )}
    </React.Fragment>
  );
};

const getJobOptions = ({jobs}: any) => {
  return jobs.map((job: any) => ({
    label: job.fullName,
    value: job.id,
  }));
};

const ClaimJob = ({form, field, project, index, isViewMode, claim}: any) => {
  const {autoAssignJobMoversToClaim} = project.organization.settings;

  const handleOnChange = (jobId: any) => {
    const jobs = project.scheduledMoveJobs;
    const job = jobs.find((job: any) => job.id === jobId);

    if (!job) {
      form.setFieldValue(`${field}.userIds`, []);
      return;
    }

    const userIds = autoAssignJobMoversToClaim
      ? job.jobUsers.map((jobUser: any) => jobUser.user.id)
      : [];

    form.setFieldValue(`${field}.userIds`, userIds);
  };

  return (
    <React.Fragment>
      {isViewMode ? (
        <ClaimInformation label={'Job'} information={claim.job ? claim.job.fullName : null} />
      ) : (
        <FieldInput
          {...form}
          label={'Job'}
          name={`${field}.jobId`}
          component={DropdownInput}
          index={index}
          isRequired={project.organization.settings.mustLinkClaimToJob}
          isResponsive
          isClearable
          input={{
            options: getJobOptions({jobs: project.scheduledMoveJobs}),
            placeholder: 'Select a job',
            setFieldValue: form.setFieldValue,
            onChangeValue: handleOnChange,
            style: {flex: 1},
          }}
        />
      )}
    </React.Fragment>
  );
};

const getMoversDisplay = (movers: any) => {
  return movers.map((mover: any) => mover.fullName).join(', ');
};

const getMoverOptionsForJob = ({scheduledMoveJobs, jobId, claimActiveUserIds}: any) => {
  const job = scheduledMoveJobs.find((job: any) => job.id === jobId);
  const filteredJobUsers = job.jobUsers.filter(
    (jobUser: any) => jobUser.user.isActive || claimActiveUserIds.includes(jobUser.user.id),
  );

  return filteredJobUsers.map((jobUser: any) => ({
    label: jobUser.user.fullName,
    value: jobUser.user.id,
  }));
};

const getSectionedMoverOptionsForJobs = ({scheduledMoveJobs, claimActiveUserIds}: any) => {
  return scheduledMoveJobs.map((job: any) => {
    const filteredJobUsers = job.jobUsers.filter(
      (jobUser: any) => jobUser.user.isActive || claimActiveUserIds.includes(jobUser.user.id),
    );

    return {
      label: job.fullName,
      options: filteredJobUsers.map((jobUser: any) => ({
        label: jobUser.user.fullName,
        value: jobUser.user.id,
      })),
    };
  });
};

const ClaimMovers = ({form, field, project, index, isViewMode, claim}: any) => {
  const jobId = _.get(form.values, `${field}.jobId`);
  const claimActiveUserIds = claim?.activeUsers
    ? claim.activeUsers.map((user: any) => user.id)
    : [];
  const {mustLinkClaimToJob} = project.organization.settings;

  return (
    <React.Fragment>
      {isViewMode ? (
        <ClaimInformation
          label={'Movers'}
          information={claim?.activeUsers ? getMoversDisplay(claim.activeUsers) : null}
        />
      ) : (
        <FieldInput
          {...form}
          label={'Movers'}
          name={`${field}.userIds`}
          component={MultiDropdownCheckboxInput}
          index={index}
          isResponsive
          input={{
            options: jobId
              ? getMoverOptionsForJob({
                  jobId,
                  scheduledMoveJobs: project.scheduledMoveJobs,
                  claimActiveUserIds,
                })
              : getSectionedMoverOptionsForJobs({
                  scheduledMoveJobs: project.scheduledMoveJobs,
                  claimActiveUserIds,
                }),
            placeholder: 'Select movers',
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
            isEnabledSelectAll: true,
            isSearchable: true,
            disabled: mustLinkClaimToJob && !jobId,
            usePills: true,
          }}
        />
      )}
    </React.Fragment>
  );
};

const ClaimAmount = ({form, field, index, isViewMode, claim}: any) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <ClaimInformation
          label={'Actual Amount'}
          information={claim.amount && Currency.display(claim.amount)}
        />
      ) : (
        <FieldInput
          {...form}
          name={`${field}.amount`}
          label={'Actual Amount'}
          component={CurrencyInput}
          index={index}
          isResponsive
          input={{
            component: FieldInput.TextInput,
            placeholder: '$0.00',
            keyboardType: 'phone-pad',
            setFieldTouched: form.setFieldTouched,
            setFieldValue: form.setFieldValue,
          }}
          style={{
            flex: 1,
          }}
        />
      )}
    </React.Fragment>
  );
};

const ClaimEstimatedAmount = ({form, field, index, isViewMode, claim}: any) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <ClaimInformation
          label={'Estimated Amount'}
          information={claim.amount && Currency.display(claim.estimatedAmount)}
        />
      ) : (
        <FieldInput
          {...form}
          name={`${field}.estimatedAmount`}
          label={'Estimated Amount'}
          component={CurrencyInput}
          index={index}
          isResponsive
          input={{
            component: FieldInput.TextInput,
            placeholder: '$0.00',
            keyboardType: 'phone-pad',
            setFieldTouched: form.setFieldTouched,
            setFieldValue: form.setFieldValue,
          }}
          style={{
            flex: 1,
          }}
        />
      )}
    </React.Fragment>
  );
};

const ClaimDates = ({form, field, index, isViewMode, claim}: any) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <Row>
          <ClaimInformation
            label={'Created At'}
            information={
              claim.createdAt
                ? Datetime.convertToDisplayDate(claim.createdAt, Datetime.DISPLAY_SHORT_DATE)
                : null
            }
            style={{
              flex: 1,
            }}
          />
          <Space width={16} />
          <ClaimInformation
            label={'Closed At'}
            information={
              claim.closedDate
                ? Datetime.convertToDisplayDate(claim.closedDate, Datetime.DISPLAY_SHORT_DATE)
                : null
            }
            style={{
              flex: 1,
            }}
          />
        </Row>
      ) : (
        // @ts-expect-error TS(2769): No overload matches this call.
        <IndexedContainer index={index}>
          <Row>
            <DateFieldComponent
              index={index}
              form={form}
              label={'Created At'}
              field={`${field}.createdAt`}
              isFullWidth
              disabled
            />
            <Space width={16} />
            <DateFieldComponent
              index={index + 1}
              form={form}
              label={'Closed At'}
              field={`${field}.closedDate`}
              isFullWidth
              isClearable
            />
          </Row>
        </IndexedContainer>
      )}
    </React.Fragment>
  );
};

const ClaimNotes = ({form, field, index, isViewMode, claim}: any) => {
  return (
    <React.Fragment>
      {isViewMode ? (
        <ClaimInformation label={'Notes'} information={claim.notes} />
      ) : (
        <React.Fragment>
          <FieldInput.LabelText isResponsive>Notes</FieldInput.LabelText>
          <Space height={4} />
          <ResponsiveTextInput
            form={form}
            field={`${field}.notes`}
            minHeight={68}
            isResponsive
            input={{
              placeholder: 'Enter notes',
              style: {zIndex: 100 - index},
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ProjectClaimDrawerFieldsContent = ({
  project,
  form,
  field,
  isViewMode,
  claim,
  refetch,
  handlePressSms,
  handlePressEmail,
}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Row>
        <SectionHeader responsive={responsive}>Claim Details</SectionHeader>
        <Space style={{flex: 1}} />
        {isViewMode && (
          <ProjectClientCommunicationButtons
            project={project}
            refetch={refetch}
            iconSize={20}
            spaceWidth={8}
            // The negative marginTop makes it so that the buttons don't
            // throw off the vertical alignment of the section header. This
            // way there isn't movement in the header text when toggling
            // from view mode to edit mode.
            buttonStyle={{height: 32, width: 32, marginTop: -6}}
            handlePressSms={handlePressSms}
            handlePressEmail={handlePressEmail}
          />
        )}
      </Row>
      <Space height={16} />
      <ProjectIdentifier project={project} />
      <Space height={16} />
      <ClientName project={project} />
      <Space height={16} />
      <ClaimType
        form={form}
        field={field}
        project={project}
        index={0}
        isViewMode={isViewMode}
        claim={claim}
        responsive={responsive}
      />
      <Space height={16} />
      <ClaimStatus
        form={form}
        field={field}
        project={project}
        index={1}
        isViewMode={isViewMode}
        claim={claim}
        responsive={responsive}
      />
      <Space height={16} />
      <ClaimJob
        form={form}
        field={field}
        project={project}
        index={2}
        isViewMode={isViewMode}
        claim={claim}
      />
      <Space height={16} />
      <ClaimMovers
        form={form}
        field={field}
        project={project}
        index={3}
        isViewMode={isViewMode}
        claim={claim}
      />
      <Space height={16} />
      <ClaimEstimatedAmount
        form={form}
        field={field}
        index={4}
        isViewMode={isViewMode}
        claim={claim}
      />
      <Space height={16} />
      <ClaimAmount form={form} field={field} index={5} isViewMode={isViewMode} claim={claim} />
      <Space height={16} />
      <ClaimDates form={form} field={field} index={6} isViewMode={isViewMode} claim={claim} />
      <Space height={16} />
      {/* index increments +2 because ClaimDates contains two inputs */}
      <ClaimNotes form={form} field={field} index={8} isViewMode={isViewMode} claim={claim} />
      <Space height={24} />
      <SectionHeader responsive={responsive}>Attachments</SectionHeader>
      <Space height={16} />
      <ProjectAttachmentUploader
        form={form}
        field={`${field}.attachmentForms`}
        project={project}
        isViewMode={isViewMode}
        dragInputContainerStyle={{padding: 0}}
        attachmentRowStyle={{paddingHorizontal: 0}}
      />
    </React.Fragment>
  );
};

const ProjectClaimDrawerFields = ({
  form,
  field,
  projectUuid,
  isOpen,
  isViewMode,
  claim,
  handlePressSms,
  handlePressEmail,
}: any) => {
  const {data, loading, refetch} = useQuery(ProjectClaimDrawerFields.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid},
    skip: !isOpen,
  });
  return (
    <Loading loading={loading || !data} as={PageLoadingIndicator}>
      {() => {
        return (
          <ProjectClaimDrawerFieldsContent
            project={data.project}
            form={form}
            field={field}
            isViewMode={isViewMode}
            claim={claim}
            refetch={refetch}
            handlePressSms={handlePressSms}
            handlePressEmail={handlePressEmail}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClaimDrawerFields.query = gql`
  ${ClaimStatusDropdown.fragment}
  ${ClaimTypeDropdown.fragment}
  ${ProjectAttachmentUploader.fragment}
  ${ProjectClientCommunicationButtons.fragment}
  query ProjectClaimDrawerFields($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      identifier
      client {
        id
        name
      }
      organization {
        id
        settings {
          id
          mustLinkClaimToJob
          autoAssignJobMoversToClaim
        }
        ...ClaimStatusDropdown
        ...ClaimTypeDropdown
      }
      scheduledMoveJobs {
        id
        fullName
        jobUsers {
          id
          user {
            id
            fullName
            isActive
          }
        }
      }
      ...ProjectAttachmentUploader
      ...ProjectClientCommunicationButtons
    }
  }
`;

ProjectClaimDrawerFields.fragment = gql`
  fragment ProjectClaimDrawerFields on Claim {
    id
    amount
    estimatedAmount
    createdAt
    closedDate
    notes
    claimType {
      id
      name
      color
    }
    claimStatus {
      id
      name
      color
    }
    job {
      id
      fullName
    }
    activeUsers {
      id
      fullName
    }
  }
`;

export default ProjectClaimDrawerFields;
