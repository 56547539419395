// Libraries
import React from 'react';

// Supermove
import {Space, Styled, Icon, IconSource} from '@supermove/components';
import {useResponsive, ResponsiveType, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Container = Styled.View<{isHovered?: boolean}>`
  flex-direction: row;
  background-color: ${({isHovered}) => (isHovered ? colors.gray.background : colors.white)};
  padding-vertical: 12px;
  padding-horizontal: 16px;
  cursor: pointer;
`;

const ContainerContent = Styled.View`
  flex:1;
`;

const LoadingContainer = Styled.View`
  padding: 12px;
`;

export const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ResponsiveRow = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: ${({responsive}) => (responsive.mobile ? 'column' : 'row')};
`;

const Divider = Styled.View`
  height: 18px;
  width: 1px;
  margin-horizontal: 8px;
  background-color: ${colors.gray.border};
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const DetailsText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const EmptyDetailsText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const Button = Styled.ButtonV2`
`;

export const ResponsiveDivider = ({responsive}: {responsive: ResponsiveType}) => {
  if (responsive.mobile) {
    return <Space height={8} />;
  }

  return <Divider />;
};

const LoadingRow = () => {
  const responsive = useResponsive();

  return (
    <LoadingContainer>
      <Row>
        <SkeletonLoader height={22} width={80} />
        <Space width={8} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={160} />
      </Row>
      <Space height={8} />
      <Row>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={64} />
        <Divider />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={80} />
      </Row>
      <Space height={8} />
      <ResponsiveRow responsive={responsive}>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
        <ResponsiveDivider responsive={responsive} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
        <ResponsiveDivider responsive={responsive} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
        <ResponsiveDivider responsive={responsive} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
      </ResponsiveRow>
    </LoadingContainer>
  );
};

const Detail = ({
  icon,
  iconColor = colors.gray.secondary,
  style = {alignItems: 'baseline'},
  children,
}: {
  icon: IconSource;
  iconColor?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) => {
  const responsive = useResponsive();
  return (
    <Row style={style}>
      <Icon source={icon} size={10} color={iconColor} />
      <Space width={8} />
      {children ? (
        <DetailsText numberOfLines={1} responsive={responsive}>
          {children}
        </DetailsText>
      ) : (
        <EmptyDetailsText responsive={responsive}>None</EmptyDetailsText>
      )}
    </Row>
  );
};

const InteractionIcon = ({responsive}: {responsive: ResponsiveType}) => {
  if (!responsive.desktop) {
    return <Icon source={Icon.ChevronRight} size={16} />;
  } else {
    return null;
  }
};

const GlobalSearchResultBuilderContainer = ({
  onPress,
  children,
}: {
  onPress: () => void;
  children: React.ReactNode;
}) => {
  const {ref, isHovered} = useHover();
  const responsive = useResponsive();
  return (
    <Button onPress={onPress}>
      <Container ref={ref} isHovered={responsive.mobile ? undefined : isHovered}>
        <ContainerContent>{children}</ContainerContent>
        <InteractionIcon responsive={responsive} />
      </Container>
    </Button>
  );
};

// Helpers
const getDisplayCount = (count: number): string => {
  return count >= 50 ? '50+' : `${count}`;
};

const GlobalSearchResultBuilder = {
  // Typography
  NameText,

  // Components
  LoadingRow,
  Detail,
  Container: GlobalSearchResultBuilderContainer,
  ResponsiveDivider,
  Row,
  ResponsiveRow,

  // Helpers
  getDisplayCount,
};

export default GlobalSearchResultBuilder;
