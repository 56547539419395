// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {EmailTemplateForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useToast} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App

import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import EmailTemplatePageContentV2 from 'modules/Organization/Settings/Email/componentsV2/EmailTemplatePageContentV2';
import useCreateEmailTemplateForm from 'modules/Organization/Settings/Email/logic/useCreateEmailTemplateForm';

const CreateEmailTemplateContent = ({organization}: {organization: OrganizationModel}) => {
  const {navigator} = useNavigationDOM();
  const createEmailTemplateSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Email template created',
  });
  const emailTemplateForm = EmailTemplateForm.new({
    organizationId: organization.id,
  });
  const {form, submitting, handleSubmit} = useCreateEmailTemplateForm({
    emailTemplateForm,
    onSuccess: () => {
      createEmailTemplateSuccessToast.handleToast();
      navigator.push('/settings/communication/email-templates');
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <React.Fragment>
      <EmailTemplatePageContentV2
        organization={organization}
        title={'Create Email Template'}
        submitButtonText={'Create'}
        form={form}
        handleSubmit={handleSubmit}
        isSubmitting={submitting}
        isKindEditable
      />
    </React.Fragment>
  );
};

const CreateEmailTemplatePage = () => {
  const {loading, data} = useQuery(CreateEmailTemplatePage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <CreateEmailTemplateContent organization={data.viewer.viewingOrganization} />}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateEmailTemplatePage.query = gql`
  ${EmailTemplatePageContentV2.fragment}

  query CreateEmailTemplatePage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...EmailTemplatePageContentV2
      }
    }
  }
`;

export default CreateEmailTemplatePage;
