// Libraries
import React from 'react';

// Supermove
import {Icon, PreventPropagation, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover} from '@supermove/hooks';
import {ProductUser, TextMessageTemplateModel, UserModel} from '@supermove/models';
import {Environment} from '@supermove/sdk';
import {colors} from '@supermove/styles';
import {openNewTab} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import CommunicationsWidgetFooterSendText from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsWidgetFooterSendText';
import ProjectSendEmailOptionsPopover from 'modules/Project/components/ProjectSendEmailOptionsPopover';

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const VerticalLine = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const FooterContainer = Styled.View`
  background-color: ${colors.white};
`;

const ActionsContainer = Styled.View`
  flex-direction: row;
  padding: 8px;
`;

const TooltipContainer = Styled.View``;

const CommunicationsWidgetFooter = ({
  customer,
  viewer,
  refetch,
  projectId,
  textMessageTemplates,
  selectedTextMessageTemplate,
}: {
  customer: UserModel;
  viewer: UserModel;
  refetch: () => void;
  projectId: string;
  textMessageTemplates: TextMessageTemplateModel[];
  selectedTextMessageTemplate: TextMessageTemplateModel | undefined;
}) => {
  const projectSendEmailOptionsPopover = usePopover({name: 'Project Send Email Options Popover'});
  const isSalesActive = viewer.productUsers.some((productUser) =>
    ProductUser.isSalesActive(productUser),
  );
  const salesCallLink = `${Environment.getSalesAppUrl()}/calls?page=1&userKind=ME&phoneNumber=${customer.phoneNumber}`;

  return (
    <FooterContainer>
      <Line />
      <ActionsContainer>
        <Space height={4} />
        {isSalesActive && (
          <React.Fragment>
            <TextTooltip
              text={!customer.phoneNumber ? 'Add a phone number to make new calls.' : undefined}
              placement='top'
            >
              <TooltipContainer>
                <PreventPropagation>
                  <TertiaryButton
                    text={'Call'}
                    iconLeft={Icon.Phone}
                    onPress={() => openNewTab(salesCallLink)}
                    isDisabled={!customer.phoneNumber}
                  />
                </PreventPropagation>
              </TooltipContainer>
            </TextTooltip>
            <Space width={16} />
            <VerticalLine />
            <Space width={16} />
          </React.Fragment>
        )}
        <React.Fragment>
          <TextTooltip
            text={!customer.email ? 'Add an email address to send new emails.' : undefined}
            placement='top'
          >
            <TooltipContainer>
              <TertiaryButton
                text={'Email'}
                iconLeft={Icon.Envelope}
                onPress={projectSendEmailOptionsPopover.handleOpen}
                isDisabled={!customer.email}
                isResponsive
              />
            </TooltipContainer>
          </TextTooltip>
          <ProjectSendEmailOptionsPopover
            actionMenuPopover={projectSendEmailOptionsPopover}
            refetch={refetch}
          />
        </React.Fragment>
      </ActionsContainer>
      <Line />
      <CommunicationsWidgetFooterSendText
        customer={customer}
        viewer={viewer}
        refetch={refetch}
        projectId={projectId}
        textMessageTemplates={textMessageTemplates}
        selectedTextMessageTemplate={selectedTextMessageTemplate}
      />
    </FooterContainer>
  );
};

CommunicationsWidgetFooter.fragment = gql`
  ${CommunicationsWidgetFooterSendText.fragment}
  ${ProductUser.isSalesActive.fragment}

  fragment CommunicationsWidgetFooter_User on User {
    id
    phoneNumber
    email
    ...CommunicationsWidgetFooterSendText_User
  }

  fragment CommunicationsWidgetFooter_TextMessageTemplate on TextMessageTemplate {
    id
    ...CommunicationsWidgetFooterSendText_TextMessageTemplate
  }

  fragment CommunicationsWidgetFooter_Viewer on User {
    id
    productUsers {
      id
      ...ProductUser_isSalesActive
    }
    ...CommunicationsWidgetFooterSendText_Viewer
  }
`;

export default CommunicationsWidgetFooter;
