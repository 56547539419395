// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {JobModel} from '@supermove/models';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';

const JobCancelModal = ({
  job,
  handleClose,
  refetch,
  isOpen,
  onSuccess,
}: {
  job: JobModel;
  handleClose: () => void;
  refetch?: () => void;
  isOpen: boolean;
  onSuccess?: () => void;
}) => {
  const form = useForm({initialValues: {}});
  const {handleSubmit, submitting} = useFormMutation({
    form,
    mutation: JobCancelModal.mutation,
    variables: {jobId: job.id},
    onSuccess: () => {
      handleClose();
      onSuccess && onSuccess();
      refetch && refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Remove ${job.fullName}`}
      subtitle={'This job will be removed from the project.'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
      deleteButtonText={'Remove'}
      cancelButtonText={'Cancel'}
    />
  );
};

JobCancelModal.mutation = gql`
  mutation JobCancelModal($jobId: Int!) {
    response: cancelJob(jobId: $jobId) {
      ${gql.errors}
      job {
        id
        primaryStatus
        calendarPrimaryStatus
      }
    }
  }
`;

JobCancelModal.fragment = gql`
  fragment JobCancelModal on Job {
    id
    uuid
    fullName
  }
`;

export default JobCancelModal;
