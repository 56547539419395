// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {FormulaModel} from '@supermove/models';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import FormulaForm from '@shared/modules/Billing/forms/FormulaForm';
import useUpdateFormulaMutation from '@shared/modules/Billing/hooks/useUpdateFormulaMutation';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import FormulaPage from 'modules/Organization/Settings/Billing/Formulas/components/FormulaPage';

const EditFormulaPageContent = ({formula, userId}: {formula: FormulaModel; userId: string}) => {
  const {navigator} = useNavigationDOM();
  const handleNavigateToFormulas = () => navigator.push(`/settings/billing/formulas`);
  const formulaForm = FormulaForm.edit(formula, {userId});
  const {form, handleSubmit, submitting} = useUpdateFormulaMutation({
    formulaForm,
    onSuccess: handleNavigateToFormulas,
    onError: (errors) => console.log({errors}),
  });
  return (
    <FormulaPage
      form={form}
      organization={formula.organization}
      title={'Edit Formula'}
      // TODO: submit validations!
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      handleClose={handleNavigateToFormulas}
    />
  );
};

const EditFormulaPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(EditFormulaPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      formulaUuid: params.formulaUuid,
    },
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const {formula, viewer} = data;
          return <EditFormulaPageContent formula={formula} userId={viewer.id} />;
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditFormulaPage.query = gql`
  ${FormulaForm.edit.fragment}
  ${FormulaPage.fragment}
  query EditFormulaPage(
    $formulaUuid: String!,
  ) {
    ${gql.query}
    viewer {
      id
    }
    formula: formulaByUuid(uuid: $formulaUuid) {
      id
      organization {
        id
        ...FormulaPage
      }
      ...FormulaForm_edit
    }
  }
`;

export default EditFormulaPage;
