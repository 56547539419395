// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useEffect, usePopover, useResponsive} from '@supermove/hooks';
import {AccountGrantModel, OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Callout from '@shared/design/components/Callout';
import FieldValue from '@shared/design/components/Field/FieldValue';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import CreateAccountGrantForm from '@shared/modules/AccountGrant/forms/CreateAccountGrantForm';
import DeactivateAccountGrantForm from '@shared/modules/AccountGrant/forms/DeactivateAccountGrantForm';
import useDeactivateAccountGrantMutation from '@shared/modules/AccountGrant/hooks/useDeactivateAccountGrantMutation';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import AccountCalloutBanner from 'modules/Organization/Settings/Communication/components/AccountCalloutBanner';
import UpdateAccountGrantPopover from 'modules/Organization/Settings/Communication/components/UpdateAccountGrantPopover';
import useConnectAccountGrant from 'modules/Organization/Settings/Communication/hooks/useConnectAccountGrant';

const CompanyAccountSettings = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const CompanyAccountPanelBody = ({
  accountGrant,
  expiredAccountGrant,
  isViewingDependentBranch,
  organization,
  hasEditPermissions,
  refetch,
}: {
  accountGrant?: AccountGrantModel;
  expiredAccountGrant?: AccountGrantModel;
  isViewingDependentBranch: boolean;
  organization: OrganizationModel;
  hasEditPermissions: boolean;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const updateAccountPopover = usePopover({name: 'Update Account Grant Popover'});
  const deactivateModal = useModal({name: 'Deactivate Account Grant Modal'});
  const accountGrantEmail = accountGrant?.identifier;
  const expiredAccountGrantEmail = expiredAccountGrant?.identifier;
  const createAccountGrantForm = CreateAccountGrantForm.new({
    kind: 'ORGANIZATION',
    provider: 'NYLAS',
    organizationId: organization.id,
  });

  const {connectAccountGrant, submitting: connectingAccount} = useConnectAccountGrant({
    createAccountGrantForm,
    onSuccess: refetch,
    redirectPath: '/settings/communication/general',
  });
  const {
    form: deactivateAccountGrantForm,
    handleSubmit: handleDeactivateFormSubmit,
    submitting: deactivatingAccount,
  } = useDeactivateAccountGrantMutation({
    deactivateAccountGrantForm: DeactivateAccountGrantForm.edit({
      accountGrantId: accountGrant?.id ?? '',
    }),
    onError: (errors) => console.log({errors}),
    onSuccess: refetch,
  });

  const handleDeactivateAccount = () => {
    deactivateModal.handleClose();
    handleDeactivateFormSubmit();
  };

  useEffect(() => {
    // When there's a new account grant id, reset the form
    if (accountGrant?.id && deactivateAccountGrantForm.values.accountGrantId !== accountGrant.id) {
      deactivateAccountGrantForm.resetForm({values: {accountGrantId: accountGrant.id}});
    }
  }, [accountGrant?.id]);

  const connectAccountText = responsive.mobile ? 'Connect Account' : 'Connect Company Account';
  const updateAccountText = responsive.mobile ? 'Update Account' : 'Update Company Account';
  const grantEmail = expiredAccountGrantEmail || accountGrantEmail;

  if (isViewingDependentBranch) {
    return (
      <Callout text={'You are viewing a branch. To make changes, switch to the main branch.'} />
    );
  }

  return (
    <React.Fragment>
      <AccountCalloutBanner
        connectAccountGrant={connectAccountGrant}
        expiredAt={expiredAccountGrant?.statusUpdatedAt}
        hasEditPermissions={hasEditPermissions}
        daysUntilExpiration={accountGrant?.daysUntilExpiration}
        grantKind={'ORGANIZATION'}
      />
      <CompanyAccountSettings>
        <FieldValue
          isResponsive
          label={'Company Account'}
          empty={'None'}
          value={
            expiredAccountGrantEmail ? (
              <React.Fragment>
                <Icon source={Icon.Ban} size={12} /> <Space width={4} />
                {expiredAccountGrantEmail}
              </React.Fragment>
            ) : (
              accountGrantEmail
            )
          }
          valueStyle={
            expiredAccountGrantEmail ? {color: colors.red.warning, fontStyle: 'italic'} : undefined
          }
        />
        <Space width={8} />
        {hasEditPermissions && (
          <React.Fragment>
            <Popover.Content innerRef={updateAccountPopover.ref}>
              <Button
                isResponsive
                isSubmitting={deactivatingAccount || connectingAccount}
                onPress={grantEmail ? updateAccountPopover.handleToggle : connectAccountGrant}
                text={grantEmail ? updateAccountText : connectAccountText}
                iconRight={grantEmail ? Icon.AngleDown : null}
                iconLeft={grantEmail ? null : Icon.Plus}
                iconSize={grantEmail ? 14 : 12}
              />
            </Popover.Content>
            <UpdateAccountGrantPopover
              responsive={responsive}
              popover={updateAccountPopover}
              handleUpdateAccount={connectAccountGrant}
              handleDeactivateAccount={deactivateModal.handleOpen}
            />
            <WarningModal
              isOpen={deactivateModal.isOpen}
              title={'Disconnect account'}
              message={`This will unlink ${grantEmail} and remove access to this email address, all emails will be sent from Supermove.`}
              handlePressOutside={deactivateModal.handleClose}
              primaryActionText={'Disconnect'}
              handlePrimaryAction={handleDeactivateAccount}
              secondaryActionText={'Cancel'}
              handleSecondaryAction={deactivateModal.handleClose}
            />
          </React.Fragment>
        )}
      </CompanyAccountSettings>
    </React.Fragment>
  );
};

const CompanyAccountPanel = ({
  organization,
  index,
  refetch,
  hasEditPermissions,
}: {
  organization: OrganizationModel;
  index: number;
  refetch: () => void;
  hasEditPermissions: boolean;
}) => {
  const isViewingDependentBranch = !OrganizationKind.getIsIndependent(organization.kind);

  return (
    <ActionPanel
      title={'Company Account'}
      headerSubtitle={
        'Connect a company account to send emails from the company email or staff individual accounts.'
      }
      index={index}
      width={784}
      bodyComponentProps={{
        isViewingDependentBranch,
        hasEditPermissions,
        organization,
        accountGrant: organization.activeAccountGrant,
        expiredAccountGrant: organization.expiredAccountGrant,
        refetch,
      }}
      BodyComponent={CompanyAccountPanelBody}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyAccountPanel.fragment = gql`
  fragment CompanyAccountPanel on Organization {
    id
    kind
    activeAccountGrant {
      id
      identifier
      daysUntilExpiration
    }
    expiredAccountGrant {
      id
      identifier
      statusUpdatedAt
    }
  }
`;

export default CompanyAccountPanel;
