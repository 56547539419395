// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {PreventPropagation, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, usePopover, useDrawer, useResponsive} from '@supermove/hooks';
import {Location, Job, JobModel, OrganizationModel} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import Line from '@shared/design/components/Line';
import Table from '@shared/design/components/Table';
import JobTagsButton from 'modules/Calendar/Capacity/components/JobTagsButton';
import JobDispatchNotesPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchNotesPopover';
import JobArrivalTime from 'modules/Job/JobCard/components/JobArrivalTime';
import JobDispatchNotes from 'modules/Job/JobCard/components/JobDispatchNotes';
import ProjectCommentsButton from 'modules/Project/components/ProjectCommentsButton';

const TrainingJobsLabel = Styled.Text`
  ${Typography.Responsive.Subheading}
  padding-left: 16px;
`;

const handleRefetch = ({
  shouldFetchJobsListTable,
  refetch,
  refetchTable,
}: {
  shouldFetchJobsListTable: boolean;
  refetch: () => void;
  refetchTable: () => void;
}) => {
  refetch();
  // Instead of refetching here in every case, we return data from
  // useSetCrewSlotsToCrewMutation and useSetCrewSlotsToCrewMutation in order to
  // update the frontend without needing to reach the loading state. See those
  // files for the data we are returning
  if (shouldFetchJobsListTable) {
    refetchTable();
  }
};

const JobProjectCommentsWithSidebar = ({job}: {job: JobModel}) => {
  const projectCommentsDrawer = useDrawer({
    name: 'Project Comments Drawer',
  });

  return <ProjectCommentsButton project={job.project} sidebar={projectCommentsDrawer} />;
};

const JobNotes = ({job}: {job: JobModel}) => {
  const jobDispatchNotesPopover = usePopover();
  return (
    <PreventPropagation>
      <JobDispatchNotes
        job={job}
        popover={jobDispatchNotesPopover}
        numberOfLines={1}
        isResponsive
        hideIcon
        style={{width: '100%'}}
      />
      <JobDispatchNotesPopover job={job} popover={jobDispatchNotesPopover} refetch={() => null} />
    </PreventPropagation>
  );
};

// Table Helper Methods
const getJobWeightText = ({job}: {job: JobModel}) => `${job.project.weight} lbs`;
const getJobVolumeText = ({job}: {job: JobModel}) => `${job.project.volume} ft³`;
const getJobTruckNames = ({job}: {job: JobModel}) => {
  const truckNamesText = job.jobTrucks.map((jobTruck) => jobTruck.truck.name).join('\n');
  return truckNamesText || 'No trucks assigned';
};
const getJobCrewMemberNames = ({job}: {job: JobModel}) => {
  const moverNamesText = job.assignedJobUsers.map((jobUser) => jobUser.user.fullName).join('\n');
  return moverNamesText || 'No movers assigned';
};

// Column Definitions
const getMobileColumnDefinitions = ({refetch}: {refetch: () => void}) => [
  {
    cellText: (job: JobModel) => `${job.project.projectType.name} ${job.identifier}`,
    mobileOptions: {
      isInHeader: true,
      rank: 0,
    },
  },
  {
    headerLabel: 'Customer Name',
    cellText: (job: JobModel) => {
      const {client} = job.project;
      const isPrimaryClientNameDifferent = client.name !== client.primaryContact.fullName;
      return `${client.name}${
        isPrimaryClientNameDifferent ? ` (${client.primaryContact.fullName})` : ''
      }`;
    },
    mobileOptions: {
      isInHeader: true,
      rank: 1,
    },
  },
  {
    cellText: (job: JobModel) => {
      const {startLocation, endLocation} = job;
      return `${Location.getDisplayCityState(startLocation)}${endLocation ? ` - ${Location.getDisplayCityState(endLocation)}` : ''}`;
    },
    mobileOptions: {
      isInHeader: true,
      rank: 2,
    },
  },
  {
    headerLabel: 'Dispatch Status',
    cellText: (job: JobModel) => Job.getCalendarPrimaryStatusText(job),
  },
  {
    headerLabel: 'Tags',
    cellComponent: (job: JobModel) => <JobTagsButton job={job} refetch={refetch} />,
  },
  {
    headerLabel: 'Mileage',
    cellText: (job: JobModel) => Job.getTotalDistanceText(job),
  },
  {
    headerLabel: 'Weight',
    cellText: (job: JobModel) => getJobWeightText({job}),
  },
  {
    headerLabel: 'Volume',
    cellText: (job: JobModel) => getJobVolumeText({job}),
  },
  {
    headerLabel: 'Arrival Time',
    cellText: (job: JobModel) => JobArrivalTime.getDisplayArrivalTime({job}),
  },
  {
    headerLabel: 'Est. Hours',
    cellText: (job: JobModel) => Job.getDisplayEstimateHours(job),
  },
  {
    headerLabel: 'Trucks',
    cellText: (job: JobModel) =>
      `${Job.getDispatchJobTrucksCount(job)}\n${getJobTruckNames({job})}`,
  },
  {
    headerLabel: 'Movers',
    cellText: (job: JobModel) =>
      `${Job.getDispatchJobUsersCount(job)}\n${getJobCrewMemberNames({job})}`,
  },
  {
    headerLabel: 'Comments',
    cellComponent: (job: JobModel) => <JobProjectCommentsWithSidebar job={job} />,
  },
  {
    headerLabel: 'Notes',
    cellComponent: (job: JobModel) => <JobNotes job={job} />,
  },
];

const JobsTable = ({
  jobs,
  isLoading,
  refetch,
  refetchTable,
  shouldHideIfEmpty,
}: {
  jobs: JobModel[];
  isLoading: boolean;
  refetch: () => void;
  refetchTable: () => void;
  shouldHideIfEmpty: boolean;
}) => {
  const {navigator} = useNavigationDOM();
  const columnDefinitions = getMobileColumnDefinitions({
    refetch: ({shouldFetchJobsListTable = false} = {}) =>
      handleRefetch({shouldFetchJobsListTable, refetch, refetchTable}),
  });

  if (shouldHideIfEmpty && _.isEmpty(jobs)) {
    return null;
  }

  return (
    <React.Fragment>
      <Table
        isLoading={isLoading}
        columnDefinitions={columnDefinitions}
        emptyStateText={'No jobs to display'}
        items={jobs}
        itemKey={'id'}
        onRowPress={(job: JobModel) => navigator.push(`/jobs/${job.uuid}`)}
      />
      {_.some(jobs) && <Line />}
    </React.Fragment>
  );
};

const TrainingJobsSection = ({
  jobs,
  isLoading,
  refetch,
  refetchTable,
}: {
  jobs: JobModel[];
  isLoading: boolean;
  refetch: () => void;
  refetchTable: () => void;
}) => {
  const responsive = useResponsive();

  if (_.isEmpty(jobs)) {
    return null;
  }

  return (
    <React.Fragment>
      <Space height={16} />
      <TrainingJobsLabel responsive={responsive}>Training Jobs</TrainingJobsLabel>
      <Space height={16} />
      <Line />
      <JobsTable
        jobs={jobs}
        isLoading={isLoading}
        refetch={refetch}
        refetchTable={refetchTable}
        shouldHideIfEmpty
      />
    </React.Fragment>
  );
};

const MobileCapacityCalendarDayJobsLists = ({
  organization,
  isTrainingJobs,
  refetch,
}: {
  organization: OrganizationModel;
  isTrainingJobs?: boolean;
  refetch: () => void;
}) => {
  const {params} = useNavigationDOM();
  const variables = {
    slugs: params.slugs
      ? params.slugs
      : organization.isPrimary
        ? ['ALL_ORGANIZATIONS']
        : [organization.slug],
    date: params.date || Datetime.toMutationDate(Datetime.today),
    projectTypes: params.projectTypes,
    projectTagIds: params.projectTagIds,
    jobTagIds: params.jobTagIds,
    salespersons: URL.decodeEmptyStringToNull(params.salespersons),
    searchQuery: params.searchQuery,
  };

  const realJobsQuery = useQuery(MobileCapacityCalendarDayJobsLists.query, {
    fetchPolicy: 'cache-and-network',
    variables,
  });
  const trainingJobsQuery = useQuery(MobileCapacityCalendarDayJobsLists.trainingJobsQuery, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const realJobs = realJobsQuery.data?.filteredJobsV2 || [];
  const trainingJobs = trainingJobsQuery.data?.filteredJobsV2 || [];

  return (
    <React.Fragment>
      <JobsTable
        jobs={realJobs}
        isLoading={realJobsQuery.loading}
        refetch={refetch}
        refetchTable={realJobsQuery.refetch}
        shouldHideIfEmpty={_.some(trainingJobs)}
      />
      <TrainingJobsSection
        jobs={trainingJobs}
        isLoading={trainingJobsQuery.loading}
        refetch={refetch}
        refetchTable={trainingJobsQuery.refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const JobFragment = gql`
  ${Job.getCalendarPrimaryStatusText.fragment}
  ${Job.getDispatchJobTrucksCount.fragment}
  ${Job.getDispatchJobUsersCount.fragment}
  ${Job.getDisplayEstimateHours.fragment}
  ${Job.getTotalDistanceText.fragment}
  ${JobArrivalTime.fragment}
  ${JobDispatchNotes.fragment}
  ${JobDispatchNotesPopover.fragment}
  ${JobTagsButton.fragment}
  ${Location.getDisplayCityState.fragment}
  ${ProjectCommentsButton.fragment}

  fragment JobFragment on Job {
    id
    uuid
    identifier
    jobTrucks {
      id
      truck {
        id
        name
      }
    }
    assignedJobUsers {
      id
      user {
        id
        fullName
      }
    }
    project {
      id
      weight
      volume
      projectType {
        id
        name
      }
      client {
        id
        name
        primaryContact {
          id
          fullName
        }
      }
      ...ProjectCommentsButton
    }
    startLocation {
      id
      ...Location_getDisplayCityState
    }
    endLocation {
      id
      ...Location_getDisplayCityState
    }
    ...Job_getCalendarPrimaryStatusText
    ...Job_getDispatchJobTrucksCount
    ...Job_getDispatchJobUsersCount
    ...Job_getDisplayEstimateHours
    ...Job_getTotalDistanceText
    ...JobArrivalTime
    ...JobDispatchNotes
    ...JobDispatchNotesPopover
    ...JobTagsButton
  }
`;

MobileCapacityCalendarDayJobsLists.query = gql`
  ${JobFragment}
  query MobileCapacityCalendarDayJobsLists(
    $slugs: [String!]!,
    $date: String!,
    $projectTypes: [Int!],
    $projectTagIds: [Int!],
    $jobTagIds: [Int!],
    $salespersons: [Int],
    $searchQuery: String,
  ) {
    ${gql.query}
    filteredJobsV2(
      slugs: $slugs,
      date: $date,
      projectTypes: $projectTypes,
      projectTagIds: $projectTagIds,
      jobTagIds: $jobTagIds,
      salespersons: $salespersons,
      searchQuery: $searchQuery,
    ) {
      id
      ...JobFragment
    }
  }
`;

MobileCapacityCalendarDayJobsLists.trainingJobsQuery = gql`
  ${JobFragment}
  query MobileCapacityCalendarDayJobsLists(
    $slugs: [String!]!,
    $date: String!,
    $projectTypes: [Int!],
    $projectTagIds: [Int!],
    $jobTagIds: [Int!],
    $salespersons: [Int],
    $searchQuery: String,
  ) {
    ${gql.query}
    filteredJobsV2(
      slugs: $slugs,
      date: $date,
      projectTypes: $projectTypes,
      projectTagIds: $projectTagIds,
      jobTagIds: $jobTagIds,
      salespersons: $salespersons,
      searchQuery: $searchQuery,
      isTrainingOnly: true,
    ) {
      id
      ...JobFragment
    }
  }
`;

MobileCapacityCalendarDayJobsLists.fragment = gql`
  fragment MobileCapacityCalendarDayJobsLists on Organization {
    id
    slug
    isPrimary
  }
`;

export default MobileCapacityCalendarDayJobsLists;
