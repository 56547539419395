// Library
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {PaginationType, ResponsiveType, TabsType} from '@supermove/hooks';
import {ConversationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App

import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import {PageLoadingIndicator} from 'modules/App/components';
import CommunicationInboxConversationItem from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxConversationItem';
import {CommunicationInboxProjectListUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';

interface CommunicationInboxProjectListProps {
  responsive: ResponsiveType;
  urlFilters: CommunicationInboxProjectListUrlFilterType;
  conversations: ConversationModel[];
  pagination: PaginationType;
  loading: boolean;
  error: unknown;
  mobilePaneTabs?: TabsType;
}

const Container = Styled.View`
  flex: 1;
`;

const PaginationBarContainer = Styled.View`
  background-color: ${colors.gray.background}
`;

const ErrorNoMessagesContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray.background};
  padding: 16px;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
  text-align: center;
`;

const Subtitle = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const CommunicationInboxProjectListContent = ({
  responsive,
  conversations,
  urlFilters,
  pagination,
  mobilePaneTabs,
}: {
  responsive: ResponsiveType;
  conversations: ConversationModel[];
  urlFilters: CommunicationInboxProjectListUrlFilterType;
  pagination: PaginationType;
  mobilePaneTabs?: TabsType;
}) => {
  return (
    <Container>
      <FlatList
        listKey={'communication-inbox-project-list'}
        data={conversations}
        extraData={urlFilters.get('projectUuid')}
        keyExtractor={(item) => item.id}
        renderItem={({item}) => (
          <CommunicationInboxConversationItem
            responsive={responsive}
            conversation={item}
            handleProjectPress={() => {
              urlFilters.handleUpdate({projectUuid: item.project.uuid});
              mobilePaneTabs?.handleChangeIndex(1);
            }}
            isSelected={urlFilters.get('projectUuid') === item.project.uuid}
          />
        )}
      />
      <PaginationBarContainer>
        <Space height={16} />
        <PaginationBar pagination={pagination} />
        <Space height={16} />
      </PaginationBarContainer>
    </Container>
  );
};

const NoMessages = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <ErrorNoMessagesContainer>
      <Title responsive={responsive}>No conversations.</Title>
      <Space height={8} />
      <Subtitle responsive={responsive}>
        Start a conversation on the individual project page.
      </Subtitle>
    </ErrorNoMessagesContainer>
  );
};

const Error = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <ErrorNoMessagesContainer>
      <Title responsive={responsive}>Error loading conversations.</Title>
      <Space height={8} />
      <Subtitle responsive={responsive}>Please try again later.</Subtitle>
    </ErrorNoMessagesContainer>
  );
};

const CommunicationInboxProjectList = ({
  responsive,
  urlFilters,
  conversations,
  pagination,
  loading,
  error,
  mobilePaneTabs,
}: CommunicationInboxProjectListProps) => {
  if (!loading && error) {
    return <Error responsive={responsive} />;
  }
  if (!loading && conversations.length === 0) {
    return <NoMessages responsive={responsive} />;
  }
  if (loading || !conversations) {
    return <PageLoadingIndicator />;
  }
  return (
    <CommunicationInboxProjectListContent
      conversations={conversations}
      responsive={responsive}
      urlFilters={urlFilters}
      pagination={pagination}
      mobilePaneTabs={mobilePaneTabs}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxProjectList.fragment = gql`
  ${CommunicationInboxConversationItem.fragment}

  fragment CommunicationInboxProjectList on Conversation {
    id
    ...CommunicationInboxConversationItem
    project {
      id
      uuid
    }
  }
`;

export default CommunicationInboxProjectList;
