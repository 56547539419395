// Libraries
// @ts-expect-error library is not typed
import DOMPurify from 'dompurify';

// Gets a simplified version of the email content to display in the collapsed view
export const toTextFromHtml = (html: string, newLineReplacement = ' ') => {
  return `${DOMPurify.sanitize(html)
    // Replace newlines with spaces so lines still have spaces between them when they collapse
    .replace(/<br\s*[/]?>/gi, newLineReplacement)
    .replace(/<[^>]*>/g, '')
    .replace('&nbsp;', ' ')
    .trim()}`;
};
