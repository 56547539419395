// Libraries
import React from 'react';

// Supermove
import Styled from '@supermove/components/src/Styled';
import {TextInputRefType} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SearchBar from '@shared/design/components/SearchBar';

const Container = Styled.View`
  background-color: ${colors.white};
  padding: 16px;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const CommunicationsWidgetHeader = ({
  searchInput,
  handleChangeQuery,
  isLoading,
}: {
  searchInput?: TextInputRefType;
  handleChangeQuery: (text: string) => void;
  isLoading: boolean;
}) => {
  return (
    <React.Fragment>
      <Container>
        <SearchBar
          inputRef={searchInput?.ref}
          onChangeText={(text) => handleChangeQuery(text)}
          placeholder={'Search'}
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          defaultValue={''}
          isLoading={isLoading}
          isClearable
          isResponsive
        />
      </Container>
      <Line />
    </React.Fragment>
  );
};

export default CommunicationsWidgetHeader;
