// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useEffect,
  useModal,
  useNavigationDOM,
  useQuery,
  useResponsive,
  usePagination,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize, Phone, URL} from '@supermove/utils';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import ClientChatButton from 'modules/Client/Show/components/ClientChatButton';
import ClientProjectsList from 'modules/Client/Show/components/ClientProjectsList';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import ClientCreditCardsDrawer from 'modules/Client/components/ClientCreditCardsDrawer';
import InventoryItemsPanel from 'modules/Inventory/InventoryItemsPanel';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const ErrorContent = Styled.View`
  flex: 1;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const SectionLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const InfoSection = Styled.View`
  background-color: ${colors.white};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const InfoSectionContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const ClientNotFound = ({responsive}: any) => {
  return (
    <PageContentContainer>
      <ErrorContent>
        <Space height={42} />
        <Title responsive={responsive}>Client Not Found</Title>
      </ErrorContent>
    </PageContentContainer>
  );
};

const InformationField = ({label, value, empty, link}: any) => {
  return (
    <React.Fragment>
      <Space height={12} />
      <FieldValue value={value} label={label} empty={empty} link={link} />
    </React.Fragment>
  );
};

const PrimaryContactInfo = ({primaryContact, client, editClientModal, responsive}: any) => {
  return (
    <InfoSection>
      <InfoSectionContentContainer>
        <Space height={12} />
        <Row>
          <SectionLabel responsive={responsive}>Contact Information</SectionLabel>
          <Space style={{flex: 1}} />
          <ClientChatButton
            client={client}
            handleMissingPhoneNumber={editClientModal.handleOpen}
            noText
          />
        </Row>
        <Space height={12} />
      </InfoSectionContentContainer>
      <Line />
      <InfoSectionContentContainer>
        <InformationField
          label={'Contact Name'}
          value={_.get(primaryContact, 'fullName')}
          empty={'None'}
        />
        <InformationField
          label={'Phone Number'}
          value={Phone.display(_.get(primaryContact, 'phoneNumber'))}
          link={Phone.createUrl(_.get(primaryContact, 'phoneNumber'))}
          empty={'None'}
        />
        <InformationField label={'Email'} value={_.get(primaryContact, 'email')} empty={'None'} />
        <Space height={12} />
      </InfoSectionContentContainer>
    </InfoSection>
  );
};

const AdditionalInfo = ({client}: any) => {
  const {primaryLocation, billingLocation, referralSource} = client;
  return (
    <InfoSection>
      <InfoSectionContentContainer>
        <Space height={12} />
        <SectionLabel>Additional Info</SectionLabel>
        <Space height={12} />
      </InfoSectionContentContainer>
      <Line />
      <InfoSectionContentContainer>
        <InformationField
          label={'Client Address'}
          value={_.get(primaryLocation, 'address')}
          empty={'None'}
        />
        {billingLocation && (
          <InformationField
            label={'Billing Address'}
            value={billingLocation.address}
            empty={'None'}
          />
        )}
        <InformationField
          label={'Salesperson'}
          value={client.salesperson?.fullName}
          empty={'None'}
        />
        <InformationField
          label={'Coordinator'}
          value={client.coordinator?.fullName}
          empty={'None'}
        />
        <InformationField label={'Referral Source'} value={referralSource} empty={'None'} />
        <InformationField
          label={'Accounting Customer'}
          value={client.latestExternalIntegrationCustomer?.contactName}
          empty={'None'}
        />
        <Space height={12} />
      </InfoSectionContentContainer>
    </InfoSection>
  );
};

const ClientNotes = ({client}: any) => {
  return (
    <InfoSection>
      <InfoSectionContentContainer>
        <Space height={12} />
        <SectionLabel>Client Notes (Internal Only)</SectionLabel>
        <Space height={12} />
      </InfoSectionContentContainer>
      <Line />
      <InfoSectionContentContainer>
        <Space height={12} />
        <FieldValue value={client.notes} empty={'None'} />
        <Space height={12} />
      </InfoSectionContentContainer>
    </InfoSection>
  );
};

const ClientInformationColumn = ({client, editClientModal, responsive}: any) => {
  return (
    <Container style={{width: 240}}>
      <Space height={4} />
      <HeaderText responsive={responsive}>Client Information</HeaderText>
      <Space height={12} />
      <Container style={{zIndex: 0}}>
        <Space height={4} />
        <PrimaryContactInfo
          primaryContact={client.primaryContact}
          client={client}
          editClientModal={editClientModal}
          responsive={responsive}
        />
        <Space height={12} />
        <AdditionalInfo client={client} />
        <Space height={12} />
        <ClientNotes client={client} />
        <Space height={32} />
      </Container>
    </Container>
  );
};

const ClientProjects = ({client, refetch}: any) => {
  const {navigator, params} = useNavigationDOM();
  const pagination = usePagination({
    currentPage: _.toNumber(params.pagination.page), // The URL is the single source of truth for currentPage
    paginationMetadata: client.projectsPaginatedList.paginationMetadata,
    onChangePage: (page) => {
      navigator.push(
        URL.getUrlFromVariables(`/clients/${client.uuid}`, {
          pagination: {
            page,
            resultsPerPage: PaginationBar.DEFAULT_PAGINATION.resultsPerPage,
          },
        }),
      );
    },
  });

  const projectsCount = client.projectsPaginatedList.paginationMetadata.totalResults;

  return (
    <React.Fragment>
      <Row>
        {!!projectsCount && <HeaderText>{pluralize('Project', projectsCount, true)}</HeaderText>}
        <Space style={{flex: 1}} />
        <SecondaryButton
          onPress={() => navigator.push(`/projects/new?clientUuid=${client.uuid}`)}
          iconLeft={Icon.Plus}
          text={'Create Project'}
        />
      </Row>
      <Space height={12} />
      <ClientProjectsList
        clientName={client.name}
        projects={client.projectsPaginatedList.projects}
        refetch={refetch}
      />
      <Space height={40} />
      <PaginationBar pagination={pagination} />
      <Space height={40} />
    </React.Fragment>
  );
};

const ClientInventory = ({client}: any) => {
  return (
    <Container style={{flex: 1}}>
      <Row style={{maxWidth: 1200}}>
        <HeaderText>Inventory</HeaderText>
      </Row>
      <Space height={12} />
      <InventoryItemsPanel inventory={client.driverInventory} />
    </Container>
  );
};

const ShowClientPageContent = ({client, refetch, editClientModal, responsive}: any) => {
  return (
    <PageContentContainer>
      <Space width={24} />
      <ClientInformationColumn
        client={client}
        refetch={refetch}
        editClientModal={editClientModal}
        responsive={responsive}
      />
      <Space width={24} />
      <Container style={{flex: 1}}>
        <ClientProjects client={client} refetch={refetch} />
        {client.driverInventory && <ClientInventory client={client} />}
      </Container>
      <Space width={24} />
    </PageContentContainer>
  );
};

const ShowClientPage = () => {
  const {navigator, params} = useNavigationDOM();
  const responsive = useResponsive();

  const editClientModal = useModal({name: 'Edit Client Modal', enableTracking: true});
  const manageCreditCardsDrawer = useDrawer({
    name: 'Manage Credit Cards Drawer',
    enableTracking: true,
  });

  const {loading, data, refetch} = useQuery(ShowClientPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      clientUuid: params.clientUuid,
      pagination: params.pagination || PaginationBar.DEFAULT_PAGINATION,
    },
  });

  useEffect(() => {
    if (!params.pagination) {
      navigator.replace(
        URL.getUrlFromVariables(`/clients/${params.clientUuid}`, {
          pagination: PaginationBar.DEFAULT_PAGINATION,
        }),
      );
    }
  }, [params, navigator]);

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <SidebarPageV2 selected={'clients'}>
      <Container style={{flex: 1}}>
        <StandardOfficeHeader title={''}>
          <Row style={{flex: 1}}>
            <Container>
              <Breadcrumbs
                breadcrumbs={[
                  {name: 'Clients', onPress: () => navigator.push('/clients/customers')},
                  {
                    name: data.client.primaryContact.fullName,
                    onPress: () => navigator.push(`clients/${data.client.uuid}`),
                  },
                ]}
                mobileOptions={{showPreviousScreenOnly: true}}
              />
            </Container>
            <Space style={{flex: 1}} />
            <Container style={{zIndex: 1}}>
              {data.client.organization.features.isEnabledManageCreditCardsFromClient ? (
                <DropdownButton
                  text={'Edit Profile'}
                  iconLeft={Icon.Pen}
                  isWidthOfContainer
                  ButtonComponent={Button}
                  buttonStyle={{borderColor: colors.gray.border}}
                  actions={[
                    {
                      text: 'Edit client information',
                      onPress: editClientModal.handleOpen,
                    },
                    {
                      text: 'Manage credit cards',
                      onPress: manageCreditCardsDrawer.handleOpen,
                    },
                  ]}
                />
              ) : (
                <Button
                  text={'Edit Profile'}
                  iconLeft={Icon.Pen}
                  isWidthOfContainer
                  onPress={editClientModal.handleOpen}
                  style={{borderColor: colors.gray.border}}
                />
              )}
            </Container>
          </Row>
        </StandardOfficeHeader>
        <ScrollView
          horizontal
          contentContainerStyle={{
            flexGrow: 1,
            backgroundColor: colors.gray.background,
            paddingVertical: 20,
          }}
        >
          <ScrollView contentContainerStyle={{flexGrow: 1}}>
            {/* This handles a situation where our cx team mistakenly sent out links with invalid client uuids */}
            {data.client ? (
              <ShowClientPageContent
                client={data.client}
                refetch={refetch}
                editClientModal={editClientModal}
                responsive={responsive}
              />
            ) : (
              <ClientNotFound responsiv={responsive} />
            )}
          </ScrollView>
        </ScrollView>
      </Container>
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={data.client.uuid}
        refetch={refetch}
      />
      <ClientCreditCardsDrawer
        key={manageCreditCardsDrawer.key}
        isOpen={manageCreditCardsDrawer.isOpen}
        clientId={data.client.id}
        handleClose={manageCreditCardsDrawer.handleClose}
      />
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowClientPage.query = gql`
  ${ClientChatButton.fragment}
  ${ClientProjectsList.fragment}
  ${usePagination.fragment}
  ${InventoryItemsPanel.fragment}

  query ShowClientPage ($clientUuid: String!, $pagination: PaginationInput!, $jobIds: [Int]){
    ${gql.query}
    client(clientUuid: $clientUuid) {
      id
      uuid
      name
      referralSource
      notes
      organization {
        id
        features {
          isEnabledManageCreditCardsFromClient: isEnabled(feature: "MANAGE_CREDIT_CARDS_FROM_CLIENT")
        }
      }
      primaryContact {
        id
        fullName
        phoneNumber
        email
      }
      primaryLocation {
        id
        address
      }
      billingLocation {
        id
        address
      }
      salesperson {
        id
        fullName
      }
      coordinator {
        id
        fullName
      }
      projectsPaginatedList(
        pagination: $pagination,
      ) {
        projects: results {
          id
          ...ClientProjectsList
        }
        paginationMetadata {
          totalResults
          ...usePagination
        }
      }
      driverInventory {
        id
        ...InventoryItemsPanel
      }
      latestExternalIntegrationCustomer {
        id
        contactName
      }
      ...ClientChatButton
    }
  }
`;

export default ShowClientPage;
