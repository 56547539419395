// Libraries
import React from 'react';

// Supermove
import {MapView, MapType} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {RefType} from '@supermove/hooks';
import MapMarker from '@supermove/manager/src/modules/App/Map/components/MapMarker';
import SkeletonLoader from '@supermove/manager/src/modules/App/components/SkeletonLoader';
import {LocationModel, OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Location} from '@supermove/utils';

const SkeletonComponent = ({height}: any) => {
  return <SkeletonLoader height={height} />;
};

const LocationsMap = ({
  locations,
  organization,
  jobWarehouseLocation,
  mapRef,
  setLocationIdsWithStreetView,
  showRoute = false,
  hideLabel = false,
  onRouteUpdate,
}: {
  locations: LocationModel[];
  organization: OrganizationModel;
  jobWarehouseLocation?: LocationModel;
  mapRef: RefType<MapType>;
  setLocationIdsWithStreetView: (locationIdsWithStreetView: any) => void;
  showRoute?: boolean;
  hideLabel?: boolean;
  onRouteUpdate?: () => void;
}) => {
  const validLocations = locations.filter(
    (location: any) => location.latitude && location.longitude,
  );
  const locationPoints = validLocations.map((location: any) => Location.create(location));
  const organizationWarehousePosition =
    organization.warehouseLocation && Location.create(organization.warehouseLocation);

  const jobWarehousePosition = jobWarehouseLocation ? Location.create(jobWarehouseLocation) : null;

  const warehousePosition = organization.features.isEnabledCreateStorageMultipleWarehouse
    ? jobWarehousePosition
    : organizationWarehousePosition;

  return (
    <MapView
      mapTypeControl
      streetViewControl
      bounds={[...locationPoints, ...(warehousePosition ? [warehousePosition] : [])]}
      directions={showRoute ? locationPoints : undefined}
      onRouteUpdate={onRouteUpdate}
      style={{flex: 1, maxHeight: 400}}
      onReady={(mapProps, map) => {
        if (mapRef) {
          mapRef.current = map;
        }
      }}
    >
      {({route, ...props}) => {
        const streetViewService = new (props as any).google.maps.StreetViewService();
        const warehouseLocation = jobWarehouseLocation || organization.warehouseLocation;
        if (warehouseLocation) {
          const latLng = new (props as any).google.maps.LatLng(
            warehouseLocation.latitude,
            warehouseLocation.longitude,
          );
          streetViewService.getPanorama({location: latLng}, (data: any, status: any) => {
            if (status === 'OK') {
              setLocationIdsWithStreetView((locationIdsWithStreetView: any) => {
                if (locationIdsWithStreetView.includes(warehouseLocation.id)) {
                  return locationIdsWithStreetView;
                }
                return [...locationIdsWithStreetView, warehouseLocation.id];
              });
            } else {
              setLocationIdsWithStreetView((locationIdsWithStreetView: any) => {
                return locationIdsWithStreetView.filter((id: any) => id !== warehouseLocation.id);
              });
            }
          });
        }
        return (
          <React.Fragment>
            {warehousePosition && (
              <MapMarker kind={MapMarker.WAREHOUSE} position={warehousePosition} {...props} />
            )}
            {validLocations.map((location: any, index: any) => {
              const latLng = new (props as any).google.maps.LatLng(
                location.latitude,
                location.longitude,
              );
              streetViewService.getPanorama({location: latLng}, (data: any, status: any) => {
                if (status === 'OK') {
                  setLocationIdsWithStreetView((locationIdsWithStreetView: any) => {
                    if (locationIdsWithStreetView.includes(location.id)) {
                      return locationIdsWithStreetView;
                    }
                    return [...locationIdsWithStreetView, location.id];
                  });
                }
              });
              return (
                <React.Fragment key={location.id}>
                  <MapMarker
                    kind={MapMarker.DEFAULT}
                    label={hideLabel ? undefined : MapMarker.createMarkerLabel(index + 1)}
                    position={Location.create({
                      latitude: location.latitude,
                      longitude: location.longitude,
                    })}
                    {...props}
                  />
                  {showRoute && (
                    <MapView.Polyline
                      path={route}
                      strokeColor={colors.gray.primary}
                      strokeWeight={5}
                      {...props}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      }}
    </MapView>
  );
};

LocationsMap.SkeletonComponent = SkeletonComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationsMap.fragment = gql`
  fragment LocationsMap_Location on Location {
    id
    latitude
    longitude
  }
  fragment LocationsMap_Organization on Organization {
    id
    features {
      isEnabledCreateStorageMultipleWarehouse: isEnabled(
        feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
      )
    }
    warehouseLocation {
      latitude
      longitude
    }
  }
`;

export default LocationsMap;
