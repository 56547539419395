// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useResponsive,
  useQuery,
  useState,
  useKeyboardShortcutEffect,
} from '@supermove/hooks';
import {ProjectModel, ClientModel, InvoiceModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import SearchBar from '@shared/design/components/SearchBar';
import Tag from '@shared/design/components/Tag';
import GlobalSearchResultBuilder from 'modules/App/Global/components/GlobalSearchResultBuilder';
import GlobalSearchResultClient from 'modules/App/Global/components/GlobalSearchResultClient';
import GlobalSearchResultGroup, {
  GlobalSearchResultGroupSeparator,
} from 'modules/App/Global/components/GlobalSearchResultGroup';
import GlobalSearchResultInvoice from 'modules/App/Global/components/GlobalSearchResultInvoice';
import GlobalSearchResultProject from 'modules/App/Global/components/GlobalSearchResultProject';
import Line from 'modules/App/components/Line';
import useAppContext from 'modules/App/context/useAppContext';

const SearchResultsContainer = Styled.View`
  flex: 1;
  min-height: 512px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  padding-vertical: 8px;
  padding-horizontal: 16px;
  background-color: ${colors.gray.background}
`;

const FooterKeyboardShortcutContainer = Styled.View`
  flex-direction: row;
  align-items: baseline;
`;

const FooterKeyboardShortcutButton = Styled.ButtonV2`
  padding-horizontal: 4px;
  background-color: ${colors.white};
  border-color: ${colors.gray.border};
  border-width: 1px;
  border-radius: 2px;
`;

const FooterText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const FiltersContainer = Styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
  flex-direction: row;
`;

const EmptyQueryResultsContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const EmptyResultsText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const EmptyQueryResults = () => {
  const responsive = useResponsive();
  return (
    <EmptyQueryResultsContainer>
      <React.Fragment>
        <EmptyResultsText responsive={responsive}>
          {'Start typing to search across Supermove'}
        </EmptyResultsText>
      </React.Fragment>
    </EmptyQueryResultsContainer>
  );
};

const FilterTag = ({
  label,
  isSelected,
  showCount,
  count,
  onPress,
}: {
  label: string;
  isSelected: boolean;
  count: number;
  showCount: boolean;
  onPress: () => void;
}) => {
  const displayCount = GlobalSearchResultBuilder.getDisplayCount(count);
  const displayLabel = isSelected && showCount ? `${label} (${displayCount})` : label;

  return <Tag label={displayLabel} isSelected={isSelected} onPress={onPress} />;
};

const GlobalSearchContent = ({handleClose}: {handleClose: () => void}) => {
  // Hooks
  const responsive = useResponsive();
  const {viewer} = useAppContext();
  const [input, setInput] = useState('');
  const [search, setSearch] = useState(input);
  const [activeFilters, setActiveFilters] = useState({
    projects: true,
    clients: true,
    invoices: true,
  });

  useKeyboardShortcutEffect({
    handleKeyPress: (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
  });

  // Helpers
  const handleUpdateSearch = useDebouncedCallback((text) => setSearch(text), 300);

  const hasProjects = !!activeFilters.projects;
  const hasClients = !!activeFilters.clients;
  const hasInvoices = !!activeFilters.invoices;
  const hasSearchFilters = hasProjects || hasClients || hasInvoices;
  const hasSearchQuery = search.length >= 3 && hasSearchFilters;

  // Queries
  const {data: projectsData, loading: projectsLoading} = useQuery(
    GlobalSearchContent.projectsQuery,
    {
      fetchPolicy: 'network-only',
      skip: !hasSearchQuery || !viewer || !hasProjects,
      variables: {
        searchQuery: search,
      },
    },
  );
  const {data: clientsData, loading: clientsLoading} = useQuery(GlobalSearchContent.clientsQuery, {
    fetchPolicy: 'network-only',
    skip: !hasSearchQuery || !viewer || !hasClients,
    variables: {
      searchQuery: search,
    },
  });
  const {data: invoicesData, loading: invoicesLoading} = useQuery(
    GlobalSearchContent.invoicesQuery,
    {
      fetchPolicy: 'network-only',
      skip: !hasSearchQuery || !viewer || !hasInvoices,
      variables: {
        searchQuery: search,
      },
    },
  );

  // Helpers
  const loading = projectsLoading || clientsLoading || invoicesLoading;
  const showEmptyQuery = !hasSearchQuery && !loading;

  const projects =
    projectsData?.projects?.results?.map((result: {project: ProjectModel}) => result.project) || [];
  const clients =
    clientsData?.clients?.results?.map((result: {client: ClientModel}) => result.client) || [];
  const invoices =
    invoicesData?.invoices?.results?.map((result: {invoice: InvoiceModel}) => result.invoice) || [];

  return (
    <React.Fragment>
      <SearchBar
        autoFocus
        isResponsive
        isClearable
        size={SearchBar.SIZE.LARGE}
        placeholder={'Search across projects, clients, and invoices'}
        style={{flex: 1, borderWidth: 0}}
        iconColor={colors.blue.interactive}
        defaultValue={input}
        onChangeText={(text: string) => {
          setInput(text);
          handleUpdateSearch(text);
        }}
      />
      <Line />
      <FiltersContainer>
        <FilterTag
          label={'Projects'}
          isSelected={hasProjects}
          showCount={!!projectsData}
          count={projects.length}
          onPress={() => setActiveFilters({...activeFilters, projects: !activeFilters.projects})}
        />
        <Space width={8} />
        <FilterTag
          label={'Clients'}
          isSelected={hasClients}
          showCount={!!clientsData}
          count={clients.length}
          onPress={() => setActiveFilters({...activeFilters, clients: !activeFilters.clients})}
        />
        <Space width={8} />
        <FilterTag
          label={'Invoices'}
          isSelected={hasInvoices}
          showCount={!!invoicesData}
          count={invoices.length}
          onPress={() => setActiveFilters({...activeFilters, invoices: !activeFilters.invoices})}
        />
      </FiltersContainer>
      <Line />
      <SearchResultsContainer>
        <ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1}}>
          {showEmptyQuery && <EmptyQueryResults />}
          {hasSearchQuery && (
            <React.Fragment>
              {hasProjects && (
                <GlobalSearchResultGroup
                  key={`RESULTS_GROUP_PROJECTS_${search}`}
                  handleClose={handleClose}
                  resultsData={projects}
                  ResultComponent={GlobalSearchResultProject}
                  groupTitle={'Projects'}
                  keyExtractor={(project: ProjectModel) => `PROJECT_${project.uuid}`}
                  itemPropName={'project'}
                  query={search}
                  isLoading={projectsLoading}
                />
              )}

              {hasProjects && hasClients && <GlobalSearchResultGroupSeparator />}

              {hasClients && (
                <GlobalSearchResultGroup
                  key={`RESULTS_GROUP_CLIENTS_${search}`}
                  handleClose={handleClose}
                  resultsData={clients}
                  ResultComponent={GlobalSearchResultClient}
                  groupTitle={'Clients'}
                  keyExtractor={(client: ClientModel) => `Client_${client.uuid}`}
                  itemPropName={'client'}
                  query={search}
                  isLoading={clientsLoading}
                />
              )}

              {(hasProjects || hasClients) && hasInvoices && <GlobalSearchResultGroupSeparator />}

              {hasInvoices && (
                <GlobalSearchResultGroup
                  key={`RESULTS_GROUP_INVOICES_${search}`}
                  handleClose={handleClose}
                  resultsData={invoices}
                  ResultComponent={GlobalSearchResultInvoice}
                  groupTitle={'Invoices'}
                  keyExtractor={(invoice: InvoiceModel) => `Invoice_${invoice.uuid}`}
                  itemPropName={'invoice'}
                  query={search}
                  isLoading={invoicesLoading}
                />
              )}
            </React.Fragment>
          )}
        </ScrollView>
      </SearchResultsContainer>
      {responsive.desktop && (
        <React.Fragment>
          <Line />
          <FooterContainer>
            <FooterKeyboardShortcutContainer>
              <FooterKeyboardShortcutButton>
                <FooterText responsive={responsive}>{'esc'}</FooterText>
              </FooterKeyboardShortcutButton>
              <Space width={4} />
              <FooterText responsive={responsive}>{'to close'}</FooterText>
            </FooterKeyboardShortcutContainer>
          </FooterContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalSearchContent.query = gql`
  ${GlobalSearchResultProject.fragment}
  ${GlobalSearchResultClient.fragment}
  ${GlobalSearchResultInvoice.fragment}

  query GlobalSearchContent(
    $searchQuery: String!,
    $includeProjects: Boolean!,
    $includeClients: Boolean!,
    $includeInvoices: Boolean!
  ) {
    ${gql.query}
    projects: quickSearchProjects(query: $searchQuery, options: {}) @include(if: $includeProjects) {
      results {
        project {
          id
          uuid
        ...GlobalSearchResultProject
        }
      }
    }
    clients: quickSearchClients(query: $searchQuery, options: {}) @include(if: $includeClients) {
      results {
        client {
          id
          uuid
        ...GlobalSearchResultClient
        }
      }
    }
    invoices: quickSearchInvoices(query: $searchQuery, options: {}) @include(if: $includeInvoices) {
      results {
        invoice {
          id
          uuid
        ...GlobalSearchResultInvoice
        }
      }
    }
  }
`;

GlobalSearchContent.projectsQuery = gql`
  ${GlobalSearchResultProject.fragment}

  query GlobalSearchContent_Projects(
    $searchQuery: String!,
  ) {
    ${gql.query}
    projects: quickSearchProjects(query: $searchQuery, options: {limit: 50}) {
      results {
        project {
          id
          uuid
        ...GlobalSearchResultProject
        }
      }
    }
  }
`;
GlobalSearchContent.clientsQuery = gql`
  ${GlobalSearchResultClient.fragment}

  query GlobalSearchContent_Clients(
    $searchQuery: String!,
  ) {
    ${gql.query}
    clients: quickSearchClients(query: $searchQuery, options: {limit: 50}) {
      results {
        client {
          id
          uuid
        ...GlobalSearchResultClient
        }
      }
    }
  }
`;

GlobalSearchContent.invoicesQuery = gql`
  ${GlobalSearchResultInvoice.fragment}

  query GlobalSearchContent_Invoices(
    $searchQuery: String!,
  ) {
    ${gql.query}
    invoices: quickSearchInvoices(query: $searchQuery, options: {limit: 50}) {
      results {
        invoice {
          id
          uuid
        ...GlobalSearchResultInvoice
        }
      }
    }
  }
`;

export default GlobalSearchContent;
