// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import {CommunicationDirectionType} from '@shared/modules/Communication/enums/CommunicationDirection';

const MessageContainer = Styled.View`
  flex: 1;
  max-width: 592px;
  width: fit-content;
`;

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const BodyContainer = Styled.View`
  flex-direction: row;
  border-radius: 12px;
  padding: 16px;
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.primary};
`;

const TimeText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const CommunicationsListItemWrapper = ({
  direction,
  sentInfo,
  name,
  displayTime,
  backgroundColorOverride,
  children,
}: {
  direction: CommunicationDirectionType;
  sentInfo: React.ReactNode;
  name?: string;
  displayTime?: string;
  backgroundColorOverride?: string;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();

  const backgroundColor = direction === 'INBOUND' ? colors.gray.border : colors.blue.accentDark;
  const borderBottomRightRadius = direction === 'INBOUND' ? 12 : 0;
  const borderBottomLeftRadius = direction === 'INBOUND' ? 0 : 12;
  const flexDirection = direction === 'INBOUND' ? 'row' : 'row-reverse';
  const textAlign = direction === 'INBOUND' ? 'start' : 'end';

  return (
    <Column style={{flexDirection}}>
      <MessageContainer style={{alignSelf: direction === 'INBOUND' ? 'flex-start' : 'flex-end'}}>
        <Space height={8} />
        <BodyContainer
          style={{
            backgroundColor: backgroundColorOverride || backgroundColor,
            borderBottomRightRadius,
            borderBottomLeftRadius,
          }}
        >
          <Column style={{flex: 1}}>{children}</Column>
        </BodyContainer>
        <Space height={4} />
        <Row
          style={{
            justifyContent: 'space-between',
            flexDirection,
          }}
        >
          <Row style={{flexDirection, flex: 1}}>
            {sentInfo}
            <Space width={8} />
            {name ? (
              <NameText style={{textAlign}} responsive={responsive} numberOfLines={1}>
                {name}
              </NameText>
            ) : null}
          </Row>
          <Space width={4} />
          <TimeText style={{whiteSpace: 'nowrap'}} responsive={responsive}>
            {displayTime}
          </TimeText>
        </Row>
        <Space height={8} />
      </MessageContainer>
      <Space width={32} />
    </Column>
  );
};

export default CommunicationsListItemWrapper;
