// Libraries
import React from 'react';

// Supermove
import {Space, FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import BillStage from '@shared/modules/Billing/enums/BillStage';

const Container = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${(props) => (props as any).color}
`;

const Label = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const InvoiceSectionLabel = Styled.Text`
  ${Typography.Subheading}
`;

const Line = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const InvoiceSectionContainer = Styled.View`
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const InvoiceSectionTotalContainer = Styled.View`
  padding: 8px;
  background-color: ${colors.gray.background};
`;

const InvoiceItemContainer = Styled.View`
  flex-direction: row;
  justify-content: ${(props) => ((props as any).isPostSubtotal ? 'space-between' : 'flex-start')}
`;

const InvoiceListPreSubtotalItem = ({invoiceItem, index}: any) => {
  return (
    <InvoiceItemContainer>
      <Text style={{flex: 3, textAlign: 'left'}}>{`${invoiceItem.name}`}</Text>
      <Text
        color={colors.gray.secondary}
        style={{flex: 1, textAlign: 'right'}}
      >{`${invoiceItem.quantity}`}</Text>
      <Text
        color={colors.gray.secondary}
        style={{flex: 1, textAlign: 'right'}}
      >{`${Currency.display(invoiceItem.amount)}`}</Text>
      <Text style={{flex: 1, textAlign: 'right'}}>{`${Currency.display(invoiceItem.total)}`}</Text>
    </InvoiceItemContainer>
  );
};

const InvoiceListPostSubtotalItem = ({invoiceItem, subtotal, index}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <InvoiceItemContainer isPostSubtotal>
      <Text>{`${invoiceItem.name}`}</Text>
      <Text>{`${Currency.display(invoiceItem.total)}`}</Text>
    </InvoiceItemContainer>
  );
};

const InvoiceSectionItem = ({invoiceSection, index}: any) => {
  return (
    <InvoiceSectionContainer>
      <InvoiceSectionLabel>{`${invoiceSection.title}`}</InvoiceSectionLabel>
      <Space height={8} />
      <InvoiceItemContainer>
        <Label style={{flex: 3, textAlign: 'left'}}>Item</Label>
        <Label style={{flex: 1, textAlign: 'right'}}>Quantity</Label>
        <Label style={{flex: 1, textAlign: 'right'}}>Amount</Label>
        <Label style={{flex: 1, textAlign: 'right'}}>Total</Label>
      </InvoiceItemContainer>
      <Space height={8} />
      <Line />
      <Space height={8} />
      {/* Pre subtotal invoice items */}
      {invoiceSection.invoiceItems
        .filter((item: any) => item.invoiceItemStage === BillStage.PRE_SUBTOTAL)
        .map((invoiceItem: any, index: any) => {
          return (
            <React.Fragment key={`${index}_PRE_SUBTOTAL_INVOICE_ITEM`}>
              <InvoiceListPreSubtotalItem invoiceItem={invoiceItem} index={index} />
              <Space height={8} />
            </React.Fragment>
          );
        })}
      <Line />
      <Space height={8} />
      {/* Subtotal Section */}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <InvoiceItemContainer isPostSubtotal>
        <Text>Subtotal</Text>
        <Text>{`${Currency.display(invoiceSection.subtotal)}`}</Text>
      </InvoiceItemContainer>
      <Space height={8} />
      {/* Post subtotal section */}
      {invoiceSection.invoiceItems
        .filter((item: any) => item.invoiceItemStage === BillStage.POST_SUBTOTAL)
        .map((invoiceItem: any, index: any) => {
          return (
            <React.Fragment key={`${index}_POST_SUBTOTAL_INVOICE_ITEM`}>
              <InvoiceListPostSubtotalItem
                invoiceItem={invoiceItem}
                subtotal={invoiceSection.subtotal}
                index={index}
              />
              <Space height={8} />
            </React.Fragment>
          );
        })}
      <Space height={8} />
      {/* Invoice section total */}
      <InvoiceSectionTotalContainer>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <InvoiceItemContainer isPostSubtotal>
          <Text style={{fontWeight: 'bold'}}>Total</Text>
          <Text style={{fontWeight: 'bold'}}>{`${Currency.display(invoiceSection.total)}`}</Text>
        </InvoiceItemContainer>
      </InvoiceSectionTotalContainer>
    </InvoiceSectionContainer>
  );
};

const InvoiceSectionsContent = ({invoiceSections}: any) => {
  return (
    <Container>
      <FlatList
        listKey={'customer-invoice-sections'}
        data={invoiceSections}
        keyExtractor={(invoiceSection: any) => invoiceSection.id}
        renderItem={({item: invoiceSection, index}: any) => (
          <React.Fragment key={`${index}_INVOICE_SECTION`}>
            <InvoiceSectionItem invoiceSection={invoiceSection} index={index} />
          </React.Fragment>
        )}
        ItemSeparatorComponent={() => <Space height={8} />}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceSectionsContent.fragment = gql`
  fragment InvoiceSectionsContent on InvoiceSection {
    id
    title
    subtotal
    total
    invoiceItems {
      id
      name
      amount
      quantity
      percentage
      kind
      invoiceItemStage
      total
    }
  }
`;

export default InvoiceSectionsContent;
