// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import FormulaForm from '@shared/modules/Billing/forms/FormulaForm';
import useCreateFormulaMutation from '@shared/modules/Billing/hooks/useCreateFormulaMutation';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import FormulaPage from 'modules/Organization/Settings/Billing/Formulas/components/FormulaPage';

const CreateFormulaPageContent = ({
  organization,
  userId,
}: {
  organization: OrganizationModel;
  userId: string;
}) => {
  const {navigator} = useNavigationDOM();
  const handleNavigateToFormulas = () => navigator.push(`/settings/billing/formulas`);
  const formulaForm = FormulaForm.new({organizationId: organization.id, userId});
  const {form, handleSubmit, submitting} = useCreateFormulaMutation({
    formulaForm,
    onSuccess: () => handleNavigateToFormulas(),
    onError: (errors) => console.log({errors}),
  });
  return (
    <FormulaPage
      form={form}
      organization={organization}
      title={'Create Formula'}
      // TODO: submit validations!
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      handleClose={handleNavigateToFormulas}
    />
  );
};

const CreateFormulaPage = () => {
  const {loading, data} = useQuery(CreateFormulaPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const {viewer} = data;
          return (
            <CreateFormulaPageContent
              organization={viewer.viewingOrganization}
              userId={viewer.id}
            />
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateFormulaPage.query = gql`
  ${FormulaPage.fragment}
  query CreateFormulaPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...FormulaPage
      }
    }
  }
`;

export default CreateFormulaPage;
