/* eslint-disable no-unused-vars */

// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DocumentItemsList from '@shared/modules/Document/components/DocumentItemsList';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const PageContentRendererHeaderContainer = Styled.View`
  border-bottom-width: 1px;
  padding: 16px;
  border-color: ${colors.gray.border};
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = Styled.View`
  position: absolute;
  right: 10px;
`;

const DocumentTemplateHeaderContainer = Styled.View`
  width: 50%;
  align-items: center;
  justify-content: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1};
  text-align: center;
`;

const DocumentTemplatePreviewContentContainer = Styled.View`
  padding: 32px;
`;

const PageLoadingContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TemplateContainerMargin = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const TemplateContainer = Styled.View`
  padding: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.border};
  width: 800px;
  margin: 32px;
`;

const BlankTemplateContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const BlankDocumentTemplatePreviewContent = Styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content:center;
`;

const BlankDocumentTemplatePreviewContentText = Styled.Text`
  ${Typography.Heading6};
  color: ${colors.gray.tertiary};
`;

const DocumentPreviewRendererContainer = Styled.View`
  height: 100%;
`;

const DocumentTemplatePreviewContent = ({documentContentJson, documentTemplate}: any) => {
  const {isEnabledDocumentPerPositionTimesheetTotals, isEnabledDocumentPerPositionTimesheetTimes} =
    documentTemplate.organization.features;
  return (
    <DocumentTemplatePreviewContentContainer>
      <DocumentItemsList
        documentItems={documentContentJson.documentItems}
        isPreview
        isEnabledDocumentPerPositionTimesheetTotals={isEnabledDocumentPerPositionTimesheetTotals}
        isEnabledDocumentPerPositionTimesheetTimes={isEnabledDocumentPerPositionTimesheetTimes}
      />
    </DocumentTemplatePreviewContentContainer>
  );
};

const PageContentRendererContainerHeader = ({documentTemplate}: any) => {
  const {navigator} = useNavigationDOM();
  return (
    <PageContentRendererHeaderContainer>
      {documentTemplate ? (
        <React.Fragment>
          <DocumentTemplateHeaderContainer>
            <HeaderText>{documentTemplate.name}</HeaderText>
          </DocumentTemplateHeaderContainer>
          {!documentTemplate.organization.features.isEnabledSyncDocumentTemplatesOnOrgSync && (
            <ButtonContainer>
              <Button
                text={'Edit Template'}
                iconLeft={Icon.Pen}
                onPress={() => {
                  navigator.push(
                    `/settings/documents/document-templates/${documentTemplate.uuid}` +
                      `/version/${documentTemplate.draftTemplateVersion.uuid}/edit`,
                  );
                }}
              />
            </ButtonContainer>
          )}
        </React.Fragment>
      ) : (
        <HeaderText style={{color: colors.gray.tertiary}}>
          {'Select a document to preview'}
        </HeaderText>
      )}
    </PageContentRendererHeaderContainer>
  );
};

const OrganizationSettingsDocumentPreviewRendererContent = ({documentTemplateUuid}: any) => {
  const {loading, data} = useQuery(OrganizationSettingsDocumentPreviewRenderer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: documentTemplateUuid,
    },
  });

  return (
    <DocumentPreviewRendererContainer>
      {loading ? (
        <PageLoadingContainer>
          <PageLoadingIndicator />
        </PageLoadingContainer>
      ) : (
        <React.Fragment>
          <PageContentRendererContainerHeader documentTemplate={data.documentTemplate} />
          <TemplateContainerMargin>
            <ScrollView horizontal>
              <TemplateContainer>
                {data.documentTemplate.activeOrMostRecentDocumentTemplateVersion ? (
                  <ScrollView>
                    <DocumentTemplatePreviewContent
                      documentTemplate={data.documentTemplate}
                      documentContentJson={Json.toObject(
                        data.documentTemplate.activeOrMostRecentDocumentTemplateVersion
                          .documentContentJson,
                      )}
                    />
                  </ScrollView>
                ) : (
                  <BlankDocumentTemplatePreviewContent>
                    <BlankDocumentTemplatePreviewContentText>
                      {'This document has not been published'}
                    </BlankDocumentTemplatePreviewContentText>
                  </BlankDocumentTemplatePreviewContent>
                )}
              </TemplateContainer>
            </ScrollView>
          </TemplateContainerMargin>
        </React.Fragment>
      )}
    </DocumentPreviewRendererContainer>
  );
};

const OrganizationSettingsDocumentPreviewRenderer = ({documentTemplateUuid}: any) => {
  return (
    <React.Fragment>
      {documentTemplateUuid ? (
        <OrganizationSettingsDocumentPreviewRendererContent
          documentTemplateUuid={documentTemplateUuid}
        />
      ) : (
        <React.Fragment>
          <PageContentRendererContainerHeader />
          <BlankTemplateContainer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsDocumentPreviewRenderer.query = gql`
  query OrganizationSettingsDocumentPreviewRenderer($uuid: String!) {
    ${gql.query}
    documentTemplate(uuid: $uuid) {
      id
      name
      uuid
      updatedAt
      createdById
      activeOrMostRecentDocumentTemplateVersion {
        id
        documentContentJson
      }
      draftTemplateVersion {
        id
        uuid
      }
      organization {
        id
        features {
          isEnabledSyncDocumentTemplatesOnOrgSync: isEnabled(feature: "SYNC_DOCUMENT_TEMPLATES_ON_ORG_SYNC")
          isEnabledDocumentPerPositionTimesheetTotals: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TOTALS")
          isEnabledDocumentPerPositionTimesheetTimes: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TIMES")
        }
      }
    }
  }
`;

export default OrganizationSettingsDocumentPreviewRenderer;
