// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useResponsive} from '@supermove/hooks';
import {ReferralSourceModel, OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import Table, {ColumnDefinitionType} from '@shared/design/components/Table';
import EditReferralTypeModal from 'modules/Organization/Settings/Company/components/EditReferralTypeModal';
import RemoveReferralTypeModal from 'modules/Organization/Settings/Company/components/RemoveReferralTypeModal';

const TableHeaderText = Styled.Text`
    ${Typography.Label3}
`;

const Text = Styled.Text`
    ${Typography.Body3}
`;

const EditReferralButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const ReferralActions = ({organization, referralSources, referral, refetch}: any) => {
  const editReferralTypeModal = useModal({name: 'Edit Referral Type Modal'});
  const removeReferralTypeModal = useModal({name: 'Remove Referral Type Modal'});
  const referralActionPopover = usePopover();

  return (
    <React.Fragment>
      <EditReferralButton onPress={editReferralTypeModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditReferralButton>
      <Space width={12} />
      <Popover.Content innerRef={referralActionPopover.ref}>
        <MoreActionsButton onPress={referralActionPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </MoreActionsButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={referralActionPopover.isOpen}
        handleOpen={referralActionPopover.handleOpen}
        handleClose={referralActionPopover.handleClose}
        reference={referralActionPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={referralActionPopover.handleClose}
          actions={[{text: 'Remove', onPress: removeReferralTypeModal.handleOpen}]}
        />
      </Popover>
      <EditReferralTypeModal
        key={editReferralTypeModal.key}
        editReferralTypeModal={editReferralTypeModal}
        refetch={refetch}
        referral={referral}
        referralSources={referralSources}
        organizationId={organization.id}
      />
      <RemoveReferralTypeModal
        key={removeReferralTypeModal.key}
        removeReferralTypeModal={removeReferralTypeModal}
        refetch={refetch}
        referral={referral}
        referralSources={referralSources}
        organizationId={organization.id}
      />
    </React.Fragment>
  );
};

const getReferralTableColumnDefinitions = ({
  organization,
  referralSources,
  refetch,
}: {
  organization: OrganizationModel;
  referralSources: ReferralSourceModel[];
  refetch: () => void;
}): ColumnDefinitionType<ReferralSourceModel>[] => [
  {
    width: 300,
    headerLabel: 'Name',
    cellText: (referral) => referral.name,
  },
  {
    width: 100,
    headerLabel: 'Actions',
    actions: (referral) => [
      {
        text: 'Edit referral source',
        desktopIcon: Icon.Pen,
        onPress: ({handleOpen}) => handleOpen?.(),
        actionHook: {
          hook: useModal,
          hookArgument: {name: 'Edit Referral Source Modal'},
          renderComponent: ({hookKey, isOpen, handleClose}) => {
            return (
              <EditReferralTypeModal
                key={hookKey}
                editReferralTypeModal={{isOpen, handleClose}}
                refetch={refetch}
                referral={referral}
                referralSources={referralSources}
                organizationId={organization.id}
              />
            );
          },
        },
      },
      {
        text: 'Remove referral source',
        onPress: ({handleOpen}) => handleOpen?.(),
        actionHook: {
          hook: useModal,
          hookArgument: {name: 'Delete Referral Source Modal'},
          renderComponent: ({hookKey, isOpen, handleClose}) => {
            return (
              <RemoveReferralTypeModal
                key={hookKey}
                removeReferralTypeModal={{isOpen, handleClose}}
                refetch={refetch}
                referral={referral}
                referralSources={referralSources}
                organizationId={organization.id}
              />
            );
          },
        },
      },
    ],
  },
];

const ReferralSourcesTable = ({
  organization,
  referralSources,
  refetch,
}: {
  organization: OrganizationModel;
  referralSources: ReferralSourceModel[];
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  return (
    <Table
      columnDefinitions={getReferralTableColumnDefinitions({
        organization,
        referralSources,
        refetch,
      })}
      emptyStateText='No referrals to display'
      items={referralSources}
      itemKey={'name'}
      containerStyle={{width: responsive.desktop ? 400 : undefined}}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReferralSourcesTable.fragment = gql`
  ${EditReferralTypeModal.fragment}
  ${RemoveReferralTypeModal.fragment}
  fragment ReferralSourcesTable on Organization {
    id
    settings {
      id
      referralSources {
        name
      }
    }
    ...EditReferralTypeModal
    ...RemoveReferralTypeModal
  }
`;

export default ReferralSourcesTable;
