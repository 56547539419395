// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, ResponsiveType} from '@supermove/hooks';
import {BillItemModel, BillModel, BillRule, BillRuleModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';

const LabelContainer = Styled.View`
  flex: 1;
`;

const CaptionText = Styled.H8`
  ${Typography.Responsive.Micro}
`;

const BillRuleContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({responsive}) => (responsive.mobile ? colors.blue.accent : colors.gray.background)};
  border-radius: 4px;
  padding: 8px;
`;

const BillRuleMainText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 16px;
  height: 18px;
  align-items: center;
  justify-content: center;
`;

const getBillRules = ({bill, isEditable}: {bill: BillModel; isEditable: boolean}) => {
  // If the bill is editable, then it is not a customer facing bill.
  // Only moving company facing bills are editable, so these bills
  // should not hide bill rules based on isVisibleToCustomer.
  if (isEditable) {
    return bill.billRules;
  }
  return _.filter(bill.billRules, 'isVisibleToCustomer');
};

const ConditionalBillRuleValue = ({conditionalBillItem}: {conditionalBillItem: BillItemModel}) => {
  const responsive = useResponsive();
  return (
    <Row>
      <BillRuleMainText responsive={responsive} style={{color: colors.gray.secondary}}>
        {conditionalBillItem.isDeleted ? 'N/A' : 'Added to Bill'}
      </BillRuleMainText>
      <Space width={8} />
      <TextTooltip
        isEnabledMobileToast={false}
        text={
          conditionalBillItem.isDeleted
            ? `“${conditionalBillItem.name}” bill item will be added to the bill when it has a specific value.`
            : `“${conditionalBillItem.name}” bill item has been added to the bill.`
        }
      >
        <IconContainer>
          <Icon source={Icon.Calculator} color={colors.gray.secondary} size={12} />
        </IconContainer>
      </TextTooltip>
    </Row>
  );
};

const BillBillRule = ({
  billRule,
  isEditable,
  conditionalBillItem,
  isEnabledConditionalBillItems,
}: {
  billRule: BillRuleModel;
  isEditable: boolean;
  conditionalBillItem: BillItemModel;
  isEnabledConditionalBillItems: boolean;
}) => {
  const responsive = useResponsive();
  const isConditionalBillRule = billRule.kind === BillRuleKind.CONDITIONAL_BILL_ITEM;
  const displayValue = BillRule.getDisplayValues(billRule);
  const isMobileLongDisplayValue = !responsive.desktop && displayValue.length > 5;
  const showConditionalBillRuleValue =
    isEnabledConditionalBillItems && isConditionalBillRule && isEditable;

  return (
    <BillRuleContainer responsive={responsive}>
      <LabelContainer>
        <BillRuleMainText responsive={responsive}>{billRule.name}</BillRuleMainText>
        <CaptionText responsive={responsive}>{billRule.description}</CaptionText>
        {!showConditionalBillRuleValue && isMobileLongDisplayValue && (
          <CaptionText responsive={responsive}>{displayValue}</CaptionText>
        )}
      </LabelContainer>
      {showConditionalBillRuleValue ? (
        <ConditionalBillRuleValue conditionalBillItem={conditionalBillItem} />
      ) : (
        !isMobileLongDisplayValue && (
          <BillRuleMainText responsive={responsive}>{displayValue}</BillRuleMainText>
        )
      )}
    </BillRuleContainer>
  );
};

const BillsListItemBillRulesList = ({
  bill,
  isEditable,
  isEnabledConditionalBillItems = false,
}: {
  bill: BillModel;
  isEditable: boolean;
  isEnabledConditionalBillItems?: boolean;
}) => {
  const billRules = getBillRules({bill, isEditable});
  // Pre and post subtotal bill items are used by other components so we use those properties here
  // instead of bill.billItems to prevent double fetching.
  const billItems = [
    ...bill.billItemsPreSubtotal,
    ...bill.billItemsPostSubtotal,
    ...bill.deletedBillItems,
  ];
  return (
    <React.Fragment>
      <Space height={8} />
      {billRules.map((billRule: any, index: any) => {
        const conditionalBillItem =
          billRule.billItemId &&
          _.find(
            billItems,
            (billItem) => _.toString(billItem.id) === _.toString(billRule.billItemId),
          );
        return (
          <React.Fragment key={index}>
            {index > 0 && <Space height={8} />}
            <BillBillRule
              billRule={billRule}
              isEditable={isEditable}
              conditionalBillItem={conditionalBillItem}
              isEnabledConditionalBillItems={isEnabledConditionalBillItems}
            />
          </React.Fragment>
        );
      })}
      {!isEditable && <Space height={16} />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

BillsListItemBillRulesList.fragment = gql`
  ${BillRule.getDisplayValues.fragment}

  fragment BillsListItemBillRulesList on Bill {
    id
    billRules {
      name
      description
      values
      isVisibleToCustomer
      kind
      billItemId
      ...BillRule_getDisplayValues
    }
    billItemsPreSubtotal {
      id
      name
      isDeleted
    }
    billItemsPostSubtotal {
      id
      name
      isDeleted
    }
    deletedBillItems {
      id
      name
      isDeleted
    }
  }
`;

export default BillsListItemBillRulesList;
