// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {FormulaModel} from '@supermove/models';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import FormulaForm from '@shared/modules/Billing/forms/FormulaForm';
import useDeleteFormulaMutation from '@shared/modules/Billing/hooks/useDeleteFormulaMutation';

const LoadingModal = () => (
  <SmallModal isOpen>
    <Space height={24} />
    <PageLoadingIndicator />
    <Space height={24} />
  </SmallModal>
);

const DeleteFormulaModalContent = ({
  formula,
  handleClose,
  isOpen,
  userId,
  refetch,
}: {
  formula: FormulaModel;
  handleClose: () => void;
  isOpen: boolean;
  userId: string;
  refetch: () => void;
}) => {
  const formulaForm = FormulaForm.edit(formula, {userId});
  const {handleSubmit, submitting} = useDeleteFormulaMutation({
    formulaForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Are you sure you want to delete "${formula.name}"?`}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

const DeleteFormulaModal = ({
  formulaUuid,
  isOpen,
  handleClose,
  refetch,
  userId,
}: {
  formulaUuid: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  userId: string;
}) => {
  const {loading, data} = useQuery(DeleteFormulaModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {formulaUuid},
    skip: !isOpen,
  });
  if (!isOpen) return null;

  return (
    <Loading loading={loading} as={LoadingModal}>
      {() => (
        <DeleteFormulaModalContent
          formula={data.formula}
          handleClose={handleClose}
          isOpen={isOpen}
          userId={userId}
          refetch={refetch}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteFormulaModal.query = gql`
  ${FormulaForm.edit.fragment}
  query DeleteFormulaModal ($formulaUuid: String!) {
    ${gql.query}
    formula: formulaByUuid(uuid: $formulaUuid) {
      id
      name
      ...FormulaForm_edit
    }
  }
`;

export default DeleteFormulaModal;
