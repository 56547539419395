// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useDebouncedCallback, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {URL, pluralize} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CapacityProjectListFilters from 'modules/Calendar/Capacity/components/CapacityProjectListFilters';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const Subheading = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ToolbarHeader = Styled.View<{isDayView: boolean}>`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  padding-top: ${({isDayView}) => (isDayView ? '20px' : '0px')};
`;

const ExpandedDispatchViewButton = Styled.ButtonV2<{isSelected: boolean; height: number}>`
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.border)};
  background-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.white)};
  padding-horizontal: 12px;
  height: ${({height}) => height}px;
  width: 50px;
`;

const TABS = [
  {
    label: 'Month',
    url: '/moves/capacity/calendar/month',
  },
  {
    label: 'Day',
    url: '/moves/capacity/calendar/day',
  },
];

const getFilteredUrlFromParams = (params: any, baseUrl: any) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}: any) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const handleClearProjectsListFilters = ({
  baseUrl,
  navigator,
  params,
  isRestricted,
  viewerId,
}: any) => {
  navigator.replace(
    getFilteredUrlFromParams(
      {
        ...params,
        projectTypes: null,
        salespersons: isRestricted ? [viewerId] : null,
        projectTagIds: null,
        jobTagIds: null,
      },
      baseUrl,
    ),
  );
};

const SearchInput = () => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const handleUpdateQuery = useDebouncedCallback((text) => {
    handleUpdateParam({
      baseUrl: location.pathname,
      navigator,
      params,
      paramKey: 'searchQuery',
      paramValue: text,
    });
  }, 500);
  return (
    <SearchBar
      onChangeText={handleUpdateQuery}
      placeholder={responsive.desktop ? 'Search by customer, locations, or job type' : 'Search'}
      style={responsive.desktop ? {width: '400px'} : {width: '100%'}}
      defaultValue={params.query}
      isResponsive
      containerStyle={responsive.desktop ? {} : {flex: 1}}
    />
  );
};

const MonthViewCalendarControls = ({responsive, onNavigate, label}: any) => {
  return (
    <React.Fragment>
      <Space width={8} />
      <SecondaryButton onPress={() => onNavigate('TODAY')} text={'Today'} />
      <Space width={16} />
      <SecondaryButton onPress={() => onNavigate('PREV')}>
        <Icon source={Icon.ChevronLeft} color={colors.blue.interactive} size={16} />
      </SecondaryButton>
      <Space width={8} />
      <SecondaryButton onPress={() => onNavigate('NEXT')}>
        <Icon source={Icon.ChevronRight} color={colors.blue.interactive} size={16} />
      </SecondaryButton>
      <Space width={16} />
      <Subheading responsive={responsive}>{label}</Subheading>
    </React.Fragment>
  );
};

const DayViewControls = ({
  responsive,
  totalJobsForDay,
  handleUpdateParam,
  organization,
  handleClearProjectsListFilters,
}: any) => {
  return (
    <React.Fragment>
      {totalJobsForDay === null ? (
        <SkeletonLoader width={50} height={SkeletonLoader.HEIGHT.SubheadingText} />
      ) : (
        <Subheading responsive={responsive}>{pluralize('Job', totalJobsForDay, true)}</Subheading>
      )}
      <Space width={16} />
      <SearchInput />
      <Space width={16} />
      <CapacityProjectListFilters
        handleUpdateParam={handleUpdateParam}
        organization={organization}
        handleClearProjectsListFilters={handleClearProjectsListFilters}
      />
    </React.Fragment>
  );
};

const DispatchButton = ({isJobsListTableExpanded, setIsJobsListTableExpanded, responsive}: any) => {
  return (
    <React.Fragment>
      <ExpandedDispatchViewButton
        height={responsive.desktop ? 36 : 48}
        isSelected={isJobsListTableExpanded}
        onPress={() => setIsJobsListTableExpanded(!isJobsListTableExpanded)}
      >
        <Icon
          source={Icon.Truck}
          size={16}
          color={isJobsListTableExpanded ? colors.white : colors.gray.tertiary}
        />
      </ExpandedDispatchViewButton>
      <Space width={8} />
    </React.Fragment>
  );
};

const CalendarViewTabs = ({tabs, currentTabIndex, navigator, params}: any) => {
  return (
    <Tabs<{url: string}>
      tabs={tabs}
      handlePressTab={(tab) => navigator.push(URL.getUrlFromVariables(tab.url, params))}
      activeTabIndex={currentTabIndex}
      isEnclosed
    />
  );
};

const DesktopHeader = ({
  isDayView,
  totalJobsForDay,
  handleUpdateParam,
  organization,
  handleClearProjectsListFilters,
  isJobsListTableExpanded,
  setIsJobsListTableExpanded,
  onNavigate,
  label,
  organizations,
  currentTabIndex,
  canViewOtherBranchesData,
}: any) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  return (
    <ToolbarHeader isDayView={isDayView}>
      <Row>
        {isDayView ? (
          <DayViewControls
            responsive={responsive}
            totalJobsForDay={totalJobsForDay}
            handleUpdateParam={handleUpdateParam}
            organization={organization}
            handleClearProjectsListFilters={handleClearProjectsListFilters}
          />
        ) : (
          <MonthViewCalendarControls
            responsive={responsive}
            onNavigate={onNavigate}
            label={label}
          />
        )}
      </Row>
      <Space flex={1} style={{minWidth: 16}} />
      <Row>
        {isDayView && responsive.desktop && (
          <DispatchButton
            responsive={responsive}
            isJobsListTableExpanded={isJobsListTableExpanded}
            setIsJobsListTableExpanded={setIsJobsListTableExpanded}
          />
        )}
        <CalendarViewTabs
          tabs={TABS}
          currentTabIndex={currentTabIndex}
          navigator={navigator}
          params={params}
        />
        <Space width={8} />
        {canViewOtherBranchesData && (
          <MultiBranchOrganizationField
            value={params.slugs || []}
            organizations={organizations}
            onChangeValue={(newSlugs: any) => {
              handleUpdateParam({
                baseUrl: location.pathname,
                navigator,
                params,
                paramKey: 'slugs',
                paramValue: newSlugs,
              });
            }}
          />
        )}
      </Row>
    </ToolbarHeader>
  );
};

const CapacityCalendarToolbarHeader = ({
  onNavigate,
  label,
  isDayView,
  organization,
  canViewOtherBranchesData,
  isJobsListTableExpanded,
  totalJobsForDay,
  setIsJobsListTableExpanded,
}: any) => {
  const {navigator} = useNavigationDOM();
  const organizations = _.get(organization, 'company.organizations', []);
  const currentTabIndex = _.findIndex(TABS, (tab) => navigator.location.pathname.includes(tab.url));

  return (
    <DesktopHeader
      isDayView={isDayView}
      totalJobsForDay={totalJobsForDay}
      handleUpdateParam={handleUpdateParam}
      organization={organization}
      handleClearProjectsListFilters={handleClearProjectsListFilters}
      isJobsListTableExpanded={isJobsListTableExpanded}
      setIsJobsListTableExpanded={setIsJobsListTableExpanded}
      onNavigate={onNavigate}
      label={label}
      organizations={organizations}
      currentTabIndex={currentTabIndex}
      canViewOtherBranchesData={canViewOtherBranchesData}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarToolbarHeader.fragment = gql`
  ${CapacityProjectListFilters.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment CapacityCalendarToolbarHeader on Organization {
    id
    isPrimary
    hasMultipleOrganizations
    ...CapacityProjectListFilters
    ...MultiBranchOrganizationField
  }
`;

export default CapacityCalendarToolbarHeader;
