// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useModal,
  useResponsive,
  usePagination,
  useState,
  useDrawer,
} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/TableV2Deprecated';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import useSyncIntegrationPaymentMethodsForCodatIntegrationMutation from '@shared/modules/Integration/hooks/useSyncIntegrationPaymentMethodsForCodatIntegrationMutation';
import EditPaymentMethodDrawer from 'modules/Organization/Settings/Company/components/EditPaymentMethodDrawer';

const TableContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View<{index: number; isMobile: boolean}>`
  justify-content: space-between;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  z-index: ${({index}) => 100 - index};
`;

const TitleRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.View`
`;

const FlexContainer = Styled.View`
  flex: 1;
`;

const RightPageContainer = Styled.View`
  flex: 1;
  padding-horizontal: 24px;
  background-color: ${colors.gray.background}
`;

const Column = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MicroGrayText = Styled.Text`
  padding-top: 10px;
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const EditPaymentMethodLinkButton = ({paymentMethod, refetch, responsive}: any) => {
  const editPaymentMethodDrawer = useDrawer({
    name: 'Edit Payment Method Drawer',
  });
  return (
    <React.Fragment>
      <LinkButton onPress={editPaymentMethodDrawer.handleOpen}>
        <LinkText responsive={responsive}>{paymentMethod.name}</LinkText>
      </LinkButton>
      <EditPaymentMethodDrawer
        key={editPaymentMethodDrawer.key}
        isOpen={editPaymentMethodDrawer.isOpen}
        handleClose={editPaymentMethodDrawer.handleClose}
        paymentMethodId={paymentMethod.id}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const getExternalIntegrationPaymentMethodsColumnDefinitions = ({refetch, responsive}: any) => [
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Name</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return <Table.CellText>{item.name}</Table.CellText>;
    },
  },
  {
    flex: 3,
    headerContent: () => <Table.HeaderText>Payment Methods</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return (
        <Table.CellText>
          {item.paymentMethods.map((paymentMethod: any, index: any) => (
            <React.Fragment key={index}>
              <EditPaymentMethodLinkButton
                paymentMethod={paymentMethod}
                refetch={refetch}
                responsive={responsive}
              />
              {index !== item.paymentMethods.length - 1 && <Table.CellText>{', '}</Table.CellText>}
            </React.Fragment>
          ))}
        </Table.CellText>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Last Imported</Table.HeaderText>,
    cellContent: ({item}: any) => {
      return (
        <Table.CellText>
          {item.lastSyncedAt ? Datetime.convertToDisplayDatetime(item.lastSyncedAt) : ''}
        </Table.CellText>
      );
    },
  },
];

const AccountingExternalPaymentMethodsContent = ({
  organization,
  refetch,
  loading,
  pagination,
  viewer,
}: any) => {
  const responsive = useResponsive();
  const {codatIntegrationByUuid} = organization;
  const syncIntegrationPaymentMethodsModal = useModal({
    name: 'Sync Integration Payment Methods Modal',
  });

  const {handleSubmit, submitting} = useSyncIntegrationPaymentMethodsForCodatIntegrationMutation({
    codatIntegrationForm: CodatIntegrationForm.edit(codatIntegrationByUuid),
    onSuccess: () => {
      syncIntegrationPaymentMethodsModal.handleClose();
      refetch();
    },
    onError: (errors: any) => console.log(errors),
  });

  return (
    <RightPageContainer>
      <Space height={24} />
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '100%'}}>
        <Column>
          <TitleRow>
            <Title responsive={responsive}>Accounting Payment Methods</Title>
            <Space width={16} />
            <Badge
              label={organization.codatIntegrationByUuid.sourceKindDisplayName}
              color={colors.gray.secondary}
              size={Badge.SIZE.LARGE}
            />
          </TitleRow>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export payment methods from your accounting integration`}
          </Description>
        </Column>
        {responsive.mobile && <Space height={12} />}
        <ButtonContainer>
          {codatIntegrationByUuid.codatLastSync && (
            <React.Fragment>
              <Space height={12} />
              <MicroGrayText
                responsive={responsive}
              >{`Last Imported: ${Datetime.convertToDisplayDatetime(
                codatIntegrationByUuid.codatLastSync,
              )}`}</MicroGrayText>
            </React.Fragment>
          )}
          <Space width={16} />
          <SecondaryButton
            text={'Import'}
            onPress={syncIntegrationPaymentMethodsModal.handleOpen}
            iconLeft={Icon.Sync}
          />
        </ButtonContainer>
      </Row>
      <Space height={24} />
      <TableContainer>
        <Table.FixedHeaderScroll
          columnDefinitions={getExternalIntegrationPaymentMethodsColumnDefinitions({
            refetch,
            responsive,
          })}
          emptyStateText='No payment methods to display'
          items={
            codatIntegrationByUuid.externalIntegrationPaymentMethodsPaginatedList
              .externalIntegrationPaymentMethods
          }
          containerStyle={{overflow: 'visible'}}
          scrollViewStyle={{paddingBottom: 24}}
          style={{flex: 1}}
          loading={loading}
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <Space height={24} />
      <Container>
        <PaginationBar pagination={pagination} />
      </Container>
      <Space height={24} />
      <ConfirmationModal
        icon={Icon.Sync}
        title={'Import Payment Methods?'}
        subtitle={'Please allow 5-10 minutes for this to complete.'}
        isOpen={syncIntegrationPaymentMethodsModal.isOpen}
        key={syncIntegrationPaymentMethodsModal.key}
        handleSecondaryAction={syncIntegrationPaymentMethodsModal.handleClose}
        handlePressOutside={syncIntegrationPaymentMethodsModal.handleClose}
        secondaryActionText={'Cancel'}
        primaryActionText={'Confirm'}
        handlePrimaryAction={handleSubmit}
        isSubmitting={submitting}
      />
    </RightPageContainer>
  );
};

const AccountingExternalPaymentMethodsSettingsLoading = ({viewer, refetch}: any) => {
  const responsive = useResponsive();

  return (
    <RightPageContainer>
      <Space height={24} />
      <Row index={0} isMobile={responsive.mobile} style={{maxWidth: '1280px'}}>
        <Column>
          <Title responsive={responsive}>Accounting Payment Methods</Title>
          <Space height={12} />
          <Description responsive={responsive}>
            {`View, import, and export payment methods from your accounting integration`}
          </Description>
        </Column>
      </Row>
      <Space height={24} />
      <TableContainer>
        <Table
          columnDefinitions={getExternalIntegrationPaymentMethodsColumnDefinitions({
            refetch,
            responsive,
          })}
          emptyStateText='No payment methods to display'
          // @ts-expect-error TS(2322): Type '{ columnDefinitions: { flex: number; headerC... Remove this comment to see the full error message
          scrollViewStyle={{paddingBottom: 24}}
          style={{flex: 1}}
          items={[]}
          loading
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <Space height={24} />
    </RightPageContainer>
  );
};

const AccountingExternalPaymentMethods = () => {
  const {params} = useNavigationDOM();
  const {uuid} = params;
  const [currentPage, setCurrentPage] = useState(1);
  const {loading, data, refetch} = useQuery(AccountingExternalPaymentMethods.query, {
    // 'network-only' is used to avoid using empty cached values
    fetchPolicy: 'network-only',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 20,
      },
      uuid,
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(
      data,
      'viewer.viewingOrganization.codatIntegrationByUuid.externalIntegrationPaymentMethodsPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  return (
    <FlexContainer>
      {loading ? (
        <AccountingExternalPaymentMethodsSettingsLoading />
      ) : (
        <AccountingExternalPaymentMethodsContent
          organization={data.viewer.viewingOrganization}
          refetch={refetch}
          pagination={pagination}
          viewer={data.viewer}
          loading={loading}
        />
      )}
    </FlexContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingExternalPaymentMethods.query = gql`
  ${CodatIntegrationForm.edit.fragment}
  ${usePagination.fragment}

  query AccountingExternalPaymentMethods(
    $pagination: PaginationInput!
    $uuid: String!
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        apiToken
        codatIntegrationByUuid(uuid: $uuid){
          id
          codatLastSync
          sourceKindDisplayName
          externalIntegrationPaymentMethodsPaginatedList(
            pagination: $pagination
          ) {
            externalIntegrationPaymentMethods: results {
              id
              name
              paymentMethods {
                id
                name
              }
            }
            paginationMetadata {
              ...usePagination
            }
          }
      ...CodatIntegrationForm_edit
        }
      }
    }
  }
`;

export default AccountingExternalPaymentMethods;
