/* eslint-disable no-promise-executor-return */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useQuery,
  useResponsive,
  useFlatList,
  UrlFiltersType,
} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import ProjectBlockKind, {
  ProjectBlockKindType,
} from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import ProjectJobBlock from 'modules/Project/V2/Show/Blocks/Job/ProjectJobBlock';
import ProjectAccountingSummaryBlock from 'modules/Project/V2/Show/Blocks/ProjectAccountingSummaryBlock';
import ProjectBillingBlock from 'modules/Project/V2/Show/Blocks/ProjectBillingBlock';
import ProjectClaimsBlock from 'modules/Project/V2/Show/Blocks/ProjectClaimsBlock';
import ProjectClientsBlock from 'modules/Project/V2/Show/Blocks/ProjectClientsBlock';
import ProjectCostAndCompensationBlock from 'modules/Project/V2/Show/Blocks/ProjectCostAndCompensationBlock';
import ProjectInternalBlock from 'modules/Project/V2/Show/Blocks/ProjectInternalBlock';
import ProjectInventoryItemsBlock from 'modules/Project/V2/Show/Blocks/ProjectInventoryItemsBlock';
import ProjectInventorySummaryBlock from 'modules/Project/V2/Show/Blocks/ProjectInventorySummaryBlock';
import ProjectInvoiceBlock from 'modules/Project/V2/Show/Blocks/ProjectInvoiceBlock';
import ProjectJobsBlock from 'modules/Project/V2/Show/Blocks/ProjectJobsBlock';
import ProjectLocationsBlock from 'modules/Project/V2/Show/Blocks/ProjectLocationsBlock';
import ProjectOverviewBlock from 'modules/Project/V2/Show/Blocks/ProjectOverviewBlock';
import ProjectProposalsBlock from 'modules/Project/V2/Show/Blocks/ProjectProposalsBlock';
import ProjectSurveyBlock from 'modules/Project/V2/Show/Blocks/ProjectSurveyBlock';
import ProjectValuationCoverageBlock from 'modules/Project/V2/Show/Blocks/ProjectValuationCoverageBlock';
import useProjectBlockAutoScroll from 'modules/Project/V2/Show/hooks/useProjectBlockAutoScroll';
import useProjectPageContext from 'modules/Project/V2/Show/hooks/useProjectPageContext';

type ProjectNavigationSectionType = {
  name: string;
  blocks: ProjectBlockKindType[];
};
type ProjectNavigationSectionsType = ProjectNavigationSectionType[];

const BLOCK_PADDING = 24;

const BlockContainer = Styled.View`
`;

const JobsEmptyStateContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
  padding: 24px;
`;

const ProjectSectionBlock = ({
  kind,
  index,
  project,
  urlFilters,
  pageRefetch,
  refetchAndReset,
}: {
  kind: ProjectBlockKindType;
  index: number;
  project: ProjectModel;
  urlFilters: UrlFiltersType;
  pageRefetch: () => void;
  refetchAndReset: () => void;
}) => {
  switch (kind) {
    case ProjectBlockKind.PROJECT_OVERVIEW:
      return (
        <ProjectOverviewBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
          refetch={pageRefetch}
          refetchAndReset={refetchAndReset}
        />
      );
    case ProjectBlockKind.ACCOUNTING_SUMMARY:
      return (
        <ProjectAccountingSummaryBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.BILLING:
      return (
        <ProjectBillingBlock
          key={project.totalRevenue}
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
          pageRefetch={pageRefetch}
          refetchAndReset={refetchAndReset}
        />
      );
    case ProjectBlockKind.CLAIMS:
      return (
        <ProjectClaimsBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.CLIENTS:
      return (
        <ProjectClientsBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.COST_AND_COMPENSATION:
      return (
        <ProjectCostAndCompensationBlock projectBlockKind={kind} index={index} project={project} />
      );
    case ProjectBlockKind.Job.JOB_DETAILS:
    case ProjectBlockKind.Job.STOPS:
    case ProjectBlockKind.Job.DISPATCH:
    case ProjectBlockKind.Job.EQUIPMENT_AND_MATERIALS:
    case ProjectBlockKind.Job.TIMESHEETS:
    case ProjectBlockKind.Job.JOB_TIMESHEET:
    case ProjectBlockKind.Job.CREW_HOURS_AND_TIPS:
    case ProjectBlockKind.Job.CREW_HOURS:
    case ProjectBlockKind.Job.TIP_PAYOUTS:
      return (
        <ProjectJobBlock
          jobUuid={urlFilters.get('jobUuid')}
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
          pageRefetch={pageRefetch}
          refetchAndReset={refetchAndReset}
        />
      );
    case ProjectBlockKind.JOBS:
      return (
        <ProjectJobsBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.INVOICE:
      return (
        <ProjectInvoiceBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
          pageRefetch={pageRefetch}
          refetchAndReset={refetchAndReset}
        />
      );
    case ProjectBlockKind.INTERNAL:
      return <ProjectInternalBlock projectBlockKind={kind} index={index} project={project} />;
    case ProjectBlockKind.LOCATIONS:
      return <ProjectLocationsBlock projectBlockKind={kind} index={index} project={project} />;
    case ProjectBlockKind.PROPOSALS:
      return (
        <ProjectProposalsBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.SURVEY:
      return (
        <ProjectSurveyBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.VALUATION_COVERAGE:
      return (
        <ProjectValuationCoverageBlock
          projectBlockKind={kind}
          index={index}
          project={project}
          urlFilters={urlFilters}
        />
      );
    case ProjectBlockKind.INVENTORY_SUMMARY:
      return (
        <ProjectInventorySummaryBlock projectBlockKind={kind} index={index} project={project} />
      );
    case ProjectBlockKind.INVENTORY_ITEMS:
      return <ProjectInventoryItemsBlock projectBlockKind={kind} index={index} project={project} />;
    default:
      return null;
  }
};

const JobsEmptyState = () => {
  const {navigator, params} = useNavigationDOM();
  return (
    <JobsEmptyStateContainer>
      <EmptyState
        icon={Icon.Search}
        title={'No jobs.'}
        message={'There are no jobs on this project.\nAdd a job and it will appear here.'}
        primaryActionIcon={Icon.Plus}
        primaryActionText={'Add Job'}
        handlePrimaryAction={() =>
          navigator.push(
            `/projects/${params.projectUuid}/edit/jobs?action=${Project.JOB_ACTIONS.ADD}`,
          )
        }
      />
    </JobsEmptyStateContainer>
  );
};

const ProjectSectionContent = ({
  project,
  blocks,
  urlFilters,
  pageRefetch,
  refetchAndReset,
}: {
  project: ProjectModel;
  blocks: ProjectBlockKindType[];
  urlFilters: UrlFiltersType;
  pageRefetch: () => void;
  refetchAndReset: () => void;
}) => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();
  const flatList = useFlatList();
  const hasJobs = _.some(Project.getDisplayAllJobsExcludingChildJobs(project, params));
  const {
    projectBlockKindToHeight,
    setProjectBlockKindToHeight,
    isOnLoadScrollComplete,
    setIsOnLoadScrollComplete,
  } = useProjectPageContext();

  const {setPositionY, getBlockOfPositionY} = useProjectBlockAutoScroll({
    allBlocks: blocks,
    flatList,
  });

  const handleOnScrollToIndexFailed = async ({index}: {index: number}) => {
    // Retry scrolling to the index
    await new Promise((resolve) => setTimeout(resolve, 500));
    flatList.handleScrollToIndex({index, animated: true});
  };

  return (
    <FlatList
      listKey={'project-section-flat-list'}
      ref={flatList.ref}
      keyExtractor={(block) => block}
      data={blocks}
      style={{flex: 1, backgroundColor: responsive.desktop ? colors.gray.background : colors.white}}
      contentContainerStyle={{paddingHorizontal: responsive.desktop ? BLOCK_PADDING : 0}}
      initialNumToRender={blocks.length} // Render all items initially
      maxToRenderPerBatch={blocks.length} // Render all items per batch
      windowSize={blocks.length} // Render all items
      removeClippedSubviews={false}
      // Scroll events
      scrollEventThrottle={Infinity}
      onScrollToIndexFailed={handleOnScrollToIndexFailed}
      onScroll={({nativeEvent}) => {
        const positionY = nativeEvent.contentOffset.y;
        setPositionY(positionY);

        const blockOfPositionY = getBlockOfPositionY(positionY);
        if (params.block !== blockOfPositionY) {
          urlFilters.handleUpdate({block: blockOfPositionY});
        }

        if (!isOnLoadScrollComplete) {
          setIsOnLoadScrollComplete(true);
        }
      }}
      // Layout (for handling scroll to index)
      getItemLayout={(blocks, index) => {
        if (!blocks) {
          return {
            length: 0,
            offset: 0,
            index,
          };
        }

        return {
          length: projectBlockKindToHeight[blocks[index]]
            ? projectBlockKindToHeight[blocks[index]]
            : 0,
          offset: _.sumBy(blocks.slice(0, index), (block) => projectBlockKindToHeight[block]),
          index,
        };
      }}
      // Components
      ListFooterComponent={<Space height={120} />}
      renderItem={({item: projectBlockKind, index}) => {
        const isJobBlock = _.includes(ProjectBlockKind.JobBlocks, projectBlockKind);

        // If there are no jobs, only let the job details block pass through and render the empty state
        if (isJobBlock && !hasJobs && projectBlockKind !== ProjectBlockKind.Job.JOB_DETAILS) {
          return null;
        }

        return (
          <BlockContainer
            key={projectBlockKind}
            onLayout={(e) => {
              setProjectBlockKindToHeight((stateBlocks: {[key: string]: number}) => ({
                ...stateBlocks,
                [projectBlockKind]: e.nativeEvent.layout.height,
              }));
            }}
          >
            {responsive.desktop && <Space height={24} />}
            {!responsive.desktop && index > 0 && <Line style={{height: 4}} />}
            {isJobBlock && !hasJobs ? (
              <JobsEmptyState />
            ) : (
              <ProjectSectionBlock
                kind={projectBlockKind}
                project={project}
                urlFilters={urlFilters}
                index={index}
                pageRefetch={pageRefetch}
                refetchAndReset={refetchAndReset}
              />
            )}
          </BlockContainer>
        );
      }}
    />
  );
};

const ProjectSection = ({
  project,
  urlFilters,
  projectNavigationSections,
  pageRefetch,
  refetchAndReset,
}: {
  project: ProjectModel;
  urlFilters: UrlFiltersType;
  projectNavigationSections: ProjectNavigationSectionsType;
  pageRefetch: () => void;
  refetchAndReset: () => void;
}) => {
  const blocks = projectNavigationSections.reduce(
    (result: ProjectBlockKindType[], section) => [...result, ...section.blocks],
    [],
  );

  const {data, loading} = useQuery(ProjectSection.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: project.uuid,
      hasProjectOverviewBlock: blocks.includes(ProjectBlockKind.PROJECT_OVERVIEW),
      hasAccountingSummaryBlock: blocks.includes(ProjectBlockKind.ACCOUNTING_SUMMARY),
      hasBillingBlock: blocks.includes(ProjectBlockKind.BILLING),
      hasClaimsBlock: blocks.includes(ProjectBlockKind.CLAIMS),
      hasClientsBlock: blocks.includes(ProjectBlockKind.CLIENTS),
      hasCostAndCompensationBlock: blocks.includes(ProjectBlockKind.COST_AND_COMPENSATION),
      hasInvoiceBlock: blocks.includes(ProjectBlockKind.INVOICE),
      hasLocationsBlock: blocks.includes(ProjectBlockKind.LOCATIONS),
      hasSurveyBlock: blocks.includes(ProjectBlockKind.SURVEY),
      hasInventorySummaryBlock: blocks.includes(ProjectBlockKind.INVENTORY_SUMMARY),
      hasInventoryItemsBlock: blocks.includes(ProjectBlockKind.INVENTORY_ITEMS),
      hasValuationCoverageBlock: blocks.includes(ProjectBlockKind.VALUATION_COVERAGE),
      hasJobsBlock: blocks.includes(ProjectBlockKind.JOBS),
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <ProjectSectionContent
            project={data.project}
            blocks={blocks}
            urlFilters={urlFilters}
            pageRefetch={pageRefetch}
            refetchAndReset={refetchAndReset}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSection.listener = gql`
  ${ProjectJobBlock.listener}

  fragment ProjectSection_listener on Project {
    id
    totalRevenue
    ...ProjectJobBlock_listener
  }
`;

ProjectSection.fragment = gql`
  ${ProjectSection.listener}

  fragment ProjectSection on Project {
    id
    uuid
    ...ProjectSection_listener
  }
`;

ProjectSection.query = gql`
  ${ProjectOverviewBlock.fragment}
  ${ProjectAccountingSummaryBlock.fragment}
  ${ProjectBillingBlock.fragment}
  ${ProjectClaimsBlock.fragment}
  ${ProjectClientsBlock.fragment}
  ${ProjectCostAndCompensationBlock.fragment}
  ${ProjectInvoiceBlock.fragment}
  ${ProjectJobBlock.fragment}
  ${ProjectLocationsBlock.fragment}
  ${ProjectSurveyBlock.fragment}
  ${ProjectInventorySummaryBlock.fragment}
  ${ProjectInventoryItemsBlock.fragment}
  ${ProjectValuationCoverageBlock.fragment}
  ${ProjectJobsBlock.fragment}
  ${ProjectSection.listener}
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}

  query ProjectSection(
    $projectUuid: String!
    $hasProjectOverviewBlock: Boolean!
    $hasAccountingSummaryBlock: Boolean!
    $hasBillingBlock: Boolean!
    $hasClaimsBlock: Boolean!
    $hasClientsBlock: Boolean!
    $hasCostAndCompensationBlock: Boolean!
    $hasInvoiceBlock: Boolean!
    $hasLocationsBlock: Boolean!
    $hasSurveyBlock: Boolean!
    $hasInventorySummaryBlock: Boolean!
    $hasInventoryItemsBlock: Boolean!
    $hasValuationCoverageBlock: Boolean!
    $hasJobsBlock: Boolean!
  ) {
    project(uuid: $projectUuid) {
      id
      activeJobsExcludingChildJobs {
        id
      }
      ...ProjectOverviewBlock @include(if: $hasProjectOverviewBlock)
      ...ProjectAccountingSummaryBlock @include(if: $hasAccountingSummaryBlock)
      ...ProjectBillingBlock @include(if: $hasBillingBlock)
      ...ProjectClaimsBlock @include(if: $hasClaimsBlock)
      ...ProjectClientsBlock @include(if: $hasClientsBlock)
      ...ProjectCostAndCompensationBlock @include(if: $hasCostAndCompensationBlock)
      ...ProjectInvoiceBlock @include(if: $hasInvoiceBlock)
      ...ProjectLocationsBlock @include(if: $hasLocationsBlock)
      ...ProjectSurveyBlock @include(if: $hasSurveyBlock)
      ...ProjectInventorySummaryBlock @include(if: $hasInventorySummaryBlock)
      ...ProjectInventoryItemsBlock @include(if: $hasInventoryItemsBlock)
      ...ProjectValuationCoverageBlock @include(if: $hasValuationCoverageBlock)
      ...ProjectJobsBlock @include(if: $hasJobsBlock)
      ...ProjectSection_listener
      ...Project_getDisplayAllJobsExcludingChildJobs
      ...ProjectJobBlock
    }
  }
`;

export default ProjectSection;
