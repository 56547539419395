// Libraries
import React from 'react';

// Supermove
import {FlatList, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import JobEventTableItem from './JobEventTableItem';

const Space = Styled.View`
  height: 15px;
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const Cell = Styled.View`
`;

const CellSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H6`
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
`;

const ListHeader = () => {
  return (
    <React.Fragment>
      <Header>
        <Cell style={{width: 160}}>
          <Text>Date & Time</Text>
        </Cell>
        <CellSpace />
        <Cell style={{width: 150}}>
          <Text>Job</Text>
        </Cell>
        <CellSpace />
        <Cell style={{width: 240}}>
          <Text>Event</Text>
        </Cell>
        <CellSpace />
        <Cell style={{width: 150}}>
          <Text>Creator</Text>
        </Cell>
        <CellSpace />
        <Cell style={{width: 400}}>
          <Text>Details</Text>
        </Cell>
      </Header>
      <Space />
    </React.Fragment>
  );
};

const EmptyContainer = Styled.View`
  padding-horizontal: 12px;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ListEmpty = () => {
  return (
    <EmptyContainer>
      <EmptyMessage>No Events</EmptyMessage>
    </EmptyContainer>
  );
};

const Item = Styled.View`
`;

type JobEventsListProps = {
  jobEvents: any[];
};

const JobEventsList = ({jobEvents}: JobEventsListProps) => {
  return (
    <ScrollView horizontal>
      <FlatList
        listKey={'job-events-list'}
        initialNumToRender={30}
        data={jobEvents}
        keyExtractor={(jobEvent) => jobEvent.id}
        renderItem={({item: jobEvent}) => (
          <Item key={jobEvent.id}>
            <JobEventTableItem jobEvent={jobEvent} />
          </Item>
        )}
        ItemSeparatorComponent={Space}
        ListHeaderComponent={ListHeader}
        ListEmptyComponent={ListEmpty}
        style={{
          paddingHorizontal: 30,
        }}
      />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobEventsList.fragment = gql`
  ${JobEventTableItem.fragment}

  fragment JobEventsList on JobEvent {
    id
    ...JobEventTableItem
  }
`;

export default JobEventsList;
