// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UrlFiltersType, useEffect, useNavigationDOM, useQuery, useState} from '@supermove/hooks';
import {JobModel, Project, ProjectModel, ProjectType} from '@supermove/models';
import {ParamsType} from '@supermove/navigation/src/NavigationTypes';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Tabs from '@shared/design/components/Tabs';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ProjectSection from 'modules/Project/V2/Show/components/ProjectSection';
import ProjectSectionV1 from 'modules/Project/V2/Show/components/ProjectSectionV1';

const HeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

const LoaderContainer = Styled.View`
  padding-horizontal: 16px;
`;

const handleInvalidParams = ({
  params,
  jobs,
  urlFilters,
  isValidJobUuid,
}: {
  params: ParamsType;
  jobs: JobModel[];
  urlFilters: UrlFiltersType;
  isValidJobUuid: boolean;
}) => {
  // 1. Handle an invalid jobUuid param
  // 2. Handle when a jobUuid or block param is missing
  const {jobUuid, block} = params;

  if (_.some(jobs)) {
    return urlFilters.handleUpdate({
      jobUuid: isValidJobUuid ? jobUuid : jobs[0].uuid,
      block: block || ProjectBlockKind.EditJobBlocks[0],
    });
  }
};

const getJobTabs = ({jobs}: {jobs: JobModel[]}) => {
  return jobs.map((job) => ({
    key: job.id,
    label: job.shortName,
    jobUuid: job.uuid,
  }));
};

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <LoaderContainer style={{height: 48, justifyContent: 'center'}}>
        <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.SubheadingText} />
      </LoaderContainer>
      <Line />
      <LoaderContainer style={{height: 48, justifyContent: 'center'}}>
        <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.SubheadingText} />
      </LoaderContainer>
      <Line style={{height: 2}} />
      <Space height={16} />
      <LoaderContainer>
        <SkeletonLoader isFullWidth height={200} />
      </LoaderContainer>
      <Space height={16} />
      <LoaderContainer>
        <SkeletonLoader isFullWidth height={200} />
      </LoaderContainer>
      <Space height={16} />
      <LoaderContainer>
        <SkeletonLoader isFullWidth height={200} />
      </LoaderContainer>
    </React.Fragment>
  );
};

const Header = ({project, urlFilters}: {project: ProjectModel; urlFilters: UrlFiltersType}) => {
  return (
    <HeaderRow>
      <Space width={16} />
      <TertiaryButton
        onPress={() =>
          urlFilters.handleReset({
            block: ProjectBlockKind.JOBS,
            showCancelledJobs: urlFilters.get('showCancelledJobs'),
          })
        }
        isHitSlop
        iconLeft={Icon.ChevronLeft}
        text={`Back to ${Project.getName(project)}`}
        isResponsive
      />
    </HeaderRow>
  );
};

const ViewProjectJobsContent = ({
  project,
  urlFilters,
  refetch,
}: {
  project: ProjectModel;
  urlFilters: UrlFiltersType;
  refetch: () => void;
}) => {
  const {params} = useNavigationDOM();
  const parentJobs = Project.getDisplayAllJobsExcludingChildJobs(project, params);
  const parentAndChildJobs = _.reduce(
    parentJobs,
    // @ts-expect-error TS(2769): No overload matches this call.
    (result, job) => [...result, ...(job.isEstimatedRange ? job.jobsForEstimatedRange : [job])],
    [],
  );
  const isValidJobUuid = !!_.find(parentAndChildJobs, ['uuid', params.jobUuid]);

  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (!isValidJobUuid || !params.block) {
      handleInvalidParams({params, jobs: parentAndChildJobs, urlFilters, isValidJobUuid});
    }
  }, [params.block, params.jobUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const job = _.find(parentAndChildJobs, ['uuid', params.jobUuid]);

  return (
    <React.Fragment>
      <Header project={project} urlFilters={urlFilters} />
      <Line />
      <Tabs<{jobUuid: string}>
        tabs={getJobTabs({jobs: parentAndChildJobs})}
        handlePressTab={(tab) =>
          urlFilters.handleUpdate({jobUuid: tab.jobUuid, block: ProjectBlockKind.JobBlocks[0]})
        }
        activeTabIndex={
          _.findIndex(parentAndChildJobs, (job: JobModel) => job.uuid === params.jobUuid) || 0
        }
        scrollViewStyle={{paddingTop: 12}}
        tabStyle={{paddingLeft: 24, paddingRight: 24}}
      />
      <Line style={{height: 2}} />
      {job &&
        (project.organization.features.isEnabledProjectPageAllSections ? (
          <ProjectSection
            key={`${(job as any).uuid}_${resetKey}`}
            project={project}
            urlFilters={urlFilters}
            projectNavigationSections={[
              {
                name: 'Jobs',
                blocks: ProjectType.getJobBlocks(project.projectType),
              },
            ]}
            pageRefetch={refetch}
            refetchAndReset={() => {
              refetch();
              setResetKey(resetKey + 1);
            }}
          />
        ) : (
          <ProjectSectionV1
            key={`${(job as any).uuid}_${resetKey}`}
            tab={{label: (job as any).shortName}}
            project={project}
            urlFilters={urlFilters}
            projectNavigationSections={[
              {
                name: 'Jobs',
                blocks: ProjectType.getJobBlocks(project.projectType),
              },
            ]}
            pageRefetch={refetch}
            refetchAndReset={() => {
              refetch();
              setResetKey(resetKey + 1);
            }}
          />
        ))}
    </React.Fragment>
  );
};

const ViewProjectJobs = ({urlFilters}: {urlFilters: UrlFiltersType}) => {
  const {params} = useNavigationDOM();
  const {data, loading, refetch} = useQuery(ViewProjectJobs.query, {
    fetchPolicy: 'network-only',
    variables: {projectUuid: params.projectUuid},
  });

  return (
    <Loading loading={loading} as={LoadingComponent}>
      {() => {
        return (
          <ViewProjectJobsContent
            project={data.project}
            urlFilters={urlFilters}
            refetch={refetch}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewProjectJobs.query = gql`
  ${ProjectSectionV1.fragment}
  ${Project.getName.fragment}
  ${Project.getDisplayAllJobsExcludingChildJobs.fragment}
  ${ProjectType.getJobBlocks.fragment}

  query ViewProjectJobs($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
        features {
          isEnabledProjectPageAllSections: isEnabled(feature: "PROJECT_PAGE_ALL_SECTIONS")
        }
      }
      allJobsExcludingChildJobs {
        id
        uuid
        shortName
        isEstimatedRange
        jobsForEstimatedRange {
          id
          uuid
          shortName
        }
      }
      projectType {
        id
        ...ProjectType_getJobBlocks
      }
      ...ProjectSectionV1
      ...Project_getName
      ...Project_getDisplayAllJobsExcludingChildJobs
    }
  }
`;

export default ViewProjectJobs;
