// Library
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, ResponsiveType, useNavigationDOM} from '@supermove/hooks';
import {Project, ProjectModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import IconButton from '@shared/design/components/IconButton';
import ProjectStatusBadge from '@shared/modules/Project/components/ProjectStatusBadge';

interface CommunicationInboxProjectInformationProps {
  project?: ProjectModel;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const ContentContainer = Styled.View<{width?: number; responsive: ResponsiveType}>`
  width: ${({width}) => (width ? `${width}px` : '100%')};
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 16 : 0)}px;
  background-color: ${({responsive}) => (responsive.desktop ? colors.white : 'transparent')};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ProjectInformation = ({
  project,
  handleClose,
}: {
  project: ProjectModel;
  handleClose: () => void;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  return (
    <React.Fragment>
      <Row>
        <TertiaryButton
          onPress={() =>
            navigator.push(`${project.isStorage ? '/storage' : ''}/projects/${project.uuid}`)
          }
          text={Project.getName(project)}
          iconLeft={Icon.ArrowUpRightFromSquare}
        />
        {responsive.desktop && (
          <React.Fragment>
            <Space flex={1} />
            <IconButton source={Icon.ArrowRightToLine} onPress={handleClose} isSecondary />
          </React.Fragment>
        )}
      </Row>
      <Space height={16} />
      <FieldValue label={'Client'} value={project.client.name} isResponsive />
      <Space height={16} />
      <FieldValue label={'Contact'} value={project.client.primaryContact.fullName} isResponsive />
      <Space height={16} />
      <FieldValue.LabelText isResponsive>Status</FieldValue.LabelText>
      <FieldValue.LabelSpace />
      <Row>
        <ProjectStatusBadge project={project} />
      </Row>
      <Space height={16} />
      <FieldValue label={'Email'} value={project.client.primaryContact.email} isResponsive />
      <Space height={16} />
      <FieldValue
        label={'Phone'}
        value={Phone.display(project.client.primaryContact.phoneNumber)}
        isResponsive
      />
      <Space height={16} />
      <FieldValue label={'Description'} value={project.description} empty={'None'} isResponsive />
    </React.Fragment>
  );
};

const CommunicationInboxProjectInformationContent = ({
  project,
  isOpen,
  handleOpen,
  handleClose,
}: CommunicationInboxProjectInformationProps) => {
  if (!project) {
    return <IconButton source={Icon.ArrowLeftToLine} onPress={handleOpen} isDisabled />;
  }
  if (!isOpen) {
    return <IconButton source={Icon.ArrowLeftToLine} onPress={handleOpen} isSecondary />;
  }
  return <ProjectInformation project={project} handleClose={handleClose} />;
};

const CommunicationInboxProjectInformation = ({
  project,
  isOpen,
  handleOpen,
  handleClose,
}: CommunicationInboxProjectInformationProps) => {
  const responsive = useResponsive();
  return (
    <ContentContainer width={isOpen ? 320 : 56} responsive={responsive}>
      <Space height={responsive.desktop ? 24 : 8} />
      <CommunicationInboxProjectInformationContent
        project={project}
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </ContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxProjectInformation.fragment = gql`
  ${Project.getName.fragment}
  ${ProjectStatusBadge.fragment}

  fragment CommunicationInboxProjectInformation on Project {
    id
    uuid
    description
    isStorage
    client {
      id
      name
      primaryContact {
        id
        fullName
        email
        phoneNumber
      }
    }
    ...Project_getName
    ...ProjectStatusBadge
  }
`;

export default CommunicationInboxProjectInformation;
