// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BillingSettingsPageNavigationTabs from 'modules/Organization/Settings/Billing/BillingSettingsPageNavigationTabs';
import SettingsContent from 'modules/Organization/Settings/components/SettingsContent';

const PageContainer = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
`;

const OrganizationSettingsBillingSettingsPage = ({
  contentContainerStyle,
  children,
}: {
  contentContainerStyle?: React.CSSProperties;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  return (
    <SidebarPageV2 selected={'settings'}>
      <PageContainer>
        <StandardOfficeHeader title={''} showLine={false}>
          <Container style={{paddingHorizontal: responsive.desktop ? 0 : 12}}>
            <Breadcrumbs
              breadcrumbs={[
                {name: 'Settings', onPress: () => navigator.push('/settings')},
                {name: 'Billing', onPress: () => navigator.push('/settings/billing')},
              ]}
              mobileOptions={{showPreviousScreenOnly: true}}
            />
          </Container>
        </StandardOfficeHeader>
        <BillingSettingsPageNavigationTabs />
        <ScrollView style={{flex: 1, backgroundColor: colors.gray.background}}>
          <SettingsContent style={contentContainerStyle}>{children}</SettingsContent>
        </ScrollView>
      </PageContainer>
    </SidebarPageV2>
  );
};

export default OrganizationSettingsBillingSettingsPage;
