// Libararies
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useState,
  useTextInput,
  useQuery,
  useModal,
  useNavigationDOM,
} from '@supermove/hooks';
import {ThreadModel} from '@supermove/models';

// App

import ThreadKind from '@shared/modules/Thread/enums/ThreadKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import EmailThreadsV2 from 'modules/Communication/Email/components/EmailThreadsV2';

const ProjectEmailsWidgetV2 = ({project}: any) => {
  const editClientModal = useModal({name: 'Edit Client Modal - Project Emails Widget'});
  const {navigator} = useNavigationDOM();
  const searchInput = useTextInput();
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeQuery = useDebouncedCallback((query) => {
    setSearchQuery(query);
  }, 250);
  const {loading, data, refetch} = useQuery(ProjectEmailsWidgetV2.query, {
    fetchPolicy: 'network-only',
    variables: {
      userUuid: project.client.primaryContact.uuid,
      kind: ThreadKind.EMAIL,
      searchQuery,
      projectId: project.id,
    },
  });

  const handleSetSelectedThread = (thread: ThreadModel) => {
    navigator.push(`/projects/${project.uuid}/emails/${thread.uuid}`);
  };

  return (
    <Loading loading={loading || !data} as={PageLoadingIndicator}>
      {() => (
        <React.Fragment>
          <EmailThreadsV2
            emailThreads={data.filteredThreads}
            handleMissingContactInfo={editClientModal.handleOpen}
            setSelectedThread={handleSetSelectedThread}
            searchInput={searchInput}
            searchQuery={searchQuery}
            handleChangeQuery={handleChangeQuery}
            isLoading={loading}
            customer={data.user}
            refetch={refetch}
          />
          <EditClientModal
            key={editClientModal.key}
            isOpen={editClientModal.isOpen}
            handleClose={editClientModal.handleClose}
            clientUuid={project.client.uuid}
            // A refetch is not needed since the mutation response updates client cache
            refetch={() => {}}
          />
        </React.Fragment>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectEmailsWidgetV2.query = gql`
  ${EmailThreadsV2.fragment}

  query ProjectEmailsWidgetV2(
    $userUuid: String!,
    $projectId: Int,
    $kind: String,
    $searchQuery: String,
    ) {
      ${gql.query}
      user(uuid: $userUuid) {
        id
        ...EmailThreadsV2_User
      }
      filteredThreads(projectId: $projectId, kind: $kind, searchQuery: $searchQuery) {
        id
        uuid
        ...EmailThreadsV2_Thread
      }
  }
`;

ProjectEmailsWidgetV2.fragment = gql`
  fragment ProjectEmailsWidgetV2 on Project {
    id
    uuid
    client {
      id
      uuid
      primaryContact {
        id
        uuid
      }
    }
  }
`;

export default ProjectEmailsWidgetV2;
