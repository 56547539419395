// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {PopoverType, ResponsiveType} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import Sheet from '@shared/design/components/Sheet';

const PopoverBody = Styled.View<{responsive: ResponsiveType}>`
    width: 250px;
    border-radius: 4px;
    border: ${({responsive}) => (responsive.desktop ? `1px solid ${colors.gray.border}` : '0px')};
    background-color: ${colors.white};
    padding-horizontal: 12px;
    overflow: visible;
    width: 100%;
`;

const Item = Styled.Text<{vars: {needsWarning?: boolean}}>`
    ${Typography.Responsive.Body}
    color: ${({vars}) => (vars.needsWarning ? colors.red.warning : undefined)};
`;

interface UpdateAccountGrantPopoverProps {
  responsive: ResponsiveType;
  popover: PopoverType;
  handleUpdateAccount: () => void;
  handleDeactivateAccount: () => void;
}

const LineItem = ({
  handlePress,
  text,
  responsive,
  needsWarning,
}: {
  handlePress: () => void;
  text: string;
  responsive: ResponsiveType;
  needsWarning?: boolean;
}) => {
  return (
    <TertiaryButton onPress={handlePress}>
      <Item vars={{needsWarning}} responsive={responsive}>
        {text}
      </Item>
    </TertiaryButton>
  );
};

const LineItemSpacing = ({responsive}: {responsive: ResponsiveType}) => {
  if (responsive.desktop) {
    return <Space height={24} />;
  }
  return (
    <React.Fragment>
      <Space height={12} />
      <Line />
      <Space height={12} />
    </React.Fragment>
  );
};

const UpdateAccountGrantPopoverContent = ({
  responsive,
  handleDisconnect,
  handleUpdate,
}: {
  responsive: ResponsiveType;
  handleDisconnect: () => void;
  handleUpdate: () => void;
}) => {
  return (
    <PopoverBody responsive={responsive}>
      <Space height={24} />
      <LineItem handlePress={handleUpdate} text={'Connect Account'} responsive={responsive} />
      <LineItemSpacing responsive={responsive} />
      <LineItem
        handlePress={handleDisconnect}
        text={'Disconnect Account'}
        responsive={responsive}
        needsWarning
      />
      <Space height={24} />
    </PopoverBody>
  );
};

const UpdateAccountGrantPopover = ({
  responsive,
  popover,
  handleUpdateAccount,
  handleDeactivateAccount,
}: UpdateAccountGrantPopoverProps) => {
  const updateAccount = () => {
    popover.handleClose();
    handleUpdateAccount();
  };

  const handleDisconnect = () => {
    popover.handleClose();
    handleDeactivateAccount();
  };

  return responsive.desktop ? (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, -8]}
    >
      <UpdateAccountGrantPopoverContent
        responsive={responsive}
        handleDisconnect={handleDisconnect}
        handleUpdate={updateAccount}
      />
    </Popover>
  ) : (
    <Sheet.PreventPropagationContainer>
      <Sheet
        isOpen={popover.isOpen}
        handleClose={popover.handleClose}
        HeaderRightComponent={() => (
          <IconButton onPress={popover.handleClose} source={Icon.Xmark} size={20} isSecondary />
        )}
        headerText={'Actions'}
        isFixedHeight={false}
        headerContainerStyle={{backgroundColor: colors.white}}
      >
        <UpdateAccountGrantPopoverContent
          responsive={responsive}
          handleDisconnect={handleDisconnect}
          handleUpdate={updateAccount}
        />
      </Sheet>
    </Sheet.PreventPropagationContainer>
  );
};

export default UpdateAccountGrantPopover;
