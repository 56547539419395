// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive, ResponsiveType} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import StorageSettingsPageNavigationTabs from 'modules/Organization/Settings/Storage/components/StorageSettingsPageNavigationTabs';

const PageContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
  background-color: ${colors.white};
`;

const StorageSettingsPageBreadcrumb = () => {
  const {navigator} = useNavigationDOM();
  const {location} = navigator;
  const endPath = _.last(location.pathname.split('/'));

  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Storage', onPress: () => navigator.push('/settings/storage')},
    ...List.insertIf(endPath === 'warehouses', {name: 'Warehouses'}),
    ...List.insertIf(endPath === 'container-types', {name: 'Container Types'}),
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} mobileOptions={{showPreviousScreenOnly: true}} />;
};

const StorageSettingsPageHeader = ({organization}: any) => {
  const responsive = useResponsive();

  return (
    <PageContainer responsive={responsive}>
      <StandardOfficeHeader title={''} showLine={false}>
        <StorageSettingsPageBreadcrumb />
      </StandardOfficeHeader>
      {!organization.features.isEnabledMultipleWarehouse && <StorageSettingsPageNavigationTabs />}
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageSettingsPageHeader.fragment = gql`
  fragment StorageSettingsPageHeader on Organization {
    id
    features {
      isEnabledMultipleWarehouse: isEnabled(feature: "MULTIPLE_WAREHOUSE")
    }
  }
`;

export default StorageSettingsPageHeader;
