// Libraries
import React from 'react';

// Supermove
import {
  Icon,
  Popover,
  PreventPropagation,
  SanitizedHTML,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, usePopover, useHover} from '@supermove/hooks';
import {EmailModel} from '@supermove/models';
import {AccountGrantInfoType} from '@supermove/models/src/AccountGrant';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import IconButton from '@shared/design/components/IconButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import EmailStatus from '@shared/modules/Email/enums/EmailStatus';
import EmailAttachments from 'modules/Communication/Email/components/EmailAttachments';
import EmailMetadataPopover from 'modules/Communication/Email/components/EmailMetadataPopover';
import EmailReplyPopover from 'modules/Communication/Email/components/EmailReplyPopover';
import getFromLine from 'modules/Communication/Email/utils/getFromLine';
import {hasMultipleRecipients} from 'modules/Communication/Email/utils/hasMultipleRecipients';
import {parseJsonRecipients} from 'modules/Communication/Email/utils/parseJsonRecipients';
import {ReplyType} from 'modules/Communication/Email/utils/replyType';
import {toTextFromHtml} from 'modules/Communication/Email/utils/toTextFromHtml';

const EmailContainer = Styled.ButtonV2<{isCollapsed?: boolean}>`
  margin-horizontal: 24px;
  cursor: ${({isCollapsed}) => (isCollapsed ? 'pointer' : 'default')};
`;

const FromEmailAndTimestampContainer = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const ToEmailAndDetailsContainer = Styled.View<{responsive: ResponsiveType}>`
  flex-direction: row;
  align-items: ${({responsive}) => (responsive.mobile ? 'flex-start' : 'center')};
  justify-content: ${({responsive}) => (responsive.mobile ? 'space-between' : 'flex-start')};
`;

const TimestampAndOptionsContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Timestamp = Styled.Text`
  ${Typography.Responsive.Micro}
  text-align: right;
`;

const EmailDetailsContainer = Styled.View<{responsive: ResponsiveType}>`
  align-items: ${({responsive}) => (responsive.mobile ? 'flex-end' : 'flex-start')};
`;

const EmailDetailsText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.blue.interactive};
  flex: 1;
`;

// Negative margin makes up for inherent padding in the Sanitized HTML body tag
const EmailHtmlContainer = Styled.View`
  margin: -8px;
`;

const FromEmail = Styled.Text<{responsive: ResponsiveType; isCollapsed: boolean}>`
  ${({responsive}) => (responsive.mobile ? Typography.Responsive.Label : Typography.Responsive.MicroLabel)}
  color: ${({isCollapsed}) => (isCollapsed ? colors.gray.secondary : colors.gray.primary)};
  flex: 1;
`;

const CollapsedEmailText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  margin-bottom: 16px;
  margin-right: 80px;
`;

const RecipientInfo = Styled.Text<{responsive: ResponsiveType}>`
  ${Typography.Responsive.Micro}
  ${({responsive}) => (responsive.mobile ? 'flex: 1' : '')};
`;

const HoverIcon = Styled.View`
`;

const FromEmailAndTimestampSection = ({
  email,
  responsive,
  toggleCollapse,
  isCollapsed,
  attachmentCount,
  handleRespond,
  accountGrantInfo,
}: {
  email: EmailModel;
  responsive: ResponsiveType;
  toggleCollapse: () => void;
  isCollapsed: boolean;
  attachmentCount: number;
  handleRespond: (replyType: ReplyType) => void;
  accountGrantInfo: AccountGrantInfoType;
}) => {
  return (
    <FromEmailAndTimestampContainer onPress={toggleCollapse}>
      <FromEmail isCollapsed={isCollapsed} responsive={responsive}>
        {getFromLine(email)}
      </FromEmail>
      <Space width={8} />
      <TimestampAndOptions
        email={email}
        responsive={responsive}
        attachmentCount={attachmentCount}
        handleRespond={handleRespond}
        isCollapsed={isCollapsed}
        accountGrantInfo={accountGrantInfo}
      />
    </FromEmailAndTimestampContainer>
  );
};

const ToEmailAndDetailsSection = ({
  email,
  responsive,
}: {
  email: EmailModel;
  responsive: ResponsiveType;
}) => {
  const toNames = [
    ...parseJsonRecipients(email.toEmailRecipientsJson),
    ...parseJsonRecipients(email.ccEmailRecipientsJson),
    ...parseJsonRecipients(email.bccEmailRecipientsJson),
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <ToEmailAndDetailsContainer responsive={responsive}>
      <RecipientInfo responsive={responsive}>To: {toNames}</RecipientInfo>
      <Space width={8} />
      <EmailDetails email={email} responsive={responsive} />
    </ToEmailAndDetailsContainer>
  );
};

const EmailDetails = ({email, responsive}: {email: EmailModel; responsive: ResponsiveType}) => {
  const metadataPopover = usePopover({name: `emailMetadataPopover${email.id}`});
  return (
    <EmailDetailsContainer responsive={responsive}>
      <Popover.Content innerRef={metadataPopover.ref}>
        <TertiaryButton onPress={metadataPopover.handleToggle}>
          {responsive.mobile ? (
            <EmailDetailsText>Details</EmailDetailsText>
          ) : (
            <Icon source={Icon.ChevronDown} color={colors.blue.interactive} size={12} />
          )}
        </TertiaryButton>
      </Popover.Content>
      <EmailMetadataPopover email={email} responsive={responsive} popover={metadataPopover} />
    </EmailDetailsContainer>
  );
};

const getTooltipText = ({
  hasSendableAccountGrant,
  hasExpiredCompanyAccount,
  hasExpiredUserAccount,
  hasActiveCompanyAccount,
  hasMultipleRecipients,
  hasReplyText,
}: {
  hasSendableAccountGrant: boolean;
  hasExpiredCompanyAccount: boolean;
  hasExpiredUserAccount: boolean;
  hasActiveCompanyAccount: boolean;
  hasMultipleRecipients: boolean;
  hasReplyText: boolean;
}) => {
  if (hasSendableAccountGrant && hasReplyText) {
    return hasMultipleRecipients ? 'Reply all' : 'Reply';
  }
  if (hasExpiredCompanyAccount) {
    return 'Reconnect the company email to reply and forward this email.';
  }
  if (hasExpiredUserAccount) {
    if (hasActiveCompanyAccount) {
      return 'This email will be replied to and forwarded using the company email account until your account is reconnected.';
    }
    return 'Reconnect your email account to reply and forward this email.';
  }
  return '';
};

const TimestampAndOptions = ({
  email,
  responsive,
  attachmentCount,
  handleRespond,
  isCollapsed,
  accountGrantInfo,
}: {
  email: EmailModel;
  responsive: ResponsiveType;
  attachmentCount: number;
  handleRespond: (replyType: ReplyType) => void;
  isCollapsed: boolean;
  accountGrantInfo: AccountGrantInfoType;
}) => {
  const {
    hasSendableAccountGrant,
    hasExpiredCompanyAccount,
    hasExpiredUserAccount,
    hasActiveCompanyAccount,
  } = accountGrantInfo;
  const emailOptionsPopover = usePopover({name: `emailOptionsPopover${email.id}`});
  const {ref: attachmentRef, isHovered: attachmentIsHovered} = useHover();
  const emailHasMultipleRecipients = hasMultipleRecipients(email);
  const sendableOrExpiredGrants =
    hasSendableAccountGrant || hasExpiredCompanyAccount || hasExpiredUserAccount;

  return (
    <PreventPropagation>
      <TimestampAndOptionsContainer>
        {sendableOrExpiredGrants && !isCollapsed && responsive.desktop ? (
          <React.Fragment>
            <IconButton
              source={emailHasMultipleRecipients ? Icon.ReplyAll : Icon.Reply}
              onPress={() => handleRespond(emailHasMultipleRecipients ? 'ReplyAll' : 'Reply')}
              isDisabled={!hasSendableAccountGrant}
              isSecondary
              tooltip={getTooltipText({
                hasSendableAccountGrant,
                hasExpiredCompanyAccount,
                hasExpiredUserAccount,
                hasActiveCompanyAccount,
                hasMultipleRecipients: emailHasMultipleRecipients,
                hasReplyText: true,
              })}
            />
            <Space width={12} />
          </React.Fragment>
        ) : null}
        {attachmentCount > 0 ? (
          <TextTooltip
            isVisible={attachmentIsHovered}
            placement='top'
            text={`${attachmentCount} attachment${attachmentCount > 1 ? 's' : ''}`}
          >
            <React.Fragment>
              <HoverIcon ref={attachmentRef}>
                <Icon source={Icon.Paperclip} color={colors.gray.tertiary} />
              </HoverIcon>
            </React.Fragment>
          </TextTooltip>
        ) : null}
        <Space width={10} />
        <Timestamp responsive={responsive}>
          {Datetime.convertToDisplayDatetime(email.sentAt || email.updatedAt)}
        </Timestamp>
        {sendableOrExpiredGrants && !isCollapsed ? (
          <React.Fragment>
            <Space width={8} />
            <Popover.Content innerRef={emailOptionsPopover.ref}>
              <IconButton
                source={Icon.EllipsisV}
                onPress={emailOptionsPopover.handleToggle}
                isDisabled={!hasSendableAccountGrant}
                isSecondary
                size={14}
                tooltip={getTooltipText({
                  hasSendableAccountGrant,
                  hasExpiredCompanyAccount,
                  hasExpiredUserAccount,
                  hasActiveCompanyAccount,
                  hasMultipleRecipients: emailHasMultipleRecipients,
                  hasReplyText: false,
                })}
              />
            </Popover.Content>
            <EmailReplyPopover
              responsive={responsive}
              popover={emailOptionsPopover}
              handleRespond={handleRespond}
            />
          </React.Fragment>
        ) : null}
      </TimestampAndOptionsContainer>
    </PreventPropagation>
  );
};

const ErrorBanner = ({responsive}: {responsive: ResponsiveType}) => {
  return (
    <React.Fragment>
      <Space height={16} />
      <WarningCallout
        text={'This email could not be delivered, please verify the details and try again.'}
        width={responsive.desktop ? 520 : 300}
      />
      <Space height={8} />
    </React.Fragment>
  );
};

interface EmailItemProps {
  email: EmailModel;
  responsive: ResponsiveType;
  isCollapsed: boolean;
  toggleCollapse: () => void;
  handleRespond: (replyType: ReplyType) => void;
  accountGrantInfo: AccountGrantInfoType;
}

const EmailItem = ({
  email,
  handleRespond,
  responsive,
  isCollapsed,
  toggleCollapse,
  accountGrantInfo,
}: EmailItemProps) => {
  return (
    <EmailContainer onPress={isCollapsed ? toggleCollapse : undefined} isCollapsed={isCollapsed}>
      <Space height={24} />
      <FromEmailAndTimestampSection
        email={email}
        responsive={responsive}
        toggleCollapse={toggleCollapse}
        isCollapsed={isCollapsed}
        attachmentCount={email.emailAttachments.length}
        handleRespond={handleRespond}
        accountGrantInfo={accountGrantInfo}
      />
      {isCollapsed ? null : (
        <React.Fragment>
          <ToEmailAndDetailsSection email={email} responsive={responsive} />
          {email.status === EmailStatus.ERROR && <ErrorBanner responsive={responsive} />}
        </React.Fragment>
      )}
      <EmailHtmlContainer style={isCollapsed ? {margin: 0} : {marginTop: 16}}>
        {isCollapsed ? (
          <CollapsedEmailText numberOfLines={1}>{toTextFromHtml(email.html)}</CollapsedEmailText>
        ) : (
          <SanitizedHTML rawHTML={email.html} linkifyUrls useAppFont initialHeight={30} />
        )}
      </EmailHtmlContainer>
      {!!email.emailAttachments.length && !isCollapsed && (
        <EmailAttachments email={email} responsive={responsive} />
      )}
      {isCollapsed ? null : <Space height={16} />}
    </EmailContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailItem.fragment = gql`
  ${EmailAttachments.fragment}
  ${EmailMetadataPopover.fragment}
  fragment EmailItem on Email {
    id
    html
    emailAttachments {
      id
    }
    fromName
    sentAt
    updatedAt
    toEmailRecipientsJson
    bccEmailRecipientsJson
    ccEmailRecipientsJson
    status
    ...EmailAttachments
    ...EmailMetadataPopover
  }
`;

export default EmailItem;
