// Libraries
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useRef, useEffect, useTrackEvent} from '@supermove/hooks';

// App
import ManagerSearchField from 'modules/App/components/ManagerSearch/components/ManagerSearchField';

const Wrapper = Styled.View`
  flex: 1;
  height: 100%;
  align-items: center;
  padding-top: ${(props) => ((props as any).mobile ? 50 : 100)}px;
  padding-horizontal: 20px;
  background-color: rgba(75, 75, 75, 0.75);
`;

type OwnProps = {
  organization: any;
  trigger: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ManagerSearch.defaultProps;

// @ts-expect-error TS(7022): 'ManagerSearch' implicitly has type 'any' because ... Remove this comment to see the full error message
const ManagerSearch = ({organization, trigger}: Props) => {
  const responsive = useResponsive();
  const closeHandlerRef = useRef(null); // Ref for the close handler

  const {trackEvent} = useTrackEvent({
    eventName: `Viewed Office Global Search Modal`,
    eventType: 'Modal View',
    // TODO(sam): Add pageName when it is available in navigator
    pageName: '',
  });

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === 'Escape') {
        // @ts-expect-error TS(2349): This expression is not callable.
        closeHandlerRef.current && closeHandlerRef.current();
      }
    };

    // Add keydown listener for closing the modal with Escape key
    window.addEventListener('keyup', handleKeyPress);

    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, []);

  return (
    <Modal trigger={trigger} onOpen={trackEvent}>
      {({handleClose}: any) => {
        // Assign the close handler to the ref
        closeHandlerRef.current = handleClose;

        return (
          <Wrapper pointerEvents={'box-none'} {...responsive}>
            {organization && (
              <ManagerSearchField organization={organization} handleClose={handleClose} />
            )}
          </Wrapper>
        );
      }}
    </Modal>
  );
};

ManagerSearch.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManagerSearch.fragment = gql`
  ${ManagerSearchField.fragment}

  fragment ManagerSearch on User {
    id
    ...ManagerSearchField
  }
`;

export default ManagerSearch;
