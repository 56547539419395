// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {ScrollViewType, useNavigationDOM, useUrlFilters} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import CapacityCalendar from 'modules/Calendar/Capacity/components/CapacityCalendar';
import CapacityCalendarDayView from 'modules/Calendar/Capacity/components/CapacityCalendarDayView';
import {CapacityCalendarUrlFiltersType} from 'modules/Calendar/Capacity/types/CapacityCalendarUrlFiltersType';

const CapacityCalendarPage = ({
  organization,
  mobileMoveProjectsScrollView,
}: {
  organization: OrganizationModel;
  mobileMoveProjectsScrollView: ScrollViewType;
}) => {
  const {navigator} = useNavigationDOM();
  const selectedTab = navigator.location.pathname.split('/').slice(-1)[0];
  const urlFilters = useUrlFilters<CapacityCalendarUrlFiltersType>({
    getRoute: () => navigator.location.pathname,
    filterKeys: [
      'date',
      'slugs',
      'searchQuery',
      'projectTypes',
      'projectTagIds',
      'jobTagIds',
      'salespersons',
    ],
  });

  return (
    <React.Fragment>
      {selectedTab === 'month' ? (
        <CapacityCalendar
          organization={organization}
          mobileMoveProjectsScrollView={mobileMoveProjectsScrollView}
          urlFilters={urlFilters}
        />
      ) : selectedTab === 'day' ? (
        <CapacityCalendarDayView organization={organization} urlFilters={urlFilters} />
      ) : null}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarPage.fragment = gql`
  ${CapacityCalendar.fragment}
  ${CapacityCalendarDayView.fragment}

  fragment CapacityCalendarPage on Organization {
    id
    ...CapacityCalendar
    ...CapacityCalendarDayView
  }
`;

export default CapacityCalendarPage;
