// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Styled, Space, Icon, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useQuery,
  useState,
  useModal,
  useToast,
  useMountEffect,
  useResponsive,
  ModalType,
} from '@supermove/hooks';
import {DocumentItemType, DocumentTemplateVersionModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Json, Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import DocumentTemplateVersionForm, {
  DocumentContentJsonFormType,
  DocumentTemplateVersionFormType,
} from '@shared/modules/Document/forms/DocumentTemplateVersionForm';
import usePublishDocumentTemplateVersionMutation from '@shared/modules/Document/hooks/usePublishDocumentTemplateVersionMutation';
import useUpdateDocumentTemplateVersionMutation from '@shared/modules/Document/hooks/useUpdateDocumentTemplateVersionMutation';
import DocumentTemplateVersionEditorTypes, {
  SelectedDocumentItemInSectionType,
  SelectedDocumentItemType,
  DocumentContentJsonWithSectionsType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import DocumentTemplateEditorDocumentItems from 'modules/Organization/Settings/Document/components/DocumentTemplateEditorDocumentItems';
import DocumentTemplateEditorPreview from 'modules/Organization/Settings/Document/components/DocumentTemplateEditorPreview';
import DocumentTemplateVersionItemInputEditor from 'modules/Organization/Settings/Document/components/DocumentTemplateVersionItemInputEditor';
import SectionDocumentItemsPanel from 'modules/Organization/Settings/Document/components/SectionDocumentItemsPanel';

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const AddItemsColumn = Styled.View`
  flex: 3;
  min-width: 300px;
  max-width: 400px;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const ItemInputsColumn = Styled.View`
  flex: 4;
  min-width: 400px;
  max-width: 600px;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const DocumentPreviewColumn = Styled.View`
  flex: 6;
`;

const DocumentPreviewContentContainer = Styled.View`
  width: 800px;
  padding-horizontal: 24px;
`;

const PageHeaderTitle = Styled.Text`
  ${Typography.Responsive.Heading2};
`;

const LastSavedText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

const PageHeaderContainer = Styled.View`
  paddingVertical: 16px;
  paddingHorizontal: 24px;
  flex-direction: row;
  align-items: center;
`;

const ItemInputEditorBlankContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ItemInputEditorBlankContainerText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
  text-align: center;
  padding-horizontal: 16px;
`;

const getInitialSelectedDocumentItem = (documentItems: DocumentItemType[]) => {
  const isEmptyDocument = !documentItems.length;
  if (isEmptyDocument) {
    return {};
  }
  const hasSections = documentItems.some(
    (documentItem) => documentItem.itemKind === DocumentItemKindV2.SECTION,
  );
  if (hasSections) {
    return {};
  }
  return {
    documentItem: documentItems[0],
    index: 0,
    sectionIndex: undefined,
  };
};

const getIsSectionSelected = (
  selectedDocumentItem: SelectedDocumentItemType,
): selectedDocumentItem is SelectedDocumentItemInSectionType => {
  return 'sectionIndex' in selectedDocumentItem && !_.isNil(selectedDocumentItem.sectionIndex);
};

const getHasSelectedDocumentItem = (
  selectedDocumentItem: SelectedDocumentItemType,
): selectedDocumentItem is SelectedDocumentItemType & {documentItem: DocumentItemType} => {
  return selectedDocumentItem.documentItem !== undefined;
};

const ExitEditDocumentTemplateModal = ({
  exitEditDocumentTemplateModal,
  documentTemplateUuid,
}: {
  exitEditDocumentTemplateModal: ModalType;
  documentTemplateUuid: string;
}) => {
  const {navigator} = useNavigationDOM();
  return (
    <WarningModal
      key={exitEditDocumentTemplateModal.key}
      title={'Exit this page?'}
      message={'Any unsaved changes will be deleted.'}
      isOpen={exitEditDocumentTemplateModal.isOpen}
      handlePrimaryAction={() => {
        navigator.replace(`/settings/documents/document-templates/${documentTemplateUuid}`);
      }}
      handleSecondaryAction={exitEditDocumentTemplateModal.handleClose}
      primaryActionText={'Exit Page'}
      secondaryActionText={'Cancel'}
    />
  );
};

const PublishDocumentTemplateModal = ({
  documentTemplateVersionForm,
  publishDocumentTemplateModal,
  handlePublishDocumentTemplateVersion,
  isPublishing,
  isUsedInSteps,
}: {
  documentTemplateVersionForm: DocumentTemplateVersionFormType;
  publishDocumentTemplateModal: ModalType;
  handlePublishDocumentTemplateVersion: () => void;
  isPublishing: boolean;
  isUsedInSteps: boolean;
}) => {
  const message = isUsedInSteps
    ? 'This document is used in one or more crew steps. Any changes you make and publish will apply to new documents moving forward.'
    : 'Once published, new jobs will use the updated document. Old jobs will not be affected.';
  return (
    <ActionModal
      title={`Publish ${documentTemplateVersionForm.name}?`}
      message={message}
      icon={Icon.File}
      key={publishDocumentTemplateModal.key}
      isOpen={publishDocumentTemplateModal.isOpen}
      handlePrimaryAction={() => {
        handlePublishDocumentTemplateVersion();
      }}
      handleSecondaryAction={publishDocumentTemplateModal.handleClose}
      primaryActionText={'Publish'}
      secondaryActionText={'Cancel'}
      isSubmitting={isPublishing}
    />
  );
};

const DocumentTemplateVersionEditorHeader = ({
  documentTemplateVersion,
  draftDocumentContentJson,
  documentTemplateUuid,
  refetch,
  isDocumentChanged,
  setIsDocumentChanged,
}: {
  documentTemplateVersion: DocumentTemplateVersionModel;
  draftDocumentContentJson: DocumentContentJsonFormType;
  documentTemplateUuid: string;
  refetch: () => void;
  isDocumentChanged: boolean;
  setIsDocumentChanged: (value: boolean) => void;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const documentTemplateVersionForm = DocumentTemplateVersionForm.edit({
    ...documentTemplateVersion,
    documentContentJsonForm: draftDocumentContentJson,
  });

  const updateDocumentSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Document draft saved',
  });
  const {handleSubmit: handleUpdateDocumentTemplateVersion, submitting: isSavingDraft} =
    useUpdateDocumentTemplateVersionMutation({
      documentTemplateVersionForm,
      onSuccess: () => {
        setIsDocumentChanged(false);
        updateDocumentSuccessToast.handleToast();
        refetch();
      },
      onError: () => {},
    });

  const publishDocumentSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Document template published',
  });
  const {handleSubmit: handlePublishDocumentTemplateVersion, submitting: isPublishing} =
    usePublishDocumentTemplateVersionMutation({
      documentTemplateVersionForm,
      onSuccess: () => {
        publishDocumentSuccessToast.handleToast();
        navigator.push(`/settings/documents/document-templates/${documentTemplateUuid}`);
      },
      onError: (errors: unknown) => console.log({errors}),
    });

  const exitEditDocumentTemplateModal = useModal({name: 'Exit Document Template Modal'});
  const publishDocumentTemplateModal = useModal({name: 'Publish Document Template Modal'});
  return (
    <PageHeaderContainer>
      <IconButton
        isSecondary
        size={20}
        source={IconButton.SOURCE.Xmark}
        onPress={() =>
          isDocumentChanged
            ? exitEditDocumentTemplateModal.handleOpen()
            : navigator.replace(`/settings/documents/document-templates/${documentTemplateUuid}`)
        }
      />
      <Space width={16} />
      <PageHeaderTitle responsive={responsive}>{documentTemplateVersion.name}</PageHeaderTitle>
      <Space style={{flex: 1}} />
      <LastSavedText responsive={responsive}>
        Last Saved: {Datetime.convertToDisplayDatetime(documentTemplateVersion.updatedAt)}
      </LastSavedText>
      <Space width={16} />
      <SecondaryButton
        isDisabled={!isDocumentChanged}
        text={'Save as Draft'}
        onPress={handleUpdateDocumentTemplateVersion}
        isSubmitting={isSavingDraft}
        isResponsive
      />
      <Space width={16} />
      <Button
        onPress={publishDocumentTemplateModal.handleOpen}
        iconLeft={Icon.Check}
        text={'Publish'}
        isResponsive
      />
      <ExitEditDocumentTemplateModal
        exitEditDocumentTemplateModal={exitEditDocumentTemplateModal}
        documentTemplateUuid={documentTemplateUuid}
      />
      <PublishDocumentTemplateModal
        documentTemplateVersionForm={documentTemplateVersionForm}
        publishDocumentTemplateModal={publishDocumentTemplateModal}
        handlePublishDocumentTemplateVersion={handlePublishDocumentTemplateVersion}
        isUsedInSteps={
          documentTemplateVersion.documentTemplate.jobTypesWithDocumentTemplateOnJobTypeStep
            .length > 0
        }
        isPublishing={isPublishing}
      />
    </PageHeaderContainer>
  );
};

const EmptyInputsPlaceholder = () => {
  const responsive = useResponsive();
  return (
    <ItemInputEditorBlankContainer>
      <ItemInputEditorBlankContainerText responsive={responsive}>
        {'Add or select a document item to configure it here and preview it in the document.'}
      </ItemInputEditorBlankContainerText>
      <Space height={16} />
      <a target={'_blank'} href={'https://help.supermove.com/en-us/articles/documents-6qVulLspYY'}>
        <TertiaryButton iconLeft={Icon.QuestionCircle} text={'Help Center'} isHitSlop />
      </a>
    </ItemInputEditorBlankContainer>
  );
};

const DocumentTemplateVersionEditorContent = ({
  documentTemplateVersion,
  documentTemplateUuid,
  refetch,
}: {
  documentTemplateVersion: DocumentTemplateVersionModel;
  documentTemplateUuid: string;
  refetch: () => void;
}) => {
  const [draftDocumentContentJson, setDraftDocumentContentJson] =
    useState<DocumentContentJsonFormType>(
      Json.toObject(documentTemplateVersion.documentContentJson) as DocumentContentJsonFormType,
    );

  const [selectedDocumentItem, setSelectedDocumentItem] = useState<SelectedDocumentItemType>(
    getInitialSelectedDocumentItem(draftDocumentContentJson.documentItems),
  );
  const [isDocumentChanged, setIsDocumentChanged] = useState(false);
  const [hoveredSectionIndex, setHoveredSectionIndex] = useState<number | undefined>(undefined);

  // TODO(dan) Need to find a way to type this so that setDraftDocumentContentJson is able to
  // take either a DocumentContentJsonType or a function that takes a DocumentContentJsonType
  const updateDraftDocumentContentJson = (value: any) => {
    setDraftDocumentContentJson(value);
    setIsDocumentChanged(true);
  };

  useMountEffect(() => {
    // Convert legacy text document items to rich text document items.
    // The itemKind will become TEXT and the text will have neccesary HTML tags.
    if (draftDocumentContentJson) {
      const copiedDraftDocumentContentJson = _.cloneDeep(draftDocumentContentJson);
      const {documentItems, hasUpdatedDocumentItems} = DocumentItemKindV2.convertTextItems({
        documentItems: copiedDraftDocumentContentJson.documentItems,
      });
      if (hasUpdatedDocumentItems) {
        copiedDraftDocumentContentJson.documentItems = documentItems;
        updateDraftDocumentContentJson(copiedDraftDocumentContentJson);
      }
    }
  });

  const firstDocumentItem = draftDocumentContentJson.documentItems[0];
  const hasSections =
    firstDocumentItem &&
    DocumentTemplateVersionEditorTypes.DocumentItem.getIsSection(firstDocumentItem);
  const isSectionSelected = hasSections && getIsSectionSelected(selectedDocumentItem);
  const hasSelectedDocumentItem = getHasSelectedDocumentItem(selectedDocumentItem);

  return (
    <PageContainer>
      <DocumentTemplateVersionEditorHeader
        documentTemplateVersion={documentTemplateVersion}
        draftDocumentContentJson={draftDocumentContentJson}
        documentTemplateUuid={documentTemplateUuid}
        refetch={refetch}
        isDocumentChanged={isDocumentChanged}
        setIsDocumentChanged={setIsDocumentChanged}
      />
      <Line />
      <PageContentContainer>
        <AddItemsColumn>
          {isSectionSelected ? (
            <SectionDocumentItemsPanel
              organization={documentTemplateVersion.organization}
              draftDocumentContentJson={
                draftDocumentContentJson as DocumentContentJsonWithSectionsType
              }
              setDraftDocumentContentJson={updateDraftDocumentContentJson}
              setSelectedDocumentItem={setSelectedDocumentItem}
              selectedDocumentItem={selectedDocumentItem}
              documentTemplateCategory={documentTemplateVersion.documentTemplate.category}
            />
          ) : (
            <DocumentTemplateEditorDocumentItems
              organization={documentTemplateVersion.organization}
              draftDocumentContentJson={draftDocumentContentJson}
              setDraftDocumentContentJson={updateDraftDocumentContentJson}
              setSelectedDocumentItem={setSelectedDocumentItem}
              selectedDocumentItem={selectedDocumentItem}
              documentTemplateCategory={documentTemplateVersion.documentTemplate.category}
              hoveredSectionIndex={hoveredSectionIndex}
              setHoveredSectionIndex={setHoveredSectionIndex}
            />
          )}
        </AddItemsColumn>
        <ItemInputsColumn>
          {hasSelectedDocumentItem ? (
            <DocumentTemplateVersionItemInputEditor
              key={`${selectedDocumentItem.index}-${selectedDocumentItem.sectionIndex}-${selectedDocumentItem.columnIndex}`}
              selectedDocumentItem={selectedDocumentItem}
              setDraftDocumentContentJson={updateDraftDocumentContentJson}
              documentTemplateVersion={documentTemplateVersion}
            />
          ) : (
            <EmptyInputsPlaceholder />
          )}
        </ItemInputsColumn>
        <DocumentPreviewColumn>
          <ScrollView
            horizontal
            contentContainerStyle={{flex: 1}}
            style={{maxWidth: 1000, backgroundColor: colors.gray.background}}
          >
            <DocumentPreviewContentContainer>
              <DocumentTemplateEditorPreview
                selectedDocumentItem={selectedDocumentItem}
                documentContentJson={draftDocumentContentJson}
                setSelectedDocumentItem={setSelectedDocumentItem}
                hoveredSectionIndex={hoveredSectionIndex}
                isEnabledDocumentPerPositionTimesheetTotals={
                  documentTemplateVersion.organization.features
                    .isEnabledDocumentPerPositionTimesheetTotals
                }
                isEnabledDocumentPerPositionTimesheetTimes={
                  documentTemplateVersion.organization.features
                    .isEnabledDocumentPerPositionTimesheetTimes
                }
              />
            </DocumentPreviewContentContainer>
          </ScrollView>
        </DocumentPreviewColumn>
      </PageContentContainer>
    </PageContainer>
  );
};

const DocumentTemplateVersionEditorPage = () => {
  const {params} = useNavigationDOM();

  const {loading, data, refetch} = useQuery(DocumentTemplateVersionEditorPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.documentTemplateVersionUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <DocumentTemplateVersionEditorContent
            refetch={refetch}
            documentTemplateUuid={params.documentTemplateUuid}
            documentTemplateVersion={data.documentTemplateVersion}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateVersionEditorPage.query = gql`
  ${DocumentTemplateVersionForm.edit.fragment}
  ${DocumentTemplateVersionItemInputEditor.fragment}
  ${DocumentTemplateEditorDocumentItems.fragment}
  ${SectionDocumentItemsPanel.fragment}

  query DocumentTemplateVersionEditorPage(
    $uuid: String!,
  ) {
    ${gql.query}
    documentTemplateVersion(uuid: $uuid) {
      id
      uuid
      name
      version
      documentContentJson
      updatedAt
      documentTemplate {
        id
        category
        jobTypesWithDocumentTemplateOnJobTypeStep {
          id
        }
      }
      organization {
        id
        features {
          isEnabledDocumentPerPositionTimesheetTotals: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TOTALS")
          isEnabledDocumentPerPositionTimesheetTimes: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TIMES")
        }
        ...DocumentTemplateEditorDocumentItems
        ...SectionDocumentItemsPanel
      }
      ...DocumentTemplateVersionForm_edit
      ...DocumentTemplateVersionItemInputEditor
    }
  }
`;

export default DocumentTemplateVersionEditorPage;
