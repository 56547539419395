// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMemo, useModal, useQuery, useResponsive} from '@supermove/hooks';
import {
  Location,
  Organization,
  OrganizationModel,
  Warehouse,
  WarehouseModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Callout from '@shared/design/components/Callout';
import Line from '@shared/design/components/Line';
import Table, {ColumnDefinitionType} from '@shared/design/components/Table';
import TextTooltip from '@shared/design/components/TextTooltip';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CreateWarehouseModal from 'modules/Organization/Settings/Storage/Warehouses/components/CreateWarehouseModal';
import DeleteWarehouseModal from 'modules/Organization/Settings/Storage/Warehouses/components/DeleteWarehouseModal';
import EditWarehouseModal from 'modules/Organization/Settings/Storage/Warehouses/components/EditWarehouseModal';
import WarehouseActionsButton from 'modules/Organization/Settings/Storage/Warehouses/components/WarehouseActionsButton';
import StorageSettingsPageHeader from 'modules/Organization/Settings/Storage/components/StorageSettingsPageHeader';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Section = Styled.View`
`;

const CenteredCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Heading4}
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const getWarehousesTableColumnDefinitions = ({
  refetch,
  userId,
  organization,
  canEdit,
}: {
  refetch: () => void;
  userId: string;
  organization: OrganizationModel;
  canEdit: boolean;
}): ColumnDefinitionType<WarehouseModel>[] => [
  {
    width: 300,
    isHidden: !organization.features.isEnabledMultipleWarehouse,
    headerLabel: 'Branch Name',
    cellText: (warehouse) => warehouse.organization.name,
    secondary: {
      headerLabel: 'Branch Type',
      cellText: (warehouse) => Organization.getBranchTypeForOrganization(warehouse.organization),
    },
  },
  {
    width: 180,
    isHidden: organization.features.isEnabledMultipleWarehouse,
    headerLabel: 'Name',
    cellText: (warehouse) => warehouse.name,
    mobileOptions: {
      isInHeader: true,
      rank: 0,
    },
  },
  {
    width: 300,
    isHidden: !organization.features.isEnabledMultipleWarehouse,
    headerLabel: 'Warehouse Name',
    cellText: (warehouse) => warehouse.name,
    secondary: {
      headerLabel: 'Address',
      cellText: (warehouse) => Location.getDisplayLocation(warehouse.location),
      tooltip: (warehouse) => Location.getDisplayLocation(warehouse.location),
    },
    mobileOptions: {
      isInHeader: true,
      rank: 0,
    },
  },
  {
    width: 240,
    isHidden: organization.features.isEnabledMultipleWarehouse,
    headerLabel: 'Address',
    cellText: (warehouse) => Location.getDisplayLocation(warehouse.location),
    mobileOptions: {
      isInHeader: true,
      rank: 1,
    },
  },
  {
    width: 100,
    isHidden: !organization.features.isEnabledMultipleWarehouse,
    headerComponent: () => (
      <Row>
        <HeaderText>Default</HeaderText>
        <Space width={2} />
        <TextTooltip
          isEnabledMobileToast={false}
          placement={'top'}
          style={{textAlign: 'center', width: 296}}
          text={`This warehouse will be automatically selected when creating a move project for its branch.`}
          numberOfLines={2}
        >
          <IconContainer>
            <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.InfoCircle} />
          </IconContainer>
        </TextTooltip>
      </Row>
    ),
    cellComponent: (warehouse) => (
      <CenteredCell>
        {warehouse.isDefault ? (
          <Icon source={Icon.Check} size={16} color={colors.gray.primary} />
        ) : null}
      </CenteredCell>
    ),
  },
  {
    width: 300,
    headerLabel: 'Notes',
    cellText: (warehouse) => warehouse.notes,
    numberOfLines: 2,
  },
  {
    width: 100,
    isHidden: !canEdit,
    headerLabel: 'Actions',
    actions: (warehouse) => [
      {
        text: 'Edit warehouse',
        desktopIcon: Icon.Pen,
        onPress: ({handleOpen}) => handleOpen?.(),
        actionHook: {
          hook: useModal,
          hookArgument: {name: 'Edit Warehouse Modal'},
          renderComponent: ({hookKey, isOpen, handleClose}) => {
            return (
              <EditWarehouseModal
                key={hookKey}
                isOpen={isOpen}
                handleClose={handleClose}
                organization={organization}
                warehouse={warehouse}
                refetch={refetch}
              />
            );
          },
        },
      },
      {
        text: 'Remove warehouse',
        onPress: ({handleOpen}) => handleOpen?.(),
        actionHook: {
          hook: useModal,
          hookArgument: {name: 'Delete Warehouse Modal'},
          renderComponent: ({hookKey, isOpen, handleClose}) => {
            return (
              <DeleteWarehouseModal
                key={hookKey}
                deleteWarehouseModal={{isOpen, handleClose}}
                handleClose={handleClose}
                warehouse={warehouse}
                userId={userId}
                refetch={refetch}
              />
            );
          },
        },
      },
    ],
  },
];

const ContentContainer = ({
  isCompanySetting,
  children,
}: {
  isCompanySetting: boolean;
  children: React.ReactNode;
}) => {
  if (isCompanySetting) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return <ScrollView style={{flex: 1}}>{children}</ScrollView>;
};

const WarehouseTable = ({refetch, warehouses, userId, organization, canEdit}: any) => {
  const sortedWarehouses = useMemo(
    () => Warehouse.sortByOrganizationKind(warehouses),
    [warehouses],
  );

  return (
    <Table
      columnDefinitions={getWarehousesTableColumnDefinitions({
        refetch,
        userId,
        organization,
        canEdit,
      })}
      emptyStateText={'No warehouses to display'}
      items={sortedWarehouses}
    />
  );
};

const WarehousesSection = ({refetch, userId, warehouses, organization, canEdit}: any) => {
  return (
    <Section style={{maxWidth: 1120}}>
      <WarehouseTable
        refetch={refetch}
        userId={userId}
        warehouses={warehouses}
        organization={organization}
        canEdit={canEdit}
      />
      <Space height={24} />
    </Section>
  );
};

const WarehouseSettingsPageContent = ({isCompanySetting}: any) => {
  const responsive = useResponsive();
  const createWarehouseModal = useModal({name: 'Create Warehouse Modal'});
  const {loading, data, refetch} = useQuery(WarehouseSettingsPageContent.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }
  const {viewingOrganization} = data.viewer;
  const canEdit = viewingOrganization.features.isEnabledMultipleWarehouse
    ? viewingOrganization.isOwnerOfSettings
    : true;

  return (
    <PageContainer>
      {!isCompanySetting && (
        <React.Fragment>
          <StorageSettingsPageHeader organization={viewingOrganization} />
          <Line />
        </React.Fragment>
      )}
      <ContentContainer isCompanySetting={isCompanySetting}>
        {!isCompanySetting && (
          <React.Fragment>
            <Section style={{paddingHorizontal: responsive.desktop ? 24 : 16}}>
              <Space height={24} />
              <Title>Warehouses</Title>
              <Space height={12} />
              <Subtitle>Manage warehouses and the branch they belong to.</Subtitle>
              <Space height={24} />
            </Section>
          </React.Fragment>
        )}
        <Section style={{paddingHorizontal: !responsive.desktop ? 16 : !isCompanySetting ? 24 : 0}}>
          {canEdit ? (
            <Button
              text={'Create Warehouse'}
              iconLeft={Icon.Plus}
              onPress={createWarehouseModal.handleOpen}
            />
          ) : (
            <Callout
              text={`You are viewing a branch. To make changes, switch to the main branch.`}
              width={responsive.desktop ? 510 : undefined}
            />
          )}
        </Section>
        <Space height={24} />
        {!responsive.desktop && <Line />}
        <Section style={{paddingHorizontal: !isCompanySetting && responsive.desktop ? 24 : 0}}>
          <WarehousesSection
            refetch={refetch}
            userId={data.viewer.id}
            organization={viewingOrganization}
            warehouses={viewingOrganization.companySettings.warehouses}
            canEdit={canEdit}
          />
        </Section>
        <CreateWarehouseModal
          key={createWarehouseModal.key} // Ensures values are empty when modal is opened
          isOpen={createWarehouseModal.isOpen}
          handleClose={createWarehouseModal.handleClose}
          organization={viewingOrganization}
          userId={data.viewer.id}
          refetch={refetch}
        />
      </ContentContainer>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WarehouseSettingsPageContent.query = gql`
  ${Location.getDisplayLocation.fragment}
  ${WarehouseActionsButton.fragment}
  ${StorageSettingsPageHeader.fragment}
  ${Organization.getBranchTypeForOrganization.fragment}
  ${CreateWarehouseModal.fragment}

  query WarehouseSettingsPageContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        name
        isOwnerOfSettings
        features {
          isEnabledMultipleWarehouse: isEnabled(feature: "MULTIPLE_WAREHOUSE")
        }
        companySettings {
          warehouses {
            id
            name
            notes
            isDefault
            location {
              id
              ...Location_getDisplayLocation
            }
            organization {
              id
              name
              ...Organization_getBranchTypeForOrganization
            }
            ...WarehouseActionsButton
          }
        }
        ...StorageSettingsPageHeader
        ...CreateWarehouseModal
        ...WarehouseActionsButton_Organization
      }
    }
  }
`;

export default WarehouseSettingsPageContent;
