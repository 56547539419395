// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App

import InventoryLibraryKind from '@shared/modules/Inventory/enums/InventoryLibraryKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const InventoryLibrarySettingsPage = ({
  children,
  isLoading,
  inventoryLibraryUuid,
  inventoryLibraryName,
  inventoryLibraryKind,
}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <SettingsPage
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        // Since these are nested library pages, we push to the libraries tab on Inventory breadcrumb
        {name: 'Inventory', onPress: () => navigator.push('/settings/inventory/libraries')},
        {name: inventoryLibraryName},
      ]}
      scrollViewContentContainerStyle={isLoading ? {flex: 1} : null}
      tabs={[
        {
          label: 'Categories',
          url: `/settings/inventory/libraries/${inventoryLibraryUuid}/categories`,
        },
        {
          label: 'Rooms',
          url: `/settings/inventory/libraries/${inventoryLibraryUuid}/rooms`,
        },
        {
          label: 'Cartons',
          url: `/settings/inventory/libraries/${inventoryLibraryUuid}/cartons`,
        },
        {
          label: 'Items',
          url: `/settings/inventory/libraries/${inventoryLibraryUuid}/items`,
        },
        ...List.insertIf(
          inventoryLibraryKind === InventoryLibraryKind.DRIVER_INVENTORY,
          {
            label: 'Item Tags',
            url: `/settings/inventory/libraries/${inventoryLibraryUuid}/item-tags`,
          },
          {
            label: 'Exception Locations',
            url: `/settings/inventory/libraries/${inventoryLibraryUuid}/exception-locations`,
          },
          {
            label: 'Exception Types',
            url: `/settings/inventory/libraries/${inventoryLibraryUuid}/exception-types`,
          },
        ),
      ]}
    >
      <Loading loading={isLoading} as={PageLoadingIndicator}>
        {() => children}
      </Loading>
    </SettingsPage>
  );
};

export default InventoryLibrarySettingsPage;
