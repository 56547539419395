// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon, IconSource} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {CapacityCalendarDayModel, CapacityCalendarModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';
import CapacityCalendarTimeFilter from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarTimeFilter';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
`;

const MetricContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const SecondaryMetricRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CapacityNumeratorText = Styled.Text`
  ${Typography.Heading3}
  color: ${colors.gray.primary};
`;

const CapacityDenominatorText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.tertiary};
`;

const MobileCapacityNumeratorText = Styled.Text`
  ${Typography.Desktop.Heading1}
  color: ${colors.gray.primary};
`;

const MobileCapacityDenominatorText = Styled.Text`
  ${Typography.Desktop.Heading1}
  color: ${colors.gray.tertiary};
`;

const SecondaryNumeratorText = Styled.Text`
  ${Typography.Desktop.Label}
  color: ${colors.gray.primary};
`;

const SecondaryDenominatorText = Styled.Text`
  ${Typography.Desktop.Micro}
  color: ${colors.gray.tertiary};
`;

const CapacityDescriptionText = Styled.Text`
  ${Typography.Desktop.Micro}
  color: ${colors.gray.tertiary};
`;

const DayNotesText = Styled.Text`
  ${Typography.Desktop.Micro}
  color: ${colors.gray.secondary};
`;

const SummaryDayNotesText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const SummaryCountContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 64px;
`;

const SummaryCountText = Styled.Text<{color?: string}>`
  ${Typography.Responsive.Label}
  color: ${({color}) => color || colors.gray.primary};
`;

const SummaryDescriptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const NumeratorAndDenominatorWithSubtitle = ({
  numerator,
  denominator,
  subtitle,
  isSecondaryMetric,
  isSummary,
  icon,
}: {
  numerator: number;
  denominator: number;
  subtitle: string;
  isSecondaryMetric: boolean;
  isSummary: boolean;
  icon: IconSource;
}) => {
  const responsive = useResponsive();
  const toolTipSubtitle = `${numerator}/${denominator} ${subtitle}`;

  if (isSummary) {
    return (
      <Row>
        <SummaryCountContainer>
          <SummaryCountText responsive={responsive}>{numerator}</SummaryCountText>
          <SummaryCountText responsive={responsive} color={colors.gray.tertiary}>
            /{denominator}
          </SummaryCountText>
        </SummaryCountContainer>
        <Space width={16} />
        <SummaryDescriptionText numberOfLines={1} responsive={responsive}>
          {subtitle}
        </SummaryDescriptionText>
      </Row>
    );
  }

  return (
    <React.Fragment>
      <TextTooltip
        style={{zIndex: 99999}}
        placement={'bottom'}
        text={toolTipSubtitle}
        isEnabledMobileToast={false}
      >
        {!isSecondaryMetric ? (
          <Container>
            {responsive.desktop ? (
              <MetricContainer>
                <CapacityNumeratorText>{numerator}</CapacityNumeratorText>
                <CapacityDenominatorText>/{denominator}</CapacityDenominatorText>
              </MetricContainer>
            ) : (
              <MetricContainer>
                <MobileCapacityNumeratorText>{numerator}</MobileCapacityNumeratorText>
                <MobileCapacityDenominatorText>/{denominator}</MobileCapacityDenominatorText>
              </MetricContainer>
            )}
            <CapacityDescriptionText>{subtitle}</CapacityDescriptionText>
          </Container>
        ) : (
          <SecondaryMetricRowContainer>
            <MetricContainer>
              <SecondaryNumeratorText>{numerator}</SecondaryNumeratorText>
              <SecondaryDenominatorText>/{denominator}</SecondaryDenominatorText>
            </MetricContainer>
            <Space width={4} />
            <Icon source={icon} color={colors.gray.secondary} size={responsive.desktop ? 12 : 16} />
          </SecondaryMetricRowContainer>
        )}
      </TextTooltip>
    </React.Fragment>
  );
};

const NumberWithSubtitle = ({
  number,
  subtitle,
  isSecondaryMetric,
  isSummary,
  icon,
}: {
  number: number;
  subtitle: string;
  isSecondaryMetric: boolean;
  isSummary: boolean;
  icon: IconSource;
}) => {
  const responsive = useResponsive();
  const toolTipSubtitle = `${number} ${subtitle}`;

  if (isSummary) {
    return (
      <Row>
        <SummaryCountContainer>
          <SummaryCountText responsive={responsive}>{number}</SummaryCountText>
        </SummaryCountContainer>
        <Space width={16} />
        <SummaryDescriptionText numberOfLines={1} responsive={responsive}>
          {subtitle}
        </SummaryDescriptionText>
      </Row>
    );
  }

  return (
    <React.Fragment>
      <TextTooltip
        style={{zIndex: 99999}}
        placement={'bottom'}
        text={toolTipSubtitle}
        isEnabledMobileToast={false}
      >
        {!isSecondaryMetric ? (
          <Container>
            <MetricContainer>
              <CapacityNumeratorText>{number}</CapacityNumeratorText>
            </MetricContainer>
            <CapacityDescriptionText>{subtitle}</CapacityDescriptionText>
          </Container>
        ) : (
          <SecondaryMetricRowContainer>
            <SecondaryNumeratorText>{number}</SecondaryNumeratorText>
            <Space width={4} />
            <Icon source={icon} color={colors.gray.secondary} size={responsive.desktop ? 12 : 16} />
          </SecondaryMetricRowContainer>
        )}
      </TextTooltip>
    </React.Fragment>
  );
};

const DayNotesMetric = ({dayNotes, isSummary}: {dayNotes?: string; isSummary: boolean}) => {
  const responsive = useResponsive();
  const displayNotes = dayNotes || '';

  if (isSummary) {
    return (
      <SummaryDayNotesText numberOfLines={1} responsive={responsive}>
        {displayNotes}
      </SummaryDayNotesText>
    );
  }
  return <DayNotesText numberOfLines={1}>{displayNotes}</DayNotesText>;
};

const DaySlotMetric = ({
  metric,
  capacityCalendarDay,
  capacityCalendar,
  shouldHideDayNotes = false,
  isSecondaryMetric = false,
  isSummary = false,
  isEnabledCapacityCalendarAmPmMetric,
}: {
  metric?: string;
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
  shouldHideDayNotes?: boolean;
  isSecondaryMetric?: boolean;
  isSummary?: boolean;
  isEnabledCapacityCalendarAmPmMetric: boolean;
}) => {
  const {positionName, secondaryPositionName, primaryTimeFilter, secondaryTimeFilter} =
    capacityCalendar.additionalCapacityCalendarMetadata;
  const primaryTimeFilterDisplay =
    CapacityCalendarTimeFilter.getCapacityCalendarTimeFilterKindDisplay(primaryTimeFilter);
  const secondaryTimeFilterDisplay =
    CapacityCalendarTimeFilter.getCapacityCalendarTimeFilterKindDisplay(secondaryTimeFilter);
  const timeFilterDeplayString = isSecondaryMetric
    ? secondaryTimeFilterDisplay
    : primaryTimeFilterDisplay;
  const positionDisplayString = `${timeFilterDeplayString}${isSecondaryMetric ? secondaryPositionName : positionName}`;

  switch (metric) {
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryTotalJobPositionCount
              : capacityCalendarDay.totalJobPositionCount
          }
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${positionDisplayString}s committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_DISPATCHED_BY_POSITION:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryTotalJobPositionDispatchedCount
              : capacityCalendarDay.totalJobPositionDispatchedCount
          }
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${positionDisplayString}s dispatched`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric && isEnabledCapacityCalendarAmPmMetric
              ? capacityCalendarDay.secondaryTotalTruckCount
              : capacityCalendarDay.totalTruckCount
          }
          denominator={
            isSecondaryMetric
              ? capacityCalendarDay.secondaryAvailablePositionCount
              : capacityCalendarDay.availablePositionCount
          }
          subtitle={`${positionDisplayString}s committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.DAY_NOTES:
      if (!shouldHideDayNotes) {
        return <DayNotesMetric dayNotes={capacityCalendarDay.dayNotes} isSummary={isSummary} />;
      }
      return null;
    case CapacityCalendarSlotMetricKind.TOTAL_TRUCKS_AVAILABLE:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric && isEnabledCapacityCalendarAmPmMetric
              ? capacityCalendarDay.secondaryTotalTruckCount
              : capacityCalendarDay.totalTruckCount
          }
          denominator={capacityCalendarDay.availableTruckCount}
          subtitle={`${timeFilterDeplayString}Trucks committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.Truck}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric && isEnabledCapacityCalendarAmPmMetric
              ? capacityCalendarDay.secondaryTotalAllJobUserCount
              : capacityCalendarDay.totalAllJobUserCount
          }
          denominator={capacityCalendarDay.availableAllMoverCount}
          subtitle={`${timeFilterDeplayString}Crew committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_JOBS:
      return (
        <NumberWithSubtitle
          number={
            isSecondaryMetric && isEnabledCapacityCalendarAmPmMetric
              ? capacityCalendarDay.secondaryTotalJobCount
              : capacityCalendarDay.totalJobCount
          }
          subtitle={`${timeFilterDeplayString}Total jobs`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.BriefCaseBlank}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_COMMITTED:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric && isEnabledCapacityCalendarAmPmMetric
              ? capacityCalendarDay.secondaryTotalCrewCommitted
              : capacityCalendarDay.totalCrewCommitted
          }
          denominator={capacityCalendarDay.availableAllMoverCount}
          subtitle={`${timeFilterDeplayString}Crew committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_CREW_DISPATCHED:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric && isEnabledCapacityCalendarAmPmMetric
              ? capacityCalendarDay.secondaryTotalCrewDispatched
              : capacityCalendarDay.totalCrewDispatched
          }
          denominator={capacityCalendarDay.availableAllMoverCount}
          subtitle={`${timeFilterDeplayString}Crew dispatched`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.User}
        />
      );
    case CapacityCalendarSlotMetricKind.TOTAL_TRUCK_COMMITTED:
      return (
        <NumeratorAndDenominatorWithSubtitle
          numerator={
            isSecondaryMetric && isEnabledCapacityCalendarAmPmMetric
              ? capacityCalendarDay.secondaryTotalTruckCommitted
              : capacityCalendarDay.totalTruckCommitted
          }
          denominator={capacityCalendarDay.availableTruckCount}
          subtitle={`${timeFilterDeplayString}Trucks committed`}
          isSecondaryMetric={isSecondaryMetric}
          isSummary={isSummary}
          icon={Icon.Truck}
        />
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DaySlotMetric.fragment = gql`
  fragment DaySlotMetric on CapacityCalendar {
    capacityCalendarDays {
      totalJobPositionCount
      secondaryTotalJobPositionCount
      totalJobPositionDispatchedCount
      secondaryTotalJobPositionDispatchedCount
      availablePositionCount
      secondaryAvailablePositionCount
      dayNotes
      totalTruckCount
      secondaryTotalTruckCount
      totalJobCount
      secondaryTotalJobCount
      availableTruckCount
      totalAllJobUserCount
      secondaryTotalAllJobUserCount
      availableAllMoverCount
      totalCrewCommitted
      secondaryTotalCrewCommitted
      totalCrewDispatched
      secondaryTotalCrewDispatched
      totalTruckCommitted
      secondaryTotalTruckCommitted
    }
    additionalCapacityCalendarMetadata {
      positionName
      secondaryPositionName
      primaryTimeFilter
      secondaryTimeFilter
    }
  }
`;

export default DaySlotMetric;
