// Library
import React from 'react';

// Supermove
import {Icon, Popover, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {PopoverType, ResponsiveType, usePopover} from '@supermove/hooks';
import {ConversationModel, Conversation, ProjectModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Sheet from '@shared/design/components/Sheet';
import ConversationForm from '@shared/modules/Communication/forms/ConversationForm';
import useUpdateConversationMutation from '@shared/modules/Communication/hooks/useUpdateConversationMutation';

interface CommunicationInboxConversationPopoverOptionsProps {
  responsive: ResponsiveType;
  projectUuid?: string;
}

const Container = Styled.View`
  padding-right: 8px;
  background-color: ${colors.white}
`;

const PopoverBody = Styled.View<{responsive: ResponsiveType}>`
    width: ${({responsive}) => (responsive.desktop ? '200px' : '100%')};
    border-radius: 5px;
    border: ${({responsive}) => (responsive.desktop ? `1px solid ${colors.gray.border}` : '0px')};
    background-color: ${colors.white};
    overflow: visible;
    padding: 24px;
`;

const ConversationOption = Styled.Text`
    ${Typography.Responsive.Body}
`;

const CommunicationInboxConversationOptionsPopoverContent = ({
  handleToggleStatus,
  responsive,
  conversation,
}: {
  responsive: ResponsiveType;
  conversation: ConversationModel;
  handleToggleStatus: () => void;
}) => {
  return (
    <PopoverBody responsive={responsive}>
      <TertiaryButton onPress={handleToggleStatus}>
        <ConversationOption responsive={responsive}>
          {conversation.status === Conversation.ConversationStatus.OPEN
            ? 'Mark as resolved'
            : 'Mark as follow up'}
        </ConversationOption>
      </TertiaryButton>
    </PopoverBody>
  );
};

const ConversationPopoverContent = ({
  conversation,
  responsive,
  popover,
  onSuccess,
}: {
  conversation: ConversationModel;
  responsive: ResponsiveType;
  popover: PopoverType;
  onSuccess: () => void;
}) => {
  const {form, handleSubmit, submitting} = useUpdateConversationMutation({
    conversationForm: ConversationForm.edit(conversation),
    refetchQueries: ['CommunicationInboxProjectList', 'GlobalNavigationSidebar'],
    onSuccess,
    onError: (error) => console.log({error}),
  });
  const handleToggleStatus = () => {
    form.setFieldValue(
      'conversationForm.status',
      conversation.status === Conversation.ConversationStatus.OPEN
        ? Conversation.ConversationStatus.CLOSED
        : Conversation.ConversationStatus.OPEN,
    );
    setImmediate(handleSubmit);
    popover.handleClose();
  };
  return responsive.desktop ? (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <CommunicationInboxConversationOptionsPopoverContent
        conversation={conversation}
        responsive={responsive}
        handleToggleStatus={handleToggleStatus}
      />
    </Popover>
  ) : (
    <Sheet.PreventPropagationContainer>
      <Sheet
        isOpen={popover.isOpen}
        handleClose={popover.handleClose}
        HeaderRightComponent={() => <Icon source={Icon.Xmark} />}
        headerText={'Actions'}
        isFixedHeight={false}
        headerContainerStyle={{backgroundColor: colors.white}}
      >
        <CommunicationInboxConversationOptionsPopoverContent
          conversation={conversation}
          responsive={responsive}
          handleToggleStatus={handleToggleStatus}
        />
      </Sheet>
    </Sheet.PreventPropagationContainer>
  );
};

const CommunicationInboxConversationPopover = ({
  conversation,
  popover,
  responsive,
  refetch,
}: {
  conversation?: ConversationModel;
  popover: PopoverType;
  responsive: ResponsiveType;
  refetch: () => void;
}) => {
  if (!conversation) {
    return null;
  }

  return (
    <ConversationPopoverContent
      conversation={conversation}
      popover={popover}
      responsive={responsive}
      onSuccess={refetch}
    />
  );
};

const CommunicationInboxConversationPopoverOptions = ({
  responsive,
  projectUuid,
}: CommunicationInboxConversationPopoverOptionsProps) => {
  const {data, refetch} = useQuery<{project: ProjectModel}>(
    CommunicationInboxConversationPopoverOptions.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        projectUuid,
      },
      skip: !projectUuid,
    },
  );
  const conversationOptionsPopover = usePopover({
    name: 'Communication Inbox Conversation Options Popover',
  });
  const conversation = data?.project?.conversations?.[0];

  const refetchInfo = () => {
    refetch();
  };

  return (
    <Container>
      <CommunicationInboxConversationPopover
        conversation={conversation}
        popover={conversationOptionsPopover}
        responsive={responsive}
        refetch={refetchInfo}
      />
      <Popover.Content innerRef={conversationOptionsPopover.ref}>
        <IconButton
          source={Icon.EllipsisV}
          onPress={() => (conversation ? conversationOptionsPopover.handleToggle() : null)}
          isSecondary
        />
      </Popover.Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxConversationPopoverOptions.query = gql`
  ${ConversationForm.edit.fragment}
  query CommunicationInboxProjectConversation(
    $projectUuid: String!,
  ) {
    ${gql.query}
      project(uuid: $projectUuid) {
        id
        conversations {
          id
          status
        ...ConversationForm_edit
        }
      }
  }
`;

export default CommunicationInboxConversationPopoverOptions;
