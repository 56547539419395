// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Icon, Popover, Styled} from '@supermove/components';
import {
  useForm,
  Form,
  useEffect,
  useResponsive,
  UrlFiltersType,
  useDebouncedCallback,
  usePopover,
  PopoverType,
} from '@supermove/hooks';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SearchBar from '@shared/design/components/SearchBar';
import PopoverFilterWithFooter from '@shared/modules/App/components/PopoverFilterWithFooter';
import UserRole, {RoleType} from '@shared/modules/User/enums/UserRole';
import {OfficeStaffContentV2FilterType} from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContentV2';

const FILTER_KEYS: (keyof OfficeStaffContentV2FilterType)[] = ['roles'];

type FormType = Form<{
  roles: string[];
}>;

const getFormInitialValues = ({
  urlFilters,
}: {
  urlFilters: UrlFiltersType<OfficeStaffContentV2FilterType>;
}) => {
  return {
    roles: urlFilters.get('roles') || [],
  };
};

const handleClear = ({form}: {form: FormType}) => {
  form.setValues({
    roles: [],
  });
};

const handleApply = ({
  form,
  urlFilters,
}: {
  form: FormType;
  urlFilters: UrlFiltersType<OfficeStaffContentV2FilterType>;
}) => {
  const updatedFilters = FILTER_KEYS.reduce((result, key) => {
    const value = _.get(form.values, key);
    return {...result, [key]: _.isEmpty(value) ? undefined : value};
  }, {});

  urlFilters.handleUpdate(updatedFilters);
};

const Row = Styled.View`
  flex-direction: row;
`;

const StaffFilters = ({form, viewerRole}: {form: FormType; viewerRole: RoleType}) => {
  return (
    <FieldInput
      {...form}
      label={'Role'}
      name={'roles'}
      component={MultiDropdownCheckboxInput}
      input={{
        options: UserRole.getDropdownOptions({viewerRole}),
        setFieldValue: form.setFieldValue,
        usePills: true,
        isResponsive: true,
        isEnabledSelectAll: true,
        isDescriptionBelow: true,
      }}
      isResponsive
      index={0}
    />
  );
};

const StaffFiltersButton = ({
  popover,
  urlFilters,
  viewerRole,
}: {
  popover: PopoverType;
  urlFilters: UrlFiltersType<OfficeStaffContentV2FilterType>;
  viewerRole: RoleType;
}) => {
  const responsive = useResponsive();
  const activeFiltersCount = urlFilters.getFilterCount({
    filterKeys: FILTER_KEYS,
  });
  const activeFiltersCountLabel = urlFilters.getFilterCountLabel({
    filterKeys: FILTER_KEYS,
  });
  const form = useForm({
    initialValues: getFormInitialValues({urlFilters}),
    enableReinitialize: true,
  });

  // Reset the form whenever you reopen the popover
  useEffect(() => {
    if (popover.isOpen && form.dirty) {
      form.setValues(getFormInitialValues({urlFilters}));
    }
  }, [popover.isOpen]);

  return (
    <React.Fragment>
      <Popover.Content innerRef={popover.ref}>
        <SecondaryButton
          onPress={popover.handleToggle}
          text={responsive.desktop ? activeFiltersCountLabel : `(${activeFiltersCount})`}
          iconLeft={Icon.Filter}
          isResponsive
        />
      </Popover.Content>
      <PopoverFilterWithFooter
        activeFiltersCount={urlFilters.getFilterCount({
          filterKeys: FILTER_KEYS,
        })}
        popover={popover}
        handleClear={() => handleClear({form})}
        handleApply={() => {
          popover.handleClose();
          handleApply({form, urlFilters});
        }}
      >
        <StaffFilters form={form} viewerRole={viewerRole} />
        <Space height={16} />
      </PopoverFilterWithFooter>
    </React.Fragment>
  );
};

const OfficeStaffSearchAndFilters = ({
  urlFilters,
  viewerRole,
}: {
  urlFilters: UrlFiltersType<OfficeStaffContentV2FilterType>;
  viewerRole: RoleType;
}) => {
  const staffFiltersPopover = usePopover();

  const handleChangeSearch = useDebouncedCallback(
    (input) => urlFilters.handleUpdate({searchQuery: input}),
    250,
  );

  return (
    <Row>
      <SearchBar
        onChangeText={handleChangeSearch}
        placeholder='Search by name, phone number, email, or position'
        containerStyle={{flex: 1}}
        style={{flex: 1}}
        defaultValue={urlFilters.get('searchQuery')}
        isClearable
        isResponsive
      />
      <Space width={8} />
      <StaffFiltersButton
        popover={staffFiltersPopover}
        urlFilters={urlFilters}
        viewerRole={viewerRole}
      />
    </Row>
  );
};

export default OfficeStaffSearchAndFilters;
