// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder = 'Select default item', value}: any) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const ExternalInvoiceItemsDropdown = ({form, field, label, options, isDisabled = false}: any) => {
  return (
    <FieldInput
      {...form}
      label={label}
      name={`codatIntegrationForm.${field}`}
      component={DropdownInput}
      input={{
        disabled: isDisabled,
        isPortaled: true,
        options,
        isClearable: true,
        placeholder: 'Select default item',
        setFieldValue: form.setFieldValue,
        style: {
          flex: 1,
        },
      }}
      style={{
        flex: 1,
      }}
    />
  );
};

const AccountingIntegrationPaymentPanelEdit = ({form, codatIntegration}: any) => {
  const externalInvoiceOptions = codatIntegration.externalInvoiceItems.map(
    (externalInvoiceOption: any) => ({
      value: _.toString(externalInvoiceOption.id),
      label: externalInvoiceOption.name,
    }),
  );
  const accountOptions = codatIntegration.accountOptions.map((accountOption: any) => ({
    // Note(Kevin): We need to append spaces after : and , in order for the backend json to
    // match the account option after its been stringified
    value: JSON.stringify({id: accountOption.id, name: accountOption.name})
      .replace(/:/g, ': ')
      .replace(/,/g, ', '),
    label: accountOption.name,
  }));

  const externalIntegrationPaymentMethods = codatIntegration.externalIntegrationPaymentMethods.map(
    (externalIntegrationPaymentMethod: any) => ({
      value: _.toString(externalIntegrationPaymentMethod.id),
      label: externalIntegrationPaymentMethod.name,
    }),
  );

  return (
    <React.Fragment>
      <Row>
        <Column>
          <ExternalInvoiceItemsDropdown
            label={'Payment Method Fees'}
            field={`paymentMethodFeeExternalInvoiceItemId`}
            options={externalInvoiceOptions}
            form={form}
          />
        </Column>
        <Space width={24} />
        <Column>
          <FieldInput
            {...form}
            label={'Associated Account for Payment'}
            name={`codatIntegrationForm.codatAccountReferenceJson`}
            isRequired
            component={DropdownInput}
            input={{
              isPortaled: true,
              options: accountOptions,
              isClearable: true,
              placeholder: 'Select account',
              noOptionsMessage: () => 'No accounts found. Please refresh the page.',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              flex: 1,
            }}
          />
        </Column>
      </Row>
      <Space height={16} />
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Payment Methods to Skip'}
            name={`codatIntegrationForm.externalIntegrationPaymentMethodIdsToSkip`}
            isRequired
            component={MultiDropdownInput}
            input={{
              isPortaled: true,
              options: externalIntegrationPaymentMethods,
              isClearable: true,
              placeholder: 'Select payment methods',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              flex: 1,
            }}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

const AccountingIntegrationPaymentPanel = ({codatIntegration}: any) => {
  const {
    paymentMethodFeeExternalInvoiceItem,
    codatAccountReferenceJson,
    externalIntegrationPaymentMethodsToSkip,
    organization,
  } = codatIntegration;
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Payment Method Fees'}
            value={paymentMethodFeeExternalInvoiceItem?.name}
          />
        </Column>
        <Space width={24} />
        <Column>
          <ViewCell
            label={'Associated Account for Payment'}
            value={codatAccountReferenceJson ? JSON.parse(codatAccountReferenceJson).name : null}
            placeholder={'Select account'}
          />
        </Column>
      </Row>
      <Space height={16} />
      {organization.features.isEnabledRutterPaymentMethod && (
        <React.Fragment>
          <EditPanel.LabelText>{'Payment Methods to Skip'}</EditPanel.LabelText>
          <Space height={8} />
          <Row>
            {externalIntegrationPaymentMethodsToSkip.map((paymentMethod: any) => (
              <Row>
                <ResponsiveBadge
                  label={paymentMethod.name}
                  key={paymentMethod.id}
                  style={{marginBottom: 8}}
                />
                <Space width={8} />
              </Row>
            ))}
          </Row>
        </React.Fragment>
      )}
      <Space width={24} />
    </React.Fragment>
  );
};

AccountingIntegrationPaymentPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
      <Space height={16} />
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

AccountingIntegrationPaymentPanel.fragment = gql`
  fragment AccountingIntegrationPaymentPanel on CodatIntegration {
    id
    externalIntegrationPaymentMethods {
      id
      name
    }
    accountOptions {
      id
      name
    }
    externalInvoiceItems {
      id
      name
    }
    paymentMethodFeeExternalInvoiceItem {
      id
      name
    }
    externalIntegrationPaymentMethodsToSkip {
      id
      name
    }
    codatAccountReferenceJson
    organization {
      id
      features {
        isEnabledRutterPaymentMethod: isEnabled(feature: "RUTTER_PAYMENT_METHOD")
      }
    }
  }
`;

AccountingIntegrationPaymentPanel.Edit = AccountingIntegrationPaymentPanelEdit;

export default AccountingIntegrationPaymentPanel;
