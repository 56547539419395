// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useForm, useResponsive, ResponsiveType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import WorkflowBuilder from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilder';

const WorkflowPreviewPanelContainer = Styled.View<{responsive: ResponsiveType}>`
  ${({responsive}) => (responsive.desktop ? 'flex: 1; width: 580px;' : 'padding-horizontal: 16px;')}
`;

const EmptyContentContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const WorkflowPreviewPlaceholderText = Styled.Text`
  ${Typography.Subheading};
  color: ${colors.gray.tertiary};
`;

const WorkflowPreviewPlaceholder = () => {
  return (
    <EmptyContentContainer>
      <WorkflowPreviewPlaceholderText>
        Click edit automation to start building!
      </WorkflowPreviewPlaceholderText>
    </EmptyContentContainer>
  );
};

const WorkflowPreviewerContent = ({workflow}: any) => {
  const responsive = useResponsive();
  const form = useForm({
    initialValues: {workflowForm: WorkflowForm.edit(workflow)},
  });

  const isEmptyWorkflow = !workflow.workflowSteps.length;
  if (isEmptyWorkflow) {
    return <WorkflowPreviewPlaceholder />;
  }
  return (
    <WorkflowBuilder workflow={workflow} form={form} isPreview isScrollable={responsive.desktop} />
  );
};

const WorkflowPreviewPanel = ({workflows}: any) => {
  const {
    params: {workflowUuid},
  } = useNavigationDOM();
  const responsive = useResponsive();
  const selectedWorkflow = _.find(workflows, (workflow) => workflow.uuid === workflowUuid);

  const {loading, data} = useQuery(WorkflowPreviewPanel.query, {
    fetchPolicy: 'cache-and-network',
    skip: !selectedWorkflow,
    variables: {
      uuid: workflowUuid,
    },
  });

  return (
    <WorkflowPreviewPanelContainer responsive={responsive}>
      {loading ? (
        <EmptyContentContainer>
          <PageLoadingIndicator />
        </EmptyContentContainer>
      ) : (
        <WorkflowPreviewerContent workflow={data.workflow} />
      )}
    </WorkflowPreviewPanelContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowPreviewPanel.query = gql`
  ${WorkflowBuilder.fragment}
  ${WorkflowForm.edit.fragment}

  query WorkflowPreviewPanel($uuid: String!) {
    ${gql.query}
    workflow(uuid: $uuid) {
      id
      workflowSteps {
        id
      }
      ...WorkflowBuilder
      ...WorkflowForm_edit
    }
  }
`;

export default WorkflowPreviewPanel;
