/* eslint-disable react-hooks/rules-of-hooks */
// Libararies
import React from 'react';

// Supermove
import {Icon, PreventPropagation, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ResponsiveType,
  useResponsive,
  useModal,
  useState,
  useToast,
  PaginationType,
} from '@supermove/hooks';
import {Project, UserFlowRun, UserFlowRunModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/Table';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import UserFlowRunStatus from '@shared/modules/UserFlows/enums/UserFlowRunStatus';
import DocumentFlowRunDetailsDrawer from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowRunDetailsDrawer';

const Container = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 0)}px;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ColorDot = Styled.View<{color: string}>`
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background-color: ${({color}) => color};
`;

const ProgressText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const getColumnDefinitions = ({responsive}: {responsive: ResponsiveType}) => {
  const copyIdentifierSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Copied to clipboard.',
  });

  const handleCopyLink = (value: string) => {
    navigator.clipboard.writeText(value);
    copyIdentifierSuccessToast.handleToast();
  };
  return [
    {
      flex: 3,
      headerLabel: 'Status',
      cellComponent: (userFlowRun: UserFlowRunModel) =>
        userFlowRun.isExpired ? (
          <Badge label='Expired' color={colors.red700} />
        ) : (
          <Badge
            label={UserFlowRunStatus.convertUserFlowRunStatusToLabel(userFlowRun.status)}
            color={UserFlowRunStatus.convertUserFlowRunStatusToColor(userFlowRun.status)}
            isResponsive
          />
        ),
    },
    {
      flex: 3,
      headerLabel: 'Project',
      cellText: (userFlowRun: UserFlowRunModel) => Project.getName(userFlowRun.project),
      cellTextLink: (userFlowRun: UserFlowRunModel) => `/projects/${userFlowRun.project.uuid}`,
    },
    {
      flex: 3,
      headerLabel: 'Sent on',
      cellText: (userFlowRun: UserFlowRunModel) => {
        return Datetime.convertToDisplayDatetime(userFlowRun.createdAt);
      },
      secondary: {
        headerLabel: 'Sent by',
        cellText: (userFlow: UserFlowRunModel) => {
          return userFlow.createdBy.fullName;
        },
      },
    },
    {
      flex: 3,
      headerLabel: 'Progress',
      cellComponent: (userFlowRun: UserFlowRunModel) => {
        const color = UserFlowRun.getStepsProgressDotColor(userFlowRun);
        return (
          <Row>
            <ColorDot color={color} />
            <Space width={8} />
            <ProgressText responsive={responsive}>
              {UserFlowRun.getStepsProgressText(userFlowRun)}
            </ProgressText>
          </Row>
        );
      },
      secondary: {
        headerLabel: 'Completed at',
        cellText: (userFlow: UserFlowRunModel) => {
          return userFlow.completedAt
            ? Datetime.convertToDisplayDatetime(userFlow.completedAt)
            : undefined;
        },
        emptyText: '-',
      },
    },
    {
      flex: 3,
      headerLabel: 'Customer Link',
      cellComponent: (userFlowRun: UserFlowRunModel) => {
        const link = UserFlowRun.getLink(userFlowRun);
        return (
          <PreventPropagation>
            <IconButton source={Icon.Copy} onPress={() => handleCopyLink(link)} />
            <Space width={8} />
            <a href={link} target='_blank'>
              <LinkText responsive={responsive}>Flow Link</LinkText>
            </a>
          </PreventPropagation>
        );
      },
    },
  ];
};

const DocumentsSettingsDocumentFlowRunsPageContent = ({
  userFlowRuns,
  pagination,
}: {
  userFlowRuns: UserFlowRunModel[];
  pagination: PaginationType;
}) => {
  const responsive = useResponsive();
  const [selectedUserFlowRunUuid, setSelectedUserFlowRunUuid] = useState<string | null>(null);

  const documentFlowRunDetailsDrawer = useModal({name: 'Document Flow Run Details Drawer'});

  return (
    <Container responsive={responsive}>
      {!responsive.desktop && <Line />}
      <Table
        columnDefinitions={getColumnDefinitions({
          responsive,
        })}
        items={userFlowRuns}
        itemKey={'id'}
        emptyStateText={'No document flow runs to display'}
        onRowPress={(userFlowRun: UserFlowRunModel) => {
          setSelectedUserFlowRunUuid(userFlowRun.uuid);
          documentFlowRunDetailsDrawer.handleOpen();
        }}
      />
      {!responsive.desktop && <Line />}
      <Space height={24} />
      <PaginationBar pagination={pagination} />
      {selectedUserFlowRunUuid && (
        <DocumentFlowRunDetailsDrawer
          key={documentFlowRunDetailsDrawer.key}
          userFlowRunUuid={selectedUserFlowRunUuid}
          isOpen={documentFlowRunDetailsDrawer.isOpen}
          handleClose={documentFlowRunDetailsDrawer.handleClose}
        />
      )}
    </Container>
  );
};

DocumentsSettingsDocumentFlowRunsPageContent.fragment = gql`
  ${Project.getName.fragment}
  ${UserFlowRun.getLink.fragment}
  ${UserFlowRun.getStepsProgressDotColor.fragment}
  ${UserFlowRun.getStepsProgressText.fragment}

  fragment DocumentsSettingsDocumentFlowRunsPageContent on UserFlowRun {
    id
    uuid
    status
    project {
      id
      uuid
      ...Project_getName
    }
    completedAt
    isExpired
    createdAt
    createdBy {
      id
      fullName
    }
    updatedAt
    updatedBy {
      id
      fullName
    }
    ...UserFlowRun_getLink
    ...UserFlowRun_getStepsProgressDotColor
    ...UserFlowRun_getStepsProgressText
  }
`;

export default DocumentsSettingsDocumentFlowRunsPageContent;
