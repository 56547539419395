// Libraries
import React from 'react';

// Supermove
import {Icon, MapType, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  RefType,
  useDrawer,
  useModal,
  useNavigationDOM,
  usePopover,
  useToast,
} from '@supermove/hooks';
import {Job, JobModel, LocationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import IconButton from '@shared/design/components/IconButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Toast from '@shared/design/components/Toast';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import JobLocationForm from '@shared/modules/Job/forms/JobLocationForm';
import useDeleteJobLocationMutation from '@shared/modules/Job/hooks/useDeleteJobLocationMutation';
import Line from 'modules/App/components/Line';
import EditJobStopsDrawer from 'modules/Project/V2/Show/Blocks/Job/components/EditJobStopsDrawer';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import LocationActionsPopover from 'modules/Project/V2/Show/Blocks/components/LocationActionsPopover';
import LocationsListItem from 'modules/Project/V2/Show/Blocks/components/LocationsListItem';

const Column = Styled.View`
`;

const ActivityIndicator = Styled.Loading`
  height: 12px;
`;

const SkeletonComponent = () => {
  return (
    <Column>
      <LocationsListItem.SkeletonComponent />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <LocationsListItem.SkeletonComponent />
    </Column>
  );
};

const RemoveJobStopModal = ({
  isOpen,
  handleClose,
  handleDelete,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}) => {
  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Remove stop?'}
      subtitle={'This will remove the stop from the job.'}
      handleClose={handleClose}
      handleDelete={handleDelete}
      deleteButtonText={'Remove'}
    />
  );
};

const JobLocationActions = ({
  job,
  location,
  index,
  refetch,
}: {
  job: JobModel;
  location: LocationModel;
  index: number;
  refetch: () => void;
}) => {
  const {navigator, params} = useNavigationDOM();
  const editJobStopsDrawer = useDrawer({name: 'Edit Job Stops Drawer'});
  const locationActionsPopover = usePopover();
  const removeJobStopModal = useModal({name: 'Remove Job Stop Modal', enableTracking: true});
  const deleteSuccessToast = useToast({
    ToastComponent: Toast,
    message: 'Stop removed.',
  });

  const deleteFailToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Unable to remove stop.',
  });

  const jobLocationForm = JobLocationForm.delete({
    jobId: job.id,
    locationId: location.id,
  });

  const deleteJobLocationMutation = useDeleteJobLocationMutation({
    jobLocationForm,
    onSuccess: () => {
      refetch();
      deleteSuccessToast.handleToast();
    },
    onError: (errors: any) => {
      console.log({errors});
      deleteFailToast.handleToast();
    },
  });

  return (
    <React.Fragment>
      {deleteJobLocationMutation.submitting ? (
        <ActivityIndicator color={colors.gray.tertiary} />
      ) : (
        <LocationActionsPopover
          popover={locationActionsPopover}
          handleEdit={editJobStopsDrawer.handleOpen}
          handleDelete={removeJobStopModal.handleOpen}
          isStop
          isDeletable={job.locations.length > 1}
          location={location}
        >
          <JobActionDisabledTooltip job={job}>
            <IconButton
              source={Icon.EllipsisV}
              onPress={locationActionsPopover.handleToggle}
              isSecondary
              isDisabled={job.isFinal || job.isCancelled}
            />
          </JobActionDisabledTooltip>
        </LocationActionsPopover>
      )}
      <RemoveJobStopModal
        isOpen={removeJobStopModal.isOpen}
        handleClose={removeJobStopModal.handleClose}
        handleDelete={() => {
          deleteJobLocationMutation.handleSubmit();
          removeJobStopModal.handleClose();
        }}
      />
      {!job.isCancelled && (
        <EditJobStopsDrawer
          key={editJobStopsDrawer.key}
          isOpen={editJobStopsDrawer.isOpen}
          handleClose={editJobStopsDrawer.handleClose}
          job={job}
          scrollToIndex={index}
          refetch={refetch}
        />
      )}
    </React.Fragment>
  );
};

const JobLocationsList = ({
  job,
  mapRef,
  locationIdsWithStreetView,
  refetch,
}: {
  job: JobModel;
  mapRef: RefType<MapType>;
  locationIdsWithStreetView: (number | string)[];
  refetch: () => void;
}) => {
  return (
    <Column>
      {job.warehouseLocation &&
        job.organization.features.isEnabledCreateStorageMultipleWarehouse && (
          <React.Fragment>
            <LocationsListItem.WarehouseLocationListItem
              location={job.warehouseLocation}
              mapRef={mapRef}
              hasStreetView={locationIdsWithStreetView.includes(job.warehouseLocation.id)}
              LocationActionsComponent={JobLocationActions}
              locationActionsComponentProps={{
                job,
                location: job.warehouseLocation,
                index: -1,
                refetch,
              }}
            />
            <Space height={20} />
            <Line />
            <Space height={16} />
          </React.Fragment>
        )}
      {job.locations.map((location: LocationModel, index: number) => {
        return (
          <React.Fragment key={location.id}>
            {index > 0 && (
              <React.Fragment>
                <Space height={16} />
                <Line />
                <Space height={16} />
              </React.Fragment>
            )}
            <LocationsListItem
              location={location}
              totalLocations={job.locations.length}
              index={index}
              mapRef={mapRef}
              hasStreetView={locationIdsWithStreetView.includes(location.id)}
              LocationActionsComponent={JobLocationActions}
              locationActionsComponentProps={{
                job,
                location,
                index,
                refetch,
              }}
              isStops
            />
          </React.Fragment>
        );
      })}
    </Column>
  );
};

JobLocationsList.SkeletonComponent = SkeletonComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocationsList.fragment = gql`
  ${LocationActionsPopover.fragment}
  ${LocationsListItem.fragment}
  ${Job.goToEditProjectJobs.fragment}
  ${JobActionDisabledTooltip.fragment}
  ${EditJobStopsDrawer.fragment}

  fragment JobLocationsList on Job {
    id
    isCancelled
    uuid
    warehouseLocation {
      id
      index
      name
      ...LocationActionsPopover
      ...LocationsListItem
    }
    organization {
      id
      features {
        isEnabledCreateStorageMultipleWarehouse: isEnabled(
          feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
        )
      }
    }
    locations {
      id
      index
      ...LocationActionsPopover
      ...LocationsListItem
    }
    ...Job_goToEditProjectJobs
    ...JobActionDisabledTooltip
    ...EditJobStopsDrawer
  }
`;

export default JobLocationsList;
