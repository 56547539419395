// Libraries
import React from 'react';

// App
import {RichTextInput, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight} from '@supermove/styles';

// Components
import {AttachmentItem} from 'modules/App/Email/components';
import {Field, FieldValue} from 'modules/App/components';

import PreviewReportMoveInvoiceModal from './PreviewReportMoveInvoiceModal';

const Container = Styled.View`
`;

const Section = Styled.View`
  margin-top: 30px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const Row = Styled.View`
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const Title = Styled.H6`
  ${fontWeight(700)}
`;

const SendInvoiceReportMoveFields = ({form, job}: any) => {
  const responsive = useResponsive();
  const {primaryContact} = job.project.billingClient.primaryContact.email
    ? job.project.billingClient
    : job.project.client;

  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={0}>
        <Row
          style={{
            flexDirection: 'row',
            marginBottom: 10,
          }}
        >
          <Title>Attachments</Title>
        </Row>
        <Row>
          <PreviewReportMoveInvoiceModal
            job={job}
            trigger={({handleOpen}: any) => (
              <AttachmentItem name={'Move invoice'} onPreview={handleOpen} />
            )}
          />
        </Row>
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={1}>
        <Title>Email draft</Title>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <RowSpace {...responsive} />
        <Row>
          <FieldValue label={'To'} value={`${primaryContact.fullName} <${primaryContact.email}>`} />
        </Row>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <RowSpace {...responsive} />
        <Row>
          <Field
            {...form}
            name={'sendEmailForJobForm.subject'}
            label={'Subject'}
            input={{
              placeholder: 'Enter subject',
              style: {
                width: '100%',
                marginTop: 5,
              },
            }}
          />
        </Row>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <RowSpace {...responsive} />
        <Row>
          <Field
            {...form}
            name={'sendEmailForJobForm.body'}
            label={'Body'}
            component={RichTextInput}
            input={{
              placeholder: 'Enter body',
              setFieldValue: form.setFieldValue,
              style: {
                marginTop: 5,
              },
              editorStyle: {
                height: 360,
                paddingVertical: 9,
              },
            }}
          />
        </Row>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendInvoiceReportMoveFields.fragment = gql`
  ${PreviewReportMoveInvoiceModal.fragment}

  fragment SendInvoiceReportMoveFields on Job {
    id
    project {
      id
      client {
        id
        primaryContact {
          id
          fullName
          email
        }
      }
      billingClient {
        id
        primaryContact {
          id
          fullName
          email
        }
      }
    }
    ...PreviewReportMoveInvoiceModal
  }
`;

export default SendInvoiceReportMoveFields;
