// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive, useModal, useQuery} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import UserRole from '@shared/modules/User/enums/UserRole';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BulkUploadStorageProjectAndCustomerModal from 'modules/Storage/components/BulkUploadStorageProjectAndCustomerModal';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : 'fit-content')};
  padding-horizontal: ${(props) => ((props as any).mobile ? 12 : 24)}px;
  padding-bottom: 80px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionTitle = Styled.Text`
  ${Typography.Heading5}
`;

const SectionSubtitle = Styled.Text`
  ${Typography.Body2}
`;

const ProjectTypes = ({sectionIndex, navigator}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>Project Types</SectionTitle>
    <Space height={8} />
    <SectionSubtitle>View and edit your storage project types.</SectionSubtitle>
    <Space height={16} />
    <Button
      iconLeft={Icon.BoxOpen}
      text={'Manage Storage Project Types'}
      onPress={() => navigator.push('/settings/storage/project-types')}
    />
  </Section>
);

const WarehouseAndContainerTypes = ({sectionIndex, navigator}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Warehouses & Container Types'}</SectionTitle>
    <Space height={8} />
    <SectionSubtitle>View and edit your warehouses and container types.</SectionSubtitle>
    <Space height={16} />
    <Button
      iconLeft={Icon.Cog}
      text={'Go to Warehouses and Container Types'}
      onPress={() => navigator.push('/settings/storage/warehouses')}
    />
  </Section>
);

const Warehouse = ({sectionIndex, navigator}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Warehouses'}</SectionTitle>
    <Space height={8} />
    <SectionSubtitle>View and edit your warehouses.</SectionSubtitle>
    <Space height={16} />
    <Button
      iconLeft={Icon.Cog}
      text={'Manage Warehouses'}
      onPress={() => navigator.push('/settings/storage/warehouses')}
    />
  </Section>
);

const ContainerTypes = ({sectionIndex, navigator}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section sectionIndex={sectionIndex}>
    <SectionTitle>{'Container Types'}</SectionTitle>
    <Space height={8} />
    <SectionSubtitle>View and edit your container types.</SectionSubtitle>
    <Space height={16} />
    <Button
      iconLeft={Icon.Cog}
      text={'Manage Container Types'}
      onPress={() => navigator.push('/settings/storage/container-types')}
    />
  </Section>
);

const BulkCustomerUpload = ({sectionIndex}: any) => {
  const bulkUploadStorageProjectAndCustomerModal = useModal({
    name: 'Bulk Upload Storage Project and Customer Modal',
    enableTracking: true,
  });

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section sectionIndex={sectionIndex}>
      <SectionTitle>{'Import Existing Data'}</SectionTitle>
      <Space height={8} />
      <SectionSubtitle>Import existing storage projects and customers via CSV.</SectionSubtitle>
      <Space height={16} />
      <Button
        iconLeft={Icon.Upload}
        text={'Import Data via CSV'}
        onPress={bulkUploadStorageProjectAndCustomerModal.handleOpen}
      />
      <BulkUploadStorageProjectAndCustomerModal
        key={`BULK_UPLOAD_PROJECTS_AND_CUSTOMERS_MODAL-${bulkUploadStorageProjectAndCustomerModal.isOpen}`}
        isOpen={bulkUploadStorageProjectAndCustomerModal.isOpen}
        handleClose={bulkUploadStorageProjectAndCustomerModal.handleClose}
      />
    </Section>
  );
};

const StorageSettingsPage = () => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Storage'},
  ];

  const {loading, data} = useQuery(StorageSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'storage'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'settings'}>
      <Wrapper>
        <StandardOfficeHeader title={''}>
          <Breadcrumbs breadcrumbs={breadcrumbs} mobileOptions={{showPreviousScreenOnly: true}} />
        </StandardOfficeHeader>
        <ScrollView style={{flex: 1}}>
          <Container {...responsive}>
            <Space height={32} />
            <ProjectTypes sectionIndex={0} navigator={navigator} />
            <Space height={32} />
            {data.viewer.viewingOrganization.features.isEnabledMultipleWarehouse ? (
              <React.Fragment>
                <Warehouse sectionIndex={1} navigator={navigator} />
                <Space height={32} />
                <ContainerTypes sectionIndex={1} navigator={navigator} />
              </React.Fragment>
            ) : (
              <WarehouseAndContainerTypes sectionIndex={1} navigator={navigator} />
            )}
            <Space height={32} />
            {data.viewer.role === UserRole.SUPER_ADMIN && <BulkCustomerUpload sectionIndex={2} />}
          </Container>
        </ScrollView>
      </Wrapper>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageSettingsPage.query = gql`
  query StorageSettingsPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        features {
          isEnabledMultipleWarehouse: isEnabled(feature: "MULTIPLE_WAREHOUSE")
        }
      }
    }
  }
`;

export default StorageSettingsPage;
