// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {RefType, useResponsive, useState, useMemo} from '@supermove/hooks';
import {CommunicationModel, UserModel, Communication} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import CommunicationsListItem from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsListItem';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Text = Styled.Text<{color: string}>`
  ${Typography.Responsive.Body}
  color: ${({color}) => color};
`;

const DashedLine = Styled.View`
  flex: 1;
  border-bottom-width: 1px;
  border-color: ${colors.gray.secondary};
  border-style: dashed;
`;

const DateHeader = ({displayDate}: {displayDate: string}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Space height={8} />
      <Row>
        <DashedLine />
        <Space width={8} />
        <Text responsive={responsive} color={colors.gray.secondary}>
          {displayDate}
        </Text>
        <Space width={8} />
        <DashedLine />
      </Row>
      <Space height={8} />
    </React.Fragment>
  );
};

const INITIAL_ITEM_COUNT = 50;

const CommunicationsList = ({
  conversations,
  customerId,
  conversationsScrollView,
  viewer,
}: {
  conversations: CommunicationModel[];
  customerId: string;
  conversationsScrollView: {ref: RefType<typeof FlatList<CommunicationModel>>};
  viewer: UserModel;
}) => {
  const [filteredMessageCount, setFilteredMessageCount] = useState(INITIAL_ITEM_COUNT);
  const orderedConversations = useMemo(
    () => _.orderBy(conversations, 'createdAt', 'desc').slice(0, filteredMessageCount),
    [conversations, filteredMessageCount],
  );

  // Pre-compute showDate for each conversation
  const showDates = useMemo(() => {
    return orderedConversations.map((conversation, index) => {
      const nextConversation = orderedConversations.at(index + 1);
      const nextDisplayDate = nextConversation
        ? Communication.getDisplayDate(nextConversation)
        : undefined;
      const currentDisplayDate = Communication.getDisplayDate(conversation);
      return {
        showDate: currentDisplayDate !== nextDisplayDate,
        displayDate: currentDisplayDate,
      };
    });
  }, [orderedConversations]);

  const flatListStyle = {
    paddingHorizontal: 16,
    flexDirection: 'column-reverse',
    backgroundColor: colors.gray.background,
  } as const;
  const flatListContentStyle = {flexDirection: 'column-reverse'} as const;

  return (
    <FlatList
      listKey={'communications-list'}
      ref={conversationsScrollView.ref}
      // This way the most recent message gets sorted as first and then gets rendered at the bottom
      data={orderedConversations}
      keyExtractor={(conversation) => conversation.id}
      // We use column-reverse so that the bottom of the list renders first. This way rather than having to scroll to the bottom, we automatically start at the bottom of the list.
      style={flatListStyle}
      contentContainerStyle={flatListContentStyle}
      initialNumToRender={filteredMessageCount}
      renderItem={({item: communication, index}) => {
        const {showDate, displayDate} = showDates[index];

        return (
          <React.Fragment>
            {showDate && displayDate && <DateHeader displayDate={displayDate} />}
            <CommunicationsListItem
              communication={communication}
              customerId={customerId}
              viewer={viewer}
            />
          </React.Fragment>
        );
      }}
      ListFooterComponent={
        <React.Fragment>
          {filteredMessageCount < conversations.length && (
            <Row style={{justifyContent: 'center'}}>
              <TertiaryButton
                text={'Load more'}
                isResponsive
                onPress={() => setFilteredMessageCount(filteredMessageCount + 25)}
              />
            </Row>
          )}
        </React.Fragment>
      }
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationsList.fragment = gql`
  ${CommunicationsListItem.fragment}
  ${Communication.getDisplayDate.fragment}

  fragment CommunicationsList_Viewer on User {
    id
    ...CommunicationsListItem_Viewer
  }

  fragment CommunicationsList_Communication on Communication {
    id
    createdAt
    ...Communication_getDisplayDate
    ...CommunicationsListItem_Communication
  }
`;

export default CommunicationsList;
