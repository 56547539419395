// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CurrencyCode from '@shared/modules/Integration/enums/CurrencyCode';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder = 'Select default item', value}: any) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const AccountingIntegrationCurrencyPanelEdit = ({form, codatIntegration}: any) => {
  const isMulticurrency = _.get(form.values, 'codatIntegrationForm.isMulticurrency');
  return (
    <React.Fragment>
      <Row>
        <Column>
          <EditPanel.LabelText>{'Enable Multi-Currency'}</EditPanel.LabelText>
          <Space height={8} />
          <SwitchField
            form={form}
            field={'codatIntegrationForm.isMulticurrency'}
            onChangeValue={(value) => {
              if (!value) {
                form.setFieldValue('codatIntegrationForm.isMulticurrency', false);
                form.setFieldValue(
                  'codatIntegrationForm.defaultExternalIntegrationTaxRateId',
                  null,
                );
              }
            }}
          />
        </Column>
      </Row>
      {isMulticurrency && (
        <React.Fragment>
          <Space height={16} />
          <Row>
            <Column>
              <FieldInput
                {...form}
                label={'Default Tax Rate'}
                name={`codatIntegrationForm.defaultExternalIntegrationTaxRateId`}
                isRequired={isMulticurrency}
                component={DropdownInput}
                input={{
                  isPortaled: true,
                  options: codatIntegration.externalIntegrationTaxRates.map((taxRate: any) => ({
                    label: taxRate.name,
                    value: taxRate.id,
                    description: taxRate.salesTotalTaxRate,
                  })),
                  placeholder: 'Choose Tax Rate',
                  setFieldValue: form.setFieldValue,
                  style: {
                    flex: 1,
                  },
                }}
                style={{
                  flex: 1,
                }}
              />
            </Column>
          </Row>
        </React.Fragment>
      )}
      <Space height={16} />
      <Row>
        <Column>
          <FieldInput
            {...form}
            label={'Currency'}
            name={`codatIntegrationForm.currencyCode`}
            isRequired
            component={DropdownInput}
            input={{
              isPortaled: true,
              options: CurrencyCode.DROPDOWN_OPTIONS,
              placeholder: 'Choose Currency',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
            style={{
              flex: 1,
            }}
          />
        </Column>
      </Row>
      <Space height={16} />
    </React.Fragment>
  );
};

const AccountingIntegrationCurrencyPanel = ({codatIntegration}: any) => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Enable Multi-Currency'}
            value={codatIntegration.isMulticurrency ? 'Yes' : 'No'}
          />
        </Column>
      </Row>
      {codatIntegration.isMulticurrency && (
        <React.Fragment>
          <Space height={8} />
          <Row>
            <Column>
              <ViewCell
                label={'Default Tax Rate'}
                value={`${codatIntegration.defaultExternalIntegrationTaxRate?.name} ${codatIntegration.defaultExternalIntegrationTaxRate?.salesTotalTaxRate}`}
              />
            </Column>
          </Row>
        </React.Fragment>
      )}
      <Space height={8} />
      <Row>
        <Column>
          <ViewCell label={'Currency'} value={codatIntegration.currencyCode} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

AccountingIntegrationCurrencyPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
      <Space height={16} />
      <Row>
        <Column>
          <SkeletonLoader height={24} width={250} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

AccountingIntegrationCurrencyPanel.fragment = gql`
  fragment AccountingIntegrationCurrencyPanel on CodatIntegration {
    id
    isMulticurrency
    currencyCode
    defaultExternalIntegrationTaxRate {
      id
      name
      salesTotalTaxRate
    }
    externalIntegrationTaxRates {
      id
      name
      salesTotalTaxRate
    }
  }
`;

AccountingIntegrationCurrencyPanel.Edit = AccountingIntegrationCurrencyPanelEdit;

export default AccountingIntegrationCurrencyPanel;
