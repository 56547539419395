// Library
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Communication, CommunicationModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  align-self: baseline;
`;

const CommunicationStatusText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const CommunicationItemStatus = ({
  responsive,
  communication,
}: {
  responsive: ResponsiveType;
  communication: CommunicationModel;
}) => {
  const {icon, color, backgroundColor, text} = Communication.getStatusInfo(communication);
  return (
    <Container style={{backgroundColor}}>
      <Icon source={icon} color={color} />
      <Space width={4} />
      <CommunicationStatusText responsive={responsive} style={{color}}>
        {text}
      </CommunicationStatusText>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationItemStatus.fragment = gql`
  ${Communication.getStatusInfo.fragment}
  fragment CommunicationItemStatus on Communication {
    id
    ...Communication_getStatusInfo
  }
`;

export default CommunicationItemStatus;
